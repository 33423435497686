import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip/Tooltip';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { Link } from 'react-router-dom';
import { API, ShareAPI } from '../../API';
import { ReactComponent as ChevronDown } from '../../pages/NarrativePage/assets/chevron-down.svg';
import { ReactComponent as ShareThreatIcon } from '../../assets/shareThreat.svg';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { SummaryPanel } from '../SummaryPanel/SummaryPanel';
import { SentimentBar } from '../SentimentBar/SentimentBar';
import { TrendChart } from '../TrendChart/TrendChart';
import { ActorsTypes } from '../ActorsTypesWidget/ActorsTypes';
import { TopActorsWidget } from '../TopActorsWidget/TopActorsWidget';
import { sentimentData } from './sentiment';
import { trendData } from './evolution';
import { OpinionCard } from '../OpinionCard/OpinionCard';

import styles from './StoryContent.module.scss';
import { EvolutionChart } from '../EvolutionChart/EvolutionChart';

const opinionsData = {
  "1" : {
    "objects": [
      {
        "id": 1,
        "title": "McDonald's confirms Vice President Harris's claim of past employment is unfounded",
        "rating": 100,
        "sources": [
          {
            "id": 1,
            "name": "Source 1",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 2,
            "name": "Source 2",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 3,
            "name": "Source 3",
            "avatar": "https://via.placeholder.com/150",
          },
        ],
        "sentiment": {
          "sentiment": "positive",
          "reactions_analyzed": 66766,
          "comments_analyzed": 1269
        }
      },
      {
        "id": 2,
        "title": "Allegations of misinformation regarding Kamala Harris's McDonald's employment circulate",
        "rating": 90,
        "sources": [
          {
            "id": 1,
            "name": "Source 1",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 2,
            "name": "Source 2",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 3,
            "name": "Source 3",
            "avatar": "https://via.placeholder.com/150",
          },
        ],
        "sentiment": {
          "sentiment": "negative",
          "reactions_analyzed": 66766,
          "comments_analyzed": 1269
        }
      },
      {
        "id": 3,
        "title": "McDonald's celebrated Ukrainian Independence Day with a special burger giveaway",
        "rating": 80,
        "sources": [
          {
            "id": 1,
            "name": "Source 1",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 2,
            "name": "Source 2",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 3,
            "name": "Source 3",
            "avatar": "https://via.placeholder.com/150",
          },
        ],
        "sentiment": {
          "sentiment": "neutral",
          "reactions_analyzed": 66766,
          "comments_analyzed": 1269
        }
      },
      {
        "id": 4,
        "title": "Calls for a McDonald's boycott emerge over allegations of association with child harm",
        "rating": 70,
        "sources": [
          {
            "id": 1,
            "name": "Source 1",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 2,
            "name": "Source 2",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 3,
            "name": "Source 3",
            "avatar": "https://via.placeholder.com/150",
          },
        ],
        "sentiment": {
          "sentiment": "positive",
          "reactions_analyzed": 66766,
          "comments_analyzed": 1269
        }
      },
      {
        "id": 5,
        "title": "A criminal case saw a sentencing delay request due to the defendant's employment at McDonald's",
        "rating": 60,
        "sources": [
          {
            "id": 1,
            "name": "Source 1",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 2,
            "name": "Source 2",
            "avatar": "https://via.placeholder.com/150",
          },
          {
            "id": 3,
            "name": "Source 3",
            "avatar": "https://via.placeholder.com/150",
          },
        ],
        "sentiment": {
          "sentiment": "negative",
          "reactions_analyzed": 66766,
          "comments_analyzed": 1269
        }
      }

    ]
  }
}


export const StoryContent = ({ story, isShare }) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);


  const [statsAggregationOptions] = useState(() => [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ]);
  const [statsBreakdownOptions] = useState(() => [
    { value: null, label: t('All') },
    { value: 'PLATFORM', label: t('Platform') },
    { value: 'COUNTRY', label: t('Country') },
    { value: 'ACTOR_GROUP', label: t('Actor group') },
  ]);

  const [viewOptions] = useState(() => [
    {value: 'STANDARD', name: t('Standard')},
    {value: 'VALUES', name: t('Values')},
    {value: 'POINTS', name: t('Points')}
  ])

  const api = isShare ? ShareAPI : API;
  const [totalEvolutionChart, setTotalEvolutionChart] = useState('publications');
  const [storyShares, setStoryShares] = useState(trendData[story?.id]);

  useEffect(() => {
    setStoryShares(trendData[story?.id]);
  }, [story])

  const [statsAggregation, setStatsAggregation] = useState(
    () => statsAggregationOptions[0],
  );

  const [statsBreakdown, setStatsBreakdown] = useState(
    () => statsBreakdownOptions[0],
  );

  const [chartViewOptions, setChartViewOptions] = useState(() => viewOptions[0]);

  const fetchStoryShares = (story, statsAggregation, statsBreakdown) => {
    // const urlParams = new URLSearchParams();
    // urlParams.append('story_id', story.id)

    // urlParams.set('aggregation', statsAggregation);

    // let url = `/API/v1/stats/narratives/shares?${urlParams.toString()}`;

    // if (statsBreakdown !== null) {
    //   urlParams.set('breakdown', statsBreakdown);
    //   url = `/API/v1/stats/narratives/${story.id}/shares?${urlParams.toString()}`;
    // }

    // api.fetch('GET', url)
    // .then((data) => {
    //   setStoryShares(data);
    // });
  }

  useEffect(() => {
    fetchStoryShares(story, statsAggregation.value, statsBreakdown.value)
  }, [statsAggregation, statsBreakdown])



  const handleCreateCase = () => {
    console.log('Creating case...');
  };


  return (
    <div className={styles.storyContentContainer}>
      <div className={styles.controlsPanel}>
        <Tooltip content={t('Save as case')} position="bottom">
          <button
            type="button"
            className="new-button"
            onClick={() => {
              handleCreateCase();
            }}
          >
            <ShareThreatIcon />
          </button>
        </Tooltip>
        <DropdownMenu
          isOpen={isExportMenuOpen}
          header={t('Format')}
          onClick={() => setIsExportMenuOpen(!isExportMenuOpen)}
          buttonName={t('Export')}
          icon={<ChevronDown />}
          menuClassName="export"
        >
          <ul>
            <li
              type="li"
              className="new-li"
              onClick={() => {
                setIsExportMenuOpen(false);
                // handleExport('csv', narrative.id, sortingQuery, searchQuery);
                // handleAsyncExport('csv', story.id);
              }}
            >
              {t('CSV')}
            </li>
            <li
              type="li"
              className="new-li"
              onClick={() => {
                setIsExportMenuOpen(false);
                // handleExport('json', narrative.id, sortingQuery, searchQuery);
                // handleAsyncExport('jsonl', story.id);
              }}
            >
              {t('JSON')}
            </li>
            <li
              type="li"
              className="new-li"
              onClick={() => {
                setIsExportMenuOpen(false);
                // handleExport('xml', narrative.id, sortingQuery, searchQuery);
                // handleAsyncExport('xml', story.id);
              }}
            >
              {t('XML')}
            </li>
            <li
              type="li"
              className="new-li"
              onClick={() => {
                setIsExportMenuOpen(false);
                // handleExport('docx', story.id, sortingQuery, searchQuery);
              }}
            >
              {t('DOCX')}
            </li>
          </ul>
        </DropdownMenu>
      </div>

      <div className={styles.storyDescriptionContainer}>
        <h3 className={styles.storyTitle}>{story?.title}</h3>
        <div className={styles.storyContent}>{story?.content}</div>
      </div>

      <div className='report-section'>
        <h3>{t('Summary')}</h3>
        <SummaryPanel narrativeIds={[story?.id]} isShare={isShare} isStory={true}/>
      </div>

      
      <SentimentBar
        data={sentimentData[story?.id]}
        entity={story}
        entityType={'story'}
        tabList={['posts','comments', 'likes']}
      />

      <div className='report-section'>
        <h3>{t('Top opinions')}</h3>
        {opinionsData[1].objects.map((opinion, index) => (
          <OpinionCard key={index} opinion={opinion} />
        ))}
      </div>

      <TopActorsWidget story={story} isShare={isShare}/>

      <ActorsTypes story={story} />

      <EvolutionChart
        data={storyShares}
        narrative={story}
        showMessageManipulationIndex={false}
        chartTab={totalEvolutionChart}
        agregation={statsAggregation}
        breakdown={statsBreakdown}
        view={chartViewOptions}
        onChange={(tab, agregation, breakdown, view) => {
          setTotalEvolutionChart(tab);
          setStatsAggregation(agregation);
          setStatsBreakdown(breakdown);
          setChartViewOptions(view);
        }}
      />

    </div>
  );
};
