import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import {ReactComponent as AnchorIcon} from '../../assets/anchor.svg';
import {ReactComponent as RatingIcon} from '../../assets/rating.svg';
import {ReactComponent as PositiveIcon} from '../../assets/positiveSentiment.svg';
import {ReactComponent as NegativeIcon} from '../../assets/negativeSentiment.svg';
import {ReactComponent as NeutralIcon} from '../../assets/neutralSentiment.svg';
import { da, enGB, uk} from "date-fns/locale";
import { AvatarGroup } from '../AvatarGroup/AvatarGroup';
import 'react-tooltip/dist/react-tooltip.css'

import { Tooltip } from 'react-tooltip';


import styles from './StoryCard.module.scss';


const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

export const StoryCard = ({ story, isActive=false, isStory=true, onChange}) => {
  const { t } = useTranslation();
  let dateLocale = enGB;
  let dateFormat = 'LLLL dd, yyyy';
  // let dateFormat = 'dd LLLL yyyy';
  if(
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
    dateLocale = uk;
    dateFormat = 'dd LLL yyyy';

  } else {
    dateLocale = enGB;
  }

  const sentimentMap = {
  'positive': {text: t('Positive'), className: styles.positive, icon: <PositiveIcon/>},
  'negative': {text: t('Negative'), className: styles.negative, icon: <NegativeIcon/>},
  'neutral': {text: t('Neutral'), className: styles.neutral, icon: <NeutralIcon/>},
  }


  return (
    <div
      className={
        isActive
          ? `${styles.storyCardContainer} ${styles.active}`
          : styles.storyCardContainer
      }
      onClick={() => onChange(story)}
    >
     {isStory ? <div className={styles.storyCardDate}>
        <div className={styles.bullet}>
          <div className={styles.bulletIcon}></div>
        </div>
        {format(parseISO(story?.publication_date + 'Z'), dateFormat, { locale: dateLocale })}
      </div> : ''}
      <div className={styles.storyCardHeader}>{story?.title}</div>
      <div className={styles.storyCardInfo}>
        <div className={styles.storyCardRatingContainer}>
          <div className={styles.storyCardRating}>
            <div className={styles.storyCardRatingIcon}>
              <RatingIcon />
            </div>
            <div className={styles.storyCardRatingValue}>{story?.rating}</div>
          </div>
          <div className={styles.storyCardHint}>
            <div className={styles.storyCardHintText}>{t('Rating')}</div>
            <div
              className={styles.storyCardHintIcon}
              data-tooltip-id="rating-tooltip"
              data-tooltip-content={'Based on the number of reactions and comments'}
              data-tooltip-place='bottom-start'
            >
              <AnchorIcon />
              <Tooltip
                id="rating-tooltip"
                style={{ width: '200px'}}
              />
              
            </div>
          </div>
        </div>
        <div className={styles.storyCardSourcesContainer}>
          <AvatarGroup sources={story?.sources} />
          <div className={styles.storyCardHint}>
            <div className={styles.storyCardHintText}>{t('Actors')}</div>
            <div
              className={styles.storyCardHintIcon}
              data-tooltip-id="actors-tooltip"
              data-tooltip-content={'Top actors based on the number of reactions and comments'}
              data-tooltip-place='bottom'
            >
              <AnchorIcon />
              <Tooltip
                id="actors-tooltip"
                style={{ width: '200px'}}
              />
            </div>
          </div>
        </div>
        {isStory ? <div className={styles.storyCardOpinionBalanceContainer}>
          <div className={styles.storyCardOpinionBalance}>
            <div className={styles.storyCardOpinionBalancePositive}>{story?.opinion_balance.positive_share}%</div>
            <div className={styles.storyCardOpinionBalanceNegative}>{story?.opinion_balance.negative_share}%</div>
          </div>
          <div className={styles.storyCardHint}>
            <div className={styles.storyCardHintText}>{t('Opinion balance')}</div>
            <div
              className={styles.storyCardHintIcon}
              data-tooltip-id="opinion-balance-tooltip"
              data-tooltip-content={`Based on ${formatNumberSignificant(story?.opinion_balance.reactions_analyzed)} reactions and ${formatNumberSignificant(story?.opinion_balance.comments_analyzed)} comments`}
              data-tooltip-place='bottom-end'
            >
              <AnchorIcon />
              <Tooltip
                id="opinion-balance-tooltip"
                style={{ width: '250px'}}
              />
            </div>
          </div>
        </div> : (
          <div className={styles.storyCardSentimentContainer}>
            <div className={`${styles.storyCardSentiment} ${sentimentMap[story.sentiment.sentiment].className}`}>
              {sentimentMap[story.sentiment.sentiment].icon}
              {/* <div className={styles.storyCardSentimentText}>{sentimentMap[story.sentiment.sentiment].text}</div> */}
            </div>
            <div className={styles.storyCardHint}>
              <div className={styles.storyCardHintText}>{t('Sentiment')}</div>
              <div
                className={styles.storyCardHintIcon}
                data-tooltip-id="opinion-balance-tooltip"
                data-tooltip-content={`Based on ${formatNumberSignificant(story?.sentiment.reactions_analyzed)} reactions and ${formatNumberSignificant(story?.sentiment.comments_analyzed)} comments`}
                data-tooltip-place='bottom-end'
              >
                <AnchorIcon />
                <Tooltip
                  id="opinion-balance-tooltip"
                  style={{ width: '250px'}}
                />
                
              </div>
            </div>

          </div>
          
        )}
      </div>
    </div>
  );
};
