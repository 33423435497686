import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { API, ShareAPI } from "../../API";
import { useTranslation } from "react-i18next";
import { LoaderSmall } from "../LoaderSmall/LoaderSmall";

import styles from './ActorsTypes.module.scss';

const platformsData = {
  "1": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 618 },
      { "bucket": "TELEGRAM", "count": 13 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "2": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 105 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "3": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 0 },
      { "bucket": "TELEGRAM", "count": 65 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "4": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 74 },
      { "bucket": "TELEGRAM", "count": 13 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "5": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 56 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "6": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 61 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "7": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 52 },
      { "bucket": "TELEGRAM", "count": 4 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 2 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "8": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 28 },
      { "bucket": "TELEGRAM", "count": 23 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "9": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 43 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "10": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 12 },
      { "bucket": "TELEGRAM", "count": 28 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "11": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 13 },
      { "bucket": "TELEGRAM", "count": 22 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  },
  "12": {
    "objects": [
      { "bucket": "FACEBOOK", "count": 0 },
      { "bucket": "TWITTER", "count": 7 },
      { "bucket": "TELEGRAM", "count": 0 },
      { "bucket": "YOUTUBE", "count": 0 },
      { "bucket": "WEB", "count": 0 },
      { "bucket": "TIKTOK", "count": 0 },
      { "bucket": "VK", "count": 0 }
    ]
  }
}



export const PlatformsChart = ({story, isShare, isClickable=true }) => {
  const { t } = useTranslation();
  const [platforms, setPlatforms] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState(null);
  const api = isShare ? ShareAPI : API;

  const bucketNameMap = {
    'FACEBOOK': t('Facebook'),
    'TWITTER': t('Twitter'),
    'TELEGRAM': t('Telegram'),
    'YOUTUBE': t('Youtube'),
    'WEB': t('Web'),
    'TIKTOK': t('TikTok'),
    'VK': t('VK'),
  }

  const audienceSizeMap = {
    'very_small': [0, 1000],
    'small': [1000, 10000],
    'medium': [10000, 100000],
    'big': [100000, 500000],
    'very_big': [500000, 1000000000]
  }

  const getPlatforms = () => {
    // api.fetch('GET', `/API/v1/narratives/${narrative.id}/audience_sizes`).then(
    //   data => setAudience(data)
    // )
    setPlatforms(platformsData[story.id])
  }

  useEffect(() => {
    getPlatforms()
  }, [story])

  // const colors = ['#BEFDEE', '#7FFADD', '#45F1C9', '#1CD6AA', '#00BA8D'];
  const colors = ['#3b82f6'];

  const getChartData = (platforms) => {
    let data = [
      ['Platform', 'Number', { role: "style" }, { role: 'annotation' }]
    ];
    
    platforms.objects?.forEach((platform, index) => 
       data.push([bucketNameMap[platform.bucket], platform.count, '#3b82f6', platform.count])
    )
    
    return data;
  }

  const options = {
    chartArea: { left: '5%', top: '15%', width:'100%', height:'75%' },
    legend: 'none',
    bar: { groupWidth: "55%" },
    annotations: {alwaysOutside: true, stem: {color: 'transparent'}, textStyle: {bold: true, opacity: 1, color: '#151419'}, highContrast: false}
  }

  return (
    <>
      {platforms ? (platforms.objects?.length > 0
      ? <div className={styles.chartWrapper}>
        <Chart
          chartType="ColumnChart"
          data={getChartData(platforms)}
          width={"100%"}
          height={"400px"}
          className='barChart'
          options={options}
          chartEvents={
            isClickable 
             ? [
                {
                  eventName: "select",
                  callback({ chartWrapper }) {
                    let selected = chartWrapper.getChart().getSelection();
                    let index = selected[0]?.row;
                    let bucket = platforms.objects[index].bucket;
                    // let option = {value: 'audience', label: 'Audience',}
                    // setFilter(audienceSizeMap[bucket])
                    setIsModalOpen(true);
                  }
                }
              ]
             : []
            
          }
          style={{margin: '0px', padding: '0px'}}
          
        />
      </div> 
      : <div className={styles.noData}>{t('There is no data to display')}</div>) 
      : (<div className={styles.widdgetLoader}><LoaderSmall /></div>)}
    </>
  )
};