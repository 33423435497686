import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AudienceChart } from "./AudienceChart";
import { PlatformsChart } from "./PlatformsChart";


export const ActorsTypes = ({ story }) => {
  const { t } = useTranslation();
  const [widgetTab, setWidgetTab] = useState('audience');
  return(
    <div className="report-section">
      <h3 className="trend-chart-controlls">
        {t('Actor Types')}
        <button
          type="button"
          className={widgetTab === 'audience' ? 'active' : ''}
          onClick={() => setWidgetTab('audience')}
        >
          <span>{t('Audience')}</span>
        </button>
        <button
          type="button"
          className={widgetTab === 'platform' ? 'active' : ''}
          onClick={() => setWidgetTab('platform')}
        >
          <span>{t('Platform')}</span>
        </button>
        {/* <button
          type="button"
          className={widhetTab === 'type' ? 'active' : ''}
          onClick={() => setWidgetTab('type')}
        >
          <span>{t('Type')}</span>
        </button> */}
      </h3>
      <div className="card">
        <div className="card-body">
          {widgetTab === 'platform' ? <PlatformsChart story={story} /> : null}
          {widgetTab === 'audience' ? <AudienceChart story={story} /> : null}
        </div>
      </div>
    </div>
  )
};
