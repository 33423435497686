export const actorsPositiveData = {
  "1": { "objects": [], "page": 1, "size": 5, "total": 0 },
  "2": {
    "objects": [
      {
        "id": 46992,
        "name": "Veteran",
        "url": "https://twitter.com/Veteran97178373",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 13802.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1014000,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 72128,
        "name": "Independent US",
        "url": "https://twitter.com/IndyUSA",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 34260.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1081967,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 74603,
        "name": "Sal Vadacchino\ud83c\udde8\ud83c\udde6",
        "url": "https://twitter.com/SalVadacchino1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 4653.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1040098,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 165336,
        "name": "Steven Seegel \ud83c\uddfa\ud83c\udde6",
        "url": "https://twitter.com/steven_seegel",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 24421.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1126935,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 206430,
        "name": "Julia Fleming",
        "url": "https://twitter.com/WeAreTheSame77",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 8544.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1163784,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "3": {
    "objects": [
      {
        "id": 114854936,
        "name": "\u041f\u0430\u043b\u044f\u043d\u0438\u0446\u044f - \u0423\u043a\u0440\u0430\u0457\u043d\u0430",
        "url": "https://t.me/palyanitsyanews",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 117992.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 114959600,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 2.0,
          "name": "Ukraine",
          "code": "UA",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "4": {
    "objects": [
      {
        "id": 127537,
        "name": "Moh Musthafa Hussain",
        "url": "https://twitter.com/musthafaaa",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 4400.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1035079,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 404.0,
          "name": "Maldives",
          "code": "MV",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 176785,
        "name": "Shaikh Javed Iqbal",
        "url": "https://twitter.com/ShaikhJavedIqb1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 1167.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1138349,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2044990,
        "name": "WWG1WGA - Jeanne d'Arc",
        "url": "https://t.me/real_jeannedarc",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 391.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2063052,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 13.0,
          "name": "Germany",
          "code": "DE",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 4383209,
        "name": "Darrell Thompson",
        "url": "https://twitter.com/5yUbRVjsdZBOSrj",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 1026.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 4481197,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 82159423,
        "name": "\u00d6zcan Altunta\u015f",
        "url": "https://twitter.com/61Mollaahmet",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 14804.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 82258165,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 31.0,
          "name": "Turkey",
          "code": "TR",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "5": { "objects": [], "page": 1, "size": 5, "total": 0 },
  "6": {
    "objects": [
      {
        "id": 248361,
        "name": "Arystokrata",
        "url": "https://twitter.com/Arystokrata1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 10505.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2032116,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 25.0,
          "name": "Poland",
          "code": "PL",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "7": {
    "objects": [
      {
        "id": 64421,
        "name": "Bo Snerdley",
        "url": "https://twitter.com/BoSnerdley",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 613680.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1076423,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 77855,
        "name": "BPR",
        "url": "https://twitter.com/BIZPACReview",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 56202.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1043839,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 153189,
        "name": "NBC4 Washington",
        "url": "https://twitter.com/nbcwashington",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 361798.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1114844,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 196402,
        "name": "NBC Bay Area",
        "url": "https://twitter.com/nbcbayarea",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 424405.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1153753,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "8": { "objects": [], "page": 1, "size": 5, "total": 0 },
  "9": {
    "objects": [
      {
        "id": 3385407,
        "name": "Ali Adair \ud83d\udfe7",
        "url": "https://twitter.com/AliAdair22",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 37874.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 3484117,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 68968,
        "name": "Gerry Negron",
        "url": "https://twitter.com/NegronDoc",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 4211.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1079668,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 132285,
        "name": "The Recount",
        "url": "https://twitter.com/therecount",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 362260.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1041159,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 92168,
        "name": "Muzamana Victor",
        "url": "https://twitter.com/Muzamana_Victor",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 1299.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1097331,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 41923,
        "name": "The Washington Times",
        "url": "https://twitter.com/WashTimes",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 463367.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1026472,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "10": { "objects": [], "page": 1, "size": 5, "total": 0 },
  "11": { "objects": [], "page": 1, "size": 5, "total": 0 },
  "12": { "objects": [], "page": 1, "size": 5, "total": 0 }
}
