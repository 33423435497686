import * as d3 from 'd3';
import { useEffect, useState, useContext } from "react";
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import {platformIconsMapping, platformNamesMapping} from '../../../utils/platforms';
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { cutString } from "../../../utils/cutString";
import styles from '../NarrativePage.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export const TopActorsByPosts = ({narrative, isShare, isClickable=true, title, size=15}) => {
  const { t } = useTranslation();
  const [actors, setActors] = useState(null);

  const {openModal, setSourceFilter} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;

  const getActors = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/sources/by/posts?size=${size}`).then(
      data => setActors(data)
    )
  }

  useEffect(() => {
    getActors()
  }, [narrative])

  return(
    <BreakdownsContainer
      title={title ? title : 'Top actors by number of posts'}
      onClick={() => openModal()}
      details={false}
    >
     {actors 
      ? (actors.objects?.length > 0
      ? <div className={styles.topSourcesContainer}>
      <div className={styles.nameHeader}><h5>{t('Actor')}</h5></div>
      <div className={styles.countryHeader}><h5>{t('Country')}</h5></div>
      <div className={styles.audienceHeader}><h5>{t('Audience')}</h5></div>
      <div className={styles.messagesHeader}><h5>{t('Messages')}</h5></div>
      <div className={styles.name}>
        <div className={styles.breakdownsListMain}>
          {actors.objects.map(source => {
            return(
              <div key={`topSources_${source?.id}`} className={styles.listItem}>
                <div className={styles.info}>
                  <span>{platformIconsMapping[source?.source_type]}</span>
                  <span 
                    className={styles.title}
                    onClick={() => {
                      if (isClickable) {
                        setSourceFilter(source?.id)
                        openModal()
                      }
                    }}
                    >
                      <Tooltip content={source.name} position='right'>
                        {cutString(source.name, 35)}
                      </Tooltip>
                    </span>
                </div>
                {/* <div className={styles.count}>{source.messages_count}</div> */}
              </div>
            )
          })}
        </div>
        
      </div>
      
      <div className={styles.country}>
        <div className={styles.breakdownsListMain}>
          {actors?.objects?.map(source => {
            return(
              <div key={`topSources_country_${source?.id}`} className={styles.listItem}>
                <div className={styles.count}>{source?.origin_country ? source?.origin_country.name : ''} &nbsp; </div>
              </div>
            )
          })}
        </div>
        
      </div>

      <div className={styles.audience}>
        <div className={styles.breakdownsListMain}>
          {actors?.objects?.map(source => {
            let followers_cnt = source?.audience;

            return(
              <div key={`topSources_audience_${source?.id}`} className={styles.listItem}>
                <div className={styles.count}>{followers_cnt === null ? '' : formatNumberSignificant(followers_cnt)} &nbsp;</div>
              </div>
            )
          })}
        </div>
      </div>

      <div className={styles.messages}>
        <div className={styles.breakdownsListMain}>
            {actors?.objects?.map(source => {
              return(
                <div key={`topSources_messages_${source?.id}`} className={styles.listItem}>
                  <div className={styles.count}>{source.messages_count}</div>
                </div>
              )
            })}
          </div>
      </div>
    </div>
      : <div className={styles.noData}>{t('There is no data to display')}</div>
      ) : (<div className={styles.widdgetLoader}><LoaderSmall /></div>)}

    </BreakdownsContainer>
  )
}

