import { useState, useEffect, useCallback } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API, ShareAPI } from "../../API";
import { ampli } from "../../ampli";
import { useCurrentUser } from "../../contexts/CurrentUser";
import { Loader } from "../../components/Loader/Loader";
import { StoryContent } from "../../components/StoryContent/StoryContent";
import { StoriesFeed } from "../../components/StoriesFeed/StoriesFeed";
import { eventsData } from "./events";

import styles from './TopicReportPageNew.module.scss';
import './TopicReportPage.scss';

const reportData = {
  "1": {
    "id":1,
    "name":"McDonalds's Aug 2024",
    "description":"Analysis of messages on McDonald's in August, 2024",
    "start_date": "2024-08-01",
    "end_date": "2024-08-31",
  }
}

const opinions = {objects: [
  {
    id: 1,
    "publication_date": "2024-09-02T07:48:42.560703+00:00",
    title: `Cultural Bridges: The Artistic Exchange`,
    content: 'During his visit, Putin attended a series of cultural events that showcased the rich heritage of both countries. A highlight was the joint ballet performance titled "Harmony and Courage," which symbolized the resilience and unity of the two nations. This event not only entertained but also fostered a deeper cultural understanding between Russia and North Korea.',
    "rating": 87,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'positive', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
  {
    id: 2,
    publication_date: "2024-09-01T07:48:42.560703+00:00",
    title: `Strengthening Ties: The Bilateral Agreement`,
    content: 'A landmark moment of the visit was the signing of the "Pyongyang-Moscow Friendship Treaty," aimed at enhancing economic cooperation and mutual security. The agreement included plans for joint ventures in technology and infrastructure, marking a new era in the diplomatic relationship between Russia and North Korea.',
    "rating": 68,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'neutral', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
  {
    id: 3,
    publication_date: "2024-09-03T07:48:42.560703+00:00",
    title: `The Peace Garden Ceremony`,
    content: 'An unforgettable event was the inauguration of the Peace Garden in Pyongyang, attended by Putin and Kim Jong Un. This garden, featuring flora from both countries, was established as a living symbol of peace and friendship. The ceremony included the planting of a "Tree of Unity," with both leaders participating, symbolizing their commitment to a peaceful future.',
    "rating": 72,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'negative', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
  {
    id: 4,
    publication_date: "2024-09-01T07:48:42.560703+00:00",
    title: `Humanitarian Horizons: A New Project Launch`,
    content: `Amidst the diplomatic engagements, a significant announcement was the launch of the "Brighter Future" initiative, a joint humanitarian project aimed at providing educational resources to underprivileged areas in both countries. This initiative underscored the visit's focus on not just political, but also social and humanitarian collaboration.`,
    "rating": 85,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'positive', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
  {
    id: 5,
    publication_date: "2024-09-05T07:48:42.560703+00:00",
    title: `An Unexpected Friendship`,
    content: 'The visit took an unexpected turn when a sudden storm stranded both leaders at a remote cultural site. The incident, far from being a setback, allowed for unplanned, genuine interactions between the two. Sharing stories over a campfire, they found common ground, leading to a spontaneous agreement on cultural exchange programs, further solidifying their countries’ bonds.',
    "rating": 77,
    "sources": [
      {
        "id": 1,
        "name": "CNN",
        "url": "https://www.cnn.com/2024/09/02/politics/putin-north-korea-visit/index.html",
        "source_icon": "https://www.cnn.com/favicon.ico",
      },
      {
        "id": 2,
        "name": "BBC",
        "url": "https://www.bbc.com/news/world-asia-58324563",
        "source_icon": "https://www.bbc.com/favicon.ico",
      },
      {
        "id": 3,
        "name": "Reuters",
        "url": "https://www.reuters.com/world/asia-pacific/putin-visit-north-korea-2024-09-02/",
        "source_icon": "https://www.reuters.com/favicon.ico",
      }
    ],
    sentiment: {sentiment: 'positive', "reactions_analyzed": 16000, "comments_analyzed": 527}
  },
]}


export const TopicReportPageNew = ({isShare = false}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const { topicReportId } = useParams();
  const [searchParams] = useSearchParams();
  const [report, setReport] = useState(reportData[topicReportId]);
  const [currentStory, setCurrentStory] = useState(eventsData[topicReportId].objects[0]);
  const [currentOpininon, setCurrentOpinion] = useState(null);
  const [currentTab, setCurrentTab] = useState('stories');

  if(!report) {
    return <Loader />;
  }

  return (
    <div className={styles.root}>
      <div className="list-content">
      <div className="page-header">
        <div className="breadcrumb">
        <span>
            <Link to="/topic_reports">{t('Topic discovery')}</Link>
          </span>
          <span>{report?.name}</span>
        </div>
        <div className="controls"></div>
      </div>
      <div className="report-section">
        <div className={styles.reportHeader}>
          <h2>{report?.name}</h2>
          <p>{report?.description}</p>
        </div>
      </div>
      <div className={styles.reportContent}>
        <div className={styles.reportFeed}>
          <StoriesFeed
            report={reportData[topicReportId]}
            stories={eventsData[topicReportId].objects}
            opinions={opinions.objects}
            onStoryChange={setCurrentStory}
            onOpinionChange={setCurrentOpinion}
            onTabChange={setCurrentTab}

          />
        </div>
        <div className={styles.reportStory}>
          <StoryContent story={currentStory} isShare={isShare} />
        </div>
      </div>
    </div>
    </div>
  );
};