import {
  useParams,
} from 'react-router-dom';
import { useEffect, useState, useContext, useRef } from 'react';
import { MessagesContext } from '../contexts/MessagesContext';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader/Loader';
import styles from '../NarrativePage.module.scss';
import settingsStyles from '../Filter/Filter.module.scss';
import { DistributionTabChooser } from './PDFSettings/DistributionTabChooser';
import { ActorsTabChooser } from './PDFSettings/ActorsTabChooser';
import { Collapsible } from '../Filter/Collapsible';
import { Summary } from './Components/Summary';
import { Description } from './Components/Description';
import { OriginalSource } from './Components/OriginalSource';
import { BubbleChartIcons } from './Components/BubbleChartIcons';
import { BubbleChartStories } from './Components/BubbleChartStories';
import { EvolutionChart } from './Components/EvolutionChart';
import { SentimentBar } from './Components/SentimentBar';
import { Stories } from './Components/Stories';
import { Messages } from './Components/Messages';
import { StoriesTabChooser } from './PDFSettings/StoriesTabChooser';
import { MessagesTabChooser } from './PDFSettings/MessagesTabChooser';
import { Platforms } from '../Breakdowns/Platforms';
import { Countries } from '../Breakdowns/Countries';
import { Verification } from '../Breakdowns/Verification';
import { Audience } from '../Breakdowns/Audience';
import { Compromised } from '../Breakdowns/Compromised';
import { Bots } from '../Breakdowns/Bots';
import { StateAffiliated } from '../Breakdowns/StateAffiliated';
import { TopActorsByPosts } from '../Breakdowns/TopActorsByPosts';
import { TopTagsByPosts } from '../Breakdowns/TopTagsByPosts';
import { ActorsBreakdowns } from './Components/ActorsBreakdowns';
import { Tops } from './Components/Tops';
import { SpecificGroups } from './Components/SpecificGroups';
import { Header } from './Components/Header';


const pdfSettings = {
  "title_page": false,
  "widgets": [
    {
      "id": "description",
      "name": "Description",
      "is_active": true,
      "settings": {
        "title": null,
        "text": null,
      },
      "notes": [],
    },
    {
      "id": "summaryActors",
      "name": "Summary: Actors",
      "is_active": true,
      "settings": {
        "mode": ['actors'],
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "summaryPublications",
      "name": "Summary: Publications",
      "is_active": true,
      "settings": {
        "mode": ['publications'],
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "summaryViews",
      "name": "Summary: Views",
      "is_active": true,
      "settings": {
        "mode": ['views'],
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "summaryReactions",
      "name": "Summary: Reactions",
      "is_active": true,
      "settings": {
        "mode": ['reactions'],
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "originalSource",
      "name": "Original source",
      "is_active": true,
      "settings": {
        "title": null,
        "show_full_text": true,
        "text_length": 100,
      },
      "notes": [],
    },
    {
      "id": "bubbleChartStories",
      "name": "Messages distribution: Stories",
      "is_active": true,
      "settings": {
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "bubbleChartCountries",
      "name": "Messages distribution: Countries",
      "is_active": true,
      "settings": {
        "title": null,
        "mode": "countries",
      },
      "notes": [],
    },
    {
      "id": "bubbleChartPlatforms",
      "name": "Messages distribution: Platforms",
      "is_active": true,
      "settings": {
        "title": null,
        "mode": "platforms",
      },
      "notes": [],
    },
    {
      "id": "evolutionMessages",
      "name": "Evolution: Messages",
      "is_active": true,
      "settings": {
        "title": null,
        "agregation_period": 'DAY',
        "breakdown": null,
        "mode": "publications",
        "view": "STANDARD",
      },
      "notes": [],
    },
    {
      "id": "evolutionViews",
      "name": "Evolution: Views",
      "is_active": true,
      "settings": {
        "title": null,
        "agregation_period": 'DAY',
        "breakdown": null,
        "mode": "impressions",
        "view": "STANDARD",
      },
      "notes": [],
    },
    {
      "id": "evolutionReactions",
      "name": "Evolution: Reactions",
      "is_active": true,
      "settings": {
        "title": null,
        "agregation_period": 'DAY',
        "breakdown": null,
        "mode": "engagement",
        "view": "STANDARD",
      },
      "notes": [],
    },
    {
      "id": "evolutionManipulations",
      "name": "Evolution: Manipulations",
      "is_active": true,
      "settings": {
        "title": null,
        "agregation_period": 'DAY',
        "breakdown": null,
        "mode": "manipulation_index",
        "view": "STANDARD",
      },
      "notes": [],
    },
    {
      "id": "sentimentMessages",
      "name": "Sentiment: Messages",
      "is_active": true,
      "settings": {
        "title": null,
        "mode": "messages",
      },
      "notes": [],
    },
    {
      "id": "sentimentViews",
      "name": "Sentiment: Views",
      "is_active": true,
      "settings": {
        "title": null,
        "mode": "views",
      },
      "notes": [],
    },
    {
      "id": "sentimentReactions",
      "name": "Sentiment: Reactions",
      "is_active": true,
      "settings": {
        "title": null,
        "mode": "likes",
      },
      "notes": [],
    },
    {
      "id": "actorsBreakdowns",
      "name": "Actors breakdowns",
      "is_active": true,
      "settings": {
        "title": null,
        "show_platforms": true,
        "show_countries": true,
        "show_verification": true,
        "show_audience": true,
        "platforms_title": null,
        "countries_title": null,
        "verification_title": null,
        "audience_title": null,
        "platforms_notes": [],
        "countries_notes": [],
        "verification_notes": [],
        "audience_notes": [],
      },
      "notes": [],
    },
    {
      "id": "tops",
      "name": "Tops",
      "is_active": true,
      "settings": {
        "title": null,
        "show_top_actors": true,
        "show_top_tags": true,
        "top_actors_title": null,
        "top_tags_title": null,
        "top_actors_size": 15,
        "top_tags_size": 15,
        "top_actors_notes": [],
        "top_tags_notes": [],
      },
      "notes": [],
    },
    {
      "id": "specificGroups",
      "name": "Specific groups",
      "is_active": true,
      "settings": {
        "title": null,
        "show_state_affiliated": true,
        "show_compromised": true,
        "show_bots": true,
        "state_affiliated_title": null,
        "compromised_title": null,
        "bots_title": null,
        "state_affiliated_notes": [],
        "compromised_notes": [],
        "bots_notes": [],
      },
      "notes": [],
    },
    {
      "id": "stories",
      "name": "Stories",
      "is_active": true,
      "settings": {
        "title": null,
        "show_full_text": true,
        "text_length": 100,
      },
      "notes": [],
    },
    {
      "id": "messages",
      "name": "Messages",
      "is_active": true,
      "settings": {
        "title": null,
        "show_full_text": true,
        "text_length": 100,
        "show_only_favorites": false,
        "messages_status": [],
        "show_highlights": true,
      },
      "notes": [],
    },
  ]
}

const pdfSettingsImpactAssessment = {
  "title_page": false,
  "widgets": [
    {
      "id": "description",
      "name": "Description",
      "is_active": true,
      "settings": {},
      "notes": [],
    },
    {
      "id": "summaryActors",
      "name": "Summary: Actors",
      "is_active": true,
      "settings": {
        "mode": ['actors'],
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "summaryPublications",
      "name": "Summary: Publications",
      "is_active": true,
      "settings": {
        "mode": ['publications'],
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "summaryViews",
      "name": "Summary: Views",
      "is_active": true,
      "settings": {
        "mode": ['views'],
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "summaryReactions",
      "name": "Summary: Reactions",
      "is_active": true,
      "settings": {
        "mode": ['reactions'],
        "title": null,
      },
      "notes": [],
    },
    {
      "id": "bubbleChartStories",
      "name": "Messages distribution: Stories",
      "is_active": true,
      "settings": {},
      "notes": [],
    },
    {
      "id": "sentimentMessages",
      "name": "Sentiment: Messages",
      "is_active": true,
      "settings": {},
      "notes": [],
    },
    {
      "id": "sentimentViews",
      "name": "Sentiment: Views",
      "is_active": true,
      "settings": {},
      "notes": [],
    },
    {
      "id": "sentimentReactions",
      "name": "Sentiment: Reactions",
      "is_active": true,
      "settings": {},
      "notes": [],
    },
  ]
}

const mapWidgets = {
  "description": Description,
  "summaryActors": Summary,
  "summaryPublications": Summary,
  "summaryViews": Summary,
  "summaryReactions": Summary,
  "originalSource": OriginalSource,
  "bubbleChartStories": BubbleChartStories,
  "bubbleChartCountries": BubbleChartIcons,
  "bubbleChartPlatforms": BubbleChartIcons,
  "evolutionMessages": EvolutionChart,
  "evolutionViews": EvolutionChart,
  "evolutionReactions": EvolutionChart,
  "evolutionManipulations": EvolutionChart,
  "sentimentMessages": SentimentBar,
  "sentimentViews": SentimentBar,
  "sentimentReactions": SentimentBar,
  "platforms": Platforms,
  "countries": Countries,
  "verification": Verification,
  "audience": Audience,
  "topActors": TopActorsByPosts,
  "topTags": TopTagsByPosts,
  "stateAffiliated": StateAffiliated,
  "compromised": Compromised,
  "bots": Bots,
  "stories": Stories,
  "messages": Messages,
  "actorsBreakdowns": ActorsBreakdowns,
  "tops": Tops,
  "specificGroups": SpecificGroups,
}

export const ExportPDFPage = ({ isShare = false }) => {
  const {
    narrative,
    fetchNarrative,
  } = useContext(MessagesContext);
  const { t } = useTranslation();

  const { narrativeId, threatId } = useParams();
  const [isMessagesTabOpen, setIsMessagesTabOpen] = useState(true);
  const [isStoriesTabOpen, setIsStoriesTabOpen] = useState(true);
  const [isDistributionTabOpen, setIsDistributionTabOpen] = useState(true);
  const [isActorsTabOpen, setIsActorsTabOpen] = useState(true);
  const [PDFSettings, setPDFSettings] = useState(pdfSettings);

  const distributionTabOptionsGeneral = [
    {value: 'description', label: t('Description'), is_active: PDFSettings.widgets.find(w => w.id === 'description').is_active},
    {value: 'summaryActors', label: t('Summary: Actors'), is_active: PDFSettings.widgets.find(w => w.id === 'summaryActors').is_active},
    {value: 'summaryPublications', label: t('Summary: Publications'), is_active: PDFSettings.widgets.find(w => w.id === 'summaryPublications').is_active},
    {value: 'summaryViews', label: t('Summary: Views'), is_active: PDFSettings.widgets.find(w => w.id === 'summaryViews').is_active},
    {value: 'summaryReactions', label: t('Summary: Reactions'), is_active: PDFSettings.widgets.find(w => w.id === 'summaryReactions').is_active},
    {value: 'originalSource', label: t('Original source'), is_active: PDFSettings.widgets.find(w => w.id === 'originalSource').is_active},
    {value: 'bubbleChartCountries', label: t('Messages distribution: Countries'), is_active: PDFSettings.widgets.find(w => w.id === 'bubbleChartCountries').is_active},
    {value: 'bubbleChartPlatforms', label: t('Messages distribution: Platforms'), is_active: PDFSettings.widgets.find(w => w.id === 'bubbleChartPlatforms').is_active},
    {value: 'evolutionMessages', label: t('Evolution: Messages'), is_active: PDFSettings.widgets.find(w => w.id === 'evolutionMessages').is_active},
    {value: 'evolutionViews', label: t('Evolution: Views'), is_active: PDFSettings.widgets.find(w => w.id === 'evolutionViews').is_active},
    {value: 'evolutionReactions', label: t('Evolution: Reactions'), is_active: PDFSettings.widgets.find(w => w.id === 'evolutionReactions').is_active},
    {value: 'evolutionManipulations', label: t('Evolution: Manipulation index'), is_active: PDFSettings.widgets.find(w => w.id === 'evolutionManipulations').is_active},
    {value: 'sentimentMessages', label: t('Sentiment: Messages'), is_active: PDFSettings.widgets.find(w => w.id === 'sentimentMessages').is_active},
    {value: 'sentimentViews', label: t('Sentiment: Views'), is_active: PDFSettings.widgets.find(w => w.id === 'sentimentViews').is_active},
    {value: 'sentimentReactions', label: t('Sentiment: Reactions'), is_active: PDFSettings.widgets.find(w => w.id === 'sentimentReactions').is_active},
  ]

  if(narrative?.parameters?.create_stories === true) {
    distributionTabOptionsGeneral.splice(6, 0, {value: 'bubbleChartStories', label: t('Messages distribution: Stories'), is_active: PDFSettings.widgets.find(w => w.id === 'bubbleChartStories').is_active});
  }
  
  const distributionTabOptionsImpactAssessment = [
    {value: 'description', label: t('Description'), is_active: PDFSettings.widgets.find(w => w.id === 'description').is_active},
    {value: 'summaryActors', label: t('Summary: Actors'), is_active: PDFSettings.widgets.find(w => w.id === 'summaryActors').is_active},
    {value: 'summaryPublications', label: t('Summary: Publications'), is_active: PDFSettings.widgets.find(w => w.id === 'summaryPublications').is_active},
    {value: 'summaryViews', label: t('Summary: Views'), is_active: PDFSettings.widgets.find(w => w.id === 'summaryViews').is_active},
    {value: 'summaryReactions', label: t('Summary: Reactions'), is_active: PDFSettings.widgets.find(w => w.id === 'summaryReactions').is_active},
    {value: 'bubbleChartStories', label: t('Messages distribution: Stories'), is_active: PDFSettings.widgets.find(w => w.id === 'bubbleChartStories').is_active},
    {value: 'sentimentMessages', label: t('Sentiment: Messages'), is_active: PDFSettings.widgets.find(w => w.id === 'sentimentMessages').is_active},
    {value: 'sentimentViews', label: t('Sentiment: Views'), is_active: PDFSettings.widgets.find(w => w.id === 'sentimentViews').is_active},
    {value: 'sentimentReactions', label: t('Sentiment: Reactions'), is_active: PDFSettings.widgets.find(w => w.id === 'sentimentReactions').is_active},
  ]

  let distributionTabOptions = narrative?.type === 'IMPACT_ASSESSMENT' ? distributionTabOptionsImpactAssessment : distributionTabOptionsGeneral;
  const isActorsBreakdownsActive = PDFSettings.widgets.find(w => w.id === 'actorsBreakdowns').settings.show_platforms 
    || PDFSettings.widgets.find(w => w.id === 'actorsBreakdowns').settings.show_countries
    || PDFSettings.widgets.find(w => w.id === 'actorsBreakdowns').settings.show_verification
    || PDFSettings.widgets.find(w => w.id === 'actorsBreakdowns').settings.show_audience;

  const isTopsActive = PDFSettings.widgets.find(w => w.id === 'tops').settings.show_top_actors
    || PDFSettings.widgets.find(w => w.id === 'tops').settings.show_top_tags;

  const isSpecificGroupsActive = PDFSettings.widgets.find(w => w.id === 'specificGroups').settings.show_state_affiliated
    || PDFSettings.widgets.find(w => w.id === 'specificGroups').settings.show_compromised
    || PDFSettings.widgets.find(w => w.id === 'specificGroups').settings.show_bots;

  const actorsTabOptions = [
    {value: 'actorsBreakdowns', label: t('Actors breakdowns'), is_active: isActorsBreakdownsActive},
    {value: 'tops', label: t('Tops'), is_active: isTopsActive},
    {value: 'specificGroups', label: t('Specific groups'), is_active: isSpecificGroupsActive},
  ]
  
  const messagesTabOptions = [
    {value: 'messages', label: t('Messages'), is_active: PDFSettings.widgets.find(w => w.id === 'messages').is_active},
  ]
  
  const storiesTabOptions = [
    {value: 'stories', label: t('Stories'), is_active: PDFSettings.widgets.find(w => w.id === 'stories').is_active},
  ]

  useEffect(() => {
    if (narrative?.type === 'IMPACT_ASSESSMENT') {
      setPDFSettings(pdfSettingsImpactAssessment);
    } else {
      setPDFSettings(pdfSettings);
    }
  }, [narrative]);

  useEffect(() => {
    let id = narrativeId || threatId;
    fetchNarrative(id);
  }, [narrativeId, threatId]);

  if (!narrative) {
    return <Loader />;
  }

  const scrollToWidget = (ref) => {
    ref?.scrollIntoView({ behavior: 'smooth', block: 'start', passive: true})
  }

  const handleSettingsChanges = (widget, settings, notes, ref) => {
    let newWidgets = PDFSettings.widgets.map((w) => {
      if(widget === 'actorsBreakdowns' && w.id === 'actorsBreakdowns') {
        let isActive = settings.show_platforms || settings.show_countries || settings.show_verification || settings.show_audience;
        return {
          ...w,
          settings: settings,
          is_active: isActive,
          notes: notes,
        };
      } else if(widget === 'tops' && w.id === 'tops') {
        let isActive = settings.show_top_actors || settings.show_top_tags;
        return {
          ...w,
          settings: settings,
          is_active: isActive,
          notes: notes,
        };
      } else if(widget === 'specificGroups' && w.id === 'specificGroups') {
        let isActive = settings.show_state_affiliated || settings.show_compromised || settings.show_bots;
        return {
          ...w,
          settings: settings,
          is_active: isActive,
          notes: notes,
        };
      } else if (w.id === widget) {
        return {
          ...w,
          settings: settings,
          notes: notes,
        };
      }
      return w;
    });
    
    setPDFSettings({...PDFSettings, widgets: newWidgets});

    scrollToWidget(ref);
  }

  const handleWidgetsStatus = (widgets, bool) => {
    let newWidgets = PDFSettings.widgets.map((w) => {
      if (widgets.includes(w.id)) {
        if(w.id === 'actorsBreakdowns') {
          return {
            ...w,
            is_active: bool,
            settings: {
              ...w.settings,
              show_platforms: bool,
              show_countries: bool,
              show_verification: bool,
              show_audience: bool,
            },
          };
        } else if(w.id === 'tops') {
          return {
            ...w,
            is_active: bool,
            settings: {
              ...w.settings,
              show_top_actors: bool,
              show_top_tags: bool,
            },
          };
        } else if(w.id === 'specificGroups') {
          return {
            ...w,
            is_active: bool,
            settings: {
              ...w.settings,
              show_state_affiliated: bool,
              show_compromised: bool,
              show_bots: bool,
            },
          };
        } else {
        return {
          ...w,
          is_active: bool,
        };
      }}
      return w;
    });
    setPDFSettings({...PDFSettings, widgets: newWidgets});
  }

  return (
    <div className={styles.exportContainer}>
      <div className={styles.pdfPageContainer}>
        {/* {narrative ? (
          <div className="report-section">
            <div className={styles.exportHeaderCard}>
              <div className={styles.logoContainer}>
                <OsavulLogo />
                <h2 className={styles.logoTitle}>OSAVUL</h2>
              </div>
              <h2 className={styles.headerInfoTitle}>{narrative?.name}</h2>
            </div>
          </div>
        ) : (
          ''
        )} */}

        <Header narrative={narrative}/>

        {PDFSettings?.widgets.map((widget) => {
          if (widget.id === 'actorsBreakdowns' && isActorsBreakdownsActive) {
            let WidgetComponent = mapWidgets[widget.id];
            return (
              <WidgetComponent
                narrative={narrative}
                isShare={isShare}
                settings={widget.settings}
                notes={widget.notes}
                onSettingsChange={(settings, notes, ref) =>
                  handleSettingsChanges(widget.id, settings, notes, ref)
                }
                onWidgetStatusChange={(bool) =>
                  handleWidgetsStatus([widget.id], bool)
                }
              />
            );
          } else if (widget.id === 'tops' && isTopsActive) {
            let WidgetComponent = mapWidgets[widget.id];
            return (
              <WidgetComponent
                narrative={narrative}
                isShare={isShare}
                settings={widget.settings}
                notes={widget.notes}
                onSettingsChange={(settings, notes, ref) =>
                  handleSettingsChanges(widget.id, settings, notes, ref)
                }
                onWidgetStatusChange={(bool) =>
                  handleWidgetsStatus([widget.id], bool)
                }
              />
            );
          } else if (widget.id === 'specificGroups' && isSpecificGroupsActive) {
            let WidgetComponent = mapWidgets[widget.id];
            return (
              <WidgetComponent
                narrative={narrative}
                isShare={isShare}
                settings={widget.settings}
                notes={widget.notes}
                onSettingsChange={(settings, notes, ref) =>
                  handleSettingsChanges(widget.id, settings, notes, ref)
                }
                onWidgetStatusChange={(bool) =>
                  handleWidgetsStatus([widget.id], bool)
                }
              />
            );
          } else if (
            narrative?.type !== 'IMPACT_ASSESSMENT' &&
            (widget.id === 'stories' || widget.id === 'bubbleChartStories') &&
            narrative?.parameters?.create_stories === false
          ) {
            return null;
          } else if (widget.is_active) {
            let WidgetComponent = mapWidgets[widget.id];
            return (
              <WidgetComponent
                narrative={narrative}
                isShare={isShare}
                settings={widget.settings}
                notes={widget.notes}
                onSettingsChange={(settings, notes, ref) =>
                  handleSettingsChanges(widget.id, settings, notes, ref)
                }
                onWidgetStatusChange={(bool) =>
                  handleWidgetsStatus([widget.id], bool)
                }
              />
            );
          }
        })}
      </div>
      <div className={styles.pdfSettingsContainer}>
        <div className={settingsStyles.root}>
          <div
            className={`${settingsStyles.container} ${settingsStyles.show}`}
            style={{ top: '0px', right: '0px' }}
          >
            <div className={settingsStyles.header}>
              <span>{t('PDF settings')}</span>

              <span
                onClick={() => {
                  if (
                    !isMessagesTabOpen ||
                    !isStoriesTabOpen ||
                    !isDistributionTabOpen ||
                    !isActorsTabOpen
                  ) {
                    setIsMessagesTabOpen(true);
                    setIsStoriesTabOpen(true);
                    setIsDistributionTabOpen(true);
                    setIsActorsTabOpen(true);
                  } else {
                    setIsMessagesTabOpen(false);
                    setIsStoriesTabOpen(false);
                    setIsDistributionTabOpen(false);
                    setIsActorsTabOpen(false);
                  }
                }}
              >
                {isMessagesTabOpen &&
                isStoriesTabOpen &&
                isActorsTabOpen &&
                isDistributionTabOpen
                  ? `${t('Minimize all')}`
                  : `${t('Expand all')}`}
              </span>
            </div>

            <div className={settingsStyles.collapsiblesWrapper}>
              <Collapsible
                name="Overview"
                isOpen={isDistributionTabOpen}
                summ={distributionTabOptions.filter((w) => w.is_active).length}
                onChange={(open) => setIsDistributionTabOpen(open)}
              >
                <DistributionTabChooser
                  onChange={(widgetIds, bool) =>
                    handleWidgetsStatus(widgetIds, bool)
                  }
                  options={distributionTabOptions}
                />
              </Collapsible>

              <Collapsible
                name="Actors"
                isOpen={isActorsTabOpen}
                summ={actorsTabOptions.filter((w) => w.is_active).length}
                onChange={(open) => setIsActorsTabOpen(open)}
              >
                <ActorsTabChooser
                  onChange={(widgetIds, bool) =>
                    handleWidgetsStatus(widgetIds, bool)
                  }
                  options={actorsTabOptions}
                />
              </Collapsible>

              {narrative?.type === 'IMPACT_ASSESSMENT' ||
              narrative?.parameters?.create_stories === true ? (
                <Collapsible
                  name="Stories"
                  isOpen={isStoriesTabOpen}
                  summ={storiesTabOptions.filter((w) => w.is_active).length}
                  onChange={(open) => setIsStoriesTabOpen(open)}
                >
                  <StoriesTabChooser
                    narrative={narrative}
                    onChange={(widgetIds, bool) =>
                      handleWidgetsStatus(widgetIds, bool)
                    }
                    options={storiesTabOptions}
                  />
                </Collapsible>
              ) : (
                ''
              )}

              <Collapsible
                name="Messages"
                isOpen={isMessagesTabOpen}
                summ={messagesTabOptions.filter((w) => w.is_active).length}
                onChange={(open) => setIsMessagesTabOpen(open)}
              >
                <MessagesTabChooser
                  narrative={narrative}
                  onChange={(widgetIds, bool) =>
                    handleWidgetsStatus(widgetIds, bool)
                  }
                  options={messagesTabOptions}
                />
              </Collapsible>
            </div>

            <div className={settingsStyles.controls}>
              <button
                className={`${settingsStyles.button} ${settingsStyles.apply}`}
                style={{ fontSize: '14px' }}
                onClick={() => {
                  window.print();
                }}
              >
                {t('Download PDF')}
              </button>

              <button
                className={`${settingsStyles.button} ${settingsStyles.reset}`}
                style={{ fontSize: '14px' }}
                onClick={() => {
                  setPDFSettings(
                    narrative?.type === 'IMPACT_ASSESSMENT'
                      ? pdfSettingsImpactAssessment
                      : pdfSettings,
                  );
                }}
              >
                {t('Reset all')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
