import { useRef, useState, useEffect } from 'react';
import { TrendChart } from '../../../../components/TrendChart/TrendChart';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { palette } from '../../../../utils/colors';
import Select from 'react-select';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import styles from '../../NarrativePage.module.scss';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import Markdown from 'react-markdown';
import { API, ShareAPI } from '../../../../API';

export const EvolutionChart = ({
  narrative,
  notes,
  settings,
  onSettingsChange,
  onWidgetStatusChange,
  isShare,
}) => {
  const { t } = useTranslation();
  const trendChartRef = useRef();
  const trendChartCanvasRef = useRef();
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [note, setNote] = useState(
    notes?.length > 0 ? notes[0] : { text: '', position: 'bottom' },
  );
  const [isNote, setIsNote] = useState(notes?.length > 0);
  const [data, setData] = useState(null);
  const componentRef = useRef(null);

  let chartTitle;
  if (widgetSettings.mode === 'publications') {
    chartTitle = t('Evolution: Messages');
  } else if (widgetSettings.mode === 'impressions') {
    chartTitle = t('Evolution: Views');
  } else if (widgetSettings.mode === 'engagement') {
    chartTitle = t('Evolution: Reactions');
  } else if (widgetSettings.mode === 'manipulation_index') {
    chartTitle = t('Evolution: Manipulation index');
  }

  const [title, setTitle] = useState(widgetSettings.title || chartTitle);

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : chartTitle;
    setTitle(newTitle);
  }, [settings]);

  const api = isShare ? ShareAPI : API;

  const fetchChartData = (narrative, statsAggregation, statsBreakdown) => {
    const urlParams = new URLSearchParams();
    urlParams.append('narrative_id', narrative.id);

    urlParams.set('aggregation', statsAggregation);

    let url = `/API/v1/stats/narratives/shares?${urlParams.toString()}`;

    if (statsBreakdown !== null) {
      urlParams.set('breakdown', statsBreakdown);
      url = `/API/v1/stats/narratives/${
        narrative.id
      }/shares?${urlParams.toString()}`;
    }

    api.fetch('GET', url).then((newData) => {
      setData(newData);
    });
  };

  const [statsAggregationOptions] = useState(() => [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ]);
  const [statsBreakdownOptions] = useState(() => [
    { value: null, label: t('All') },
    { value: 'PLATFORM', label: t('Platform') },
    { value: 'COUNTRY', label: t('Country') },
    { value: 'ACTOR_GROUP', label: t('Actor group') },
  ]);

  const viewOptions = [
    { value: 'STANDARD', label: t('Standard') },
    { value: 'VALUES', label: t('Values') },
    { value: 'POINTS', label: t('Points') },
  ];

  const mapViewOptions = {
    STANDARD: { value: 'STANDARD', label: t('Standard') },
    VALUES: { value: 'VALUES', label: t('Values') },
    POINTS: { value: 'POINTS', label: t('Points') },
  };

  useEffect(() => {
    fetchChartData(narrative, settings.agregation_period, settings.breakdown);
  }, []);

  useEffect(() => {
    fetchChartData(narrative, settings.agregation_period, settings.breakdown);
  }, [settings]);

  if (!data) {
    return <Loader />;
  }

  const narrativeColor = Object.fromEntries(
    data?.objects?.map((narrative, i) => [
      narrative.id,
      palette[i % palette.length],
    ]),
  );

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t(chartTitle)}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              let newNotes;
              setIsEditingMode(false);
              if (note.text.length > 0) {
                newNotes = [note];
              } else {
                newNotes = [];
              }
              onSettingsChange(widgetSettings, newNotes, componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
              setTitle(settings.title || chartTitle);
              setIsEditingMode(false);
              setWidgetSettings(settings);
              setNote(
                notes?.length > 0 ? notes[0] : { text: '', position: 'bottom' },
              );
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>

            <div className="form-element">
              <label htmlFor="agregation">{t('Agregation')}</label>
              <Select
                className="select-settings"
                inputId="settings-agregation-select"
                onChange={(data) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    agregation_period: data.value,
                  });
                }}
                value={statsAggregationOptions.find(
                  (option) => option.value === widgetSettings.agregation_period,
                )}
                options={statsAggregationOptions}
              />
            </div>
            <div className="form-element">
              <label htmlFor="breakdown">{t('Breakdown')}</label>
              <Select
                inputId="settings-breakdown-select"
                className="select-settings"
                onChange={(data) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    breakdown: data.value,
                  });
                }}
                value={statsBreakdownOptions.find(
                  (option) => option.value === widgetSettings.breakdown,
                )}
                options={statsBreakdownOptions}
              />
            </div>
            <div className="form-element">
              <label htmlFor="mode">{t('View mode')}</label>
              <Select
                inputId="settings-view-select"
                className="select-settings"
                onChange={(data) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    view: data.value,
                  });
                }}
                value={mapViewOptions[widgetSettings.view]}
                options={viewOptions}
              />
            </div>
            <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="platforms"
                    checked={isNote}
                    onChange={() => {
                      setIsNote(!isNote);
                    }}
                  />
                  <label htmlFor="create-note">{t('Create a note')}</label>
                </li>
              </ul>
            </div>
            {isNote ? (
              <div className="form-element">
                <div className="form-element">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="platforms"
                        checked={note?.position === 'top'}
                        onChange={() => {
                          if (note?.position === 'top') {
                            setNote({ ...note, position: 'bottom' });
                          } else {
                            setNote({ ...note, position: 'top' });
                          }
                        }}
                      />
                      <label htmlFor="note-position">
                        {t('Place a note on the top of the widget')}
                      </label>
                    </li>
                  </ul>
                </div>

                <label htmlFor="note">{t('Note')}</label>
                <TextEditor
                  id={'note-editor'}
                  value={note.text}
                  onChange={(text) => setNote({ ...note, text: text })}
                  isOpen={true}
                />
              </div>
            ) : (
              ''
            )}
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="list-content widget-container">
        <div className="report-section">
          <div className="report-section__header">
            <h3>{widgetSettings.title ? widgetSettings.title : chartTitle}</h3>
            <div className="report-section__controls">
              <button
                className="report-control-button"
                onClick={() => setIsEditingMode(true)}
              >
                <EditIcon />
              </button>
              <button
                className="report-control-button danger"
                onClick={() => onWidgetStatusChange(false)}
              >
                <DeleteIcon />
              </button>
            </div>
          </div>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'top' ? (
                      <div className="report-note-container note-position-top">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
          <div className="card">
            <div className={'card-header ' + styles.cardHeader}></div>
            <div className="card-body">
              {!data ? (
                <Loader />
              ) : (
                <TrendChart
                  rawdata={data?.objects}
                  metric={settings.mode}
                  showLegend={true}
                  narrativeColor={narrativeColor}
                  view={mapViewOptions[widgetSettings.view]}
                  svgRef={trendChartRef}
                  canvasRef={trendChartCanvasRef}
                />
              )}
            </div>
          </div>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'bottom' ? (
                      <div className="report-note-container note-position-bottom">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
        </div>
      </div>
    );
  }
};
