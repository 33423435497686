import { useEffect, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import styles from '../NarrativePage.module.scss';


export const Audience = ({isShare, narrative, isClickable=true, title}) => {
  const { t } = useTranslation();
  const [audience, setAudience] = useState(null);
  const {openModal, setAudienceFilter} = useContext(BreakdownsContext);
  const api = isShare ? ShareAPI : API;

  const bucketNameMap = {
    'very_small': t('Very small (< 1k)'),
    'small': t('Small (1k - 10k)'),
    'medium': t('Medium (10k - 100k)'),
    'big': t('Big (100k - 500k)'),
    'very_big': t('Very big (> 500k)')
  }

  const audienceSizeMap = {
    'very_small': [0, 1000],
    'small': [1000, 10000],
    'medium': [10000, 100000],
    'big': [100000, 500000],
    'very_big': [500000, 1000000000]
  }

  const getAudience = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/audience_sizes`).then(
      data => setAudience(data)
    )
  }

  useEffect(() => {
    getAudience()
  }, [narrative])

  const colors = ['#BEFDEE', '#7FFADD', '#45F1C9', '#1CD6AA', '#00BA8D'];

  const getChartData = (audience) => {
    let data = [
      ['Audience', 'Number', { role: "style" }, { role: 'annotation' }]
    ];
    
    audience.objects?.forEach((audience, index) => 
       data.push([bucketNameMap[audience.bucket], audience.count, colors[index], audience.count])
    )
    
    return data;
  }

  const options = {
    chartArea: { left: '5%', top: '15%', width:'100%', height:'75%' },
    legend: 'none',
    bar: { groupWidth: "55%" },
    annotations: {alwaysOutside: true, stem: {color: 'transparent'}, textStyle: {bold: true, opacity: 1, color: '#151419'}, highContrast: false}
  }
  
  return(
    <BreakdownsContainer
      title={title ? title : 'Audience size'}
      onClick={() => openModal()}
      details={false}
    >
      {audience ? (audience.objects?.length > 0
      ? <div className={styles.chartWrapper}>
        <Chart
          chartType="ColumnChart"
          data={getChartData(audience)}
          width={"100%"}
          height={"400px"}
          className='barChart'
          options={options}
          chartEvents={
            isClickable 
             ? [
                {
                  eventName: "select",
                  callback({ chartWrapper }) {
                    let selected = chartWrapper.getChart().getSelection();
                    let index = selected[0]?.row;
                    let bucket = audience.objects[index].bucket;
                    // let option = {value: 'audience', label: 'Audience',}
                    setAudienceFilter(audienceSizeMap[bucket])
                    openModal()
                  }
                }
              ]
             : []
            
          }
          
          style={{margin: '0px', padding: '0px'}}
          
        />
      </div> 
      : <div className={styles.noData}>{t('There is no data to display')}</div>) 
      : (<div className={styles.widdgetLoader}><LoaderSmall /></div>)}
      
    </BreakdownsContainer>
  )
}

