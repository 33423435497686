export const sentimentData = {
  "1": {
    "positive": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    },
    "negative": {
      "posts": 790,
      "posts_share": 87.58314855875831,
      "likes": 58998,
      "likes_share": 88.36533564988167,
      "comments": 1173,
      "comments_share": 92.43498817966903
    },
    "neutral": {
      "posts": 112,
      "posts_share": 12.416851441241684,
      "likes": 7768,
      "likes_share": 11.634664350118324,
      "comments": 96,
      "comments_share": 7.5650118203309695
    }
  },
  "2": {
    "positive": {
      "posts": 7,
      "posts_share": 4.964539007092198,
      "likes": 111,
      "likes_share": 6.809815950920245,
      "comments": 3,
      "comments_share": 4.0
    },
    "negative": {
      "posts": 87,
      "posts_share": 61.702127659574465,
      "likes": 1352,
      "likes_share": 82.94478527607362,
      "comments": 48,
      "comments_share": 64.0
    },
    "neutral": {
      "posts": 47,
      "posts_share": 33.333333333333336,
      "likes": 167,
      "likes_share": 10.245398773006135,
      "comments": 24,
      "comments_share": 32.0
    }
  },
  "3": {
    "positive": {
      "posts": 1,
      "posts_share": 1.5384615384615385,
      "likes": 76,
      "likes_share": 1.0542377583576086,
      "comments": 0,
      "comments_share": 0.0
    },
    "negative": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    },
    "neutral": {
      "posts": 64,
      "posts_share": 98.46153846153847,
      "likes": 7133,
      "likes_share": 98.94576224164238,
      "comments": 4,
      "comments_share": 100.0
    }
  },
  "4": {
    "positive": {
      "posts": 14,
      "posts_share": 15.217391304347826,
      "likes": 126,
      "likes_share": 0.9778053701691759,
      "comments": 3,
      "comments_share": 1.8072289156626506
    },
    "negative": {
      "posts": 78,
      "posts_share": 84.78260869565217,
      "likes": 12760,
      "likes_share": 99.02219462983082,
      "comments": 163,
      "comments_share": 98.19277108433735
    },
    "neutral": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    }
  },
  "5": {
    "positive": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0
    },
    "negative": {
      "posts": 13,
      "posts_share": 15.853658536585366,
      "likes": 8,
      "likes_share": 100.0,
      "comments": 0,
      "comments_share": 0
    },
    "neutral": {
      "posts": 69,
      "posts_share": 84.14634146341463,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0
    }
  },
  "6": {
    "positive": {
      "posts": 1,
      "posts_share": 1.6129032258064515,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    },
    "negative": {
      "posts": 1,
      "posts_share": 1.6129032258064515,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    },
    "neutral": {
      "posts": 60,
      "posts_share": 96.7741935483871,
      "likes": 8782,
      "likes_share": 100.0,
      "comments": 87,
      "comments_share": 100.0
    }
  },
  "7": {
    "positive": {
      "posts": 4,
      "posts_share": 6.451612903225806,
      "likes": 16,
      "likes_share": 4.278074866310161,
      "comments": 0,
      "comments_share": 0.0
    },
    "negative": {
      "posts": 19,
      "posts_share": 30.64516129032258,
      "likes": 54,
      "likes_share": 14.438502673796792,
      "comments": 1,
      "comments_share": 10.0
    },
    "neutral": {
      "posts": 39,
      "posts_share": 62.903225806451616,
      "likes": 304,
      "likes_share": 81.28342245989305,
      "comments": 9,
      "comments_share": 90.0
    }
  },
  "8": {
    "positive": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    },
    "negative": {
      "posts": 42,
      "posts_share": 80.76923076923077,
      "likes": 1812,
      "likes_share": 95.77167019027485,
      "comments": 17,
      "comments_share": 94.44444444444444
    },
    "neutral": {
      "posts": 10,
      "posts_share": 19.23076923076923,
      "likes": 80,
      "likes_share": 4.2283298097251585,
      "comments": 1,
      "comments_share": 5.555555555555555
    }
  },
  "9": {
    "positive": {
      "posts": 35,
      "posts_share": 70.0,
      "likes": 3853,
      "likes_share": 60.629425649095204,
      "comments": 64,
      "comments_share": 69.56521739130434
    },
    "negative": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    },
    "neutral": {
      "posts": 15,
      "posts_share": 30.0,
      "likes": 2502,
      "likes_share": 39.370574350904796,
      "comments": 28,
      "comments_share": 30.434782608695652
    }
  },
  "10": {
    "positive": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    },
    "negative": {
      "posts": 43,
      "posts_share": 100.0,
      "likes": 15084,
      "likes_share": 100.0,
      "comments": 1076,
      "comments_share": 100.0
    },
    "neutral": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    }
  },
  "11": {
    "positive": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0.0,
      "comments": 0,
      "comments_share": 0.0
    },
    "negative": {
      "posts": 35,
      "posts_share": 89.74358974358974,
      "likes": 8039,
      "likes_share": 96.31005151551456,
      "comments": 368,
      "comments_share": 99.45945945945945
    },
    "neutral": {
      "posts": 4,
      "posts_share": 10.256410256410257,
      "likes": 308,
      "likes_share": 3.689948484485444,
      "comments": 2,
      "comments_share": 0.5405405405405406
    }
  },
  "12": {
    "positive": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0,
      "comments": 0,
      "comments_share": 0
    },
    "negative": {
      "posts": 7,
      "posts_share": 100.0,
      "likes": 0,
      "likes_share": 0,
      "comments": 0,
      "comments_share": 0
    },
    "neutral": {
      "posts": 0,
      "posts_share": 0.0,
      "likes": 0,
      "likes_share": 0,
      "comments": 0,
      "comments_share": 0
    }
  }
}
