import { useTranslation } from 'react-i18next';

import '../../Filter/Filter.scss';

export const ActorsTabChooser = ({ onChange, options }) => {
  const { t } = useTranslation();

  let selectedOptions = options.filter((item) => item.is_active);

  const handleSelect = () => {
    let widgets = options.map((item) => item.value);
    if (options.length !== selectedOptions.length) {
      onChange(widgets, true);
    } else {
      onChange(widgets, false);
    }
  };

  return (
    <div className="chooser">
      <div className="control" onClick={() => handleSelect()}>
        {options.length === selectedOptions.length
          ? t('Deselect all')
          : t('Select all')}
      </div>
      <ul>
        {options.map((item) => {
          return (
            <li key={item.value}>
              <input
                id={`actorsTab_${item.value}`}
                type="checkbox"
                checked={item.is_active}
                onChange={() => {
                  onChange([item.value], !item.is_active);
                }}
              />
              <label htmlFor={`actorsTab_${item.value}`}>{item.label}</label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
