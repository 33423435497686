import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { Loader } from '../../../../components/Loader/Loader';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import { StateAffiliatedWidget } from './StateAffiliated';
import { CompromisedWidget } from './Compromised';
import { BotsWidget } from './Bots';
import styles from '../../NarrativePage.module.scss';

export const SpecificGroups = ({
  narrative,
  isShare,
  settings,
  notes,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const { t } = useTranslation();
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [title, setTitle] = useState(widgetSettings.title || t('Specific groups'));
  const componentRef = useRef(null);

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : t('Specific groups');
    setTitle(newTitle);
  }, [settings]);


  if (!narrative) {
    return <Loader />;
  }

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t('Specific groups')}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsEditingMode(false);
              onSettingsChange(widgetSettings, [], componentRef.current);
            }}
            onReset={() => {
              setTitle(settings.title || t('Specific groups'));
              setIsEditingMode(false);
              setWidgetSettings(settings);
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>

            <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="state-affiliated"
                    checked={widgetSettings.show_state_affiliated}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_state_affiliated: !widgetSettings.show_state_affiliated,
                      });
                    }}
                  />
                  <label htmlFor="state-affiliated">{t('Show State-affiliated')}</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="compromised"
                    checked={widgetSettings.show_compromised}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_compromised: !widgetSettings.show_compromised,
                      });
                    }}
                  />
                  <label htmlFor="compromised">{t('Show Compromised')}</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="bots"
                    checked={widgetSettings.show_bots}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_bots: !widgetSettings.show_bots,
                      });
                    }}
                  />
                  <label htmlFor="bots">{t('Show Bots')}</label>
                </li>
              </ul>
            </div>
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="widget-container">
        <div className="report-section">
          <div className="report-section__header">
            <h3>
              {widgetSettings.title
                ? widgetSettings.title
                : t('Specific groups')}
            </h3>
            <div className="report-section__controls">
              <button
                className="report-control-button"
                onClick={() => setIsEditingMode(true)}
              >
                <EditIcon />
              </button>
              <button
                className="report-control-button danger"
                onClick={() => onWidgetStatusChange(false)}
              >
                <DeleteIcon />
              </button>
            </div>
          </div>
          <div className={styles.breakdownsContainer}>
            <StateAffiliatedWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_state_affiliated: !widgetSettings.show_state_affiliated,
                });
                onSettingsChange({ ...widgetSettings, show_state_affiliated: value });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />

            <CompromisedWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_compromised: !widgetSettings.show_compromised,
                });
                onSettingsChange({ ...widgetSettings, show_compromised: value });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />

            <BotsWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_bots: !widgetSettings.show_bots,
                });
                onSettingsChange({
                  ...widgetSettings,
                  show_bots: value,
                });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};
