export const eventsData = {
  "1": {
    "objects": [
      {
        "id": 1,
        "publication_date": "2024-08-11 04:01:00+00:00",
        "title": "Allegations of misinformation regarding Kamala Harris's McDonald's employment circulate",
        "content": "A source claims Kamala Harris lied about working at McDonald's, with evidence suggesting she never did. This has sparked accusations and investigations into her past employment claims, leading to widespread discussion and criticism across social media and political spheres.",
        "rating": 94,
        "sources": [
          {
            "id": 202758,
            "name": "Rachel",
            "url": "https://twitter.com/GoldnemovesGold",
            "source_icon": ""
          },
          {
            "id": 121572,
            "name": "\u201cDems Are Desperate\u201d Tenorman \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/thetenorman",
            "source_icon": ""
          },
          {
            "id": 207155,
            "name": "Lies Matter!",
            "url": "https://twitter.com/liesmatr",
            "source_icon": ""
          },
          {
            "id": 66323,
            "name": "Marc Londo (O.T.B. Productions) \ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/mlondo856",
            "source_icon": ""
          },
          {
            "id": 210050,
            "name": "NahBabyNah #Trump",
            "url": "https://twitter.com/NahBabyNah",
            "source_icon": ""
          },
          {
            "id": 145317,
            "name": "WendylDail",
            "url": "https://twitter.com/wendylwilson2",
            "source_icon": ""
          },
          {
            "id": 157307,
            "name": "James Cox The Perfect Coincidence",
            "url": "https://twitter.com/jimboazil",
            "source_icon": ""
          },
          {
            "id": 2038016,
            "name": "Ultra Ultra Nuclear TRUMP 2024 Patti the Patriot",
            "url": "https://twitter.com/Patti55866878",
            "source_icon": ""
          },
          {
            "id": 192746,
            "name": "Charles S. Viar",
            "url": "https://twitter.com/Charles_S_Viar",
            "source_icon": ""
          },
          {
            "id": 126476,
            "name": "Joseph A Dowdy",
            "url": "https://twitter.com/hockeyseasntime",
            "source_icon": ""
          },
          {
            "id": 137095,
            "name": "Kathy Cogburn",
            "url": "https://twitter.com/cogburn_kathy",
            "source_icon": ""
          },
          {
            "id": 2041039,
            "name": "Evjone2019",
            "url": "https://twitter.com/evjone2019",
            "source_icon": ""
          },
          {
            "id": 96191,
            "name": "Harry Parker",
            "url": "https://twitter.com/RaidersHLP",
            "source_icon": ""
          },
          {
            "id": 124482,
            "name": "SCW",
            "url": "https://twitter.com/SCWOFTX",
            "source_icon": ""
          },
          {
            "id": 139633,
            "name": "The Booker Method",
            "url": "https://twitter.com/Booker10038",
            "source_icon": ""
          },
          {
            "id": 85351,
            "name": "Michael E. Dehn",
            "url": "https://twitter.com/mediapython",
            "source_icon": ""
          },
          {
            "id": 2052236,
            "name": "masonbo",
            "url": "https://twitter.com/masonbo",
            "source_icon": ""
          },
          {
            "id": 2053326,
            "name": "SENDCONGRESSHOME",
            "url": "https://twitter.com/Congressgohome",
            "source_icon": ""
          },
          {
            "id": 137947,
            "name": "Brenda K.\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/SmellyCat2Sunny",
            "source_icon": ""
          },
          {
            "id": 68758,
            "name": "\ud83c\uddfa\ud83c\uddf8JFer0dez \ud83c\uddfa\ud83c\uddf8\ud83c\uddf7\ud83c\uddfa Trump Won!!",
            "url": "https://twitter.com/JLFer0dez01",
            "source_icon": ""
          },
          {
            "id": 205230,
            "name": "Angela",
            "url": "https://twitter.com/angassistant",
            "source_icon": ""
          },
          {
            "id": 217245,
            "name": "\u2693 Truth Will Prevail . . . Magna Est Veritas \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/barbiebrox",
            "source_icon": ""
          },
          {
            "id": 139892,
            "name": "Payman14",
            "url": "https://twitter.com/Payman141",
            "source_icon": ""
          },
          {
            "id": 2051993,
            "name": "@kimbasmom1\ud83c\uddee\ud83c\uddf1",
            "url": "https://twitter.com/kimbasmom1",
            "source_icon": ""
          },
          {
            "id": 2049405,
            "name": "Fortune Favors the Wicked",
            "url": "https://twitter.com/Loco_Presidenti",
            "source_icon": ""
          },
          {
            "id": 87039,
            "name": "Montgomery Granger",
            "url": "https://twitter.com/mjgranger1",
            "source_icon": ""
          },
          {
            "id": 86654,
            "name": "Donald Hughes \ud83c\udf4e",
            "url": "https://twitter.com/donahugh216",
            "source_icon": ""
          },
          {
            "id": 215581,
            "name": "Tom",
            "url": "https://twitter.com/irishteo",
            "source_icon": ""
          },
          {
            "id": 2051401,
            "name": "Donna Davis",
            "url": "https://twitter.com/DonnaDavisHNL",
            "source_icon": ""
          },
          {
            "id": 2051732,
            "name": "TheBitchIsBack\ud83d\ude0b",
            "url": "https://twitter.com/hotnostril",
            "source_icon": ""
          },
          {
            "id": 197820,
            "name": "David Barwick",
            "url": "https://twitter.com/ISeeWordsPeople",
            "source_icon": ""
          },
          {
            "id": 79389,
            "name": "\ud83c\udf37\ud83e\udd8b\ud83c\udf37Just me\ud83d\udc40Cassie\u271d\ud83d\udc9c",
            "url": "https://twitter.com/browneyegirl400",
            "source_icon": ""
          },
          {
            "id": 2052682,
            "name": "RayBrat",
            "url": "https://twitter.com/raybrat54",
            "source_icon": ""
          },
          {
            "id": 202062,
            "name": "LS Knip",
            "url": "https://twitter.com/KnipLs",
            "source_icon": ""
          },
          {
            "id": 71991,
            "name": "Phyllis",
            "url": "https://twitter.com/Phyllis94584953",
            "source_icon": ""
          },
          {
            "id": 71226,
            "name": "Pleasant Day \ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Jantxnc",
            "source_icon": ""
          },
          {
            "id": 21894677,
            "name": "ABITA",
            "url": "https://twitter.com/ABITA54906711",
            "source_icon": ""
          },
          {
            "id": 87594,
            "name": "KatieScarlett.",
            "url": "https://twitter.com/KatieScarlet13",
            "source_icon": ""
          },
          {
            "id": 2042160,
            "name": "Donna Dale",
            "url": "https://twitter.com/mommad0863",
            "source_icon": ""
          },
          {
            "id": 2040623,
            "name": "Michelle - MAGA",
            "url": "https://twitter.com/MichelleLee0324",
            "source_icon": ""
          },
          {
            "id": 107498,
            "name": "Jan real American",
            "url": "https://twitter.com/grtck",
            "source_icon": ""
          },
          {
            "id": 138333,
            "name": "Ronnie Waalkes",
            "url": "https://twitter.com/WaalkesR105",
            "source_icon": ""
          },
          {
            "id": 140133,
            "name": "KatattackTruth \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/KatattackTruth",
            "source_icon": ""
          },
          {
            "id": 152388,
            "name": "Mark6569",
            "url": "https://twitter.com/Mark6569",
            "source_icon": ""
          },
          {
            "id": 2040851,
            "name": "Eric S - Ultra MAGA Extremist",
            "url": "https://twitter.com/glugalug",
            "source_icon": ""
          },
          {
            "id": 133753,
            "name": "Grady Hughes",
            "url": "https://twitter.com/DukeThe57",
            "source_icon": ""
          },
          {
            "id": 133040,
            "name": "mzee26 \ud83c\uddfa\ud83c\uddf8 \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/mzee26",
            "source_icon": ""
          },
          {
            "id": 68625,
            "name": "Marcia Sessler",
            "url": "https://twitter.com/MarciaSessler",
            "source_icon": ""
          },
          {
            "id": 2041036,
            "name": "AFCAZIVA \ud83c\uddf3\ud83c\uddf1\ud83c\uddee\ud83c\uddf1\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/afcaziva",
            "source_icon": ""
          },
          {
            "id": 159039,
            "name": "JMcMillon",
            "url": "https://twitter.com/jackie_mcmillon",
            "source_icon": ""
          },
          {
            "id": 2038826,
            "name": "ConstitutionalPoet",
            "url": "https://twitter.com/clockismelted",
            "source_icon": ""
          },
          {
            "id": 2038620,
            "name": "BarbBradley Juarez\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/BarbBradleyJ",
            "source_icon": ""
          },
          {
            "id": 2041272,
            "name": "LeoTerrell",
            "url": "https://twitter.com/TheLeoTerrell",
            "source_icon": ""
          },
          {
            "id": 184014,
            "name": "Roxie\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/roxie32819",
            "source_icon": ""
          },
          {
            "id": 2041396,
            "name": "FBR",
            "url": "https://twitter.com/FBR77488088",
            "source_icon": ""
          },
          {
            "id": 108625,
            "name": "Jeanette \ud83d\ude0e\ud83c\udf1e\ud83c\udf35\ud83c\udf3b\ud83e\udd8e\ud83c\udf34\ud83c\uddfa\ud83c\uddf8ULTRA MAGA**USA**ARIZONA*",
            "url": "https://twitter.com/Outhere",
            "source_icon": ""
          },
          {
            "id": 132970,
            "name": "Patsy \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/JohnSmithJr147",
            "source_icon": ""
          },
          {
            "id": 207349,
            "name": "Quaternion Group",
            "url": "https://twitter.com/QuaternionGroup",
            "source_icon": ""
          },
          {
            "id": 109681107,
            "name": "AnnLee",
            "url": "https://twitter.com/Anngirllee",
            "source_icon": ""
          },
          {
            "id": 41147570,
            "name": "Amy",
            "url": "https://twitter.com/AmyCampbellUSA",
            "source_icon": ""
          },
          {
            "id": 45002,
            "name": "Marc Rudov",
            "url": "https://twitter.com/MarcRudov",
            "source_icon": ""
          },
          {
            "id": 46617,
            "name": "The Real GOP \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/TheRealGOP",
            "source_icon": ""
          },
          {
            "id": 55565,
            "name": "Vicki",
            "url": "https://twitter.com/gojeffie24",
            "source_icon": ""
          },
          {
            "id": 66890,
            "name": "Arizona 0cy \ud83c\udf35\ud83c\udf34\ud83c\udf84",
            "url": "https://twitter.com/Arizo0ancy48",
            "source_icon": ""
          },
          {
            "id": 190846769,
            "name": "David K Webb",
            "url": "https://twitter.com/davidwebb27",
            "source_icon": ""
          },
          {
            "id": 114593,
            "name": "Kerry \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/K1erry",
            "source_icon": ""
          },
          {
            "id": 42310,
            "name": "James Stephens",
            "url": "https://twitter.com/Jamesw74",
            "source_icon": ""
          },
          {
            "id": 181841,
            "name": "\ud83c\uddec\ud83c\udde7 BLOKE IN PUB \ud83c\uddec\ud83c\udde7",
            "url": "https://twitter.com/paulturner2012",
            "source_icon": ""
          },
          {
            "id": 117822,
            "name": "Marty",
            "url": "https://twitter.com/terpmarty80",
            "source_icon": ""
          },
          {
            "id": 2038452,
            "name": "MAGS",
            "url": "https://twitter.com/TAftermath2020",
            "source_icon": ""
          },
          {
            "id": 188393,
            "name": "\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8Nik4Freedom\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Nik4Freedom",
            "source_icon": ""
          },
          {
            "id": 179237,
            "name": "Clark (MBN)",
            "url": "https://twitter.com/monkeybone64",
            "source_icon": ""
          },
          {
            "id": 2038466,
            "name": "Kenny Webster",
            "url": "https://twitter.com/KennethRWebster",
            "source_icon": ""
          },
          {
            "id": 179021,
            "name": "Che Gato",
            "url": "https://twitter.com/Gatedades",
            "source_icon": ""
          },
          {
            "id": 119190,
            "name": "V.Streifel",
            "url": "https://twitter.com/streifel_v",
            "source_icon": ""
          },
          {
            "id": 119273,
            "name": "@BeeNewsDailyB",
            "url": "https://twitter.com/BeenewsdailyB",
            "source_icon": ""
          },
          {
            "id": 120234,
            "name": "Claire Balan",
            "url": "https://twitter.com/ClaireBalan",
            "source_icon": ""
          },
          {
            "id": 120692,
            "name": "judyann451",
            "url": "https://twitter.com/judyannaggie",
            "source_icon": ""
          },
          {
            "id": 184427,
            "name": "FidelmaBack",
            "url": "https://twitter.com/FJEB88",
            "source_icon": ""
          },
          {
            "id": 189542,
            "name": "Pepper InVegas",
            "url": "https://twitter.com/PepperInVegas",
            "source_icon": ""
          },
          {
            "id": 2041768,
            "name": "Paula Alquist Anton",
            "url": "https://twitter.com/PaulaAlquist",
            "source_icon": ""
          },
          {
            "id": 121288,
            "name": "Peckerman",
            "url": "https://twitter.com/wisepeckerman",
            "source_icon": ""
          },
          {
            "id": 189859,
            "name": "Michael Legero #MAGAveteran",
            "url": "https://twitter.com/LegeroMichael",
            "source_icon": ""
          },
          {
            "id": 225557,
            "name": "Valdivia \ud83e\udd65 \ud83c\udf34",
            "url": "https://twitter.com/TheCorollary",
            "source_icon": ""
          },
          {
            "id": 45810,
            "name": "Yahtahei_TwoPointZero",
            "url": "https://twitter.com/YahtaheiT",
            "source_icon": ""
          },
          {
            "id": 190640,
            "name": "Des",
            "url": "https://twitter.com/dezmon71",
            "source_icon": ""
          },
          {
            "id": 223577,
            "name": "Cindy",
            "url": "https://twitter.com/asheborn57",
            "source_icon": ""
          },
          {
            "id": 218903,
            "name": "Freedom2020",
            "url": "https://twitter.com/Freedom20207",
            "source_icon": ""
          },
          {
            "id": 218749,
            "name": "Common Cent$",
            "url": "https://twitter.com/Common_Cent1",
            "source_icon": ""
          },
          {
            "id": 218746,
            "name": "tony",
            "url": "https://twitter.com/wtony_tony",
            "source_icon": ""
          },
          {
            "id": 193811,
            "name": "Debra Moge-Ultra MAGA",
            "url": "https://twitter.com/DebraMoge",
            "source_icon": ""
          },
          {
            "id": 217824,
            "name": "Gracie51",
            "url": "https://twitter.com/Gracie5111",
            "source_icon": ""
          },
          {
            "id": 42309,
            "name": "ljuljaj Javore",
            "url": "https://twitter.com/javor_ivanjica",
            "source_icon": ""
          },
          {
            "id": 216022,
            "name": "teresa",
            "url": "https://twitter.com/teresa59420516",
            "source_icon": ""
          },
          {
            "id": 196718,
            "name": "PRESIDENT TRUMP WILL NEVER BACK DOWN\u2764\ufe0f\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Ihelpu2c",
            "source_icon": ""
          },
          {
            "id": 178623,
            "name": "Sherri KC",
            "url": "https://twitter.com/kc_sherri",
            "source_icon": ""
          },
          {
            "id": 44514,
            "name": "#TuckFrump",
            "url": "https://twitter.com/realTuckFrumper",
            "source_icon": ""
          },
          {
            "id": 109677967,
            "name": "Sirpuu",
            "url": "https://twitter.com/sirpuu",
            "source_icon": ""
          },
          {
            "id": 137802,
            "name": "Mr Grumpy.",
            "url": "https://twitter.com/PCisfinished",
            "source_icon": ""
          },
          {
            "id": 144399,
            "name": "CarolinaHawk 1776\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/HawkinsRandy",
            "source_icon": ""
          },
          {
            "id": 143354,
            "name": "TwittaChicca",
            "url": "https://twitter.com/TwittaChicca",
            "source_icon": ""
          },
          {
            "id": 2040476,
            "name": "ULTRA MAGA Dog Mom",
            "url": "https://twitter.com/Deplorable_JJ",
            "source_icon": ""
          },
          {
            "id": 150572,
            "name": "James M #TRUMP2024 \ud83c\udde8\ud83c\udde6 \ud83c\uddec\ud83c\udde7 \ud83c\udf4e",
            "url": "https://twitter.com/James999_travel",
            "source_icon": ""
          },
          {
            "id": 2040532,
            "name": "God*Family*Country",
            "url": "https://twitter.com/maga_mae",
            "source_icon": ""
          },
          {
            "id": 2040533,
            "name": "jillymildotter",
            "url": "https://twitter.com/jmildotter",
            "source_icon": ""
          },
          {
            "id": 2040541,
            "name": "no one you know",
            "url": "https://twitter.com/ConcordMaga",
            "source_icon": ""
          },
          {
            "id": 46002,
            "name": "K. Cosgrove",
            "url": "https://twitter.com/getthenet145",
            "source_icon": ""
          },
          {
            "id": 2040555,
            "name": "Deplorable Ric",
            "url": "https://twitter.com/reed_ric",
            "source_icon": ""
          },
          {
            "id": 46336,
            "name": "Steve Ferguson",
            "url": "https://twitter.com/lsferguson",
            "source_icon": ""
          },
          {
            "id": 157046,
            "name": "Alexander Anthony",
            "url": "https://twitter.com/HawkmanAlex",
            "source_icon": ""
          },
          {
            "id": 45115,
            "name": "*",
            "url": "https://twitter.com/CrystalBlueRed",
            "source_icon": ""
          },
          {
            "id": 159166,
            "name": "EZE99EE",
            "url": "https://twitter.com/AguilarEdward54",
            "source_icon": ""
          },
          {
            "id": 160784,
            "name": "Ze t\u00eappd\u0646de \ud83c\udf36\ud83c\udf36",
            "url": "https://twitter.com/teppdude",
            "source_icon": ""
          },
          {
            "id": 109681141,
            "name": "Paul Jankura",
            "url": "https://twitter.com/Anthropic",
            "source_icon": ""
          },
          {
            "id": 93009,
            "name": "Savannah",
            "url": "https://twitter.com/BasedSavannah",
            "source_icon": ""
          },
          {
            "id": 2040875,
            "name": "Ian Jaeger",
            "url": "https://twitter.com/IanJaeger29",
            "source_icon": ""
          },
          {
            "id": 67050,
            "name": "democrat no more",
            "url": "https://twitter.com/WalkFromDems",
            "source_icon": ""
          },
          {
            "id": 134922,
            "name": "JOHN YOUNG - US NAVY - Ret. #MAGA!",
            "url": "https://twitter.com/jyparkway",
            "source_icon": ""
          },
          {
            "id": 2039064,
            "name": "1mZerOCool\ud83c\udf4e\ud83c\udfb8\ud83e\udd18",
            "url": "https://twitter.com/1mZerOCool",
            "source_icon": ""
          },
          {
            "id": 2040998,
            "name": "Drew \ud83c\uddfa\ud83c\uddf8\u271d\ufe0f",
            "url": "https://twitter.com/Drew3691",
            "source_icon": ""
          },
          {
            "id": 130827,
            "name": "truth news /war,immigration",
            "url": "https://twitter.com/papacs",
            "source_icon": ""
          },
          {
            "id": 2041019,
            "name": "Dardar614 \ud83c\uddfa\ud83c\uddf8\ud83d\ude82",
            "url": "https://twitter.com/Darlene94477614",
            "source_icon": ""
          },
          {
            "id": 109679311,
            "name": "Ga",
            "url": "https://twitter.com/63787975ag",
            "source_icon": ""
          },
          {
            "id": 2041088,
            "name": "Blue-I-Ang",
            "url": "https://twitter.com/veraangie11",
            "source_icon": ""
          },
          {
            "id": 2041172,
            "name": "True Patriot",
            "url": "https://twitter.com/TruePatriot4321",
            "source_icon": ""
          },
          {
            "id": 125668,
            "name": "Yzamendment",
            "url": "https://twitter.com/Yzhadenough",
            "source_icon": ""
          },
          {
            "id": 125025,
            "name": "russ h. \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/RussellHeathcoe",
            "source_icon": ""
          },
          {
            "id": 2038647,
            "name": "Tina",
            "url": "https://twitter.com/swflasenior",
            "source_icon": ""
          },
          {
            "id": 109678596,
            "name": "Hitosi_Suwa",
            "url": "https://twitter.com/2010Phoenix",
            "source_icon": ""
          },
          {
            "id": 95441,
            "name": "Vivienne Coe",
            "url": "https://twitter.com/Audrakat",
            "source_icon": ""
          },
          {
            "id": 2040453,
            "name": "MAGA Joey45-47 \ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Joey_S45",
            "source_icon": ""
          },
          {
            "id": 197671,
            "name": "Nyorky2000",
            "url": "https://twitter.com/nyorky2000",
            "source_icon": ""
          },
          {
            "id": 211449,
            "name": "jron",
            "url": "https://twitter.com/jronnychi",
            "source_icon": ""
          },
          {
            "id": 4618927,
            "name": "Dea Riley",
            "url": "https://twitter.com/DeaRileyKY",
            "source_icon": ""
          },
          {
            "id": 77003,
            "name": "Country girl",
            "url": "https://twitter.com/America1Eagle1",
            "source_icon": ""
          },
          {
            "id": 77128,
            "name": "kath",
            "url": "https://twitter.com/Muskadoptme",
            "source_icon": ""
          },
          {
            "id": 77457,
            "name": "Carol RN *Miss Rush & the Gipper* \ud83d\udc69\u200d\u2695\ufe0f\ud83c\uddfa\ud83c\uddf8 \ud83c\uddee\ud83c\uddf1\ud83e\udd88",
            "url": "https://twitter.com/pasqueflower19",
            "source_icon": ""
          },
          {
            "id": 202232,
            "name": "\u2764\ufe0f\u200d\ud83d\udd25 \ud835\udcd3\ud835\udcea\ud835\udcfb \u2764\ufe0f\u200d\ud83d\udd25",
            "url": "https://twitter.com/DameScorpio",
            "source_icon": ""
          },
          {
            "id": 2052915,
            "name": "Steve Guest",
            "url": "https://twitter.com/SteveGuest",
            "source_icon": ""
          },
          {
            "id": 201825,
            "name": "Dee DeStefano(MAGA mean girl)\ud83d\ude02",
            "url": "https://twitter.com/DeeDeStefano",
            "source_icon": ""
          },
          {
            "id": 82473,
            "name": "@PalasAtenea\ud83c\udf4a",
            "url": "https://twitter.com/AthenaMia2nd",
            "source_icon": ""
          },
          {
            "id": 79461,
            "name": "Mike Porter",
            "url": "https://twitter.com/MikePorterTX",
            "source_icon": ""
          },
          {
            "id": 61749,
            "name": "Les Ebling Jr\u2605",
            "url": "https://twitter.com/EblingJr",
            "source_icon": ""
          },
          {
            "id": 65110268,
            "name": "G S",
            "url": "https://twitter.com/gsleg",
            "source_icon": ""
          },
          {
            "id": 201379,
            "name": "Miranda Devine",
            "url": "https://twitter.com/mirandadevine",
            "source_icon": ""
          },
          {
            "id": 81693,
            "name": "Claudia",
            "url": "https://twitter.com/iOTWclaudia",
            "source_icon": ""
          },
          {
            "id": 81757,
            "name": "Patrick Wagner",
            "url": "https://twitter.com/Patrick40799748",
            "source_icon": ""
          },
          {
            "id": 74162,
            "name": "Barrie21 - \ud83c\uddec\ud83c\udde7\ud83c\uddec\ud83c\udde7 @NeverLabour",
            "url": "https://twitter.com/barrienunn21",
            "source_icon": ""
          },
          {
            "id": 7014404,
            "name": "Brian Tomkinson",
            "url": "https://twitter.com/britom1947",
            "source_icon": ""
          },
          {
            "id": 7125138,
            "name": "RedVegasPatriot21",
            "url": "https://twitter.com/5CitiesV",
            "source_icon": ""
          },
          {
            "id": 73245,
            "name": "DK\ud83c\uddfa\ud83c\uddf8\ud83e\udd85\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/1Nicdar",
            "source_icon": ""
          },
          {
            "id": 210543,
            "name": "Ultra MAGA \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/ColeenC123",
            "source_icon": ""
          },
          {
            "id": 72807,
            "name": "Thomas Dorworth",
            "url": "https://twitter.com/ThomasDorworth",
            "source_icon": ""
          },
          {
            "id": 64633,
            "name": "\ud83c\uddfa\ud83c\uddf8 53 Strong \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/JOHNBOEHME3",
            "source_icon": ""
          },
          {
            "id": 190858100,
            "name": "Kimble Di",
            "url": "https://twitter.com/KimbleDi",
            "source_icon": ""
          },
          {
            "id": 40824,
            "name": "Gerry Callahan",
            "url": "https://twitter.com/GerryCallahan",
            "source_icon": ""
          },
          {
            "id": 205863,
            "name": "celt_memes",
            "url": "https://twitter.com/CelticViking19",
            "source_icon": ""
          },
          {
            "id": 22480599,
            "name": "Gill Smith",
            "url": "https://twitter.com/Gillianamy2911",
            "source_icon": ""
          },
          {
            "id": 69829,
            "name": "Papa Rich",
            "url": "https://twitter.com/PapaRic70082091",
            "source_icon": ""
          },
          {
            "id": 69697,
            "name": "Ltlnicky",
            "url": "https://twitter.com/ltlnicky43",
            "source_icon": ""
          },
          {
            "id": 209198,
            "name": "Marie Baldwin",
            "url": "https://twitter.com/d1baldwin",
            "source_icon": ""
          },
          {
            "id": 43361966,
            "name": "\u5353\u30ad\u30c1\u56f2\u7881\u30ad\u30c1\u9ad8\u5e02\u3055\u3093",
            "url": "https://twitter.com/7IwIch3bWuq2v4o",
            "source_icon": ""
          },
          {
            "id": 206693,
            "name": "Carrie Wehrenberg",
            "url": "https://twitter.com/CarrieWehrenbe2",
            "source_icon": ""
          },
          {
            "id": 40757,
            "name": "\ud83c\uddfa\ud83c\uddf8 SwayTheSwamp \ud83d\udcbf\ud83c\udfbc\ud83d\ude80",
            "url": "https://twitter.com/gbellnasa",
            "source_icon": ""
          },
          {
            "id": 82108,
            "name": "John Ned\ud83d\udc99\ud83c\udf0a\ud83c\uddfa\ud83c\udde6\ud83d\udc4d",
            "url": "https://twitter.com/johndned",
            "source_icon": ""
          },
          {
            "id": 202930,
            "name": "DeplorableDave",
            "url": "https://twitter.com/donttrustgovt",
            "source_icon": ""
          },
          {
            "id": 55458,
            "name": "Pro America Politics",
            "url": "https://twitter.com/Pro__Trading",
            "source_icon": ""
          },
          {
            "id": 86660,
            "name": "Matt Couch",
            "url": "https://twitter.com/RealMattCouch",
            "source_icon": ""
          },
          {
            "id": 41566,
            "name": "ESL teacher NDP=Hamas supporters",
            "url": "https://twitter.com/ESLTeac16885695",
            "source_icon": ""
          },
          {
            "id": 88498,
            "name": "Diane Marie Mazzoni",
            "url": "https://twitter.com/MamaMazzoni",
            "source_icon": ""
          },
          {
            "id": 90302,
            "name": "Funny_Guy",
            "url": "https://twitter.com/FunnyGu31492803",
            "source_icon": ""
          },
          {
            "id": 89153,
            "name": "duane poole @ Duanepoole",
            "url": "https://twitter.com/duanepoole",
            "source_icon": ""
          },
          {
            "id": 197963,
            "name": "Seniorchief45",
            "url": "https://twitter.com/seniorchief45",
            "source_icon": ""
          },
          {
            "id": 214756,
            "name": "LindaB",
            "url": "https://twitter.com/maslbs0604",
            "source_icon": ""
          },
          {
            "id": 85659,
            "name": "USAHipster \u2b50\ufe0f\u2b50\ufe0f\u2b50\ufe0f",
            "url": "https://twitter.com/usa_hipster",
            "source_icon": ""
          },
          {
            "id": 2037938,
            "name": "LUCCHINA_",
            "url": "https://twitter.com/LUCCHINA_",
            "source_icon": ""
          },
          {
            "id": 214916,
            "name": "Carolyn Hoxton",
            "url": "https://twitter.com/CLHoxstone",
            "source_icon": ""
          },
          {
            "id": 2040366,
            "name": "boyjohn",
            "url": "https://twitter.com/havetoolpsv",
            "source_icon": ""
          },
          {
            "id": 2040221,
            "name": "Feisty is proud to be a Democrat!",
            "url": "https://twitter.com/FeistyLibLady",
            "source_icon": ""
          },
          {
            "id": 2040205,
            "name": "Janie Johnson - America is Exceptional",
            "url": "https://twitter.com/jjauthor",
            "source_icon": ""
          },
          {
            "id": 2039456,
            "name": "Hoosier_Friend?",
            "url": "https://twitter.com/hoosier_friend",
            "source_icon": ""
          },
          {
            "id": 2039135,
            "name": "Backbencher",
            "url": "https://twitter.com/Backben54267893",
            "source_icon": ""
          },
          {
            "id": 2039418,
            "name": "Alan",
            "url": "https://twitter.com/YousirnaymTaken",
            "source_icon": ""
          },
          {
            "id": 2040375,
            "name": "Gramps House\u2122",
            "url": "https://twitter.com/gramps_house",
            "source_icon": ""
          },
          {
            "id": 210838,
            "name": "MW",
            "url": "https://twitter.com/shumakerhill",
            "source_icon": ""
          },
          {
            "id": 210879,
            "name": "Todd H",
            "url": "https://twitter.com/Todd_H_225",
            "source_icon": ""
          },
          {
            "id": 219672,
            "name": "Lisa Rand",
            "url": "https://twitter.com/hotontheside",
            "source_icon": ""
          },
          {
            "id": 2039109,
            "name": "Den Byrne",
            "url": "https://twitter.com/DenByrne2",
            "source_icon": ""
          },
          {
            "id": 2039104,
            "name": "Maca Iglesias #Harris-Walz2024\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/rotterdamvvg",
            "source_icon": ""
          },
          {
            "id": 210428,
            "name": "Reficul Ratsgninrom",
            "url": "https://twitter.com/jva20095th",
            "source_icon": ""
          },
          {
            "id": 210378,
            "name": "DD214 Alumni",
            "url": "https://twitter.com/DD214Alum",
            "source_icon": ""
          },
          {
            "id": 215356,
            "name": "Dorothy Hesp",
            "url": "https://twitter.com/DorothyHesp",
            "source_icon": ""
          },
          {
            "id": 2040412,
            "name": "johnny maga",
            "url": "https://twitter.com/_johnnymaga",
            "source_icon": ""
          },
          {
            "id": 2040435,
            "name": "\ud83c\udfdb \ud83c\udf39PeriklestheGREAT \ud83c\udf39 \ud83c\udfdb \"Do Unto Others\"",
            "url": "https://twitter.com/PeriklesGREAT",
            "source_icon": ""
          },
          {
            "id": 215597,
            "name": "Danketh745",
            "url": "https://twitter.com/Becketh711Dan",
            "source_icon": ""
          },
          {
            "id": 208266,
            "name": "Susan Cleary Rittger",
            "url": "https://twitter.com/ClearyRittger",
            "source_icon": ""
          },
          {
            "id": 2040427,
            "name": "twk4usa",
            "url": "https://twitter.com/Tweeklives",
            "source_icon": ""
          },
          {
            "id": 215710,
            "name": "SC REP",
            "url": "https://twitter.com/Lainstep",
            "source_icon": ""
          },
          {
            "id": 2040419,
            "name": "I am Ken",
            "url": "https://twitter.com/Ikennect",
            "source_icon": ""
          },
          {
            "id": 147369976,
            "name": "David",
            "url": "https://twitter.com/Dwarb5",
            "source_icon": ""
          },
          {
            "id": 215515,
            "name": "Michel Petrucci \u2764\ufe0f \ud83c\uddee\ud83c\uddf9",
            "url": "https://twitter.com/appolo007",
            "source_icon": ""
          },
          {
            "id": 209621,
            "name": "The Colossus of N.Y.",
            "url": "https://twitter.com/ColossusofNY",
            "source_icon": ""
          },
          {
            "id": 217645,
            "name": "@joy",
            "url": "https://twitter.com/joy_korneta",
            "source_icon": ""
          },
          {
            "id": 217677,
            "name": "Patricia C.",
            "url": "https://twitter.com/PatriciaSea",
            "source_icon": ""
          },
          {
            "id": 2040410,
            "name": "Brian Cardone \ud83c\udff4\u200d\u2620\ufe0f\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/cardon_brian",
            "source_icon": ""
          },
          {
            "id": 218570,
            "name": "GINA ROW - Karas",
            "url": "https://twitter.com/row_gina",
            "source_icon": ""
          },
          {
            "id": 2039087,
            "name": "Alesha",
            "url": "https://twitter.com/aleshadalin",
            "source_icon": ""
          },
          {
            "id": 220742,
            "name": "Mary Williams",
            "url": "https://twitter.com/MaryDil29664033",
            "source_icon": ""
          },
          {
            "id": 2039065,
            "name": "LA Blue Dot in GA \ud83c\udf0a\ud83d\udc94",
            "url": "https://twitter.com/namwella1961",
            "source_icon": ""
          },
          {
            "id": 2039083,
            "name": "LanaQuest aka RosaSparks",
            "url": "https://twitter.com/LqLana",
            "source_icon": ""
          },
          {
            "id": 213661,
            "name": "Just plain  ASTUTE.",
            "url": "https://twitter.com/MikeXcaberate",
            "source_icon": ""
          },
          {
            "id": 2038533,
            "name": "AVKIII",
            "url": "https://twitter.com/AVK_III",
            "source_icon": ""
          },
          {
            "id": 214209,
            "name": "Georgia Girl",
            "url": "https://twitter.com/susan_irvin12",
            "source_icon": ""
          },
          {
            "id": 2038491,
            "name": "Cochise Adatlichi",
            "url": "https://twitter.com/CAdatlichi",
            "source_icon": ""
          },
          {
            "id": 190855441,
            "name": "jean ward",
            "url": "https://twitter.com/jeannie_19530",
            "source_icon": ""
          },
          {
            "id": 225739,
            "name": "Still\ud83c\udfdd\ufe0fRoaming",
            "url": "https://twitter.com/roaming_rn",
            "source_icon": ""
          },
          {
            "id": 213682,
            "name": "\ud835\udc09\ud835\udc0e\ud835\udc07\ud835\udc0d \ud835\udc16\ud835\udc08\ud835\udc02\ud835\udc0a \ud835\udd4f\u02b0\u2071\u1d57\u1d50\u1d43\u207f \ud83d\udd2b",
            "url": "https://twitter.com/imUrB00gieman",
            "source_icon": ""
          },
          {
            "id": 211937,
            "name": "Son of the Village Idiot Opinions & Parody",
            "url": "https://twitter.com/sonvillageidiot",
            "source_icon": ""
          },
          {
            "id": 225851,
            "name": "@JosephGilleland \ud83c\uddee\ud83c\uddf1",
            "url": "https://twitter.com/josephgilleland",
            "source_icon": ""
          },
          {
            "id": 214238,
            "name": "Dr. Rich Palazzolo",
            "url": "https://twitter.com/dr_palazzolo",
            "source_icon": ""
          },
          {
            "id": 252542,
            "name": "itsallmostmidnightbb",
            "url": "https://t.me/itsallmostmidnightbb",
            "source_icon": ""
          },
          {
            "id": 211964,
            "name": "Lean Right",
            "url": "https://twitter.com/LeanRight6",
            "source_icon": ""
          },
          {
            "id": 213269,
            "name": "mjhubbard\ud83c\uddfa\ud83c\uddf8Hold Fauci and China Accountable",
            "url": "https://twitter.com/mjhubbard1",
            "source_icon": ""
          },
          {
            "id": 256158,
            "name": "Raheem Kassam",
            "url": "https://t.me/RaheemKassam",
            "source_icon": ""
          },
          {
            "id": 2038011,
            "name": "NoTaxForTracksP--MAGA \ud83c\udf4a",
            "url": "https://twitter.com/NoTaxForTracksP",
            "source_icon": ""
          },
          {
            "id": 212711,
            "name": "Robert Butler",
            "url": "https://twitter.com/Gibbylespaul",
            "source_icon": ""
          },
          {
            "id": 2037996,
            "name": "Jason Coursey",
            "url": "https://twitter.com/JasonCoursey",
            "source_icon": ""
          },
          {
            "id": 2038540,
            "name": "That One Guy",
            "url": "https://twitter.com/SucitBish",
            "source_icon": ""
          },
          {
            "id": 190856674,
            "name": "Black Dogs Matter",
            "url": "https://twitter.com/jomaruss",
            "source_icon": ""
          },
          {
            "id": 212366,
            "name": "Pedal to the metal \u2018till you see God, then brake!",
            "url": "https://twitter.com/thefloridarose",
            "source_icon": ""
          },
          {
            "id": 2039031,
            "name": "Catturd \u2122",
            "url": "https://twitter.com/catturd2",
            "source_icon": ""
          },
          {
            "id": 2039054,
            "name": "Daniel F. Baranowski",
            "url": "https://twitter.com/DFBHarvard",
            "source_icon": ""
          },
          {
            "id": 211208,
            "name": "Kristi Lade \ud83c\uddfa\ud83c\uddf2",
            "url": "https://twitter.com/LadeKristi",
            "source_icon": ""
          },
          {
            "id": 211221,
            "name": "CT2020",
            "url": "https://twitter.com/Cynthia34318447",
            "source_icon": ""
          },
          {
            "id": 211277,
            "name": "I Will Not Comply",
            "url": "https://twitter.com/YouknowTheguy3",
            "source_icon": ""
          },
          {
            "id": 2039049,
            "name": "Antonio Sabato Jr",
            "url": "https://twitter.com/AntonioSabatoJr",
            "source_icon": ""
          },
          {
            "id": 223896,
            "name": "Ewart, Dave",
            "url": "https://twitter.com/davidbewart",
            "source_icon": ""
          },
          {
            "id": 2039043,
            "name": "Ultra MAGA Joyce Day",
            "url": "https://twitter.com/Daytobehappy",
            "source_icon": ""
          },
          {
            "id": 211338,
            "name": "Ca",
            "url": "https://twitter.com/CaAmericanLady",
            "source_icon": ""
          },
          {
            "id": 214367,
            "name": "Mom\u2019s Right",
            "url": "https://twitter.com/ex_lefty",
            "source_icon": ""
          },
          {
            "id": 2038938,
            "name": "Rick \u201cNo One\u201d Robinson \ud83c\uddfa\ud83c\uddf8 \ud83c\uddee\ud83c\uddf1",
            "url": "https://twitter.com/RowdyRick73",
            "source_icon": ""
          },
          {
            "id": 2038931,
            "name": "Lewis3288",
            "url": "https://twitter.com/LewisBo68063286",
            "source_icon": ""
          },
          {
            "id": 170162281,
            "name": "Catturd \u2122",
            "url": "https://t.me/catturd2",
            "source_icon": ""
          },
          {
            "id": 2038717,
            "name": "FLMowerDude",
            "url": "https://twitter.com/Floridadude76",
            "source_icon": ""
          },
          {
            "id": 224677,
            "name": "LeBon Travel Culture",
            "url": "https://twitter.com/LeBonTravel",
            "source_icon": ""
          },
          {
            "id": 2038709,
            "name": "\ud835\udd4d\ud835\udd60\ud835\udd5a\ud835\udd54\ud835\udd56\ud835\udd46\ud835\udd57\ud835\udd4b\ud835\udd59\ud835\udd56\ud835\udd3d\ud835\udd60\ud835\udd63\ud835\udd58\ud835\udd60\ud835\udd65\ud835\udd65\ud835\udd56\ud835\udd5f \ud83c\uddec\ud83c\udde7 \ud83c\uddff\ud83c\uddfc",
            "url": "https://twitter.com/GiftCee",
            "source_icon": ""
          },
          {
            "id": 190854061,
            "name": "Ian Harding",
            "url": "https://twitter.com/iharding51",
            "source_icon": ""
          },
          {
            "id": 224789,
            "name": "Susan Dietz \ud83c\uddfa\ud83c\uddf8\ud83e\udd85\ud83e\udd81\ud83c\udf4a\ud83d\ude9a",
            "url": "https://twitter.com/Calim1701",
            "source_icon": ""
          },
          {
            "id": 2040884,
            "name": "\ud83c\uddfa\ud83c\uddf8\ud835\udcd2\ud835\udcf1\ud835\udcea\ud835\udced\ud83c\uddfa\ud83c\uddf8\ud835\udcd7\ud835\udcea\ud835\udcfb\ud835\udcfb\ud835\udcf2\ud835\udcfc\ud835\udcf8\ud835\udcf7\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Texas_jeep__guy",
            "source_icon": ""
          },
          {
            "id": 2040454,
            "name": "\ud83c\uddfa\ud83c\uddf8 Digital Warrior \ud83c\uddfa\ud83c\uddf8 Real Patriot Not MAGA",
            "url": "https://twitter.com/DigitalWarfare1",
            "source_icon": ""
          },
          {
            "id": 2050011,
            "name": "TexasStong",
            "url": "https://twitter.com/Richard31865731",
            "source_icon": ""
          },
          {
            "id": 2049782,
            "name": "ConservativeDame",
            "url": "https://twitter.com/Oliver_Murray15",
            "source_icon": ""
          },
          {
            "id": 2049484,
            "name": "marina",
            "url": "https://twitter.com/marinasmigielsk",
            "source_icon": ""
          },
          {
            "id": 77404180,
            "name": "Antonello Romeo",
            "url": "https://twitter.com/Antobello",
            "source_icon": ""
          },
          {
            "id": 2049148,
            "name": "Jarvis Patriot \ud83c\uddfa\ud83c\uddf8\ud83e\udd81",
            "url": "https://twitter.com/JarvisCyberKing",
            "source_icon": ""
          },
          {
            "id": 81115065,
            "name": "Anna Albo",
            "url": "https://twitter.com/AnnaAlbo2",
            "source_icon": ""
          },
          {
            "id": 2049021,
            "name": "HoneyWest25",
            "url": "https://twitter.com/HoneyWest25x",
            "source_icon": ""
          },
          {
            "id": 2049002,
            "name": "TenleyHemingway@gmail.com",
            "url": "https://twitter.com/HemingwayT64182",
            "source_icon": ""
          },
          {
            "id": 2045719,
            "name": "Catturd",
            "url": "https://t.me/catturd_2",
            "source_icon": ""
          },
          {
            "id": 2044781,
            "name": "LARSONTV",
            "url": "https://t.me/larsontv1",
            "source_icon": ""
          },
          {
            "id": 2044733,
            "name": "Torah et Yeshoua Ruth Israelite",
            "url": "https://t.me/torahetyeshoua",
            "source_icon": ""
          },
          {
            "id": 2043205,
            "name": "LynnVac",
            "url": "https://twitter.com/LynnVac66",
            "source_icon": ""
          },
          {
            "id": 2043202,
            "name": "The Godfather",
            "url": "https://twitter.com/bostonuniv1987",
            "source_icon": ""
          },
          {
            "id": 2043199,
            "name": "SoCal Trump Gal",
            "url": "https://twitter.com/SoCalTrumpGal",
            "source_icon": ""
          },
          {
            "id": 85266023,
            "name": "CODE RED \ud83d\udea8\u2618\ufe0f\ud83c\uddfa\ud83c\uddf8",
            "url": "https://t.me/RedPillDealer4833",
            "source_icon": ""
          },
          {
            "id": 2042477,
            "name": "dcnh",
            "url": "https://twitter.com/dcnh42",
            "source_icon": ""
          },
          {
            "id": 2042472,
            "name": "New York Deplorable\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/DeplorableNew",
            "source_icon": ""
          },
          {
            "id": 2042384,
            "name": "$12 Carne Asada Burrito",
            "url": "https://twitter.com/monsterburro",
            "source_icon": ""
          },
          {
            "id": 2042372,
            "name": "Gitmo (Health is a Wealth) \ud83c\uddfa\ud83c\uddf8\ud83c\uddee\ud83c\uddf1",
            "url": "https://twitter.com/Gitmo99",
            "source_icon": ""
          },
          {
            "id": 2042361,
            "name": "THE REAL DARK JUDGE",
            "url": "https://twitter.com/ROYALMRBADNEWS",
            "source_icon": ""
          },
          {
            "id": 2042360,
            "name": "Beth Lore",
            "url": "https://twitter.com/BethLore1",
            "source_icon": ""
          },
          {
            "id": 2042209,
            "name": "We are the Change",
            "url": "https://twitter.com/1stAD123",
            "source_icon": ""
          },
          {
            "id": 2042200,
            "name": "\ud83e\udd14Snarky Oddball ~ Cat lady 4 America! \ud83c\udf0a",
            "url": "https://twitter.com/Rebel_MemeMaw",
            "source_icon": ""
          },
          {
            "id": 2042191,
            "name": "blessyou2",
            "url": "https://twitter.com/blessyou24",
            "source_icon": ""
          },
          {
            "id": 85613037,
            "name": "Free The Will Patriots [FTW]",
            "url": "https://t.me/FreeTheWillPatriots",
            "source_icon": ""
          },
          {
            "id": 2042076,
            "name": "Goo T. Gwaba",
            "url": "https://twitter.com/GooGwaba",
            "source_icon": ""
          },
          {
            "id": 2042050,
            "name": "arnoldrimmer",
            "url": "https://twitter.com/arnoldrimmer",
            "source_icon": ""
          },
          {
            "id": 2042039,
            "name": "RMD",
            "url": "https://twitter.com/rdownslll",
            "source_icon": ""
          },
          {
            "id": 70051039,
            "name": "\ud83c\udf9aK.J. Pritchard \ud83c\uddfa\ud83c\uddf8America First",
            "url": "https://twitter.com/KJPritchard4",
            "source_icon": ""
          },
          {
            "id": 69541327,
            "name": "Howard Rothenburg",
            "url": "https://twitter.com/hrothenb",
            "source_icon": ""
          },
          {
            "id": 2040463,
            "name": "MagaManPaitrotDeplorable",
            "url": "https://twitter.com/MagaManSings",
            "source_icon": ""
          },
          {
            "id": 2051698,
            "name": "Have That Going 4 Me",
            "url": "https://twitter.com/Have_thatgoing4",
            "source_icon": ""
          },
          {
            "id": 28947063,
            "name": "Angy",
            "url": "https://twitter.com/Angela43748961",
            "source_icon": ""
          },
          {
            "id": 27668885,
            "name": "Dogstar \ud83c\uddec\ud83c\udde7\ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc65\udb40\udc6e\udb40\udc67\udb40\udc7f\ud83c\uddee\ud83c\uddea #VoteReform",
            "url": "https://twitter.com/jasonhellawell",
            "source_icon": ""
          },
          {
            "id": 25583254,
            "name": "brian",
            "url": "https://twitter.com/brianstwitfeed",
            "source_icon": ""
          },
          {
            "id": 25300704,
            "name": "DDAWG94",
            "url": "https://twitter.com/Ddawg94X",
            "source_icon": ""
          },
          {
            "id": 22864250,
            "name": "\ud83d\udd25\u2b50\ufe0fEdwin\u2b50\ufe0f\ud83d\udd25",
            "url": "https://twitter.com/Nuked4Every1",
            "source_icon": ""
          },
          {
            "id": 22288603,
            "name": "APM",
            "url": "https://twitter.com/APM53852183",
            "source_icon": ""
          },
          {
            "id": 44516028,
            "name": "Kristi Bykowski",
            "url": "https://twitter.com/AGypsyGirlLife",
            "source_icon": ""
          },
          {
            "id": 45488793,
            "name": "AnnRoss \ud83c\udf34\ud83e\udd65",
            "url": "https://twitter.com/AnnRoss201111",
            "source_icon": ""
          },
          {
            "id": 17302426,
            "name": "Paul Huckle \ud83d\udc8e\ud83c\uddec\ud83c\udde7\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/huckle_paul",
            "source_icon": ""
          },
          {
            "id": 13579805,
            "name": "Bob",
            "url": "https://twitter.com/Bubba8882",
            "source_icon": ""
          },
          {
            "id": 9196315,
            "name": "Gary Lamphier",
            "url": "https://twitter.com/lamphieryeg",
            "source_icon": ""
          },
          {
            "id": 8115017,
            "name": "PatriotDonny",
            "url": "https://t.me/patriodonny",
            "source_icon": ""
          },
          {
            "id": 8050327,
            "name": "John Harwood",
            "url": "https://twitter.com/JohnJHarwood",
            "source_icon": ""
          },
          {
            "id": 6446891,
            "name": "HumanFund",
            "url": "https://twitter.com/HumanXfund",
            "source_icon": ""
          },
          {
            "id": 6367372,
            "name": "\u0412\u0430\u0441\u0438\u043b\u044c",
            "url": "https://twitter.com/2022Vasyl",
            "source_icon": ""
          },
          {
            "id": 6328279,
            "name": "Nils",
            "url": "https://twitter.com/Nova_covic",
            "source_icon": ""
          },
          {
            "id": 3849706,
            "name": "\u26a1\ufe0f5150 Bill \ud83c\udfc4\u200d\u2642\ufe0f\ud83d\ude0e\ud83e\udd19\ud83c\udffc\u26a1\ufe0f",
            "url": "https://twitter.com/5150TatorSalad",
            "source_icon": ""
          },
          {
            "id": 2648754,
            "name": "Jeffrey Meursing MA",
            "url": "https://twitter.com/JeffreyMeursing",
            "source_icon": ""
          },
          {
            "id": 45568385,
            "name": "Snyggo \ud83e\ude93\ud83c\udf84\ud83d\udc18\ud83e\udd53\ud83d\udd2b\ud83d\ude9c\ud83d\udc04",
            "url": "https://twitter.com/Snyggois",
            "source_icon": ""
          },
          {
            "id": 2053149,
            "name": "Willi@m",
            "url": "https://twitter.com/William93105180",
            "source_icon": ""
          },
          {
            "id": 2052684,
            "name": "RBe",
            "url": "https://twitter.com/RBPundit",
            "source_icon": ""
          },
          {
            "id": 46461421,
            "name": "June Hurst \ud83c\uddec\ud83c\udde7\ud83c\uddee\ud83c\uddf1\ud83d\udc08\u200d\u2b1b\ud83d\udc1d\ud83c\udf37\ud83c\udf3b",
            "url": "https://twitter.com/juneh49",
            "source_icon": ""
          },
          {
            "id": 51361594,
            "name": "\u0442\u043e\u0439 \u0441\u0430\u043c\u0438\u0439",
            "url": "https://twitter.com/Joestar05973294",
            "source_icon": ""
          },
          {
            "id": 2052248,
            "name": "MayoMonkey \ud83d\udc30",
            "url": "https://twitter.com/MayoMonkeyredux",
            "source_icon": ""
          },
          {
            "id": 2051863,
            "name": "Jennifer \ud83c\uddfa\ud83c\uddf8 \ud83e\udd85",
            "url": "https://twitter.com/Jenny_MommaLion",
            "source_icon": ""
          },
          {
            "id": 65215497,
            "name": "freespeech",
            "url": "https://twitter.com/grizzly712",
            "source_icon": ""
          },
          {
            "id": 2051720,
            "name": "bittersweet Mary",
            "url": "https://twitter.com/hippyheart4ever",
            "source_icon": ""
          },
          {
            "id": 2042025,
            "name": "Donald J. Trump - Parody",
            "url": "https://twitter.com/realDonParody",
            "source_icon": ""
          },
          {
            "id": 89025942,
            "name": "Tucker Carlson",
            "url": "https://t.me/TuckerCarlsonTVOfficial",
            "source_icon": ""
          },
          {
            "id": 2042014,
            "name": "Jen \ud83c\uddfa\ud83c\uddf8\ud83c\udff4\u200d\u2620\ufe0f",
            "url": "https://twitter.com/jerrieskid",
            "source_icon": ""
          },
          {
            "id": 94524764,
            "name": "30 EXACTLY",
            "url": "https://t.me/exactly30",
            "source_icon": ""
          },
          {
            "id": 2041070,
            "name": "Donald J. Trump Posts From His Truth Social",
            "url": "https://twitter.com/TrumpDailyPosts",
            "source_icon": ""
          },
          {
            "id": 2041064,
            "name": "TrumpWonPDP",
            "url": "https://twitter.com/pdpmaga",
            "source_icon": ""
          },
          {
            "id": 109679987,
            "name": "Anita Lanc\u00e9e",
            "url": "https://twitter.com/APLancee",
            "source_icon": ""
          },
          {
            "id": 2040976,
            "name": "\ud83d\udc93 MsBetty-Boop \ud83d\udc93",
            "url": "https://twitter.com/Ms_Betty_Bop",
            "source_icon": ""
          },
          {
            "id": 2040921,
            "name": "MrHappyNash \ud83c\udfb6\ud83d\udc95\ud83c\udfb8\ud83c\udfba\ud83c\udf7b\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/HumbleAnthem",
            "source_icon": ""
          },
          {
            "id": 2040919,
            "name": "GrumpyAss President Elect",
            "url": "https://twitter.com/fitz52006",
            "source_icon": ""
          },
          {
            "id": 2040916,
            "name": "MAGA_FROG \ud83d\ude4f\ud83c\udffb\u271d\ufe0f\ud83d\ude4f\ud83c\udffb\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/FROGGY_SLING",
            "source_icon": ""
          },
          {
            "id": 109680683,
            "name": "Alice Alice",
            "url": "https://twitter.com/AliceAl89041699",
            "source_icon": ""
          },
          {
            "id": 109681111,
            "name": "Annoula64 \u24c5",
            "url": "https://twitter.com/Annoula64",
            "source_icon": ""
          },
          {
            "id": 2040908,
            "name": "Jenny 1776\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/realouMAGAgirl",
            "source_icon": ""
          },
          {
            "id": 2040874,
            "name": "\ud835\udcd2\ud835\udcf8\ud835\udcf7\ud835\udcfc\ud835\udcee\ud835\udcfb\ud835\udcff\ud835\udcea\ud835\udcfd\ud835\udcf2\ud835\udcff\ud835\udcee\uea00\ud835\udcd3\ud835\udcf2\ud835\udcff\ud835\udcea\u2122",
            "url": "https://twitter.com/1776Diva",
            "source_icon": ""
          },
          {
            "id": 109687291,
            "name": "Irene48",
            "url": "https://twitter.com/Irene48",
            "source_icon": ""
          },
          {
            "id": 109687997,
            "name": "JohnARead",
            "url": "https://twitter.com/JohnARead",
            "source_icon": ""
          },
          {
            "id": 2040846,
            "name": "\ud83c\uddfa\ud83c\uddf2PatriotPuppy\u2764\ufe0f\ud83c\uddfa\ud83c\uddf2",
            "url": "https://twitter.com/MAGA_Puppy",
            "source_icon": ""
          },
          {
            "id": 2040768,
            "name": "mzhanz",
            "url": "https://twitter.com/SandraBHaynes45",
            "source_icon": ""
          },
          {
            "id": 2040733,
            "name": "ULTRA MAGA PROJEKT JNCOJOK\u2b50\ufe0f\u2b50\ufe0f\u2b50\ufe0f\ud83c\udf4a",
            "url": "https://twitter.com/jncojok3",
            "source_icon": ""
          },
          {
            "id": 2040638,
            "name": "MAGABoss2024 \ud83c\uddfa\ud83c\uddf8\u271d\ufe0f",
            "url": "https://twitter.com/MagaBoss2024",
            "source_icon": ""
          },
          {
            "id": 128381600,
            "name": "Patriots Light Warriors",
            "url": "https://t.me/patriotsligthwarriors",
            "source_icon": ""
          },
          {
            "id": 2040636,
            "name": "Nexus7901",
            "url": "https://twitter.com/nexus7901",
            "source_icon": ""
          },
          {
            "id": 2040634,
            "name": "Darrell. FJB & FKH Ultra MAGA Supporter",
            "url": "https://twitter.com/darrellhockens1",
            "source_icon": ""
          },
          {
            "id": 2040570,
            "name": "\ud83c\uddfa\ud83c\uddf2 Texas Ultra-MAGA Right Wing Nut \ud83c\uddfa\ud83c\uddf2\u2199\ufe0f",
            "url": "https://twitter.com/RightnTexas",
            "source_icon": ""
          },
          {
            "id": 2040558,
            "name": "Brenda\ud83d\udc9946 - Build Back Better \ud83d\ude97\ud83d\ude95\ud83d\ude99",
            "url": "https://twitter.com/kinley_brenda",
            "source_icon": ""
          },
          {
            "id": 2040557,
            "name": "Sean\u2618\ufe0f (Americans 1st\ud83c\uddfa\ud83c\uddf8 MAGA)",
            "url": "https://twitter.com/SeanSean252",
            "source_icon": ""
          },
          {
            "id": 2040550,
            "name": "Paul Wallace",
            "url": "https://twitter.com/PaulWallace201",
            "source_icon": ""
          },
          {
            "id": 140397221,
            "name": "Catturd",
            "url": "https://t.me/catturd2real",
            "source_icon": ""
          },
          {
            "id": 2040525,
            "name": "Joy \u2665\ufe0f\ud83c\uddfa\ud83c\uddf8 \ud83e\udd85",
            "url": "https://twitter.com/redtrikerjoy",
            "source_icon": ""
          },
          {
            "id": 2040498,
            "name": "Ultra Mega MAGA \ud83c\udf4a \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/St24451533",
            "source_icon": ""
          },
          {
            "id": 2041122,
            "name": "Beverly",
            "url": "https://twitter.com/bwmetts",
            "source_icon": ""
          },
          {
            "id": 2041125,
            "name": "Qbensis",
            "url": "https://twitter.com/qbensys",
            "source_icon": ""
          },
          {
            "id": 2041169,
            "name": "Heidi",
            "url": "https://twitter.com/HeidiL_RN",
            "source_icon": ""
          },
          {
            "id": 2041448,
            "name": "Princess Grace of Ife JP \ud83c\uddfa\ud83c\uddf2 \ud83c\udf0a",
            "url": "https://twitter.com/POlubunmi",
            "source_icon": ""
          },
          {
            "id": 2042006,
            "name": "Tim Pool \ud83c\uddee\ud83c\uddf1\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/Timcast",
            "source_icon": ""
          },
          {
            "id": 2041827,
            "name": "\ud83c\udf0aLiz FreedomToVote Hostbody",
            "url": "https://twitter.com/LizOT4U",
            "source_icon": ""
          },
          {
            "id": 2041820,
            "name": "Dan \ud83c\udf0a\ud83c\udf0a\ud83c\udf0a\ud83c\udf0a\ud83c\udf0a\ud83c\udf0a\u2764\ufe0f\ud83c\udd98",
            "url": "https://twitter.com/djlane",
            "source_icon": ""
          },
          {
            "id": 2041797,
            "name": "Terri Loves America \ud83d\udfe7\ud83d\udc99\ud83d\udc94\ud83d\udfe6",
            "url": "https://twitter.com/ProudAFAmerican",
            "source_icon": ""
          },
          {
            "id": 2041684,
            "name": "The Chris Salcedo Show",
            "url": "https://twitter.com/CSalcedoShow",
            "source_icon": ""
          },
          {
            "id": 2041682,
            "name": "Edwards Anderson",
            "url": "https://twitter.com/EdwardsAnd46206",
            "source_icon": ""
          },
          {
            "id": 2041505,
            "name": "Samuel Whittemore",
            "url": "https://twitter.com/SamuelWhittem14",
            "source_icon": ""
          },
          {
            "id": 2041482,
            "name": "ElizabethC \u2696\ufe0f\ud83c\uddfa\ud83c\udde6\ud83c\uddee\ud83c\uddf1\ud83d\udc9b\ud83d\udc99\ud83c\udf3b",
            "url": "https://twitter.com/econklin4",
            "source_icon": ""
          },
          {
            "id": 2041478,
            "name": "Mad_as_hell at weirdos \ud83c\uddfa\ud83c\uddf8\ud83c\udf3b\ud83c\udff3\ufe0f\u200d\ud83c\udf08\ud83d\udfe7",
            "url": "https://twitter.com/Mad_as_heII",
            "source_icon": ""
          },
          {
            "id": 2041470,
            "name": "DUBSbayarea",
            "url": "https://twitter.com/DubSbayarea",
            "source_icon": ""
          },
          {
            "id": 2041463,
            "name": "@Eileenvan55 #\ud83d\udfe6 Eileen to the left \ud83d\ude37",
            "url": "https://twitter.com/eileenvan55",
            "source_icon": ""
          },
          {
            "id": 2041451,
            "name": "Just Joan \ud83c\uddfa\ud83c\uddf8\ud83d\udc99\ud83c\udff3\ufe0f\u200d\ud83c\udf08\ud83d\udc99\ud83c\uddfa\ud83c\udde6\ud83d\udc99\ud83c\uddf3\ud83c\uddf4",
            "url": "https://twitter.com/jmrbux2",
            "source_icon": ""
          },
          {
            "id": 2041433,
            "name": "Brenda \ud83c\udf1e\ud835\udcc5\ud835\udcc9\ud835\udcbe\ud835\udcc2\ud835\udcbe\ud835\udcc8\ud835\udcc9 \ud83d\udc99",
            "url": "https://twitter.com/bkaydw",
            "source_icon": ""
          },
          {
            "id": 109678795,
            "name": "Ellie C",
            "url": "https://twitter.com/2kcmnl",
            "source_icon": ""
          },
          {
            "id": 2041427,
            "name": "Quoth the Raven Nevermore \ud83c\uddfa\ud83c\uddf8\ud83c\uddec\ud83c\udde7\ud83c\uddee\ud83c\uddf1\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/LadyRaven3",
            "source_icon": ""
          },
          {
            "id": 2041423,
            "name": "The Proud Blue Texan \ud83c\uddfa\ud83c\uddf8\ud83c\udff3\ufe0f\u200d\ud83c\udf08\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/ProgressiveTex",
            "source_icon": ""
          },
          {
            "id": 2041410,
            "name": "Phoenix \ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/MacLinx",
            "source_icon": ""
          },
          {
            "id": 2041407,
            "name": "\ud83c\uddfa\ud83c\uddf8\ud83c\uddee\ud83c\uddf1\u2708\ufe0fSir Flyzalot\u2708\ufe0f\ud83c\uddee\ud83c\uddf1\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/SirFlyzalot",
            "source_icon": ""
          },
          {
            "id": 95520552,
            "name": "Oilbets",
            "url": "https://twitter.com/oilbets",
            "source_icon": ""
          },
          {
            "id": 2041319,
            "name": "Twicz52",
            "url": "https://twitter.com/MikeT5219",
            "source_icon": ""
          },
          {
            "id": 109677404,
            "name": "Paul Peeters",
            "url": "https://twitter.com/PaulPeete25",
            "source_icon": ""
          },
          {
            "id": 2041250,
            "name": "Mark Wallbank",
            "url": "https://twitter.com/49b21322",
            "source_icon": ""
          },
          {
            "id": 207730,
            "name": "Jery Smyt",
            "url": "https://twitter.com/copper90000",
            "source_icon": ""
          },
          {
            "id": 2041186,
            "name": "StormTrooper45",
            "url": "https://twitter.com/lock_twist",
            "source_icon": ""
          },
          {
            "id": 2041178,
            "name": "\u2666\ufe0fAnn \u2666\ufe0f",
            "url": "https://twitter.com/WTFoxtrot10",
            "source_icon": ""
          },
          {
            "id": 2041176,
            "name": "\ud83c\uddfa\ud83c\uddf8 Army of the Awakened \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/armyoftheawaken",
            "source_icon": ""
          },
          {
            "id": 2041194,
            "name": "\ud83d\udda4\ud83e\udee6SammieSam\ud83e\udee6\ud83d\udda4",
            "url": "https://twitter.com/BarryBleunNila2",
            "source_icon": ""
          },
          {
            "id": 19430,
            "name": "David Frum",
            "url": "https://twitter.com/davidfrum",
            "source_icon": ""
          },
          {
            "id": 206913,
            "name": "beth powers/MAGA (BETHPOWERS14) STARTED, \"HEY JOE\"",
            "url": "https://twitter.com/msboogieman22",
            "source_icon": ""
          },
          {
            "id": 78013,
            "name": "FutureTrump2\ufe0f\u20e3\u23fa2\ufe0f\u20e34\u20e3\ud83c\udf4a",
            "url": "https://twitter.com/RealTrump2020_",
            "source_icon": ""
          },
          {
            "id": 103353,
            "name": "Stan Spak",
            "url": "https://twitter.com/stanspak",
            "source_icon": ""
          },
          {
            "id": 103072,
            "name": "total news junkie",
            "url": "https://twitter.com/dartgunintel",
            "source_icon": ""
          },
          {
            "id": 102925,
            "name": "John Wood",
            "url": "https://twitter.com/Jwood5050",
            "source_icon": ""
          },
          {
            "id": 102924,
            "name": "Deb C \u262e\ufe0f\ud83c\uddfa\ud83c\uddf8\ud83c\udf0a\ud83d\udc99",
            "url": "https://twitter.com/debc17593354",
            "source_icon": ""
          },
          {
            "id": 102405,
            "name": "Maximus",
            "url": "https://twitter.com/Maximus16837261",
            "source_icon": ""
          },
          {
            "id": 102116,
            "name": "Steve Howe",
            "url": "https://twitter.com/MattM5001",
            "source_icon": ""
          },
          {
            "id": 99824,
            "name": "Common Sense",
            "url": "https://twitter.com/nevrcomplicated",
            "source_icon": ""
          },
          {
            "id": 97410,
            "name": "LEER OLDHAM",
            "url": "https://twitter.com/knightwings38",
            "source_icon": ""
          },
          {
            "id": 95850,
            "name": "Katrina Ski",
            "url": "https://twitter.com/MtRushmore2016",
            "source_icon": ""
          },
          {
            "id": 95365,
            "name": "#M U G A I N B I O",
            "url": "https://twitter.com/smchatter1",
            "source_icon": ""
          },
          {
            "id": 94191,
            "name": "darweesh heiba",
            "url": "https://twitter.com/DarweeshHeiba",
            "source_icon": ""
          },
          {
            "id": 93006,
            "name": "Whoahbaby",
            "url": "https://twitter.com/whoahbaby22",
            "source_icon": ""
          },
          {
            "id": 91193,
            "name": "arizona cowgirl",
            "url": "https://twitter.com/ConnieBreeden8",
            "source_icon": ""
          },
          {
            "id": 88935,
            "name": "D Russell\ud83c\uddfa\ud83c\uddf8ULTRA-MAGA",
            "url": "https://twitter.com/DRussel55238296",
            "source_icon": ""
          },
          {
            "id": 87728,
            "name": "DarkLadyPatriot",
            "url": "https://twitter.com/DarkLadyPatriot",
            "source_icon": ""
          },
          {
            "id": 87673,
            "name": "Look A Squirrel",
            "url": "https://twitter.com/Reds_Herring",
            "source_icon": ""
          },
          {
            "id": 87480,
            "name": "Ray Jackson",
            "url": "https://twitter.com/ZiloElGrande",
            "source_icon": ""
          },
          {
            "id": 87452,
            "name": "LC",
            "url": "https://twitter.com/logical_lop",
            "source_icon": ""
          },
          {
            "id": 86417,
            "name": "Frab -Hell is empty, and all the devils are here\ud83c\udf4a",
            "url": "https://twitter.com/frabikins",
            "source_icon": ""
          },
          {
            "id": 86192,
            "name": "Chicago_Rosie",
            "url": "https://twitter.com/Chicago_Rosie",
            "source_icon": ""
          },
          {
            "id": 83791,
            "name": "HopeNotPC",
            "url": "https://twitter.com/HopeNotPC",
            "source_icon": ""
          },
          {
            "id": 83629,
            "name": "Dan Hemp",
            "url": "https://twitter.com/hemp_dan",
            "source_icon": ""
          },
          {
            "id": 83500,
            "name": "John Yost",
            "url": "https://twitter.com/YostHager",
            "source_icon": ""
          },
          {
            "id": 82669,
            "name": "JUGGERNAUT TRUMP! JESUS IS LORD!",
            "url": "https://twitter.com/56_Jeff",
            "source_icon": ""
          },
          {
            "id": 80670,
            "name": "MA_NYC \ud83e\udeb7",
            "url": "https://twitter.com/ma_nyc7",
            "source_icon": ""
          },
          {
            "id": 80488,
            "name": "Pasquinal",
            "url": "https://twitter.com/NASM106",
            "source_icon": ""
          },
          {
            "id": 79863,
            "name": "Ellen Dibble \ud83c\udf3b\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/EllenDibble",
            "source_icon": ""
          },
          {
            "id": 79667,
            "name": "\u2661 \ud835\udcd9\ud835\udcee\ud835\udcfc\ud835\udcfc\ud835\udcf2\ud835\udcee \u2661\ud83c\udf3f\ud83c\udf37\ud83c\udf3f\ud83d\udc8b\ud83d\ude18",
            "url": "https://twitter.com/jessies_now",
            "source_icon": ""
          },
          {
            "id": 79658,
            "name": "CrashDavis",
            "url": "https://twitter.com/CrashDavis8",
            "source_icon": ""
          },
          {
            "id": 104136,
            "name": "dcnh",
            "url": "https://twitter.com/davec_NH",
            "source_icon": ""
          },
          {
            "id": 104438,
            "name": "Halle MAGA",
            "url": "https://twitter.com/Halle2017",
            "source_icon": ""
          },
          {
            "id": 105134,
            "name": "Dave Wilburn MAGA",
            "url": "https://twitter.com/Burnman11",
            "source_icon": ""
          },
          {
            "id": 116242,
            "name": "\ud83c\uddfa\ud83c\uddf8 Regular Joe \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/tempio_joe",
            "source_icon": ""
          },
          {
            "id": 123729,
            "name": "Flo from Conservative",
            "url": "https://twitter.com/fpatterson1050",
            "source_icon": ""
          },
          {
            "id": 122684,
            "name": "elarry77@gmail.com",
            "url": "https://twitter.com/larenceInglan",
            "source_icon": ""
          },
          {
            "id": 122382,
            "name": "Live long and prosper \ud83d\ude4f",
            "url": "https://twitter.com/sunshineday250",
            "source_icon": ""
          },
          {
            "id": 121992,
            "name": "Christine",
            "url": "https://twitter.com/Christhecatsmum",
            "source_icon": ""
          },
          {
            "id": 120708,
            "name": "@Ronnymalone \u2693\ud83c\udf39\u271d\ud83d\udee1\u2694",
            "url": "https://twitter.com/Ronnymalone3",
            "source_icon": ""
          },
          {
            "id": 119020,
            "name": "Mary\u2764\ufe0f\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/patriotmary4",
            "source_icon": ""
          },
          {
            "id": 118734,
            "name": "rosesamericana",
            "url": "https://twitter.com/rosesamericana1",
            "source_icon": ""
          },
          {
            "id": 118477,
            "name": "Matt Szafranski",
            "url": "https://twitter.com/MSzafranski413",
            "source_icon": ""
          },
          {
            "id": 118335,
            "name": "I, SUPER ULTRA-MAGA Prince! \ud83c\uddfa\ud83c\uddf8\u26bd\ud83c\uddee\ud83c\uddf1\ud83c\udfc1\ud83c\udfb9",
            "url": "https://twitter.com/Cigars1776",
            "source_icon": ""
          },
          {
            "id": 118329,
            "name": "\ud83c\uddfa\ud83c\uddf8\u271d\ufe0f",
            "url": "https://twitter.com/mioficialcuenta",
            "source_icon": ""
          },
          {
            "id": 117828,
            "name": "dopaminergic13 \ud83d\udc2d",
            "url": "https://twitter.com/dopaminergic13",
            "source_icon": ""
          },
          {
            "id": 117615,
            "name": "CAL USA",
            "url": "https://twitter.com/CALUSA18",
            "source_icon": ""
          },
          {
            "id": 117021,
            "name": "Jim Agin \ud83c\uddfa\ud83c\uddf8\ud83d\udc99",
            "url": "https://twitter.com/jaangry",
            "source_icon": ""
          },
          {
            "id": 115421,
            "name": "indigoctarine",
            "url": "https://twitter.com/indigoctarine",
            "source_icon": ""
          },
          {
            "id": 105318,
            "name": "Miss Norma Jeane \ud83d\uddfd\ud83c\uddfa\ud83c\uddf8\ud83e\ude78 \ud83e\uddb7 \ud83c\udff4\u200d\u2620\ufe0f\ud83d\udc8b\ud83d\udc3e",
            "url": "https://twitter.com/rosanthony3",
            "source_icon": ""
          },
          {
            "id": 114418,
            "name": "David Shor",
            "url": "https://twitter.com/DYShor",
            "source_icon": ""
          },
          {
            "id": 112944,
            "name": "RANDOLPH HAYES",
            "url": "https://twitter.com/RANDOLPHHAYES4",
            "source_icon": ""
          },
          {
            "id": 112093,
            "name": "Rebecca C Payton\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/RebeccaCPayton2",
            "source_icon": ""
          },
          {
            "id": 111164,
            "name": "Josh Marshall",
            "url": "https://twitter.com/joshtpm",
            "source_icon": ""
          },
          {
            "id": 110866,
            "name": "Crash_the_Flash \ud83d\udc2d",
            "url": "https://twitter.com/Crash_the_Flash",
            "source_icon": ""
          },
          {
            "id": 110090,
            "name": "Brian Craig \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/BrianCraigShow",
            "source_icon": ""
          },
          {
            "id": 109847,
            "name": "C.M. Mac",
            "url": "https://twitter.com/Christo30624433",
            "source_icon": ""
          },
          {
            "id": 109208,
            "name": "Pepe le Pew \u00a9\ufe0f\ud835\udde2\ud835\uddf3\ud835\uddf3\ud835\uddf6\ud835\uddf0\ud835\uddf6\ud835\uddee\ud835\uddf9 \u2122\ufe0f",
            "url": "https://twitter.com/AlrightMyLovely",
            "source_icon": ""
          },
          {
            "id": 109035,
            "name": "Our MSM is the enemy of democracy!",
            "url": "https://twitter.com/LanceNadeau3",
            "source_icon": ""
          },
          {
            "id": 108976,
            "name": "JustMe",
            "url": "https://twitter.com/Adopt_A_Rescue",
            "source_icon": ""
          },
          {
            "id": 107884,
            "name": "MP \u2764\ufe0f\ud83c\uddfa\ud83c\uddf8\ud83c\udf0e\u2696\ufe0f",
            "url": "https://twitter.com/fritchee",
            "source_icon": ""
          },
          {
            "id": 106420,
            "name": "@Investigator_50",
            "url": "https://twitter.com/Investigator_50",
            "source_icon": ""
          },
          {
            "id": 105785,
            "name": "\ud83c\udde6\ud83c\uddfaTigers Rock!!\ud83d\udc05\ud83c\udf38(AJ Smith)\ud83c\udde6\ud83c\uddfa\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/EFAY1EIGHT",
            "source_icon": ""
          },
          {
            "id": 78141,
            "name": "mo",
            "url": "https://twitter.com/MohawkMikeCats",
            "source_icon": ""
          },
          {
            "id": 77932,
            "name": "Lady Tigress",
            "url": "https://twitter.com/LadyConstance8",
            "source_icon": ""
          },
          {
            "id": 125667,
            "name": "Christine F Chumley\u270d\ud83c\udffb",
            "url": "https://twitter.com/seeCFC",
            "source_icon": ""
          },
          {
            "id": 77654,
            "name": "Greg",
            "url": "https://twitter.com/gdrazman",
            "source_icon": ""
          },
          {
            "id": 56658,
            "name": "Lott's_TakeOurNationBack",
            "url": "https://twitter.com/USA_Lott22",
            "source_icon": ""
          },
          {
            "id": 56350,
            "name": "Never, EVER, give up",
            "url": "https://twitter.com/glenheadmama",
            "source_icon": ""
          },
          {
            "id": 53934,
            "name": "PatriotGirl1776*Caroline",
            "url": "https://twitter.com/SicilianGirl208",
            "source_icon": ""
          },
          {
            "id": 53730,
            "name": "Glockford Files",
            "url": "https://twitter.com/GlockfordFiles",
            "source_icon": ""
          },
          {
            "id": 53341,
            "name": "Tom Elliott",
            "url": "https://twitter.com/tomselliott",
            "source_icon": ""
          },
          {
            "id": 53079,
            "name": "Laurence (Larry) Boorstein",
            "url": "https://twitter.com/LarryBoorstein",
            "source_icon": ""
          },
          {
            "id": 52689,
            "name": "Jamie Fain\ud83c\uddfa\ud83c\uddf8\ud83c\uddee\ud83c\uddf1\ud83c\udf97\ufe0f",
            "url": "https://twitter.com/JamieFain66",
            "source_icon": ""
          },
          {
            "id": 52080,
            "name": "ahtlam",
            "url": "https://twitter.com/ahtlam",
            "source_icon": ""
          },
          {
            "id": 52023,
            "name": "David Knight",
            "url": "https://twitter.com/DaveKnight1776",
            "source_icon": ""
          },
          {
            "id": 51925,
            "name": "Hodgetwins",
            "url": "https://twitter.com/hodgetwins",
            "source_icon": ""
          },
          {
            "id": 51628,
            "name": "Rick Berry",
            "url": "https://twitter.com/rberrysfo94111",
            "source_icon": ""
          },
          {
            "id": 50802,
            "name": "\ud83d\udc0fRAMMIE\ud83d\udc0f",
            "url": "https://twitter.com/Rammie24",
            "source_icon": ""
          },
          {
            "id": 50638,
            "name": "MC-\ud83c\udf37",
            "url": "https://twitter.com/jinks1054",
            "source_icon": ""
          },
          {
            "id": 49946,
            "name": "James Jay Carafano",
            "url": "https://twitter.com/JJCarafano",
            "source_icon": ""
          },
          {
            "id": 49384,
            "name": "James M.",
            "url": "https://twitter.com/jmclen08",
            "source_icon": ""
          },
          {
            "id": 48396,
            "name": "JamieRJN",
            "url": "https://twitter.com/JamieRJN",
            "source_icon": ""
          },
          {
            "id": 48044,
            "name": "Andrew J Phelan",
            "url": "https://twitter.com/ajphelo",
            "source_icon": ""
          },
          {
            "id": 47183,
            "name": "Steafan Dubhuidhe (comrade/comrades)",
            "url": "https://twitter.com/AnarchoTerran",
            "source_icon": ""
          },
          {
            "id": 46940,
            "name": "Derek Gendvil",
            "url": "https://twitter.com/dgendvil",
            "source_icon": ""
          },
          {
            "id": 46869,
            "name": "Paul J Elliott",
            "url": "https://twitter.com/PaulJElliott",
            "source_icon": ""
          },
          {
            "id": 46810,
            "name": "\u201c TRUTH Speaker\u201d",
            "url": "https://twitter.com/FreeAdvice767",
            "source_icon": ""
          },
          {
            "id": 45961,
            "name": "Rteefact \ud83c\udf0a\u2327 \u2327 looking fo 5000 followers",
            "url": "https://twitter.com/Rteefact",
            "source_icon": ""
          },
          {
            "id": 45385,
            "name": "\ud83c\uddfa\ud83c\uddf2\ud83d\udea2CAPTAIN JON\ud83e\udd85\ud83c\udf0e\u2693",
            "url": "https://twitter.com/Capitaine_Jon",
            "source_icon": ""
          },
          {
            "id": 44548,
            "name": "Jack Banker",
            "url": "https://twitter.com/Jabanker",
            "source_icon": ""
          },
          {
            "id": 42602,
            "name": "Ann Carriage",
            "url": "https://twitter.com/cher1511",
            "source_icon": ""
          },
          {
            "id": 41806,
            "name": "Zena, Red Pilled Redhead",
            "url": "https://twitter.com/ZenaSawn",
            "source_icon": ""
          },
          {
            "id": 41538,
            "name": "1 CMacman",
            "url": "https://twitter.com/1Cmacman",
            "source_icon": ""
          },
          {
            "id": 41151,
            "name": "Anticipation",
            "url": "https://twitter.com/mailabull",
            "source_icon": ""
          },
          {
            "id": 40936,
            "name": "Selene",
            "url": "https://twitter.com/agravematter",
            "source_icon": ""
          },
          {
            "id": 58573,
            "name": "Alexblx",
            "url": "https://twitter.com/Alexblx",
            "source_icon": ""
          },
          {
            "id": 60302,
            "name": "NOfP-X",
            "url": "https://twitter.com/NOfPPlus",
            "source_icon": ""
          },
          {
            "id": 61728,
            "name": "mrwagmi.btc \ud83d\udfe7",
            "url": "https://twitter.com/mrwagmibtc",
            "source_icon": ""
          },
          {
            "id": 71332,
            "name": "Domenica D'Elia",
            "url": "https://twitter.com/domenicadelia22",
            "source_icon": ""
          },
          {
            "id": 77612,
            "name": "Harry",
            "url": "https://twitter.com/harrytpk",
            "source_icon": ""
          },
          {
            "id": 77195,
            "name": "Check Mark - The Unofficial Account",
            "url": "https://twitter.com/Omegama36117904",
            "source_icon": ""
          },
          {
            "id": 76608,
            "name": "Amy Atwood",
            "url": "https://twitter.com/AmyAtwood2",
            "source_icon": ""
          },
          {
            "id": 76542,
            "name": "Magadoniansmcrider\ud83d\udde3",
            "url": "https://twitter.com/ShirleyCaminiti",
            "source_icon": ""
          },
          {
            "id": 76305,
            "name": "Roger Edde \u0631\u0648\u062c\u064a\u0647 \u0625\u062f\u0651\u0647",
            "url": "https://twitter.com/EddeRoger",
            "source_icon": ""
          },
          {
            "id": 75526,
            "name": "Dianne Whitehead",
            "url": "https://twitter.com/diannew314159",
            "source_icon": ""
          },
          {
            "id": 74603,
            "name": "Sal Vadacchino\ud83c\udde8\ud83c\udde6",
            "url": "https://twitter.com/SalVadacchino1",
            "source_icon": ""
          },
          {
            "id": 74047,
            "name": "Yujin",
            "url": "https://twitter.com/Yujinesque",
            "source_icon": ""
          },
          {
            "id": 74009,
            "name": "Jim Hittler",
            "url": "https://twitter.com/HittlerJim",
            "source_icon": ""
          },
          {
            "id": 73998,
            "name": "Kathy Ultra MAGA Extremist \ud83c\udf4a\ud83c\udf4a\ud83c\udf4a\ud83c\udf4a\ud83c\udf4a",
            "url": "https://twitter.com/KLUltraMaga",
            "source_icon": ""
          },
          {
            "id": 73914,
            "name": "tricky dicky",
            "url": "https://twitter.com/trickyd90556041",
            "source_icon": ""
          },
          {
            "id": 73003,
            "name": "Daniel Meier \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/DanielMeier_",
            "source_icon": ""
          },
          {
            "id": 72912,
            "name": "Mega MAGA WebDiva playing with \ud83d\udd25 running with \u2702\ufe0f",
            "url": "https://twitter.com/OlyWebDiva",
            "source_icon": ""
          },
          {
            "id": 71079,
            "name": "Mgtywzrd",
            "url": "https://twitter.com/Mgtywzrd1",
            "source_icon": ""
          },
          {
            "id": 62070,
            "name": "Jaye T.",
            "url": "https://twitter.com/JayeJaybird54",
            "source_icon": ""
          },
          {
            "id": 70753,
            "name": "marie-louise hjelm",
            "url": "https://twitter.com/CesarHjelm",
            "source_icon": ""
          },
          {
            "id": 70294,
            "name": "\ud83c\uddfa\ud83c\uddf8\ud83e\udd85\ud83c\udf97\ufe0f\ud83e\udeb7 \ud83c\uddfa\ud83c\udde6 \ud83c\uddee\ud83c\uddf1 Catherine 4 HarrisWalz2024",
            "url": "https://twitter.com/CMargaronis",
            "source_icon": ""
          },
          {
            "id": 70261,
            "name": "nogrowthcole",
            "url": "https://twitter.com/NogrowthCole",
            "source_icon": ""
          },
          {
            "id": 69586,
            "name": "EJE",
            "url": "https://twitter.com/sanfran_972",
            "source_icon": ""
          },
          {
            "id": 68333,
            "name": "Mary Alvarez",
            "url": "https://twitter.com/MaryAlv67558984",
            "source_icon": ""
          },
          {
            "id": 68087,
            "name": "Old Lady",
            "url": "https://twitter.com/MicheleWojnar",
            "source_icon": ""
          },
          {
            "id": 68058,
            "name": "BARB59 / F/NO DMs #\ud83c\uddfa\ud83c\udde6 #FvckPutin #DemCast",
            "url": "https://twitter.com/ABrosnikoff",
            "source_icon": ""
          },
          {
            "id": 66480,
            "name": "Dr. Shannon, Mistress of She0igans",
            "url": "https://twitter.com/shay3322",
            "source_icon": ""
          },
          {
            "id": 66378,
            "name": "Penny Farrington",
            "url": "https://twitter.com/PennyFarringt14",
            "source_icon": ""
          },
          {
            "id": 66279,
            "name": "New Yorker \ud83c\uddfa\ud83c\uddf8 \ud83c\udde8\ud83c\udde6 \ud83d\udfe6\ud83e\udd40\ud83c\udf3b",
            "url": "https://twitter.com/ThomB01",
            "source_icon": ""
          },
          {
            "id": 66075,
            "name": "The\ud83d\udc30FOO",
            "url": "https://twitter.com/PolitiBunny",
            "source_icon": ""
          },
          {
            "id": 64047,
            "name": "@M.K.Sikh.",
            "url": "https://twitter.com/petersingh206",
            "source_icon": ""
          },
          {
            "id": 62483,
            "name": "CricketDiane",
            "url": "https://twitter.com/AmBeautifulShow",
            "source_icon": ""
          },
          {
            "id": 124959,
            "name": "Thomas B Stovall Jr\ud83c\uddfa\ud83c\uddf8\u26f7",
            "url": "https://twitter.com/bstovalljr",
            "source_icon": ""
          },
          {
            "id": 126814,
            "name": "\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8KATHY\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/mygammie",
            "source_icon": ""
          },
          {
            "id": 206311,
            "name": "The UK is now full blown communist",
            "url": "https://twitter.com/rolivier",
            "source_icon": ""
          },
          {
            "id": 173424,
            "name": "Susan Glover",
            "url": "https://twitter.com/SusanGloverNC",
            "source_icon": ""
          },
          {
            "id": 194719,
            "name": "Rich Hoffman",
            "url": "https://twitter.com/overmanwarrior",
            "source_icon": ""
          },
          {
            "id": 193906,
            "name": "kback",
            "url": "https://twitter.com/kbackous",
            "source_icon": ""
          },
          {
            "id": 193880,
            "name": "cordelia lear",
            "url": "https://twitter.com/iowemysoul",
            "source_icon": ""
          },
          {
            "id": 193781,
            "name": "Phil De Carolis",
            "url": "https://twitter.com/PhilDeCarolis",
            "source_icon": ""
          },
          {
            "id": 193709,
            "name": "Blue Collar Executive",
            "url": "https://twitter.com/A_Sober_Drunk",
            "source_icon": ""
          },
          {
            "id": 40151,
            "name": "Terrell Jermaine Starr \ud83c\uddfa\ud83c\udde6\ud83c\udf3b\ud83c\uddf5\ud83c\uddf8\ud83c\udf49\u270a\ud83c\udffe\ud83c\uddec\ud83c\uddea",
            "url": "https://twitter.com/terrelljstarr",
            "source_icon": ""
          },
          {
            "id": 192591,
            "name": "Victor Giardina",
            "url": "https://twitter.com/siciliantaurus",
            "source_icon": ""
          },
          {
            "id": 191197,
            "name": "Jimmy Mitchell",
            "url": "https://twitter.com/vanjimbo",
            "source_icon": ""
          },
          {
            "id": 190752,
            "name": "HelpSaveAmerica-Ultra MAGA\u271d\ud83d\uddfd\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/GraceJoyPeace55",
            "source_icon": ""
          },
          {
            "id": 190723,
            "name": "nonsumdignus",
            "url": "https://twitter.com/nonsumdignus",
            "source_icon": ""
          },
          {
            "id": 190473,
            "name": "Tony Monetti \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/MonettiforMO",
            "source_icon": ""
          },
          {
            "id": 190328,
            "name": "Frank Silvia",
            "url": "https://twitter.com/FrankSilvia9",
            "source_icon": ""
          },
          {
            "id": 189963,
            "name": "Robin",
            "url": "https://twitter.com/RobinAlldeen",
            "source_icon": ""
          },
          {
            "id": 188759,
            "name": "Carl Parker",
            "url": "https://twitter.com/CarlPar80787825",
            "source_icon": ""
          },
          {
            "id": 188216,
            "name": "Free2Speak2",
            "url": "https://twitter.com/Jigsaw_Jules",
            "source_icon": ""
          },
          {
            "id": 188041,
            "name": "\u271e\u2655In God I Trust\u2655\u271e",
            "url": "https://twitter.com/InGodIDoTrust",
            "source_icon": ""
          },
          {
            "id": 184227,
            "name": "freeamerican",
            "url": "https://twitter.com/emss1922",
            "source_icon": ""
          },
          {
            "id": 183967,
            "name": "CarrieLWebb \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/webb_carrie",
            "source_icon": ""
          },
          {
            "id": 181265,
            "name": "Brigitte Gabriel",
            "url": "https://twitter.com/ACTBrigitte",
            "source_icon": ""
          },
          {
            "id": 181012,
            "name": "Obligate Carnivore Mike",
            "url": "https://twitter.com/jmichaelp62",
            "source_icon": ""
          },
          {
            "id": 180982,
            "name": "Common Sense",
            "url": "https://twitter.com/MichaelC0717",
            "source_icon": ""
          },
          {
            "id": 180720,
            "name": "Make L.A. Great Again \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/lalovestrump",
            "source_icon": ""
          },
          {
            "id": 179095,
            "name": "BadLindaRobot",
            "url": "https://twitter.com/BadrobotLinda",
            "source_icon": ""
          },
          {
            "id": 177380,
            "name": "mommy bear \ud83c\udf0a \ud83c\udf0a\ud83c\udf0a \ud83d\udc89\ud83d\udc89\ud83d\udc89\ud83c\uddfa\ud83c\udde6\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/grandmabear44",
            "source_icon": ""
          },
          {
            "id": 177111,
            "name": "CattyScatbrat \ud83c\udff4\u200d\u2620\ufe0f | \ud83e\ude9d",
            "url": "https://twitter.com/RIPDaveHill",
            "source_icon": ""
          },
          {
            "id": 176754,
            "name": "R",
            "url": "https://twitter.com/_RadicalReality",
            "source_icon": ""
          },
          {
            "id": 175667,
            "name": "JMB \ud83c\uddfa\ud83c\uddf2 \ud83c\udf39\ud83d\uddfd",
            "url": "https://twitter.com/JGzGurl24",
            "source_icon": ""
          },
          {
            "id": 175252,
            "name": "Norris Cole - The Kabin Coronation Street",
            "url": "https://twitter.com/Pete20221",
            "source_icon": ""
          },
          {
            "id": 174932,
            "name": "Ad2it",
            "url": "https://twitter.com/Ad2it2",
            "source_icon": ""
          },
          {
            "id": 195154,
            "name": "PattyDandthearrogantshitzu",
            "url": "https://twitter.com/ScotsIrishRose",
            "source_icon": ""
          },
          {
            "id": 195380,
            "name": "The Seeker",
            "url": "https://twitter.com/parella_anthony",
            "source_icon": ""
          },
          {
            "id": 195810,
            "name": "Elbert C. Lyon",
            "url": "https://twitter.com/omahabe2",
            "source_icon": ""
          },
          {
            "id": 200836,
            "name": "Sandra \ud83d\udc94\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/SandraSBreen",
            "source_icon": ""
          },
          {
            "id": 205967,
            "name": "Eugene W. White, Jr",
            "url": "https://twitter.com/Ewwjr53",
            "source_icon": ""
          },
          {
            "id": 205119,
            "name": "Bella",
            "url": "https://twitter.com/NorrisPatriot",
            "source_icon": ""
          },
          {
            "id": 205076,
            "name": "ExNYer18",
            "url": "https://twitter.com/ExNYer18",
            "source_icon": ""
          },
          {
            "id": 205031,
            "name": "Ziva",
            "url": "https://twitter.com/zivaspeaks_ziva",
            "source_icon": ""
          },
          {
            "id": 204529,
            "name": "C-Reason\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/CreasonJana",
            "source_icon": ""
          },
          {
            "id": 204526,
            "name": "Barbara Ann Day",
            "url": "https://twitter.com/Day1Barbara",
            "source_icon": ""
          },
          {
            "id": 204335,
            "name": "God bless the USA! \ud83c\uddfa\ud83c\uddf8\ud83d\ude4f",
            "url": "https://twitter.com/re88465915",
            "source_icon": ""
          },
          {
            "id": 204144,
            "name": "Jael Israel (ALPHA FEMALE) \ud83c\uddee\ud83c\uddf1 \ud83c\uddfa\ud83c\uddf8 \ud83e\udd70",
            "url": "https://twitter.com/mommovesherbaby",
            "source_icon": ""
          },
          {
            "id": 203973,
            "name": "On A Bender",
            "url": "https://twitter.com/on_bender",
            "source_icon": ""
          },
          {
            "id": 203409,
            "name": "Steve DiBari (Dump Kamala)",
            "url": "https://twitter.com/DibariSteve",
            "source_icon": ""
          },
          {
            "id": 203361,
            "name": "Arvo G Beckwith",
            "url": "https://twitter.com/BeckwithArvo",
            "source_icon": ""
          },
          {
            "id": 202056,
            "name": "trustTheGovernment",
            "url": "https://twitter.com/TomasFrancis11",
            "source_icon": ""
          },
          {
            "id": 201384,
            "name": "AERO207\ud83d\udcafULTRAMAGA",
            "url": "https://twitter.com/rebelwitacauz",
            "source_icon": ""
          },
          {
            "id": 200676,
            "name": "James Curry",
            "url": "https://twitter.com/James40594",
            "source_icon": ""
          },
          {
            "id": 196723,
            "name": "LouiseThemis",
            "url": "https://twitter.com/LouiseThemis",
            "source_icon": ""
          },
          {
            "id": 200521,
            "name": "Freedom Martini \ud83c\uddfa\ud83c\uddf8 Dry And Dirty \ud83c\udf78",
            "url": "https://twitter.com/freedom_martini",
            "source_icon": ""
          },
          {
            "id": 200391,
            "name": "Frank",
            "url": "https://twitter.com/Frank49049002",
            "source_icon": ""
          },
          {
            "id": 200303,
            "name": "Rebecca Leach",
            "url": "https://twitter.com/JesusIsCalling",
            "source_icon": ""
          },
          {
            "id": 199563,
            "name": "YinzAwake",
            "url": "https://twitter.com/BurghGurl",
            "source_icon": ""
          },
          {
            "id": 199295,
            "name": "kate",
            "url": "https://twitter.com/buckley_kate",
            "source_icon": ""
          },
          {
            "id": 199138,
            "name": "Probable Spam",
            "url": "https://twitter.com/TheeJoeGlass",
            "source_icon": ""
          },
          {
            "id": 199037,
            "name": "The Conservative M. D. \ud83d\udde8\ufe0f\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/WarriorsForAll",
            "source_icon": ""
          },
          {
            "id": 198921,
            "name": "Ani & Brewed Cofi",
            "url": "https://twitter.com/AniR113",
            "source_icon": ""
          },
          {
            "id": 198497,
            "name": "Sandman0530",
            "url": "https://twitter.com/CarlEagan1",
            "source_icon": ""
          },
          {
            "id": 198013,
            "name": "Not My White V-jj",
            "url": "https://twitter.com/julesfuels1979",
            "source_icon": ""
          },
          {
            "id": 197995,
            "name": "LiLi",
            "url": "https://twitter.com/Gone2theBeach",
            "source_icon": ""
          },
          {
            "id": 197458,
            "name": "Christine E",
            "url": "https://twitter.com/ChristineMarieB",
            "source_icon": ""
          },
          {
            "id": 197018,
            "name": "southpaw outlaw",
            "url": "https://twitter.com/Vining5Brian",
            "source_icon": ""
          },
          {
            "id": 174528,
            "name": "JulietteSchwartz",
            "url": "https://twitter.com/Juliett59778255",
            "source_icon": ""
          },
          {
            "id": 172494,
            "name": "karl m koons",
            "url": "https://twitter.com/KoonsKarl",
            "source_icon": ""
          },
          {
            "id": 127201,
            "name": "Chris Plumley",
            "url": "https://twitter.com/reggaecrisp1",
            "source_icon": ""
          },
          {
            "id": 172278,
            "name": "DrGratefulAC19",
            "url": "https://twitter.com/gratefulAC19",
            "source_icon": ""
          },
          {
            "id": 139818,
            "name": "DKD",
            "url": "https://twitter.com/Brkahe",
            "source_icon": ""
          },
          {
            "id": 139287,
            "name": "Logan Riley",
            "url": "https://twitter.com/R34lLoganRiley",
            "source_icon": ""
          },
          {
            "id": 139142,
            "name": "Pawpawdude\ud83c\udf4a",
            "url": "https://twitter.com/pawpawdude",
            "source_icon": ""
          },
          {
            "id": 139076,
            "name": "ThunderB\ud83c\uddfa\ud83c\uddf8\ud83c\uddee\ud83c\uddf1 Avenge Them",
            "url": "https://twitter.com/Pimpernell13",
            "source_icon": ""
          },
          {
            "id": 138977,
            "name": "Lillith O",
            "url": "https://twitter.com/Orland1M",
            "source_icon": ""
          },
          {
            "id": 138757,
            "name": "goort1409\ud83c\uddf9\ud83c\udded\ud83c\uddf3\ud83c\uddf1\ud83d\ude9c",
            "url": "https://twitter.com/goort1409",
            "source_icon": ""
          },
          {
            "id": 138717,
            "name": "Dr. Quinn Medicine Woman \ud83c\uddfa\ud83c\uddf8\ud83c\udde8\ud83c\udde6",
            "url": "https://twitter.com/miaharrison59",
            "source_icon": ""
          },
          {
            "id": 138056,
            "name": "Politcswatch",
            "url": "https://twitter.com/Politcswatch",
            "source_icon": ""
          },
          {
            "id": 138011,
            "name": "Captain Beaux",
            "url": "https://twitter.com/CaptbeauX",
            "source_icon": ""
          },
          {
            "id": 137999,
            "name": "Richard D Halas",
            "url": "https://twitter.com/RichardHalas",
            "source_icon": ""
          },
          {
            "id": 137978,
            "name": "Andrew @ Don\u2019t Walk, RUN!",
            "url": "https://twitter.com/DontWalkRUN",
            "source_icon": ""
          },
          {
            "id": 137060,
            "name": "Ariel",
            "url": "https://twitter.com/ArielBos1",
            "source_icon": ""
          },
          {
            "id": 136972,
            "name": "Officer\ud83c\udf1fSharon",
            "url": "https://twitter.com/officersharon",
            "source_icon": ""
          },
          {
            "id": 136692,
            "name": "BlissfulWalksforHealth\ud83c\udf4a",
            "url": "https://twitter.com/Threeti09269839",
            "source_icon": ""
          },
          {
            "id": 135826,
            "name": "Benjamin Franklins Cousin",
            "url": "https://twitter.com/Kevin19908477",
            "source_icon": ""
          },
          {
            "id": 134348,
            "name": "Anthea Bailey",
            "url": "https://twitter.com/anthea_bailey",
            "source_icon": ""
          },
          {
            "id": 132848,
            "name": "Linda Cosy",
            "url": "https://twitter.com/lindacosylife",
            "source_icon": ""
          },
          {
            "id": 132349,
            "name": "Sam Thomas",
            "url": "https://twitter.com/Ugomego",
            "source_icon": ""
          },
          {
            "id": 131780,
            "name": "Dale Cooper",
            "url": "https://twitter.com/AgentCoopr702",
            "source_icon": ""
          },
          {
            "id": 130881,
            "name": "Dr. Regina Michele, J.D. \ud83d\udc1d\ud83d\udc9b#\ud83d\udfe6\ud83d\udfe7\ud83e\udd43",
            "url": "https://twitter.com/Chiangel1984",
            "source_icon": ""
          },
          {
            "id": 130829,
            "name": "Nick Donnelly",
            "url": "https://twitter.com/ProtecttheFaith",
            "source_icon": ""
          },
          {
            "id": 130809,
            "name": "\u210c\ud835\udd22\ud835\udd1e\ud835\udd31\ud835\udd25\ud835\udd22\ud835\udd2f \ud835\udd1e\ud835\udd2f\ud835\udd31\ud835\udd25\ud835\udd32\ud835\udd2f",
            "url": "https://twitter.com/Heathermarymag",
            "source_icon": ""
          },
          {
            "id": 130704,
            "name": "Donald Ray Massee",
            "url": "https://twitter.com/drmassee",
            "source_icon": ""
          },
          {
            "id": 130497,
            "name": "critical mix theory",
            "url": "https://twitter.com/allidoismix",
            "source_icon": ""
          },
          {
            "id": 130318,
            "name": "Andrew",
            "url": "https://twitter.com/Fi0ceTX",
            "source_icon": ""
          },
          {
            "id": 130074,
            "name": "@Dltimber488",
            "url": "https://twitter.com/Dltimber05",
            "source_icon": ""
          },
          {
            "id": 129729,
            "name": "EllyB",
            "url": "https://twitter.com/EllyB53292373",
            "source_icon": ""
          },
          {
            "id": 128308,
            "name": "Dorothy Dent",
            "url": "https://twitter.com/organicdot",
            "source_icon": ""
          },
          {
            "id": 127490,
            "name": "\u0928\u0930\u0947\u0928\u094d\u0926\u094d\u0930 \u091c\u094b\u0936\u0940 \ud83c\uddee\ud83c\uddf3",
            "url": "https://twitter.com/naren_33",
            "source_icon": ""
          },
          {
            "id": 140121,
            "name": "Stanley E Cook",
            "url": "https://twitter.com/StanleyECook",
            "source_icon": ""
          },
          {
            "id": 140404,
            "name": "Ann Karsaw",
            "url": "https://twitter.com/sawchuk_karen",
            "source_icon": ""
          },
          {
            "id": 140884,
            "name": "Pam Golden",
            "url": "https://twitter.com/PamGold77",
            "source_icon": ""
          },
          {
            "id": 162646,
            "name": "Bill Sprague",
            "url": "https://twitter.com/BillSprague43",
            "source_icon": ""
          },
          {
            "id": 170642,
            "name": "My Info",
            "url": "https://twitter.com/MendiolaGrandma",
            "source_icon": ""
          },
          {
            "id": 170504,
            "name": "AF-Pak Hand",
            "url": "https://twitter.com/stevesi28838948",
            "source_icon": ""
          },
          {
            "id": 170456,
            "name": "DRode \u262e\ufe0f Medias Mighty #WeAreAllUkraine\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/debr3322",
            "source_icon": ""
          },
          {
            "id": 169300,
            "name": "kathleen \ud83c\uddfa\ud83c\uddf8\u262e\ufe0f",
            "url": "https://twitter.com/kathlee73732050",
            "source_icon": ""
          },
          {
            "id": 169113,
            "name": "Peter",
            "url": "https://twitter.com/peterforslin",
            "source_icon": ""
          },
          {
            "id": 168969,
            "name": "Mag \ud83d\udc99\ud83d\udcaa\ud83c\udff3\ufe0f\u200d\ud83c\udf08\ud83c\uddfa\ud83c\udde6\ud83e\uddb8\u200d\u2640\ufe0f",
            "url": "https://twitter.com/TheMagdasini",
            "source_icon": ""
          },
          {
            "id": 168473,
            "name": "\u269c \u2022 \ud835\udd78\ud835\udd8e\ud835\udd88\ud835\udd8d\ud835\udd86\ud835\udd8a\ud835\udd91 \u2022 \u269c",
            "url": "https://twitter.com/BasedSolutions1",
            "source_icon": ""
          },
          {
            "id": 167788,
            "name": "Staycation -#TrudeauMustGo",
            "url": "https://twitter.com/Staycat23098138",
            "source_icon": ""
          },
          {
            "id": 166843,
            "name": "ALETTAHA\ud83d\udc8e\ud83e\udd17\ud83d\udc9c\ud83c\udf1e",
            "url": "https://twitter.com/ALETTAHA",
            "source_icon": ""
          },
          {
            "id": 165602,
            "name": "America First! - Americans First!",
            "url": "https://twitter.com/REDCO131",
            "source_icon": ""
          },
          {
            "id": 163368,
            "name": "MMCOWRD (AKA.. MadCow)",
            "url": "https://twitter.com/MMCOWRD",
            "source_icon": ""
          },
          {
            "id": 163295,
            "name": "D",
            "url": "https://twitter.com/DerekC2016",
            "source_icon": ""
          },
          {
            "id": 163239,
            "name": "TallyAnnaE \u262e\ufe0f\ud83e\udd85\ud83c\uddfa\ud83c\uddf8 \ud83e\udd65\ud83c\udf34\ud83c\udf0a\ud83c\udff3\ufe0f\u200d\u26a7\ufe0f\ud83c\udff3\ufe0f\u200d\ud83c\udf08",
            "url": "https://twitter.com/TallyAnnaE",
            "source_icon": ""
          },
          {
            "id": 161949,
            "name": "Heidi-Maaria Leinonen",
            "url": "https://twitter.com/HeidiLeinonen",
            "source_icon": ""
          },
          {
            "id": 141596,
            "name": "Rob",
            "url": "https://twitter.com/LBIRob1971",
            "source_icon": ""
          },
          {
            "id": 161704,
            "name": "CanadianPatriot..LFG",
            "url": "https://twitter.com/TrumpiestGOAT",
            "source_icon": ""
          },
          {
            "id": 159198,
            "name": "Mr Legit \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/HankOSkilz",
            "source_icon": ""
          },
          {
            "id": 153802,
            "name": "Lara Runger",
            "url": "https://twitter.com/xanaduladylara",
            "source_icon": ""
          },
          {
            "id": 152378,
            "name": "Mitch Smith \u2615\ufe0f",
            "url": "https://twitter.com/mitchmws",
            "source_icon": ""
          },
          {
            "id": 151819,
            "name": "Deirdre Walsh",
            "url": "https://twitter.com/magicdmw",
            "source_icon": ""
          },
          {
            "id": 148179,
            "name": "Col. Rob Maness ret. \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/RobManess",
            "source_icon": ""
          },
          {
            "id": 146286,
            "name": "JessinWis",
            "url": "https://twitter.com/jessinwis",
            "source_icon": ""
          },
          {
            "id": 144875,
            "name": "ATX Irish Gal \ud83d\ude4f\ud83c\udffc\ud83c\uddfa\ud83c\uddf8\u2764\ufe0f",
            "url": "https://twitter.com/Notmyfault99",
            "source_icon": ""
          },
          {
            "id": 144428,
            "name": "Marla",
            "url": "https://twitter.com/marla_vous",
            "source_icon": ""
          },
          {
            "id": 143079,
            "name": "Ron Ellis",
            "url": "https://twitter.com/RonEllis1",
            "source_icon": ""
          },
          {
            "id": 141904,
            "name": "Kerry O RN.MBA \ud83d\udc2d",
            "url": "https://twitter.com/KerryOLeary4",
            "source_icon": ""
          },
          {
            "id": 141644,
            "name": "AuditTheMachines",
            "url": "https://twitter.com/koumiss",
            "source_icon": ""
          },
          {
            "id": 141638,
            "name": "Ultra MagaBA\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Brookltnwilliw",
            "source_icon": ""
          },
          {
            "id": 190858766,
            "name": "lee",
            "url": "https://twitter.com/LeeLmcnulty",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 0.0,
          "negative_share": 100.0,
          "reactions_analyzed": 66766,
          "comments_analyzed": 1269
        }
      },
      {
        "id": 2,
        "publication_date": "2024-08-11 17:34:18+00:00",
        "title": "McDonald's confirms Vice President Harris's claim of past employment is unfounded",
        "content": "Vice President Kamala Harris announced in Nevada she previously worked at McDonald's, a claim confirmed by the company stating her role as a mascot. She took the job during college to earn extra money, a fact supported by an online-shared picture of her working there.",
        "rating": 14,
        "sources": [
          {
            "id": 210050,
            "name": "NahBabyNah #Trump",
            "url": "https://twitter.com/NahBabyNah",
            "source_icon": ""
          },
          {
            "id": 2040435,
            "name": "\ud83c\udfdb \ud83c\udf39PeriklestheGREAT \ud83c\udf39 \ud83c\udfdb \"Do Unto Others\"",
            "url": "https://twitter.com/PeriklesGREAT",
            "source_icon": ""
          },
          {
            "id": 168473,
            "name": "\u269c \u2022 \ud835\udd78\ud835\udd8e\ud835\udd88\ud835\udd8d\ud835\udd86\ud835\udd8a\ud835\udd91 \u2022 \u269c",
            "url": "https://twitter.com/BasedSolutions1",
            "source_icon": ""
          },
          {
            "id": 2040876,
            "name": "Americat \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/catmurphy209",
            "source_icon": ""
          },
          {
            "id": 2040428,
            "name": "\u2022 \u15f0IS\u1455\u157cI\u15f4\u15b4 \u2122 \u2022",
            "url": "https://twitter.com/4Mischief",
            "source_icon": ""
          },
          {
            "id": 204529,
            "name": "C-Reason\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/CreasonJana",
            "source_icon": ""
          },
          {
            "id": 2040205,
            "name": "Janie Johnson - America is Exceptional",
            "url": "https://twitter.com/jjauthor",
            "source_icon": ""
          },
          {
            "id": 145317,
            "name": "WendylDail",
            "url": "https://twitter.com/wendylwilson2",
            "source_icon": ""
          },
          {
            "id": 142844,
            "name": "44Flower",
            "url": "https://twitter.com/IZMKS44",
            "source_icon": ""
          },
          {
            "id": 2041823,
            "name": "bridget123goooo HarrisWalz 2024 \ud83e\udeb7\ud83d\uddf3\ufe0f\ud83c\udf0a",
            "url": "https://twitter.com/bridget123goooo",
            "source_icon": ""
          },
          {
            "id": 139506,
            "name": "Elena",
            "url": "https://twitter.com/helen44767171",
            "source_icon": ""
          },
          {
            "id": 2040410,
            "name": "Brian Cardone \ud83c\udff4\u200d\u2620\ufe0f\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/cardon_brian",
            "source_icon": ""
          },
          {
            "id": 2038437,
            "name": "Trump Girl \ud83c\uddfa\ud83c\uddf2\ud83e\udd85\ud83c\uddfa\ud83c\uddf2",
            "url": "https://twitter.com/MAGA__Patriot",
            "source_icon": ""
          },
          {
            "id": 169251,
            "name": "Cats for Peace",
            "url": "https://twitter.com/CatsforPeace2",
            "source_icon": ""
          },
          {
            "id": 2040863,
            "name": "5DME81",
            "url": "https://twitter.com/5dme81",
            "source_icon": ""
          },
          {
            "id": 176985,
            "name": "Insurrection Barbie",
            "url": "https://twitter.com/DefiyantlyFree",
            "source_icon": ""
          },
          {
            "id": 2040884,
            "name": "\ud83c\uddfa\ud83c\uddf8\ud835\udcd2\ud835\udcf1\ud835\udcea\ud835\udced\ud83c\uddfa\ud83c\uddf8\ud835\udcd7\ud835\udcea\ud835\udcfb\ud835\udcfb\ud835\udcf2\ud835\udcfc\ud835\udcf8\ud835\udcf7\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Texas_jeep__guy",
            "source_icon": ""
          },
          {
            "id": 2040976,
            "name": "\ud83d\udc93 MsBetty-Boop \ud83d\udc93",
            "url": "https://twitter.com/Ms_Betty_Bop",
            "source_icon": ""
          },
          {
            "id": 2041794,
            "name": "Pat Fuller \ud83d\udc1d#TeamKamala #NoKingVoteDem \ud83d\udfe7\ud83d\udfe6\u262e\ufe0f",
            "url": "https://twitter.com/bannerite",
            "source_icon": ""
          },
          {
            "id": 180720,
            "name": "Make L.A. Great Again \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/lalovestrump",
            "source_icon": ""
          },
          {
            "id": 50802,
            "name": "\ud83d\udc0fRAMMIE\ud83d\udc0f",
            "url": "https://twitter.com/Rammie24",
            "source_icon": ""
          },
          {
            "id": 2042248,
            "name": "MM \uea00",
            "url": "https://twitter.com/adgirlMM",
            "source_icon": ""
          },
          {
            "id": 22864250,
            "name": "\ud83d\udd25\u2b50\ufe0fEdwin\u2b50\ufe0f\ud83d\udd25",
            "url": "https://twitter.com/Nuked4Every1",
            "source_icon": ""
          },
          {
            "id": 41198,
            "name": "Proud Elephant \ud83c\uddfa\ud83c\uddf8\ud83e\udd85",
            "url": "https://twitter.com/ProudElephantUS",
            "source_icon": ""
          },
          {
            "id": 74831,
            "name": "Sue Knows Best",
            "url": "https://twitter.com/sues86453",
            "source_icon": ""
          },
          {
            "id": 213682,
            "name": "\ud835\udc09\ud835\udc0e\ud835\udc07\ud835\udc0d \ud835\udc16\ud835\udc08\ud835\udc02\ud835\udc0a \ud835\udd4f\u02b0\u2071\u1d57\u1d50\u1d43\u207f \ud83d\udd2b",
            "url": "https://twitter.com/imUrB00gieman",
            "source_icon": ""
          },
          {
            "id": 2038467,
            "name": "Kat  Franck",
            "url": "https://twitter.com/KathleenAFranck",
            "source_icon": ""
          },
          {
            "id": 2038016,
            "name": "Ultra Ultra Nuclear TRUMP 2024 Patti the Patriot",
            "url": "https://twitter.com/Patti55866878",
            "source_icon": ""
          },
          {
            "id": 226342,
            "name": "Jeannine Otto",
            "url": "https://twitter.com/AgNews_Otto",
            "source_icon": ""
          },
          {
            "id": 224870,
            "name": "Abstract4Art",
            "url": "https://twitter.com/Harpo4Marx",
            "source_icon": ""
          },
          {
            "id": 30809986,
            "name": "Andrea Chalupa",
            "url": "https://twitter.com/AndreaChalupa",
            "source_icon": ""
          },
          {
            "id": 2042099,
            "name": "TPBlue \ud83c\uddfa\ud83c\uddf8\ud83e\udd85",
            "url": "https://twitter.com/TPBlue4",
            "source_icon": ""
          },
          {
            "id": 2039043,
            "name": "Ultra MAGA Joyce Day",
            "url": "https://twitter.com/Daytobehappy",
            "source_icon": ""
          },
          {
            "id": 210428,
            "name": "Reficul Ratsgninrom",
            "url": "https://twitter.com/jva20095th",
            "source_icon": ""
          },
          {
            "id": 61818849,
            "name": "Sgt Daniel L McCaughan \ud83c\uddfa\ud83c\uddf8 \ud835\udd4f",
            "url": "https://twitter.com/DanMcCaughan",
            "source_icon": ""
          },
          {
            "id": 208276,
            "name": "Victoria Wolf",
            "url": "https://twitter.com/wolfiemouse",
            "source_icon": ""
          },
          {
            "id": 208266,
            "name": "Susan Cleary Rittger",
            "url": "https://twitter.com/ClearyRittger",
            "source_icon": ""
          },
          {
            "id": 206693,
            "name": "Carrie Wehrenberg",
            "url": "https://twitter.com/CarrieWehrenbe2",
            "source_icon": ""
          },
          {
            "id": 206430,
            "name": "Julia Fleming",
            "url": "https://twitter.com/WeAreTheSame77",
            "source_icon": ""
          },
          {
            "id": 45423047,
            "name": "2kitties",
            "url": "https://twitter.com/2kitties3",
            "source_icon": ""
          },
          {
            "id": 2039064,
            "name": "1mZerOCool\ud83c\udf4e\ud83c\udfb8\ud83e\udd18",
            "url": "https://twitter.com/1mZerOCool",
            "source_icon": ""
          },
          {
            "id": 12847763,
            "name": "Thomas Greenberg",
            "url": "https://twitter.com/tommyrazorcuts",
            "source_icon": ""
          },
          {
            "id": 3596722,
            "name": "No one",
            "url": "https://twitter.com/ungubunugu1274",
            "source_icon": ""
          },
          {
            "id": 2704484,
            "name": "David \ud83c\uddfa\ud83c\uddf8 \ud83e\udd85\ud83d\udfe7\u262e\ufe0f\ud83c\udff3\ufe0f\u200d\ud83c\udf08\ud83d\udc99\ud83c\udf0a",
            "url": "https://twitter.com/davidpsdem",
            "source_icon": ""
          },
          {
            "id": 2051720,
            "name": "bittersweet Mary",
            "url": "https://twitter.com/hippyheart4ever",
            "source_icon": ""
          },
          {
            "id": 2048649,
            "name": "debra hall",
            "url": "https://twitter.com/debrahatesdonal",
            "source_icon": ""
          },
          {
            "id": 2048314,
            "name": "Imani Gandy (Orca\u2019s Version) \u2693\ufe0f",
            "url": "https://twitter.com/AngryBlackLady",
            "source_icon": ""
          },
          {
            "id": 2040453,
            "name": "MAGA Joey45-47 \ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Joey_S45",
            "source_icon": ""
          },
          {
            "id": 2040647,
            "name": "Maureen Reilly",
            "url": "https://twitter.com/Moe6660001",
            "source_icon": ""
          },
          {
            "id": 2043225,
            "name": "Rochellemaryn \ud83c\udf39\ud83d\udd4a\ufe0f",
            "url": "https://twitter.com/RochelleAz",
            "source_icon": ""
          },
          {
            "id": 2042372,
            "name": "Gitmo (Health is a Wealth) \ud83c\uddfa\ud83c\uddf8\ud83c\uddee\ud83c\uddf1",
            "url": "https://twitter.com/Gitmo99",
            "source_icon": ""
          },
          {
            "id": 2042286,
            "name": "Eliza Kennedy",
            "url": "https://twitter.com/LizaStylist22",
            "source_icon": ""
          },
          {
            "id": 2041039,
            "name": "Evjone2019",
            "url": "https://twitter.com/evjone2019",
            "source_icon": ""
          },
          {
            "id": 2041200,
            "name": "Penny Palmer",
            "url": "https://twitter.com/ppalmer1952",
            "source_icon": ""
          },
          {
            "id": 2041442,
            "name": "Tommasina Says We Choose Kamala \ud83e\udd85\ud83c\uddfa\ud83c\uddf8\ud83d\udc99\ud83e\udeb7",
            "url": "https://twitter.com/TommasinaResist",
            "source_icon": ""
          },
          {
            "id": 2041453,
            "name": "Thank you Joe Biden",
            "url": "https://twitter.com/IvanasStairCam",
            "source_icon": ""
          },
          {
            "id": 206018,
            "name": "Mike",
            "url": "https://twitter.com/Mikedknight",
            "source_icon": ""
          },
          {
            "id": 40824,
            "name": "Gerry Callahan",
            "url": "https://twitter.com/GerryCallahan",
            "source_icon": ""
          },
          {
            "id": 203738,
            "name": "Cathy Lang",
            "url": "https://twitter.com/CathyLa10001258",
            "source_icon": ""
          },
          {
            "id": 72128,
            "name": "Independent US",
            "url": "https://twitter.com/IndyUSA",
            "source_icon": ""
          },
          {
            "id": 94854,
            "name": "Joyreaper",
            "url": "https://twitter.com/joyreaper",
            "source_icon": ""
          },
          {
            "id": 87594,
            "name": "KatieScarlett.",
            "url": "https://twitter.com/KatieScarlet13",
            "source_icon": ""
          },
          {
            "id": 87480,
            "name": "Ray Jackson",
            "url": "https://twitter.com/ZiloElGrande",
            "source_icon": ""
          },
          {
            "id": 85351,
            "name": "Michael E. Dehn",
            "url": "https://twitter.com/mediapython",
            "source_icon": ""
          },
          {
            "id": 84902,
            "name": "TonemanLives \ud83c\uddfa\ud83c\uddf8\ud83c\uddee\ud83c\uddf9",
            "url": "https://twitter.com/TonemanLives",
            "source_icon": ""
          },
          {
            "id": 82108,
            "name": "John Ned\ud83d\udc99\ud83c\udf0a\ud83c\uddfa\ud83c\udde6\ud83d\udc4d",
            "url": "https://twitter.com/johndned",
            "source_icon": ""
          },
          {
            "id": 77932,
            "name": "Lady Tigress",
            "url": "https://twitter.com/LadyConstance8",
            "source_icon": ""
          },
          {
            "id": 75410,
            "name": "... to the edge",
            "url": "https://twitter.com/TiknisArts",
            "source_icon": ""
          },
          {
            "id": 74603,
            "name": "Sal Vadacchino\ud83c\udde8\ud83c\udde6",
            "url": "https://twitter.com/SalVadacchino1",
            "source_icon": ""
          },
          {
            "id": 71991,
            "name": "Phyllis",
            "url": "https://twitter.com/Phyllis94584953",
            "source_icon": ""
          },
          {
            "id": 97437,
            "name": "NotAFella",
            "url": "https://twitter.com/FellaNota",
            "source_icon": ""
          },
          {
            "id": 70294,
            "name": "\ud83c\uddfa\ud83c\uddf8\ud83e\udd85\ud83c\udf97\ufe0f\ud83e\udeb7 \ud83c\uddfa\ud83c\udde6 \ud83c\uddee\ud83c\uddf1 Catherine 4 HarrisWalz2024",
            "url": "https://twitter.com/CMargaronis",
            "source_icon": ""
          },
          {
            "id": 66284,
            "name": "Cain S. LaTrans",
            "url": "https://twitter.com/snkscoyote",
            "source_icon": ""
          },
          {
            "id": 53730,
            "name": "Glockford Files",
            "url": "https://twitter.com/GlockfordFiles",
            "source_icon": ""
          },
          {
            "id": 53723,
            "name": "Jon Cohen",
            "url": "https://twitter.com/JonUPS_",
            "source_icon": ""
          },
          {
            "id": 53519,
            "name": "#AnatomyOfCapitalAttack #CruzHawleyCoup #Sedition",
            "url": "https://twitter.com/TekStol",
            "source_icon": ""
          },
          {
            "id": 50754,
            "name": "Resist hateful GOP policies",
            "url": "https://twitter.com/hateGOP",
            "source_icon": ""
          },
          {
            "id": 50277,
            "name": "Tonkin Honky \u00af\\_(\u30c4)_/\u00af",
            "url": "https://twitter.com/careyz",
            "source_icon": ""
          },
          {
            "id": 46992,
            "name": "Veteran",
            "url": "https://twitter.com/Veteran97178373",
            "source_icon": ""
          },
          {
            "id": 42602,
            "name": "Ann Carriage",
            "url": "https://twitter.com/cher1511",
            "source_icon": ""
          },
          {
            "id": 95365,
            "name": "#M U G A I N B I O",
            "url": "https://twitter.com/smchatter1",
            "source_icon": ""
          },
          {
            "id": 110193,
            "name": "TongaYork",
            "url": "https://twitter.com/YorkTonga",
            "source_icon": ""
          },
          {
            "id": 197154,
            "name": "Raymond Norman",
            "url": "https://twitter.com/Raymond_Norman",
            "source_icon": ""
          },
          {
            "id": 150878,
            "name": "rori pierpont",
            "url": "https://twitter.com/roripierpont1",
            "source_icon": ""
          },
          {
            "id": 192423,
            "name": "John Roche",
            "url": "https://twitter.com/j_rplacitas",
            "source_icon": ""
          },
          {
            "id": 189171,
            "name": "Frances",
            "url": "https://twitter.com/Frankie1654",
            "source_icon": ""
          },
          {
            "id": 41156,
            "name": "LESLIE SCOTT",
            "url": "https://twitter.com/Leslieannscott",
            "source_icon": ""
          },
          {
            "id": 172441,
            "name": "Possum Jones\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/kjon1234561234",
            "source_icon": ""
          },
          {
            "id": 170456,
            "name": "DRode \u262e\ufe0f Medias Mighty #WeAreAllUkraine\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/debr3322",
            "source_icon": ""
          },
          {
            "id": 169102,
            "name": "Loretta Kunz",
            "url": "https://twitter.com/Patigrl",
            "source_icon": ""
          },
          {
            "id": 165336,
            "name": "Steven Seegel \ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/steven_seegel",
            "source_icon": ""
          },
          {
            "id": 157846,
            "name": "Stuart Falk",
            "url": "https://twitter.com/stufalk",
            "source_icon": ""
          },
          {
            "id": 151681,
            "name": "Jonathan Landay",
            "url": "https://twitter.com/JonathanLanday",
            "source_icon": ""
          },
          {
            "id": 149706,
            "name": "Debbie Faits",
            "url": "https://twitter.com/FaitsDebbie",
            "source_icon": ""
          },
          {
            "id": 111155,
            "name": "M Hels",
            "url": "https://twitter.com/MHels01",
            "source_icon": ""
          },
          {
            "id": 146309,
            "name": "Satan \ud83c\uddfa\ud83c\udde6\ud83c\uddfa\ud83c\udde6\ud83c\uddfa\ud83c\udde6\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/MeshellePhifer3",
            "source_icon": ""
          },
          {
            "id": 144875,
            "name": "ATX Irish Gal \ud83d\ude4f\ud83c\udffc\ud83c\uddfa\ud83c\uddf8\u2764\ufe0f",
            "url": "https://twitter.com/Notmyfault99",
            "source_icon": ""
          },
          {
            "id": 141559,
            "name": "G.D. Thor",
            "url": "https://twitter.com/GDThor1",
            "source_icon": ""
          },
          {
            "id": 138977,
            "name": "Lillith O",
            "url": "https://twitter.com/Orland1M",
            "source_icon": ""
          },
          {
            "id": 137902,
            "name": "Lee (Harris 2024)Johnson",
            "url": "https://twitter.com/LeeJohnson65",
            "source_icon": ""
          },
          {
            "id": 134922,
            "name": "JOHN YOUNG - US NAVY - Ret. #MAGA!",
            "url": "https://twitter.com/jyparkway",
            "source_icon": ""
          },
          {
            "id": 121572,
            "name": "\u201cDems Are Desperate\u201d Tenorman \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/thetenorman",
            "source_icon": ""
          },
          {
            "id": 114486,
            "name": "Tybin",
            "url": "https://twitter.com/4tybin",
            "source_icon": ""
          },
          {
            "id": 113849,
            "name": "Jack Doran Jr.\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/jackdoranjr",
            "source_icon": ""
          },
          {
            "id": 140869615,
            "name": "Iam Breitbart",
            "url": "https://twitter.com/JacSarobahs",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 7.0,
          "negative_share": 93.0,
          "reactions_analyzed": 1630,
          "comments_analyzed": 75
        }
      },
      {
        "id": 3,
        "publication_date": "2024-08-23 14:01:20+00:00",
        "title": "McDonald's celebrated Ukrainian Independence Day with a special burger giveaway",
        "content": "McDonald's celebrates Ukrainian Independence Day by offering two free burgers to every Ukrainian citizen, highlighting the company's support and solidarity with Ukraine.",
        "rating": 13,
        "sources": [
          {
            "id": 387,
            "name": "Made in Ukraine \ud83c\uddfa\ud83c\udde6",
            "url": "https://t.me/Made_inUkraine",
            "source_icon": ""
          },
          {
            "id": 246019,
            "name": "\u0425\u0443\u0435\u0430\u0432\u0430\u044f \u0423\u043a\u0440\u0430\u0438\u043d\u0430",
            "url": "https://t.me/hyukraine",
            "source_icon": ""
          },
          {
            "id": 246130,
            "name": "\u0406\u0432\u0430\u043d\u043e-\u0424\u0440\u0430\u043d\u043a\u0456\u0432\u0441\u044c\u043a",
            "url": "https://t.me/frankivskpovistki",
            "source_icon": ""
          },
          {
            "id": 246251,
            "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u0414\u043d\u0456\u043f\u0440\u043e / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u0414\u043d\u0435\u043f\u0440",
            "url": "https://t.me/dneprpovestki",
            "source_icon": ""
          },
          {
            "id": 246299,
            "name": "CN | \u0427\u0443\u0433\u0443\u0435\u0432 News\ufe0f | \u0427\u0443\u0433\u0443\u0457\u0432",
            "url": "https://t.me/cn_chuguev_news",
            "source_icon": ""
          },
          {
            "id": 246331,
            "name": "\u2719 \u0427\u0435\u0440\u043d\u0456\u0433\u0456\u0432 \u041e\u043f\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0438\u0439 \ud83c\uddfa\ud83c\udde6",
            "url": "https://t.me/ChernigivOperative",
            "source_icon": ""
          },
          {
            "id": 85239275,
            "name": "\u0420\u0430\u0434\u0430\u0440\u26a1\ufe0f\u0423\u043a\u0440\u0430\u0457\u043d\u0430",
            "url": "https://t.me/radarr_raketa",
            "source_icon": ""
          },
          {
            "id": 85350376,
            "name": "\u0421\u043b\u0430\u0432\u044f\u043d\u0441\u043a / \u041a\u0440\u0430\u043c\u0430\u0442\u043e\u0440\u0441\u043a",
            "url": "https://t.me/slav_kram",
            "source_icon": ""
          },
          {
            "id": 85474485,
            "name": "\u0414\u043e\u043d\u0431\u0430\u0441 \u041e\u043f\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0438\u0439",
            "url": "https://t.me/Donbas_Operativnyi",
            "source_icon": ""
          },
          {
            "id": 85588065,
            "name": "\u041a\u0443\u0434\u0438 \u043b\u0435\u0442\u0438\u0442\u044c? | \u0422\u0440\u0438\u0432\u043e\u0433\u0430",
            "url": "https://t.me/radar_raketaa",
            "source_icon": ""
          },
          {
            "id": 85603171,
            "name": "\u0410\u043d\u0434\u0440i\u0439",
            "url": "https://t.me/actor_5850963556",
            "source_icon": ""
          },
          {
            "id": 86298317,
            "name": "\u041a\u0430\u0440\u0442\u0430 \u043f\u043e\u0432\u0456\u0442\u0440\u044f\u043d\u0438\u0445 \u0442\u0440\u0438\u0432\u043e\u0433 \ud83c\uddfa\ud83c\udde6",
            "url": "https://t.me/trevoga_radar_tryvoga",
            "source_icon": ""
          },
          {
            "id": 86577875,
            "name": "\u0420\u0430\u0434\u0430\u0440 \ud83d\ude80\u041f\u041f\u041e | \u041a\u0430\u0440\u0442\u0430 \u0442\u0440\u0438\u0432\u043e\u0433",
            "url": "https://t.me/raketa_rakety_de",
            "source_icon": ""
          },
          {
            "id": 87390084,
            "name": "\u0422\u0440\u0438\u0432\u043e\u0433\u0430 \u0420\u0430\u0434\u0430\u0440 | \u041a\u0443\u0434\u0438 \u043b\u0435\u0442\u0438\u0442\u044c?",
            "url": "https://t.me/rakety_trevoga",
            "source_icon": ""
          },
          {
            "id": 91081985,
            "name": "\u042d\u0442\u043e \u041a\u0440\u0438\u0432\u043e\u0439 \u0420\u043e\u0433, \u0414\u0415\u0422\u041a\u0410\ud83d\ude08",
            "url": "https://t.me/ekrd_osnova",
            "source_icon": ""
          },
          {
            "id": 91108290,
            "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u041a\u0438\u0457\u0432 / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u041a\u0438\u0435\u0432",
            "url": "https://t.me/kyiv_povestka",
            "source_icon": ""
          },
          {
            "id": 92107981,
            "name": "\u0420\u0435\u0430\u043b\u044c\u043d\u0438\u0439 \u0425\u0430\u0440\u043a\u0456\u0432 | \u0425\u0430\u0440\u044c\u043a\u043e\u0432\ud83c\uddfa\ud83c\udde6",
            "url": "https://t.me/kharkivrl",
            "source_icon": ""
          },
          {
            "id": 92313635,
            "name": "\u0406\u043d\u0444\u043e\u0440\u043c\u0430\u0442\u043e\u0440 \u0414\u043d\u0456\u043f\u0440\u043e\u043f\u0435\u0442\u0440\u043e\u0432\u0449\u0438\u043d\u0438 | \u0414\u043d\u0456\u043f\u0440\u043e",
            "url": "https://t.me/dnipro_informator",
            "source_icon": ""
          },
          {
            "id": 95507718,
            "name": "\u0423\u043a\u0440\u0430\u0457\u043d\u0441\u044c\u043a\u0430 \u0441\u043b\u0443\u0436\u0431\u0430 \u043d\u043e\u0432\u0438\u043d | \u0412\u0456\u0439\u043d\u0430\ud83c\uddfa\ud83c\udde6",
            "url": "https://t.me/+q-wRO34mI9thNGYy",
            "source_icon": ""
          },
          {
            "id": 95508238,
            "name": "\u041d\u043e\u0432\u0438\u043d\u0438 \u0432\u0456\u0439\u043d\u0438 | \u0413\u043e\u043b\u043e\u0432\u043d\u0435 \u0437\u0430 \u0434\u0435\u043d\u044c",
            "url": "https://t.me/joinchat/oKfjtEAQQK9hNWRi",
            "source_icon": ""
          },
          {
            "id": 98570513,
            "name": "\u0417\u0410\u041f\u041e\u0420\u0406\u0416\u0416\u042f \u041d\u041e\u0412\u0418\u041d\u0418 | \u0417\u0410\u041f\u041e\u0420\u041e\u0416\u042c\u0415 \u041d\u041e\u0412\u041e\u0421\u0422\u0418 \ud83c\uddfa\ud83c\udde6 | \u0420\u0410\u0414\u0410\u0420",
            "url": "https://t.me/pro_zp_2023",
            "source_icon": ""
          },
          {
            "id": 99564437,
            "name": "\u041f\u043e\u0432\u0456\u0442\u0440\u044f\u043d\u0430 \u0422\u0440\u0438\u0432\u043e\u0433\u0430 \u0414\u0435 \u0420\u0430\u043a\u0435\u0442\u0430",
            "url": "https://t.me/rakety_radar",
            "source_icon": ""
          },
          {
            "id": 101804658,
            "name": "\u2719 \u042f \u0441 \u0414\u043e\u043d\u0431\u0430\u0441\u0441\u0430 \u2719\ud83d\udc06",
            "url": "https://t.me/yaaa_s_Donbassa",
            "source_icon": ""
          },
          {
            "id": 114854936,
            "name": "\u041f\u0430\u043b\u044f\u043d\u0438\u0446\u044f - \u0423\u043a\u0440\u0430\u0457\u043d\u0430",
            "url": "https://t.me/palyanitsyanews",
            "source_icon": ""
          },
          {
            "id": 119815690,
            "name": "\u26a1\ufe0fINFOFAKT \u0423\u041a\u0420\u0410\u0407\u041d\u0410",
            "url": "https://t.me/infofakt_ukr",
            "source_icon": ""
          },
          {
            "id": 123229234,
            "name": "\u0420\u0430\u0434\u0430\u0440 \ud83c\uddfa\ud83c\udde6| \u0420\u0430\u043a\u0435\u0442\u0438 \ud83d\ude80| \u0411\u043f\u041b\u0410 \u2708\ufe0f",
            "url": "https://t.me/Radar2988",
            "source_icon": ""
          },
          {
            "id": 123924598,
            "name": "\u041c\u043e\u043d\u0456\u0442\u043e\u0440\u0438\u043d\u0433 UA | \u0422\u0440\u0438\u0432\u043e\u0433\u0438 \ud83d\udea8",
            "url": "https://t.me/raketna_nebespekaa",
            "source_icon": ""
          },
          {
            "id": 133590763,
            "name": "\u041e\u0444\u0456\u0446\u0456\u0439\u043d\u043e \u0423\u043a\u0440\u0430\u0457\u043d\u0430",
            "url": "https://t.me/oficial_ukraine",
            "source_icon": ""
          },
          {
            "id": 140396699,
            "name": "\u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u041e\u0434\u0435\u0441\u0441\u0430",
            "url": "https://t.me/pov_od",
            "source_icon": ""
          },
          {
            "id": 140396706,
            "name": "\u041f\u0420\u041e\u0421\u0422\u041e \u0414\u041d\u0406\u041f\u0420\u041e \u26a1\ufe0f",
            "url": "https://t.me/dnepr_prosto",
            "source_icon": ""
          },
          {
            "id": 140396709,
            "name": "\u0406\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0456\u0439\u043d\u0438\u0439 \u0410\u0433\u0435\u043d\u0442 \u0423\u043a\u0440\u0430\u0457\u043d\u0438",
            "url": "https://t.me/infoagents",
            "source_icon": ""
          },
          {
            "id": 246050,
            "name": "\u0414\u0438\u0432\u0430\u043d\u043d\u043e-\u0448\u0442\u0443\u0440\u043c\u043e\u0432\u0430\u044f \u0431\u0440\u0438\u0433\u0430\u0434\u0430 \ud83c\udf49\u2794",
            "url": "https://t.me/horevica",
            "source_icon": ""
          },
          {
            "id": 245996,
            "name": "\u0406\u043d\u0444\u043e\u0440\u043c\u0443\u0454 \u041a\u0438\u0457\u0432",
            "url": "https://t.me/inform_kiev",
            "source_icon": ""
          },
          {
            "id": 388,
            "name": "\u041d\u043e\u0432\u044b\u0439 UA",
            "url": "https://t.me/NewsUkraine_2022",
            "source_icon": ""
          },
          {
            "id": 245995,
            "name": "\u0406\u043d\u0444\u043e\u0440\u043c\u0443\u0454 \u041b\u044c\u0432\u0456\u0432",
            "url": "https://t.me/inform_lviv",
            "source_icon": ""
          },
          {
            "id": 444,
            "name": "FLASH UA \u26a1\ufe0f| \u041d\u041e\u0412\u041e\u0421\u0422\u0418",
            "url": "https://t.me/joinchat/AAAAAFgTDRaXCMuAdkFZSA",
            "source_icon": ""
          },
          {
            "id": 450,
            "name": "\u0423\u043a\u0440\u0430\u0457\u043d\u0441\u044c\u043a\u0430 \u043f\u0440\u0430\u0432\u0434\u0430 (\u0412\u0456\u0439\u043d\u0430, \u041d\u043e\u0432\u0438\u043d\u0438)",
            "url": "https://t.me/joinchat/LjahAIo9IGYxNTRi",
            "source_icon": ""
          },
          {
            "id": 451,
            "name": "\u041d\u043e\u0432\u0438\u043d\u0438 \u0423\u043a\u0440\u0430\u0457\u043d\u0438 | \u0412\u0406\u0419\u041d\u0410",
            "url": "https://t.me/joinchat/NWSc5RisnX82M2Yy",
            "source_icon": ""
          },
          {
            "id": 5789,
            "name": "\u26a1\ufe0fINFOFAKT \u0423\u041a\u0420\u0410\u0407\u041d\u0410",
            "url": "https://t.me/+T6_6QDGen4nADXNm",
            "source_icon": ""
          },
          {
            "id": 38800,
            "name": "\u0420\u0456\u0432\u043d\u0435 \u0413\u043e\u043b\u043e\u0432\u043d\u0435",
            "url": "https://t.me/rivne_golovne",
            "source_icon": ""
          },
          {
            "id": 228282,
            "name": "\u041a\u0438\u0435\u0432 | \u041d\u043e\u0432\u043e\u0441\u0442\u0438 \u0412\u043e\u0439\u043d\u044b",
            "url": "https://t.me/Kyiv_voyna",
            "source_icon": ""
          },
          {
            "id": 228309,
            "name": "\u0422\u0438\u043f\u043e\u0432\u0438\u0439 \u041b\u0443\u0446\u044c\u043a",
            "url": "https://t.me/lutsk_tipical",
            "source_icon": ""
          },
          {
            "id": 238676,
            "name": "\u0414\u0435 \u0420\u0430\u043a\u0435\u0442\u0430 ?\ud83d\ude80",
            "url": "https://t.me/deraketaua",
            "source_icon": ""
          },
          {
            "id": 239006,
            "name": "\u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u0425\u0430\u0440\u044c\u043a\u043e\u0432",
            "url": "https://t.me/kharkov_povestka",
            "source_icon": ""
          },
          {
            "id": 239008,
            "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u0425\u0430\u0440\u043a\u0456\u0432 / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u0425\u0430\u0440\u044c\u043a\u043e\u0432",
            "url": "https://t.me/kharkovpovestki",
            "source_icon": ""
          },
          {
            "id": 239246,
            "name": "\u041c\u043e\u043d\u0456\u0442\u043e\u0440\u0438\u043d\u0433 | \u0423\u043a\u0440\u0430\u0457\u043d\u0430",
            "url": "https://t.me/monitor_ukr",
            "source_icon": ""
          },
          {
            "id": 239477,
            "name": "\u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u041e\u0434\u0435\u0441\u0441\u0430",
            "url": "https://t.me/povestki",
            "source_icon": ""
          },
          {
            "id": 239564,
            "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u041b\u044c\u0432\u0456\u0432 / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u041b\u044c\u0432\u043e\u0432",
            "url": "https://t.me/rozdayut_dee",
            "source_icon": ""
          },
          {
            "id": 239806,
            "name": "\ud83d\udd0a\u0427\u043e\u043c\u0443 \u0442\u0440\u0438\u0432\u043e\u0433\u0430?\ud83d\udea8",
            "url": "https://t.me/tryvoga_chomu",
            "source_icon": ""
          },
          {
            "id": 239930,
            "name": "\u0412\u0435\u0440\u0445\u043e\u0432\u043d\u0430 \u0420\u0430\u0434\u0430 \u0417\u041c\u0406",
            "url": "https://t.me/verhovna_rada_zmi",
            "source_icon": ""
          },
          {
            "id": 244333,
            "name": "News Sumy\ud83c\uddfa\ud83c\udde6",
            "url": "https://t.me/news_sumy24",
            "source_icon": ""
          },
          {
            "id": 244424,
            "name": "\ud83d\udd2aB D S M",
            "url": "https://t.me/zp_pro_news",
            "source_icon": ""
          },
          {
            "id": 245036,
            "name": "\u0421\u043b\u0430\u0432\u043d\u0435 \u0417\u0430\u043f\u043e\u0440\u0456\u0436\u0436\u044f \ud83c\uddfa\ud83c\udde6",
            "url": "https://t.me/slavne_zaporizhia",
            "source_icon": ""
          },
          {
            "id": 245209,
            "name": "\u041f\u0420\u041e\u0421\u0422\u041e \u0414\u041d\u0415\u041f\u0420\u041e\u0414\u0417\u0415\u0420\u0416\u0418\u041d\u0421\u041a (\u041a\u0430\u043c\u0435\u043d\u0441\u043a\u043e\u0435)",
            "url": "https://t.me/prosto_dnz",
            "source_icon": ""
          },
          {
            "id": 245248,
            "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u0422\u0435\u0440\u043d\u043e\u043f\u0456\u043b\u044c / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u0422\u0435\u0440\u043d\u043e\u043f\u043e\u043b\u044c",
            "url": "https://t.me/povistkiternopil",
            "source_icon": ""
          },
          {
            "id": 245249,
            "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u041a\u0438\u0457\u0432 / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u041a\u0438\u0435\u0432",
            "url": "https://t.me/povistkikiev",
            "source_icon": ""
          },
          {
            "id": 245250,
            "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u0427\u0435\u0440\u043a\u0430\u0441\u0438 / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u0427\u0435\u0440\u043a\u0430\u0441\u0441\u044b",
            "url": "https://t.me/povistkicherkasy",
            "source_icon": ""
          },
          {
            "id": 245252,
            "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u0412\u0456\u043d\u043d\u0438\u0446\u044f / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u0412\u0438\u043d\u043d\u0438\u0446\u0430",
            "url": "https://t.me/povestkivinnitsa",
            "source_icon": ""
          },
          {
            "id": 245253,
            "name": "\u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u041f\u043e\u043b\u0442\u0430\u0432\u0430 / \u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u041f\u043e\u043b\u0442\u0430\u0432\u0430",
            "url": "https://t.me/povestkipoltava",
            "source_icon": ""
          },
          {
            "id": 245413,
            "name": "\u041d\u0423 \u0406 \u041d\u041e\u0412\u0418\u041d\u0418 \ud83c\uddfa\ud83c\udde6 | \u041d\u0423 \u0406 \u0414\u041d\u0406\u041f\u0420\u041e | Monitor | \u0420\u0410\u0414\u0410\u0420 | \u041c\u041e\u041d\u0418\u0422\u041e\u0420 \u041a\u0420\u042b\u041c | \u0413\u0414\u0415 \u0420\u0410\u041a\u0415\u0422\u0410? | \u0423\u041a\u0420\u0410\u0418\u041d\u0410 | \u0425\u0415\u0420\u0421\u041e\u041d | \u0414\u041d\u0406\u041f\u0420\u041e | \u0414\u041d\u0415\u041f\u0420 dnipro_alerts \u0441\u0432",
            "url": "https://t.me/ny_i_novyny",
            "source_icon": ""
          },
          {
            "id": 245490,
            "name": "\u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u041d\u0438\u043a\u043e\u043b\u0430\u0435\u0432 / \u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u041c\u0438\u043a\u043e\u043b\u0430\u0457\u0432",
            "url": "https://t.me/nikolaevpovestki",
            "source_icon": ""
          },
          {
            "id": 245523,
            "name": "\u041d\u0410\u0428\u0415 \u041f\u041f\u041e \ud83c\uddfa\ud83c\udde6 | \u0420\u0430\u0434\u0430\u0440 \u0440\u0430\u043a\u0435\u0442, \u0448\u0430\u0445\u0435\u0434\u0456\u0432",
            "url": "https://t.me/nashe_ppo_monitoring",
            "source_icon": ""
          },
          {
            "id": 245851,
            "name": "\u26a1\ufe0f\u0412\u041a\u041b\u042e\u0427\u0410\u0422\u0415\u041b\u042c \u041a\u0410\u041c\u0415\u041d\u0421\u041a\u041e\u0415\u26a1\ufe0f",
            "url": "https://t.me/kmsk_off",
            "source_icon": ""
          },
          {
            "id": 245909,
            "name": "\u0422\u0421 | \u0425\u0430\u0440\u043a\u0456\u0432 \ud83c\uddfa\ud83c\udde6",
            "url": "https://t.me/kharkivts",
            "source_icon": ""
          },
          {
            "id": 140396726,
            "name": "\u041b\u044c\u0432\u0456\u0432 \u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438",
            "url": "https://t.me/holovni_lviv",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 100.0,
          "negative_share": 0.0,
          "reactions_analyzed": 7209,
          "comments_analyzed": 4
        }
      },
      {
        "id": 4,
        "publication_date": "2024-08-04 08:16:28+00:00",
        "title": "Calls for a McDonald's boycott emerge over allegations of association with child harm",
        "content": "A boycott against McDonald's is urged due to alleged links to child killings. Giovanny \"Chulo\" Jennings, a 6-year-old, was murdered in Michigan. Claims also surface about the use of aborted babies in food products.",
        "rating": 9,
        "sources": [
          {
            "id": 2040326,
            "name": "THiS LiL\u2019 MiSS Can\u2019t Be Wrong",
            "url": "https://twitter.com/l42022425",
            "source_icon": ""
          },
          {
            "id": 59183,
            "name": "Beyond Massage",
            "url": "https://twitter.com/bbeyondm1",
            "source_icon": ""
          },
          {
            "id": 176785,
            "name": "Shaikh Javed Iqbal",
            "url": "https://twitter.com/ShaikhJavedIqb1",
            "source_icon": ""
          },
          {
            "id": 51792,
            "name": "David Johnson",
            "url": "https://twitter.com/davidwjohns1111",
            "source_icon": ""
          },
          {
            "id": 2050739,
            "name": "A AJZ",
            "url": "https://twitter.com/AAJZ17",
            "source_icon": ""
          },
          {
            "id": 5762431,
            "name": "11setembro",
            "url": "https://twitter.com/11setembro",
            "source_icon": ""
          },
          {
            "id": 4383209,
            "name": "Darrell Thompson",
            "url": "https://twitter.com/5yUbRVjsdZBOSrj",
            "source_icon": ""
          },
          {
            "id": 2706976,
            "name": "Carmen Fagundo",
            "url": "https://twitter.com/CarmenFagundo",
            "source_icon": ""
          },
          {
            "id": 2657572,
            "name": "Dennis Mahoney",
            "url": "https://twitter.com/dmahoney539",
            "source_icon": ""
          },
          {
            "id": 2052130,
            "name": "LookOlouk",
            "url": "https://twitter.com/LookOlouk1",
            "source_icon": ""
          },
          {
            "id": 2051862,
            "name": "JenniferM",
            "url": "https://twitter.com/Jennife11148116",
            "source_icon": ""
          },
          {
            "id": 2051732,
            "name": "TheBitchIsBack\ud83d\ude0b",
            "url": "https://twitter.com/hotnostril",
            "source_icon": ""
          },
          {
            "id": 2045320,
            "name": "Patriots Of Faith Hope Love Channel",
            "url": "https://t.me/patriotsoffaithhopelovechannel",
            "source_icon": ""
          },
          {
            "id": 16655622,
            "name": "Amir Khan",
            "url": "https://twitter.com/Amir3504kha1988",
            "source_icon": ""
          },
          {
            "id": 2044990,
            "name": "WWG1WGA - Jeanne d'Arc",
            "url": "https://t.me/real_jeannedarc",
            "source_icon": ""
          },
          {
            "id": 2044790,
            "name": "\u2139\ufe0f\ud83c\udf0dweg mit dem System\ud83c\udf0d\u2139\ufe0f",
            "url": "https://t.me/wegmitdemsystem",
            "source_icon": ""
          },
          {
            "id": 2041538,
            "name": "Enoch Rivers",
            "url": "https://twitter.com/Bonglers",
            "source_icon": ""
          },
          {
            "id": 2040999,
            "name": "Dr. Anastasia Maria Loupis",
            "url": "https://twitter.com/DrLoupis",
            "source_icon": ""
          },
          {
            "id": 2040916,
            "name": "MAGA_FROG \ud83d\ude4f\ud83c\udffb\u271d\ufe0f\ud83d\ude4f\ud83c\udffb\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/FROGGY_SLING",
            "source_icon": ""
          },
          {
            "id": 2040122,
            "name": "3luckysoab2 \ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc77\udb40\udc6c\udb40\udc73\udb40\udc7f",
            "url": "https://twitter.com/3luckysoab2",
            "source_icon": ""
          },
          {
            "id": 2039155,
            "name": "TheManWithNoName",
            "url": "https://twitter.com/Christo63776245",
            "source_icon": ""
          },
          {
            "id": 14166949,
            "name": "Ruth 007",
            "url": "https://twitter.com/Ruth11665247",
            "source_icon": ""
          },
          {
            "id": 21505571,
            "name": "Chiara\ud83c\uddf8\ud83c\uddea\ud83c\udd93\ufe0f",
            "url": "https://twitter.com/Clara55872137",
            "source_icon": ""
          },
          {
            "id": 225093,
            "name": "massoud rahnavard",
            "url": "https://twitter.com/khoshakhlag",
            "source_icon": ""
          },
          {
            "id": 103586606,
            "name": "Akhtar pathan",
            "url": "https://twitter.com/Akhtarp62412487",
            "source_icon": ""
          },
          {
            "id": 123400414,
            "name": "Rundblick",
            "url": "https://t.me/Rundblicke",
            "source_icon": ""
          },
          {
            "id": 119824056,
            "name": "Open your eyes Archiv",
            "url": "https://t.me/openyoureyesarchiv",
            "source_icon": ""
          },
          {
            "id": 119808206,
            "name": "\u2728\u2728\u2728\u1d42\u1d2cQ\u1d31\u1d41\u1d3e\u1d42\u1d3c\u1d3f\u1d38\u1d30\u2728\u2728\u2728 \ud83c\udd30\ud83c\udd41\ud83c\udd32\ud83c\udd37\ud83c\udd38\ud83c\udd45\u2728\u2728\u2728",
            "url": "https://t.me/waqeupworld",
            "source_icon": ""
          },
          {
            "id": 109681062,
            "name": "Ann Stewart \ud83c\uddff\ud83c\udde6\ud83c\uddf5\ud83c\uddf8\ud83d\udd3b",
            "url": "https://twitter.com/AnnStew71348302",
            "source_icon": ""
          },
          {
            "id": 109681036,
            "name": "angelo.piazza",
            "url": "https://twitter.com/AngeloPiazza777",
            "source_icon": ""
          },
          {
            "id": 109680802,
            "name": "Amaraa Itgel",
            "url": "https://twitter.com/AmaraaU",
            "source_icon": ""
          },
          {
            "id": 109680287,
            "name": "\u0627\u062d\u0645\u062f",
            "url": "https://twitter.com/Ahmad_Alferami",
            "source_icon": ""
          },
          {
            "id": 104398723,
            "name": "Alma Grande",
            "url": "https://twitter.com/AlmaGrande888",
            "source_icon": ""
          },
          {
            "id": 99730510,
            "name": "Suhail Ansari \ud83c\uddee\ud83c\uddf3\ud83c\uddf5\ud83c\uddf8",
            "url": "https://twitter.com/AnsariSuhail90",
            "source_icon": ""
          },
          {
            "id": 22857672,
            "name": "Alex Z",
            "url": "https://twitter.com/Alex87431641",
            "source_icon": ""
          },
          {
            "id": 97998711,
            "name": "\u064b",
            "url": "https://twitter.com/56Wala_Dhongi",
            "source_icon": ""
          },
          {
            "id": 92877940,
            "name": "Eva_ULTRA_Buntfieber \ud83d\udc38\ud83c\udf38\ud83d\udc9a",
            "url": "https://t.me/Eva_Buntfieber",
            "source_icon": ""
          },
          {
            "id": 86602264,
            "name": "K\u00e4mpft um Euer Land \ud83c\udde9\ud83c\uddea",
            "url": "https://t.me/dallekanal",
            "source_icon": ""
          },
          {
            "id": 86056441,
            "name": "\ud835\udc9c\ud835\udccc\ud835\udcb6\ud835\udcc0\ud835\udc52\ud835\udcc3\ud835\udcbe\ud835\udcc3\ud835\udc54 \ud835\udcab\ud835\udcc7\ud835\udc5c\ud835\udcbf\ud835\udc52\ud835\udcb8\ud835\udcc9 \ud835\udfe4\ud835\udfe2\ud835\udfe4\ud835\udfe5",
            "url": "https://t.me/awakeningproject",
            "source_icon": ""
          },
          {
            "id": 86014424,
            "name": "Fakten zur Wahrheitsfindung",
            "url": "https://t.me/FaktenZurWahrheitsfindung",
            "source_icon": ""
          },
          {
            "id": 85642875,
            "name": "Capello Bianco Q",
            "url": "https://t.me/capellobiancoq",
            "source_icon": ""
          },
          {
            "id": 85245680,
            "name": "Sirius \ud83c\udf15 Das Christus-Bewusstsein",
            "url": "https://t.me/AramChristus",
            "source_icon": ""
          },
          {
            "id": 82159423,
            "name": "\u00d6zcan Altunta\u015f",
            "url": "https://twitter.com/61Mollaahmet",
            "source_icon": ""
          },
          {
            "id": 225485,
            "name": "littleboy",
            "url": "https://twitter.com/little_b_o_y",
            "source_icon": ""
          },
          {
            "id": 221260,
            "name": "Entropy2224",
            "url": "https://twitter.com/BrinkandHoff23",
            "source_icon": ""
          },
          {
            "id": 222698,
            "name": "Pringle",
            "url": "https://twitter.com/Petrusngwenya",
            "source_icon": ""
          },
          {
            "id": 112075,
            "name": "#TruthtoPower",
            "url": "https://twitter.com/Adamitv",
            "source_icon": ""
          },
          {
            "id": 125025,
            "name": "russ h. \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/RussellHeathcoe",
            "source_icon": ""
          },
          {
            "id": 123790,
            "name": "Jackdaw Martin",
            "url": "https://twitter.com/davidjackdaw",
            "source_icon": ""
          },
          {
            "id": 121572,
            "name": "\u201cDems Are Desperate\u201d Tenorman \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/thetenorman",
            "source_icon": ""
          },
          {
            "id": 121288,
            "name": "Peckerman",
            "url": "https://twitter.com/wisepeckerman",
            "source_icon": ""
          },
          {
            "id": 119190,
            "name": "V.Streifel",
            "url": "https://twitter.com/streifel_v",
            "source_icon": ""
          },
          {
            "id": 118734,
            "name": "rosesamericana",
            "url": "https://twitter.com/rosesamericana1",
            "source_icon": ""
          },
          {
            "id": 118335,
            "name": "I, SUPER ULTRA-MAGA Prince! \ud83c\uddfa\ud83c\uddf8\u26bd\ud83c\uddee\ud83c\uddf1\ud83c\udfc1\ud83c\udfb9",
            "url": "https://twitter.com/Cigars1776",
            "source_icon": ""
          },
          {
            "id": 112125,
            "name": "Amir Kassam",
            "url": "https://twitter.com/AmirKassam1",
            "source_icon": ""
          },
          {
            "id": 111182,
            "name": "Dr GRACE \ud83d\ude4f\ud83c\udffc\u2764\ufe0f\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/MediumGrace",
            "source_icon": ""
          },
          {
            "id": 56081,
            "name": "It\u2019s KENNEDY.",
            "url": "https://twitter.com/Mehdi80750168",
            "source_icon": ""
          },
          {
            "id": 110866,
            "name": "Crash_the_Flash \ud83d\udc2d",
            "url": "https://twitter.com/Crash_the_Flash",
            "source_icon": ""
          },
          {
            "id": 108625,
            "name": "Jeanette \ud83d\ude0e\ud83c\udf1e\ud83c\udf35\ud83c\udf3b\ud83e\udd8e\ud83c\udf34\ud83c\uddfa\ud83c\uddf8ULTRA MAGA**USA**ARIZONA*",
            "url": "https://twitter.com/Outhere",
            "source_icon": ""
          },
          {
            "id": 106024,
            "name": "Michael Purvis",
            "url": "https://twitter.com/michaelpurvis64",
            "source_icon": ""
          },
          {
            "id": 97410,
            "name": "LEER OLDHAM",
            "url": "https://twitter.com/knightwings38",
            "source_icon": ""
          },
          {
            "id": 89153,
            "name": "duane poole @ Duanepoole",
            "url": "https://twitter.com/duanepoole",
            "source_icon": ""
          },
          {
            "id": 60136,
            "name": "\ud83e\udd85\ud83c\uddfa\ud83c\uddf8\ud83d\udc38Shan \ud83d\udc38\ud83c\uddfa\ud83c\uddf8\ud83e\udd85",
            "url": "https://twitter.com/shanprevails",
            "source_icon": ""
          },
          {
            "id": 59308,
            "name": "justsaying",
            "url": "https://twitter.com/justsay94731715",
            "source_icon": ""
          },
          {
            "id": 56437,
            "name": "Jack Straw",
            "url": "https://twitter.com/JackStr42679640",
            "source_icon": ""
          },
          {
            "id": 127537,
            "name": "Moh Musthafa Hussain",
            "url": "https://twitter.com/musthafaaa",
            "source_icon": ""
          },
          {
            "id": 128145,
            "name": "Darlene",
            "url": "https://twitter.com/Done00710063",
            "source_icon": ""
          },
          {
            "id": 136928,
            "name": "Will Alexander",
            "url": "https://twitter.com/Bill_C54",
            "source_icon": ""
          },
          {
            "id": 141867,
            "name": "Ian Foulds - no dm please",
            "url": "https://twitter.com/FitzroyPeabody3",
            "source_icon": ""
          },
          {
            "id": 218860,
            "name": "Sesinga Mavikithi",
            "url": "https://twitter.com/themankhosi",
            "source_icon": ""
          },
          {
            "id": 209551,
            "name": "\u274eFREEDOMS-WARRIOR",
            "url": "https://twitter.com/USA_MAG1",
            "source_icon": ""
          },
          {
            "id": 203242,
            "name": "Deplorable Me",
            "url": "https://twitter.com/Qrtrhrsryder",
            "source_icon": ""
          },
          {
            "id": 203063,
            "name": "Billow Ismail Jr.",
            "url": "https://twitter.com/BillowIsmail",
            "source_icon": ""
          },
          {
            "id": 200209,
            "name": "Freedom Watch",
            "url": "https://twitter.com/TheRealStoryPlz",
            "source_icon": ""
          },
          {
            "id": 197820,
            "name": "David Barwick",
            "url": "https://twitter.com/ISeeWordsPeople",
            "source_icon": ""
          },
          {
            "id": 194527,
            "name": "Publio - Palestina \ud83d\udc96\ud83d\udc96\ud83d\udc96\ud83d\udc96\ud83d\udc96\ud83d\udc96\ud83d\udc96",
            "url": "https://twitter.com/mar40248589",
            "source_icon": ""
          },
          {
            "id": 188393,
            "name": "\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8Nik4Freedom\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/Nik4Freedom",
            "source_icon": ""
          },
          {
            "id": 183181,
            "name": "Comfortably dumb",
            "url": "https://twitter.com/FlewNest",
            "source_icon": ""
          },
          {
            "id": 179742,
            "name": "Epstein's Sheet. \ud83e\uddfb",
            "url": "https://twitter.com/meantweeting1",
            "source_icon": ""
          },
          {
            "id": 170693,
            "name": "#carersweek #TUSC \ud83d\udc9c",
            "url": "https://twitter.com/otivar55",
            "source_icon": ""
          },
          {
            "id": 167324,
            "name": "Karen Kline\u2764\ud83c\udf3f\u2764",
            "url": "https://twitter.com/Bontificate",
            "source_icon": ""
          },
          {
            "id": 164589,
            "name": "innocentlyignorant\ud83e\udd37\ud83c\udffb\u200d\u2640\ufe0f",
            "url": "https://twitter.com/rkanuch",
            "source_icon": ""
          },
          {
            "id": 159209,
            "name": "Dale Dewar",
            "url": "https://twitter.com/daledewar",
            "source_icon": ""
          },
          {
            "id": 150240,
            "name": "Hobson - Please Stop That",
            "url": "https://twitter.com/chimera246",
            "source_icon": ""
          },
          {
            "id": 147142,
            "name": "Reconciliation Day",
            "url": "https://twitter.com/int_rec_day",
            "source_icon": ""
          },
          {
            "id": 145317,
            "name": "WendylDail",
            "url": "https://twitter.com/wendylwilson2",
            "source_icon": ""
          },
          {
            "id": 149928414,
            "name": "Der lebende Mensch Nothing can stop what is coming",
            "url": "https://t.me/derlebendemensch",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 15.0,
          "negative_share": 85.0,
          "reactions_analyzed": 12886,
          "comments_analyzed": 166
        }
      },
      {
        "id": 5,
        "publication_date": "2024-08-06 08:00:09+00:00",
        "title": "A criminal case saw a sentencing delay request due to the defendant's employment at McDonald's",
        "content": "A person facing criminal charges sought to delay sentencing, citing employment at McDonald's as the reason. This request highlights the impact of job pressures on legal processes.",
        "rating": 8,
        "sources": [
          {
            "id": 198049,
            "name": "FOX SA",
            "url": "https://twitter.com/KABBFOX29",
            "source_icon": ""
          },
          {
            "id": 126940,
            "name": "norman rica",
            "url": "https://twitter.com/waketfuamerica",
            "source_icon": ""
          },
          {
            "id": 2647745,
            "name": "Cathie\u270d\ufe0f\ud83c\uddfa\ud83c\uddf8\ud83e\udd85",
            "url": "https://twitter.com/AmericanMOM01",
            "source_icon": ""
          },
          {
            "id": 173229,
            "name": "TheSacredFlame",
            "url": "https://twitter.com/TheSacredFlame",
            "source_icon": ""
          },
          {
            "id": 158856,
            "name": "Miss Murray's Mom",
            "url": "https://twitter.com/sgh956",
            "source_icon": ""
          },
          {
            "id": 154635,
            "name": "Schredder",
            "url": "https://twitter.com/Schredder13",
            "source_icon": ""
          },
          {
            "id": 47309,
            "name": "kenyadad12",
            "url": "https://twitter.com/kenyadad12",
            "source_icon": ""
          },
          {
            "id": 192423,
            "name": "John Roche",
            "url": "https://twitter.com/j_rplacitas",
            "source_icon": ""
          },
          {
            "id": 194956,
            "name": "MOMZILLA@Kath2252\ud83d\udc99ProChoice,BLM,LBGQT,Peace&Love",
            "url": "https://twitter.com/Kath2252",
            "source_icon": ""
          },
          {
            "id": 2040410,
            "name": "Brian Cardone \ud83c\udff4\u200d\u2620\ufe0f\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/cardon_brian",
            "source_icon": ""
          },
          {
            "id": 131251,
            "name": "Corvid 317",
            "url": "https://twitter.com/corvid317",
            "source_icon": ""
          },
          {
            "id": 170642,
            "name": "My Info",
            "url": "https://twitter.com/MendiolaGrandma",
            "source_icon": ""
          },
          {
            "id": 121297,
            "name": "Year of Democracy",
            "url": "https://twitter.com/factbase4",
            "source_icon": ""
          },
          {
            "id": 2042318,
            "name": "Picolina \ud83c\uddfa\ud83c\udde6 \u262e\ufe0f",
            "url": "https://twitter.com/PicolinaZoo",
            "source_icon": ""
          },
          {
            "id": 168744,
            "name": "Concerned Citizen\ud83d\udfe7\ud83d\udfe6\ud83c\uddfa\ud83c\udde6\ud83c\udf08",
            "url": "https://twitter.com/lowrad57",
            "source_icon": ""
          },
          {
            "id": 2648754,
            "name": "Jeffrey Meursing MA",
            "url": "https://twitter.com/JeffreyMeursing",
            "source_icon": ""
          },
          {
            "id": 100072,
            "name": "\ud83e\udeb7TexaKiwi (post-menopausal cat\ud83d\udc08 lady) \ud83c\uddfa\ud83c\uddf8\ud83e\udd85\ud83c\uddf3\ud83c\uddff",
            "url": "https://twitter.com/kiwimer70",
            "source_icon": ""
          },
          {
            "id": 85928,
            "name": "\u267f\ufe0f\ud83c\udde8\ud83c\udde6 Litbelle\ud83c\udde8\ud83c\udde6\ud83c\udf3b",
            "url": "https://twitter.com/Libe1997",
            "source_icon": ""
          },
          {
            "id": 60666652,
            "name": "Andrea Parody\ud83d\udd96\u2764\ufe0f",
            "url": "https://twitter.com/Andrea_51544005",
            "source_icon": ""
          },
          {
            "id": 80300,
            "name": "WeThePeople\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/PrincessBravato",
            "source_icon": ""
          },
          {
            "id": 76608,
            "name": "Amy Atwood",
            "url": "https://twitter.com/AmyAtwood2",
            "source_icon": ""
          },
          {
            "id": 2704484,
            "name": "David \ud83c\uddfa\ud83c\uddf8 \ud83e\udd85\ud83d\udfe7\u262e\ufe0f\ud83c\udff3\ufe0f\u200d\ud83c\udf08\ud83d\udc99\ud83c\udf0a",
            "url": "https://twitter.com/davidpsdem",
            "source_icon": ""
          },
          {
            "id": 68968,
            "name": "Gerry Negron",
            "url": "https://twitter.com/NegronDoc",
            "source_icon": ""
          },
          {
            "id": 4565441,
            "name": "ALEH",
            "url": "https://twitter.com/ALEHDoG",
            "source_icon": ""
          },
          {
            "id": 57858,
            "name": "fantome",
            "url": "https://twitter.com/lafantome",
            "source_icon": ""
          },
          {
            "id": 4851558,
            "name": "Old Dude Fella",
            "url": "https://twitter.com/oldudefellow",
            "source_icon": ""
          },
          {
            "id": 176711,
            "name": "Pat Hicks",
            "url": "https://twitter.com/jphicks",
            "source_icon": ""
          },
          {
            "id": 45861359,
            "name": "Harolynne Bobis (she/her)",
            "url": "https://twitter.com/Amaliada",
            "source_icon": ""
          },
          {
            "id": 196455,
            "name": "KFOX14 News",
            "url": "https://twitter.com/KFOX14",
            "source_icon": ""
          },
          {
            "id": 184686,
            "name": "Stevi - \ud83c\uddfa\ud83c\udde6 #StandWithUkraine #DetestToryValues",
            "url": "https://twitter.com/steviweavi",
            "source_icon": ""
          },
          {
            "id": 196486,
            "name": "NewsChannel 8 | KTUL",
            "url": "https://twitter.com/KTULNews",
            "source_icon": ""
          },
          {
            "id": 212206,
            "name": "Greg Rossi \ud83c\uddfa\ud83c\udde6 \ud83c\udff3\ufe0f\u200d\ud83c\udf08 \ud83c\udff3\ufe0f\u200d\u26a7\ufe0f #C\u043b\u0430\u0432\u0430\u0423\u043a\u0440\u0430\u0457\u043d\u0456",
            "url": "https://twitter.com/sailorboygreg",
            "source_icon": ""
          },
          {
            "id": 2040748,
            "name": "Leezeeraaby Maalnoordar",
            "url": "https://twitter.com/leezeeraaby",
            "source_icon": ""
          },
          {
            "id": 45420,
            "name": "CBS Austin",
            "url": "https://twitter.com/cbsaustin",
            "source_icon": ""
          },
          {
            "id": 149706,
            "name": "Debbie Faits",
            "url": "https://twitter.com/FaitsDebbie",
            "source_icon": ""
          },
          {
            "id": 170154,
            "name": "Cindy Johnston \ud83d\udfe7\ud83d\udc99\ud83d\udc9b\ud83c\udf3b\u270c\ud83c\udffb\u262e\ufe0f",
            "url": "https://twitter.com/Cinderoak",
            "source_icon": ""
          },
          {
            "id": 135572,
            "name": "JohnSimons",
            "url": "https://twitter.com/JohnSim18848655",
            "source_icon": ""
          },
          {
            "id": 53271,
            "name": "\u012bngr\u012bd stam",
            "url": "https://twitter.com/ingridmeta",
            "source_icon": ""
          },
          {
            "id": 54783,
            "name": "Antonia \ud83c\udf0aBiden/Harris 2024",
            "url": "https://twitter.com/Antonia99219423",
            "source_icon": ""
          },
          {
            "id": 62932,
            "name": "Mike Salz\u262e #StrongerTogether \ud83c\uddfa\ud83c\udde6 \ud83e\udd65\ud83c\udf34",
            "url": "https://twitter.com/Mike_Salz4",
            "source_icon": ""
          },
          {
            "id": 66284,
            "name": "Cain S. LaTrans",
            "url": "https://twitter.com/snkscoyote",
            "source_icon": ""
          },
          {
            "id": 105973,
            "name": "SuzyQ.\ud83c\udf3b\ud83d\udc99\ud83c\udf0a \ud83d\udc1e \ud83e\udd8b\u2618\ufe0f\u26f1\ud83c\uddfa\ud83c\udde6\ud83c\udf08\ud83d\udfe7\u2764\ufe0f\ud83d\udc3e",
            "url": "https://twitter.com/suzyqjordan",
            "source_icon": ""
          },
          {
            "id": 106315,
            "name": "George Silva",
            "url": "https://twitter.com/GeorgeVSilva",
            "source_icon": ""
          },
          {
            "id": 112251,
            "name": "Jazzmom 2.0 \ud83e\udee4\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/AJazzmom98",
            "source_icon": ""
          },
          {
            "id": 118217,
            "name": "Tim Hogan \u6d69\u52e4",
            "url": "https://twitter.com/TimInHonolulu",
            "source_icon": ""
          },
          {
            "id": 136620,
            "name": "ABC 13 News - WSET",
            "url": "https://twitter.com/ABC13News",
            "source_icon": ""
          },
          {
            "id": 167861,
            "name": "Rob",
            "url": "https://twitter.com/RobertA89696708",
            "source_icon": ""
          },
          {
            "id": 136716,
            "name": "WLOS",
            "url": "https://twitter.com/WLOS_13",
            "source_icon": ""
          },
          {
            "id": 136984,
            "name": "NBC Montana",
            "url": "https://twitter.com/NBCMontana",
            "source_icon": ""
          },
          {
            "id": 137014,
            "name": "ABC 33/40 News",
            "url": "https://twitter.com/abc3340",
            "source_icon": ""
          },
          {
            "id": 138139,
            "name": "FOX Illinois",
            "url": "https://twitter.com/foxillinois",
            "source_icon": ""
          },
          {
            "id": 138704,
            "name": "ABC 7 Amarillo",
            "url": "https://twitter.com/ABC7Amarillo",
            "source_icon": ""
          },
          {
            "id": 139045,
            "name": "KHQA News",
            "url": "https://twitter.com/KHQA",
            "source_icon": ""
          },
          {
            "id": 146680,
            "name": "Bob The Old Hippie \ud83e\udd65\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/BobInPascoFl",
            "source_icon": ""
          },
          {
            "id": 165007,
            "name": "CodeTalker",
            "url": "https://twitter.com/CodeTalker",
            "source_icon": ""
          },
          {
            "id": 109680142,
            "name": "LIGHTNINGBUG LANE\ud83c\udf0a",
            "url": "https://twitter.com/AbugLane",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 0.0,
          "negative_share": 100.0,
          "reactions_analyzed": 8,
          "comments_analyzed": 0
        }
      },
      {
        "id": 6,
        "publication_date": "2024-08-26 08:52:45+00:00",
        "title": "Bill Gates is linked to agriculture, owning land used for McDonald's potato supply",
        "content": "Bill Gates owns significant land in Washington state, crucial for growing potatoes used in McDonald's fries.",
        "rating": 6,
        "sources": [
          {
            "id": 7739520,
            "name": "nocensuraeverita",
            "url": "https://twitter.com/nocensuraverita",
            "source_icon": ""
          },
          {
            "id": 42106,
            "name": "Vectis \ud83d\udc3e",
            "url": "https://twitter.com/iow_richard",
            "source_icon": ""
          },
          {
            "id": 5737799,
            "name": "@blokirovanmaskom",
            "url": "https://twitter.com/ubitmaskom",
            "source_icon": ""
          },
          {
            "id": 224870,
            "name": "Abstract4Art",
            "url": "https://twitter.com/Harpo4Marx",
            "source_icon": ""
          },
          {
            "id": 248361,
            "name": "Arystokrata",
            "url": "https://twitter.com/Arystokrata1",
            "source_icon": ""
          },
          {
            "id": 2039049,
            "name": "Antonio Sabato Jr",
            "url": "https://twitter.com/AntonioSabatoJr",
            "source_icon": ""
          },
          {
            "id": 2039491,
            "name": "Winston Churchill Smith God Emperor of theUniverse",
            "url": "https://twitter.com/bob16066",
            "source_icon": ""
          },
          {
            "id": 2040343,
            "name": "illuminatibot",
            "url": "https://twitter.com/iluminatibot",
            "source_icon": ""
          },
          {
            "id": 2040451,
            "name": "Brian Bushard",
            "url": "https://twitter.com/newulmspeedy1",
            "source_icon": ""
          },
          {
            "id": 2040916,
            "name": "MAGA_FROG \ud83d\ude4f\ud83c\udffb\u271d\ufe0f\ud83d\ude4f\ud83c\udffb\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/FROGGY_SLING",
            "source_icon": ""
          },
          {
            "id": 2041050,
            "name": "SHARON HENRY \ud83d\udeab DMS \ud83d\udcaf\u2764\ufe0f\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/SharonH26448",
            "source_icon": ""
          },
          {
            "id": 2041052,
            "name": "Take America Back",
            "url": "https://twitter.com/TakeAmericaBac2",
            "source_icon": ""
          },
          {
            "id": 2049833,
            "name": "PaolettaSimonin",
            "url": "https://twitter.com/PaolettaSi7619",
            "source_icon": ""
          },
          {
            "id": 2703542,
            "name": "#FreePalestine #PardonJulianAssange",
            "url": "https://twitter.com/carolehope2",
            "source_icon": ""
          },
          {
            "id": 5559127,
            "name": "Rita",
            "url": "https://twitter.com/Anna06003151",
            "source_icon": ""
          },
          {
            "id": 5762130,
            "name": "Alberto Rossi",
            "url": "https://twitter.com/Alberto93383706",
            "source_icon": ""
          },
          {
            "id": 213246,
            "name": "Illinois girl Desi",
            "url": "https://twitter.com/d_ewinger",
            "source_icon": ""
          },
          {
            "id": 8282284,
            "name": "\u271d\ufe0fAnette Su Suomesta\u271d\ufe0f\ud83c\uddeb\ud83c\uddee",
            "url": "https://twitter.com/anettesund",
            "source_icon": ""
          },
          {
            "id": 13251732,
            "name": "Frans v Elsen Anti-Institutionele Extremist",
            "url": "https://twitter.com/0Frah5",
            "source_icon": ""
          },
          {
            "id": 20819623,
            "name": "Evgeniy Balandin",
            "url": "https://twitter.com/evgbalon",
            "source_icon": ""
          },
          {
            "id": 22232383,
            "name": "HelenaTroy \ud83e\ude78\ud83d\udc9c\ud83d\uddfd\ud83d\ude9c",
            "url": "https://twitter.com/HHelenakhl",
            "source_icon": ""
          },
          {
            "id": 29284064,
            "name": "GiletGirl",
            "url": "https://twitter.com/GiletGirl",
            "source_icon": ""
          },
          {
            "id": 45568385,
            "name": "Snyggo \ud83e\ude93\ud83c\udf84\ud83d\udc18\ud83e\udd53\ud83d\udd2b\ud83d\ude9c\ud83d\udc04",
            "url": "https://twitter.com/Snyggois",
            "source_icon": ""
          },
          {
            "id": 69331267,
            "name": "Marija Z",
            "url": "https://twitter.com/MarijaMerii",
            "source_icon": ""
          },
          {
            "id": 109677967,
            "name": "Sirpuu",
            "url": "https://twitter.com/sirpuu",
            "source_icon": ""
          },
          {
            "id": 109679202,
            "name": "We The Memers",
            "url": "https://twitter.com/57stude",
            "source_icon": ""
          },
          {
            "id": 109679783,
            "name": "\ud83c\uddef\ud83c\uddf5\u30b4\u30fc\u30b9\u30c8\u306b\u3083\u3044\u3060\u30fc\ud83d\ude3a",
            "url": "https://twitter.com/9qPyyAsKBSsXS7r",
            "source_icon": ""
          },
          {
            "id": 109679805,
            "name": "\ud83c\uddee\ud83c\uddf3 Vijaya Kumar",
            "url": "https://twitter.com/A31X64Vijay",
            "source_icon": ""
          },
          {
            "id": 109681361,
            "name": "da Blues brother\ud83d\udc4a",
            "url": "https://twitter.com/ArtWesterlund",
            "source_icon": ""
          },
          {
            "id": 215356,
            "name": "Dorothy Hesp",
            "url": "https://twitter.com/DorothyHesp",
            "source_icon": ""
          },
          {
            "id": 207349,
            "name": "Quaternion Group",
            "url": "https://twitter.com/QuaternionGroup",
            "source_icon": ""
          },
          {
            "id": 52023,
            "name": "David Knight",
            "url": "https://twitter.com/DaveKnight1776",
            "source_icon": ""
          },
          {
            "id": 198921,
            "name": "Ani & Brewed Cofi",
            "url": "https://twitter.com/AniR113",
            "source_icon": ""
          },
          {
            "id": 66378,
            "name": "Penny Farrington",
            "url": "https://twitter.com/PennyFarringt14",
            "source_icon": ""
          },
          {
            "id": 69696,
            "name": "Jamie Ott Blom",
            "url": "https://twitter.com/jamie_blom",
            "source_icon": ""
          },
          {
            "id": 70986,
            "name": "DERMAN",
            "url": "https://twitter.com/NICK26215",
            "source_icon": ""
          },
          {
            "id": 78195,
            "name": "Simple Simon's Sinuses",
            "url": "https://twitter.com/Geoffs109",
            "source_icon": ""
          },
          {
            "id": 82208,
            "name": "Castello",
            "url": "https://twitter.com/castello2",
            "source_icon": ""
          },
          {
            "id": 85351,
            "name": "Michael E. Dehn",
            "url": "https://twitter.com/mediapython",
            "source_icon": ""
          },
          {
            "id": 87452,
            "name": "LC",
            "url": "https://twitter.com/logical_lop",
            "source_icon": ""
          },
          {
            "id": 88935,
            "name": "D Russell\ud83c\uddfa\ud83c\uddf8ULTRA-MAGA",
            "url": "https://twitter.com/DRussel55238296",
            "source_icon": ""
          },
          {
            "id": 92168,
            "name": "Muzamana Victor",
            "url": "https://twitter.com/Muzamana_Victor",
            "source_icon": ""
          },
          {
            "id": 92732,
            "name": "Michael Drysch",
            "url": "https://twitter.com/HalfCourtMikeD",
            "source_icon": ""
          },
          {
            "id": 97410,
            "name": "LEER OLDHAM",
            "url": "https://twitter.com/knightwings38",
            "source_icon": ""
          },
          {
            "id": 104673,
            "name": "who?????me",
            "url": "https://twitter.com/speakoutsodem",
            "source_icon": ""
          },
          {
            "id": 105500,
            "name": "roadtrips",
            "url": "https://twitter.com/roadtrips17",
            "source_icon": ""
          },
          {
            "id": 105506,
            "name": "Nish",
            "url": "https://twitter.com/NishKhanna2",
            "source_icon": ""
          },
          {
            "id": 109701,
            "name": "Jenny Taylor \ud83c\udf38\ud83c\udde6\ud83c\uddfa\ud83c\uddfa\ud83c\uddf8\ud83c\uddf5\ud83c\uddf8",
            "url": "https://twitter.com/JennyTa22393082",
            "source_icon": ""
          },
          {
            "id": 110270,
            "name": "Jaime Otoniel",
            "url": "https://twitter.com/JaimeOtoniel4",
            "source_icon": ""
          },
          {
            "id": 110866,
            "name": "Crash_the_Flash \ud83d\udc2d",
            "url": "https://twitter.com/Crash_the_Flash",
            "source_icon": ""
          },
          {
            "id": 119190,
            "name": "V.Streifel",
            "url": "https://twitter.com/streifel_v",
            "source_icon": ""
          },
          {
            "id": 120005,
            "name": "Liberty And Justice For ALL \ud83c\uddfa\ud83c\uddf8\u2b50\ufe0f\u2b50\ufe0f\u2b50\ufe0f\ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/JackClinton412",
            "source_icon": ""
          },
          {
            "id": 126476,
            "name": "Joseph A Dowdy",
            "url": "https://twitter.com/hockeyseasntime",
            "source_icon": ""
          },
          {
            "id": 136886,
            "name": "Ej Pflug-God Bless America",
            "url": "https://twitter.com/catdog5",
            "source_icon": ""
          },
          {
            "id": 139142,
            "name": "Pawpawdude\ud83c\udf4a",
            "url": "https://twitter.com/pawpawdude",
            "source_icon": ""
          },
          {
            "id": 147451,
            "name": "CISSY-I DO NOT CONSENT",
            "url": "https://twitter.com/cissy_neva",
            "source_icon": ""
          },
          {
            "id": 163984,
            "name": "Jeff_kamara2",
            "url": "https://twitter.com/Kamara2R",
            "source_icon": ""
          },
          {
            "id": 175667,
            "name": "JMB \ud83c\uddfa\ud83c\uddf2 \ud83c\udf39\ud83d\uddfd",
            "url": "https://twitter.com/JGzGurl24",
            "source_icon": ""
          },
          {
            "id": 195760,
            "name": "Carol Jennings",
            "url": "https://twitter.com/CarolJ644",
            "source_icon": ""
          },
          {
            "id": 195810,
            "name": "Elbert C. Lyon",
            "url": "https://twitter.com/omahabe2",
            "source_icon": ""
          },
          {
            "id": 109687997,
            "name": "JohnARead",
            "url": "https://twitter.com/JohnARead",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 50.0,
          "negative_share": 50.0,
          "reactions_analyzed": 8782,
          "comments_analyzed": 87
        }
      },
      {
        "id": 7,
        "publication_date": "2024-08-11 20:38:00+00:00",
        "title": "McDonald's France considers removing 'Curry' sauce from its menu for four years",
        "content": "McDonald's France is considering dropping \"Curry\" sauce for four years, possibly in response to the French basketball team's Olympic loss to the U.S., humorously linked to Steph Curry's performance.",
        "rating": 6,
        "sources": [
          {
            "id": 43921,
            "name": "KETV NewsWatch 7",
            "url": "https://twitter.com/KETV",
            "source_icon": ""
          },
          {
            "id": 40685,
            "name": "MyNBC5",
            "url": "https://twitter.com/MyNBC5",
            "source_icon": ""
          },
          {
            "id": 132663,
            "name": "ABC7 News",
            "url": "https://twitter.com/abc7newsbayarea",
            "source_icon": ""
          },
          {
            "id": 46798,
            "name": "Berkley Bear",
            "url": "https://twitter.com/BerkleyBearNews",
            "source_icon": ""
          },
          {
            "id": 40632,
            "name": "WXII 12 News",
            "url": "https://twitter.com/WXII",
            "source_icon": ""
          },
          {
            "id": 196402,
            "name": "NBC Bay Area",
            "url": "https://twitter.com/nbcbayarea",
            "source_icon": ""
          },
          {
            "id": 68759,
            "name": "8News WRIC Richmond",
            "url": "https://twitter.com/8NEWS",
            "source_icon": ""
          },
          {
            "id": 69231,
            "name": "WGN Radio News",
            "url": "https://twitter.com/WGNRadioNews",
            "source_icon": ""
          },
          {
            "id": 72217,
            "name": "EYEWITNESS NEWS",
            "url": "https://twitter.com/cnyhomepage",
            "source_icon": ""
          },
          {
            "id": 77855,
            "name": "BPR",
            "url": "https://twitter.com/BIZPACReview",
            "source_icon": ""
          },
          {
            "id": 79843,
            "name": "Carl Quintanilla",
            "url": "https://twitter.com/carlquintanilla",
            "source_icon": ""
          },
          {
            "id": 129285,
            "name": "KTVZ NewsChannel 21",
            "url": "https://twitter.com/KTVZ",
            "source_icon": ""
          },
          {
            "id": 136716,
            "name": "WLOS",
            "url": "https://twitter.com/WLOS_13",
            "source_icon": ""
          },
          {
            "id": 139031,
            "name": "News 3 Now / Channel 3000",
            "url": "https://twitter.com/WISCTV_News3",
            "source_icon": ""
          },
          {
            "id": 153189,
            "name": "NBC4 Washington",
            "url": "https://twitter.com/nbcwashington",
            "source_icon": ""
          },
          {
            "id": 196322,
            "name": "FOX 5 San Diego",
            "url": "https://twitter.com/fox5sandiego",
            "source_icon": ""
          },
          {
            "id": 198065,
            "name": "WKRG",
            "url": "https://twitter.com/WKRG",
            "source_icon": ""
          },
          {
            "id": 198091,
            "name": "WCIA",
            "url": "https://twitter.com/WCIA3",
            "source_icon": ""
          },
          {
            "id": 198381,
            "name": "CW39",
            "url": "https://twitter.com/CW39Houston",
            "source_icon": ""
          },
          {
            "id": 200928,
            "name": "KSN News Wichita",
            "url": "https://twitter.com/KSNNews",
            "source_icon": ""
          },
          {
            "id": 201136,
            "name": "FOX 56 News (WDKY)",
            "url": "https://twitter.com/FOX56News",
            "source_icon": ""
          },
          {
            "id": 201235,
            "name": "ABC22 & FOX44",
            "url": "https://twitter.com/WVNYWFFF",
            "source_icon": ""
          },
          {
            "id": 203263,
            "name": "DC News Now",
            "url": "https://twitter.com/DCNewsNow",
            "source_icon": ""
          },
          {
            "id": 207502,
            "name": "ABC4 News",
            "url": "https://twitter.com/abc4utah",
            "source_icon": ""
          },
          {
            "id": 226951,
            "name": "CNN",
            "url": "https://cnn.com",
            "source_icon": "https://www.cnn.com/favicon.ico"
          },
          {
            "id": 242740,
            "name": "Hindustan Times- Official Channel",
            "url": "https://t.me/hindustantimes",
            "source_icon": ""
          },
          {
            "id": 89077711,
            "name": "\u041f\u0435\u0440\u0432\u044b\u0439 \u0441\u043f\u043e\u0440\u0442",
            "url": "https://t.me/pervsport",
            "source_icon": ""
          },
          {
            "id": 99076899,
            "name": "cnn.com/business/energy",
            "url": "https://cnn.com/business/energy",
            "source_icon": "https://www.cnn.com/favicon.ico"
          },
          {
            "id": 100573304,
            "name": "Tek.fm \ud83c\udd95",
            "url": "https://t.me/sok_fm_news",
            "source_icon": ""
          },
          {
            "id": 64421,
            "name": "Bo Snerdley",
            "url": "https://twitter.com/BoSnerdley",
            "source_icon": ""
          },
          {
            "id": 45572,
            "name": "KSAN News",
            "url": "https://twitter.com/ksannews",
            "source_icon": ""
          },
          {
            "id": 40634,
            "name": "WMTW TV",
            "url": "https://twitter.com/WMTWTV",
            "source_icon": ""
          },
          {
            "id": 40744,
            "name": "kcranews",
            "url": "https://twitter.com/kcranews",
            "source_icon": ""
          },
          {
            "id": 40635,
            "name": "KSBW Action News 8",
            "url": "https://twitter.com/ksbw",
            "source_icon": ""
          },
          {
            "id": 40646,
            "name": "WISN 12 NEWS",
            "url": "https://twitter.com/WISN12News",
            "source_icon": ""
          },
          {
            "id": 40681,
            "name": "WPBF 25 News",
            "url": "https://twitter.com/WPBF25News",
            "source_icon": ""
          },
          {
            "id": 40682,
            "name": "koconews",
            "url": "https://twitter.com/koconews",
            "source_icon": ""
          },
          {
            "id": 40683,
            "name": "WJCL News",
            "url": "https://twitter.com/WJCLNews",
            "source_icon": ""
          },
          {
            "id": 40684,
            "name": "KMBC",
            "url": "https://twitter.com/kmbc",
            "source_icon": ""
          },
          {
            "id": 40686,
            "name": "WTAE-TV Pittsburgh",
            "url": "https://twitter.com/WTAE",
            "source_icon": ""
          },
          {
            "id": 40687,
            "name": "4029news",
            "url": "https://twitter.com/4029news",
            "source_icon": ""
          },
          {
            "id": 40688,
            "name": "WMUR TV",
            "url": "https://twitter.com/WMUR9",
            "source_icon": ""
          },
          {
            "id": 40689,
            "name": "16 WAPT News",
            "url": "https://twitter.com/16WAPTNews",
            "source_icon": ""
          },
          {
            "id": 40717,
            "name": "KOAT.com",
            "url": "https://twitter.com/koat7news",
            "source_icon": ""
          },
          {
            "id": 40745,
            "name": "WESH 2 News",
            "url": "https://twitter.com/WESH",
            "source_icon": ""
          },
          {
            "id": 45236,
            "name": "WNCT",
            "url": "https://twitter.com/wnct9",
            "source_icon": ""
          },
          {
            "id": 40746,
            "name": "WBAL-TV 11 Baltimore",
            "url": "https://twitter.com/wbaltv11",
            "source_icon": ""
          },
          {
            "id": 40766,
            "name": "WLWT",
            "url": "https://twitter.com/WLWT",
            "source_icon": ""
          },
          {
            "id": 40778,
            "name": "WGAL",
            "url": "https://twitter.com/WGAL",
            "source_icon": ""
          },
          {
            "id": 40779,
            "name": "WLKY",
            "url": "https://twitter.com/WLKY",
            "source_icon": ""
          },
          {
            "id": 40780,
            "name": "KCCI News",
            "url": "https://twitter.com/KCCINews",
            "source_icon": ""
          },
          {
            "id": 41361,
            "name": "WHLT 22 Hattiesburg",
            "url": "https://twitter.com/WHLT22",
            "source_icon": ""
          },
          {
            "id": 43463,
            "name": "KLST TV",
            "url": "https://twitter.com/klstnews",
            "source_icon": ""
          },
          {
            "id": 43651,
            "name": "wdsu",
            "url": "https://twitter.com/wdsu",
            "source_icon": ""
          },
          {
            "id": 43816,
            "name": "FOX Business",
            "url": "https://twitter.com/FoxBusiness",
            "source_icon": ""
          },
          {
            "id": 43939,
            "name": "WYFF News 4",
            "url": "https://twitter.com/wyffnews4",
            "source_icon": ""
          },
          {
            "id": 44007,
            "name": "WVTM 13",
            "url": "https://twitter.com/WVTM13",
            "source_icon": ""
          },
          {
            "id": 131786823,
            "name": "Okko \u0421\u043f\u043e\u0440\u0442",
            "url": "https://t.me/okkosport",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 17.0,
          "negative_share": 83.0,
          "reactions_analyzed": 374,
          "comments_analyzed": 10
        }
      },
      {
        "id": 8,
        "publication_date": "2024-08-22 00:00:01+00:00",
        "title": "Allegations of dangerous chemicals in fast food spark concern among consumers",
        "content": "80% of food samples from major fast-food chains, including McDonald's, were found to contain dangerous chemicals. McDonald's uses salt with aluminum, linked to health risks. Their Chicken McNuggets contain harmful additives.",
        "rating": 5,
        "sources": [
          {
            "id": 87960764,
            "name": "Digital Soldier Back Channel \ud83d\udea8\ud83d\udea8\ud83d\udea8\ud83d\udea8\ud83d\udea8",
            "url": "https://t.me/digitalsoldierbackchannel",
            "source_icon": ""
          },
          {
            "id": 47240,
            "name": "Citizen of EU",
            "url": "https://twitter.com/wavetossed",
            "source_icon": ""
          },
          {
            "id": 85770818,
            "name": "SATIRE CLOUD",
            "url": "https://t.me/satirecloud",
            "source_icon": ""
          },
          {
            "id": 48460225,
            "name": "\u20bfased male\u2122 \ud83c\uddfa\ud83c\uddf8",
            "url": "https://twitter.com/GetMeRoss",
            "source_icon": ""
          },
          {
            "id": 66446520,
            "name": "17soul",
            "url": "https://twitter.com/17soul160523",
            "source_icon": ""
          },
          {
            "id": 83055474,
            "name": "astrofon",
            "url": "https://t.me/astrozeit",
            "source_icon": ""
          },
          {
            "id": 85038346,
            "name": "Takapirulainen",
            "url": "https://twitter.com/takapirulainen",
            "source_icon": ""
          },
          {
            "id": 85238329,
            "name": "jjwils2",
            "url": "https://t.me/jjwils2",
            "source_icon": ""
          },
          {
            "id": 85245692,
            "name": "Sovereign Redneck Renegade - Christos Guardian Alliance Warrior",
            "url": "https://t.me/SRR8_CK8_ELias8_MELchizedek8",
            "source_icon": ""
          },
          {
            "id": 85257477,
            "name": "Patriot's Creative Concepts",
            "url": "https://t.me/PatriotsCreativeConcepts",
            "source_icon": ""
          },
          {
            "id": 85467454,
            "name": "\ud83c\udf3f Gesundheittipps \ud83c\udf3f",
            "url": "https://t.me/gesundheitstipps_at",
            "source_icon": ""
          },
          {
            "id": 85499215,
            "name": "Die wahre Offenbarung deutscher Geschichte/Arch\u00e4ologie",
            "url": "https://t.me/wahreOffenbarung",
            "source_icon": ""
          },
          {
            "id": 85499241,
            "name": "HIDDEN TRUTH",
            "url": "https://t.me/HiddentruthHT",
            "source_icon": ""
          },
          {
            "id": 86298327,
            "name": "Utsava",
            "url": "https://t.me/utsavaprophecy",
            "source_icon": ""
          },
          {
            "id": 8110997,
            "name": "NEWSFORCE \ud83c\udf0e",
            "url": "https://t.me/newsforce",
            "source_icon": ""
          },
          {
            "id": 88711290,
            "name": "Proof is Truth",
            "url": "https://t.me/proofistruth",
            "source_icon": ""
          },
          {
            "id": 92365602,
            "name": "PREUSSENZEITUNG - Unabh\u00e4ngig & \u00dcberparteilich! Deutsche Lobby Freunde & mehr... Top20Radio Unterst\u00fctzer\ud83d\udc93",
            "url": "https://t.me/preussenspatriotenelite",
            "source_icon": ""
          },
          {
            "id": 95320687,
            "name": "conspiracybot",
            "url": "https://t.me/conspiracyb0t1",
            "source_icon": ""
          },
          {
            "id": 95766407,
            "name": "HinzuuCOM",
            "url": "https://t.me/hinzuucom",
            "source_icon": ""
          },
          {
            "id": 102018411,
            "name": "Sarah Swift",
            "url": "https://t.me/SarahSwift",
            "source_icon": ""
          },
          {
            "id": 109678499,
            "name": "Mike D Albright",
            "url": "https://twitter.com/1MDA44",
            "source_icon": ""
          },
          {
            "id": 109681081,
            "name": "Anna Johansson",
            "url": "https://twitter.com/AnnaJoh21512129",
            "source_icon": ""
          },
          {
            "id": 117459982,
            "name": "QAnon - The Storm Is Coming",
            "url": "https://t.me/QAnonTheStorm",
            "source_icon": ""
          },
          {
            "id": 158361776,
            "name": "Julian Assange",
            "url": "https://t.me/JulianAssange3",
            "source_icon": ""
          },
          {
            "id": 13555486,
            "name": "conspiracybot",
            "url": "https://twitter.com/conspiracyb0t",
            "source_icon": ""
          },
          {
            "id": 5762130,
            "name": "Alberto Rossi",
            "url": "https://twitter.com/Alberto93383706",
            "source_icon": ""
          },
          {
            "id": 70986,
            "name": "DERMAN",
            "url": "https://twitter.com/NICK26215",
            "source_icon": ""
          },
          {
            "id": 181841,
            "name": "\ud83c\uddec\ud83c\udde7 BLOKE IN PUB \ud83c\uddec\ud83c\udde7",
            "url": "https://twitter.com/paulturner2012",
            "source_icon": ""
          },
          {
            "id": 73873,
            "name": "StopEndlessWar",
            "url": "https://twitter.com/StopEndWarsNOW",
            "source_icon": ""
          },
          {
            "id": 75726,
            "name": "ReesesPeices",
            "url": "https://twitter.com/reesespeices",
            "source_icon": ""
          },
          {
            "id": 97338,
            "name": "Defiant",
            "url": "https://twitter.com/handsandtools",
            "source_icon": ""
          },
          {
            "id": 101771,
            "name": "George W. Bu",
            "url": "https://twitter.com/George_W_Bu",
            "source_icon": ""
          },
          {
            "id": 110270,
            "name": "Jaime Otoniel",
            "url": "https://twitter.com/JaimeOtoniel4",
            "source_icon": ""
          },
          {
            "id": 126476,
            "name": "Joseph A Dowdy",
            "url": "https://twitter.com/hockeyseasntime",
            "source_icon": ""
          },
          {
            "id": 135942,
            "name": "Pat W.\ud83d\udc36\ud83d\udc31\ud83d\udc35",
            "url": "https://twitter.com/PatWardle",
            "source_icon": ""
          },
          {
            "id": 138129,
            "name": "(Dame) SMP \ud83c\uddec\ud83c\udde7",
            "url": "https://twitter.com/SP_015",
            "source_icon": ""
          },
          {
            "id": 169638,
            "name": "One does allege all of ones content\ud83c\udff4\udb40\udc67\udb40\udc62..",
            "url": "https://twitter.com/1NickMill",
            "source_icon": ""
          },
          {
            "id": 179702,
            "name": "@My.AbdelkaderELAlami\u269c\ufe0f",
            "url": "https://twitter.com/ITCAlami",
            "source_icon": ""
          },
          {
            "id": 198921,
            "name": "Ani & Brewed Cofi",
            "url": "https://twitter.com/AniR113",
            "source_icon": ""
          },
          {
            "id": 2053520,
            "name": "Ori \ud83d\udee1",
            "url": "https://twitter.com/Sovereignity999",
            "source_icon": ""
          },
          {
            "id": 205230,
            "name": "Angela",
            "url": "https://twitter.com/angassistant",
            "source_icon": ""
          },
          {
            "id": 247971,
            "name": "PepperSpray",
            "url": "https://twitter.com/Pepper2Mintt",
            "source_icon": ""
          },
          {
            "id": 248277,
            "name": "Piter z krainy Mordor, gdzie zaleg\u0142y cienie.",
            "url": "https://twitter.com/Piter920611",
            "source_icon": ""
          },
          {
            "id": 250290,
            "name": "\ud83c\udde6\ud83c\uddf9 TOPNEWS AUSTRIA \ud83c\udde6\ud83c\uddf9",
            "url": "https://t.me/topnews_at",
            "source_icon": ""
          },
          {
            "id": 251265,
            "name": "PROJECT CAMELOT",
            "url": "https://t.me/projectcamelotkerry",
            "source_icon": ""
          },
          {
            "id": 2040343,
            "name": "illuminatibot",
            "url": "https://twitter.com/iluminatibot",
            "source_icon": ""
          },
          {
            "id": 2042364,
            "name": "Jonathan Stepanich",
            "url": "https://twitter.com/JStepanich1",
            "source_icon": ""
          },
          {
            "id": 2045041,
            "name": "Tagesereignisse der Offenbarung",
            "url": "https://t.me/tagesereignisseoffenbarung",
            "source_icon": ""
          },
          {
            "id": 2045458,
            "name": "Texas Ted Talks",
            "url": "https://t.me/texastedtalks",
            "source_icon": ""
          },
          {
            "id": 2050212,
            "name": "\ud83c\uddff\ud83c\udde6 Wonderland \ud83c\uddff\ud83c\udde6",
            "url": "https://twitter.com/socialHATchat",
            "source_icon": ""
          },
          {
            "id": 170157125,
            "name": "\ud83d\udde3\ud83c\udde8\ud83c\uddedInfos\ud83c\udde6\ud83c\uddf9Patriots\ud83c\udde9\ud83c\uddeamenschen\ud83d\udde3",
            "url": "https://t.me/zusammengefassteinfos",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 0.0,
          "negative_share": 100.0,
          "reactions_analyzed": 1892,
          "comments_analyzed": 18
        }
      },
      {
        "id": 9,
        "publication_date": "2024-08-22 05:16:51+00:00",
        "title": "Bill Clinton humorously anticipates breaking a McDonald's-related record if Hillary becomes president",
        "content": "Bill Clinton anticipates Hillary Clinton surpassing his McDonald's visit record as president. Hillary believes Kamala Harris might break her record. Bill reiterates Hillary's potential to outdo his McDonald's time.",
        "rating": 5,
        "sources": [
          {
            "id": 68968,
            "name": "Gerry Negron",
            "url": "https://twitter.com/NegronDoc",
            "source_icon": ""
          },
          {
            "id": 3385407,
            "name": "Ali Adair \ud83d\udfe7",
            "url": "https://twitter.com/AliAdair22",
            "source_icon": ""
          },
          {
            "id": 132285,
            "name": "The Recount",
            "url": "https://twitter.com/therecount",
            "source_icon": ""
          },
          {
            "id": 92168,
            "name": "Muzamana Victor",
            "url": "https://twitter.com/Muzamana_Victor",
            "source_icon": ""
          },
          {
            "id": 2042244,
            "name": "Heather Thomas",
            "url": "https://twitter.com/HeatherThomasAF",
            "source_icon": ""
          },
          {
            "id": 106758,
            "name": "KP \ud83d\udcda",
            "url": "https://twitter.com/kat_pep_",
            "source_icon": ""
          },
          {
            "id": 208714,
            "name": "\u270c\ud83d\udc99 \ud83c\uddfa\ud83c\uddf2LemStraw\ud83c\uddfa\ud83c\uddf2 \ud83d\udc99\u270c#IStandWithUkraine\ud83c\uddfa\ud83c\udde6\u262e\ud83d\udd4a",
            "url": "https://twitter.com/LemStraw",
            "source_icon": ""
          },
          {
            "id": 41923,
            "name": "The Washington Times",
            "url": "https://twitter.com/WashTimes",
            "source_icon": ""
          },
          {
            "id": 219685,
            "name": "CSPAN",
            "url": "https://twitter.com/cspan",
            "source_icon": ""
          },
          {
            "id": 166366,
            "name": "Takuma \ud83c\uddfa\ud83c\udde6 \ud83c\udf34\ud83e\udd65",
            "url": "https://twitter.com/Takuma1700",
            "source_icon": ""
          },
          {
            "id": 170154,
            "name": "Cindy Johnston \ud83d\udfe7\ud83d\udc99\ud83d\udc9b\ud83c\udf3b\u270c\ud83c\udffb\u262e\ufe0f",
            "url": "https://twitter.com/Cinderoak",
            "source_icon": ""
          },
          {
            "id": 170642,
            "name": "My Info",
            "url": "https://twitter.com/MendiolaGrandma",
            "source_icon": ""
          },
          {
            "id": 184850,
            "name": "Allan Rose \ud83c\udf35\ud83c\udf39\ud83c\udf34\ud83c\udf4a\ud83d\ude0e\ud83c\udf2e\ud83c\udf08\u26bd\ud83c\udf0a\ud83c\udf0a\ud83c\udf0a\ud83d\udc99\ud83d\udfe6\ud83d\udfe7",
            "url": "https://twitter.com/SonoranSteve97",
            "source_icon": ""
          },
          {
            "id": 194956,
            "name": "MOMZILLA@Kath2252\ud83d\udc99ProChoice,BLM,LBGQT,Peace&Love",
            "url": "https://twitter.com/Kath2252",
            "source_icon": ""
          },
          {
            "id": 2041461,
            "name": "Andrea \ud83c\uddfa\ud83c\uddf8 \ud83c\uddec\ud83c\udde7 \ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc73\udb40\udc63\udb40\udc74\udb40\udc7f \ud83c\udde9\ud83c\uddea \ud83c\uddf8\ud83c\uddea\ud83c\uddfa\ud83c\udde6",
            "url": "https://twitter.com/WholePlateWay",
            "source_icon": ""
          },
          {
            "id": 2040332,
            "name": "MeidasTouch",
            "url": "https://twitter.com/MeidasTouch",
            "source_icon": ""
          },
          {
            "id": 153531,
            "name": "Karin Charmley.#BIDEN/HARRIS \ud83c\uddfa\ud83c\udde6\ud83c\udf0a",
            "url": "https://twitter.com/TazKHC",
            "source_icon": ""
          },
          {
            "id": 2704484,
            "name": "David \ud83c\uddfa\ud83c\uddf8 \ud83e\udd85\ud83d\udfe7\u262e\ufe0f\ud83c\udff3\ufe0f\u200d\ud83c\udf08\ud83d\udc99\ud83c\udf0a",
            "url": "https://twitter.com/davidpsdem",
            "source_icon": ""
          },
          {
            "id": 4851558,
            "name": "Old Dude Fella",
            "url": "https://twitter.com/oldudefellow",
            "source_icon": ""
          },
          {
            "id": 45488793,
            "name": "AnnRoss \ud83c\udf34\ud83e\udd65",
            "url": "https://twitter.com/AnnRoss201111",
            "source_icon": ""
          },
          {
            "id": 109678795,
            "name": "Ellie C",
            "url": "https://twitter.com/2kcmnl",
            "source_icon": ""
          },
          {
            "id": 109680818,
            "name": "Ambii Joy Moore",
            "url": "https://twitter.com/AmbiiMoore",
            "source_icon": ""
          },
          {
            "id": 163867,
            "name": "Original Me \ud83c\uddfa\ud83c\uddf8\ud83e\udd85\ud83c\udf0a",
            "url": "https://twitter.com/MadamWiser",
            "source_icon": ""
          },
          {
            "id": 139186,
            "name": "Ken Thomas",
            "url": "https://twitter.com/KThomasDC",
            "source_icon": ""
          },
          {
            "id": 148392,
            "name": "marybl",
            "url": "https://twitter.com/marybl62",
            "source_icon": ""
          },
          {
            "id": 101581,
            "name": "Billary\ud83c\udf69Rodham\ud83c\udf69",
            "url": "https://twitter.com/BillyESmith",
            "source_icon": ""
          },
          {
            "id": 50754,
            "name": "Resist hateful GOP policies",
            "url": "https://twitter.com/hateGOP",
            "source_icon": ""
          },
          {
            "id": 52883,
            "name": "Aaron Rupar",
            "url": "https://twitter.com/atrupar",
            "source_icon": ""
          },
          {
            "id": 56795,
            "name": "Matt Winfough \ud83c\uddfa\ud83c\uddf8\ud83c\uddfa\ud83c\udde6\ud83c\uddea\ud83c\uddfa",
            "url": "https://twitter.com/MattWinfough",
            "source_icon": ""
          },
          {
            "id": 67406,
            "name": "Jim Sinclair",
            "url": "https://twitter.com/jim_sinclairjr",
            "source_icon": ""
          },
          {
            "id": 80670,
            "name": "MA_NYC \ud83e\udeb7",
            "url": "https://twitter.com/ma_nyc7",
            "source_icon": ""
          },
          {
            "id": 87430,
            "name": "Charlie Davis",
            "url": "https://twitter.com/CharlesPDavis",
            "source_icon": ""
          },
          {
            "id": 100072,
            "name": "\ud83e\udeb7TexaKiwi (post-menopausal cat\ud83d\udc08 lady) \ud83c\uddfa\ud83c\uddf8\ud83e\udd85\ud83c\uddf3\ud83c\uddff",
            "url": "https://twitter.com/kiwimer70",
            "source_icon": ""
          },
          {
            "id": 112500,
            "name": "Acyn",
            "url": "https://twitter.com/Acyn",
            "source_icon": ""
          },
          {
            "id": 143801,
            "name": "Helen Self",
            "url": "https://twitter.com/Helens31Self",
            "source_icon": ""
          },
          {
            "id": 124223,
            "name": "Must Love \ud83d\udc36",
            "url": "https://twitter.com/RojoRurba002",
            "source_icon": ""
          },
          {
            "id": 125430,
            "name": "Jeffrey Evan Gold",
            "url": "https://twitter.com/jeffgoldesq",
            "source_icon": ""
          },
          {
            "id": 130656,
            "name": "Vote Blue SE #wtpBLUE\ud83d\udc99",
            "url": "https://twitter.com/TriciaJJones",
            "source_icon": ""
          },
          {
            "id": 138197,
            "name": "HuffPost",
            "url": "https://twitter.com/HuffPost",
            "source_icon": ""
          },
          {
            "id": 139095,
            "name": "Elaine Taylor",
            "url": "https://twitter.com/JessesLaw",
            "source_icon": ""
          },
          {
            "id": 139161,
            "name": "Cristy Bodnar",
            "url": "https://twitter.com/pookoo_2000",
            "source_icon": ""
          },
          {
            "id": 46940,
            "name": "Derek Gendvil",
            "url": "https://twitter.com/dgendvil",
            "source_icon": ""
          },
          {
            "id": 109681141,
            "name": "Paul Jankura",
            "url": "https://twitter.com/Anthropic",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 100.0,
          "negative_share": 0.0,
          "reactions_analyzed": 6355,
          "comments_analyzed": 92
        }
      },
      {
        "id": 10,
        "publication_date": "2024-08-03 20:36:07+00:00",
        "title": "Protests in D\u00fczce target McDonald's for its support to the Israeli military",
        "content": "In D\u00fczce, a significant protest occurred against McDonald's for offering free meals to the Israeli army, reflecting broader boycott actions in the Middle East due to the company's support for Israel.",
        "rating": 4,
        "sources": [
          {
            "id": 164894,
            "name": "Martyn Bradbury",
            "url": "https://twitter.com/CitizenBomber",
            "source_icon": ""
          },
          {
            "id": 2051546,
            "name": "Ertu\u011frul Altun \ud83c\uddf9\ud83c\uddf7\ud83c\udde6\ud83c\uddff\ud83c\uddf5\ud83c\uddf0\ud83c\uddf2\ud83c\uddfb\ud83c\uddf9\ud83c\uddf3\ud83c\uddef\ud83c\uddf4\ud83c\uddf2\ud83c\uddf7\ud83c\udde9\ud83c\uddff",
            "url": "https://twitter.com/ferasetname",
            "source_icon": ""
          },
          {
            "id": 49037,
            "name": "Mats Nilsson",
            "url": "https://twitter.com/mazzenilsson",
            "source_icon": ""
          },
          {
            "id": 177760801,
            "name": "Aksa Tufan\u0131 \ud83c\udde6\ud83c\uddea | Anl\u0131k \ud83d\udd34",
            "url": "https://t.me/aksaanlik",
            "source_icon": ""
          },
          {
            "id": 14989491,
            "name": "Misvak Caps",
            "url": "https://twitter.com/misvakcaps",
            "source_icon": ""
          },
          {
            "id": 35499315,
            "name": "\u30de\u30a4\u30ca\u30b9\u30a4\u30aa\u30f3@\u30d0\u30c3\u30ad\u30fc\u4e8b\u4ef6\u3092\u8a31\u3055\u306a\u3044",
            "url": "https://twitter.com/3LIbEfmnO7OlqJQ",
            "source_icon": ""
          },
          {
            "id": 86158260,
            "name": "Resistance awakening \u2728\ud83d\udc41\u2728",
            "url": "https://t.me/resistanceawakening",
            "source_icon": ""
          },
          {
            "id": 90123116,
            "name": "HELVOD \ud83c\uddf9\ud83c\uddf7",
            "url": "https://t.me/helvodhaber",
            "source_icon": ""
          },
          {
            "id": 90852845,
            "name": "\ud83c\uddf9\ud83c\uddf7 ASKER\u0130 KULE \ud83c\uddf9\ud83c\uddf7 #B\u0130R\u0130LER\u0130VAR",
            "url": "https://t.me/askerpolistr",
            "source_icon": ""
          },
          {
            "id": 112686233,
            "name": "Sancak G\u00fcndem",
            "url": "https://t.me/sancakgundem",
            "source_icon": ""
          },
          {
            "id": 177760768,
            "name": "Islamist Journal",
            "url": "https://t.me/islamistjournal",
            "source_icon": ""
          },
          {
            "id": 177760812,
            "name": "Haberin Ritmi",
            "url": "https://t.me/haberinritmi",
            "source_icon": ""
          },
          {
            "id": 8085396,
            "name": "Huzun Vefa Filistin Gazze",
            "url": "https://t.me/huzunvefa",
            "source_icon": ""
          },
          {
            "id": 177760853,
            "name": "Haber Dervi\u015f",
            "url": "https://t.me/haberdervis",
            "source_icon": ""
          },
          {
            "id": 177760855,
            "name": "Aksa Tufan\u0131",
            "url": "https://t.me/hamasfilistinaksa",
            "source_icon": ""
          },
          {
            "id": 177760857,
            "name": "Ulak Haber \ud83d\udd1e (Gazze - Canl\u0131)\ud83d\udce2",
            "url": "https://t.me/ulak_official",
            "source_icon": ""
          },
          {
            "id": 177760896,
            "name": "Son Magazin | G\u00fcndem Haberler",
            "url": "https://t.me/sonmagazin",
            "source_icon": ""
          },
          {
            "id": 177760906,
            "name": "KIZILELMA HABER \ud83c\uddf9\ud83c\uddf7",
            "url": "https://t.me/kizilelmahaberajansi",
            "source_icon": ""
          },
          {
            "id": 177760930,
            "name": "Aryan Haber",
            "url": "https://t.me/aryanhaber",
            "source_icon": ""
          },
          {
            "id": 177760934,
            "name": "Haktan Yana Haber",
            "url": "https://t.me/haktan_yanaa",
            "source_icon": ""
          },
          {
            "id": 8090866,
            "name": "HABERS\u0130Z\u0130Z \ud83c\uddf9\ud83c\uddf7\ud83c\udde6\ud83c\uddff",
            "url": "https://t.me/habersiziz",
            "source_icon": ""
          },
          {
            "id": 8085373,
            "name": "Furkan News",
            "url": "https://t.me/furkanhabernet",
            "source_icon": ""
          },
          {
            "id": 56414,
            "name": "Pierre F. Lherisson",
            "url": "https://twitter.com/P_F_Lherisson_",
            "source_icon": ""
          },
          {
            "id": 8085317,
            "name": "Breaking News T\u00fcrkiye \ud83c\uddf9\ud83c\uddf7",
            "url": "https://t.me/breakingnewsturkiye",
            "source_icon": ""
          },
          {
            "id": 65323,
            "name": "Marina Nelson \ud83c\uddfa\ud83c\uddf2 \ud83c\udde6\ud83c\uddf2 \ud83c\uddee\ud83c\uddf7 \ud83c\uddf5\ud83c\uddf8 \ud83c\uddf8\ud83c\uddfe",
            "url": "https://twitter.com/Mari078008197",
            "source_icon": ""
          },
          {
            "id": 67127,
            "name": "Renne Rus",
            "url": "https://twitter.com/RenneRus",
            "source_icon": ""
          },
          {
            "id": 86800,
            "name": "joerobertson",
            "url": "https://twitter.com/joerobertson",
            "source_icon": ""
          },
          {
            "id": 210050,
            "name": "NahBabyNah #Trump",
            "url": "https://twitter.com/NahBabyNah",
            "source_icon": ""
          },
          {
            "id": 251894,
            "name": "Buzz News \ud83c\uddf9\ud83c\uddf7 T\u00fcrkiye",
            "url": "https://t.me/buzznews_tr",
            "source_icon": ""
          },
          {
            "id": 251908,
            "name": "\ud83d\udd34 Haberin Var M\u0131\u2753",
            "url": "https://t.me/haberinvarmi_tr",
            "source_icon": ""
          },
          {
            "id": 251930,
            "name": "Rootwebofficial",
            "url": "https://t.me/rootwebfficial",
            "source_icon": ""
          },
          {
            "id": 251959,
            "name": "Daily Islamist \ud83c\uddf9\ud83c\uddf7 \ud83c\uddf5\ud83c\uddf8",
            "url": "https://t.me/dailyislamist",
            "source_icon": ""
          },
          {
            "id": 2045306,
            "name": "MintPress News",
            "url": "https://t.me/mintpress_news",
            "source_icon": ""
          },
          {
            "id": 2048677,
            "name": "\ud835\udc03\ud835\udc04\ud835\udc15\ud835\udc0b\ud835\udc04\ud835\udc13 \ud835\udc18\ud835\udc00\ud835\udc0d\ud835\udc0b\ud835\udc08\ud835\udc12\ud835\udc08",
            "url": "https://twitter.com/Devlet_024",
            "source_icon": ""
          },
          {
            "id": 2053315,
            "name": "cengo",
            "url": "https://twitter.com/cengo56677908",
            "source_icon": ""
          },
          {
            "id": 8078082,
            "name": "Asayi\u015f Berkemal",
            "url": "https://t.me/asayisberkemal",
            "source_icon": ""
          },
          {
            "id": 8082225,
            "name": "Pulse \ud83c\uddf9\ud83c\uddf7 \u267e\ufe0f #SOSYAL",
            "url": "https://t.me/pulsehaber",
            "source_icon": ""
          },
          {
            "id": 8082252,
            "name": "\ud83c\uddf9\ud83c\uddf7\ud83c\udde6\ud83c\uddff \ud83c\uddf5\ud83c\uddf8El Kassam Tugaylar\u0131 \u00d6zg\u00fcr Filistin \ud83c\uddf9\ud83c\uddf7\ud83c\udde6\ud83c\uddff\ud83c\uddf5\ud83c\uddf8",
            "url": "https://t.me/ortadogu_savas",
            "source_icon": ""
          },
          {
            "id": 8085294,
            "name": "S\u00f6zc\u00fc",
            "url": "https://t.me/sozcucom",
            "source_icon": ""
          },
          {
            "id": 177760980,
            "name": "Pulse \ud83c\uddf9\ud83c\uddf7 \u267e\ufe0f #SOSYAL",
            "url": "https://t.me/pulsegundem",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 0.0,
          "negative_share": 100.0,
          "reactions_analyzed": 15084,
          "comments_analyzed": 1076
        }
      },
      {
        "id": 11,
        "publication_date": "2024-08-21 17:25:36+00:00",
        "title": "McDonald's unexpected promotion of Solana MemeCoin on Instagram raises eyebrows",
        "content": "McDonald's Instagram was hacked, promoting Solana MemeCoin significantly. It led to a cryptocurrency scam, allegedly siphoning $700,000 in Solana through a pump-and-dump scheme.",
        "rating": 4,
        "sources": [
          {
            "id": 87832015,
            "name": "osintcorpbot",
            "url": "https://t.me/osintcorpbot",
            "source_icon": ""
          },
          {
            "id": 141914,
            "name": "John Morgan",
            "url": "https://twitter.com/johnmorganFL",
            "source_icon": ""
          },
          {
            "id": 145317,
            "name": "WendylDail",
            "url": "https://twitter.com/wendylwilson2",
            "source_icon": ""
          },
          {
            "id": 113020888,
            "name": "Korean Alpha EN",
            "url": "https://t.me/korean_alpha_en",
            "source_icon": ""
          },
          {
            "id": 43647,
            "name": "Mario Nawfal",
            "url": "https://twitter.com/MarioNawfal",
            "source_icon": ""
          },
          {
            "id": 113722654,
            "name": "News Dump \ud83e\ude96",
            "url": "https://t.me/newsdumpage",
            "source_icon": ""
          },
          {
            "id": 87250884,
            "name": "\u0422\u0438\u043f\u0438\u0447\u043d\u044b\u0439 \u043c\u0430\u043c\u043e\u043d\u0442 | \u041c\u043e\u0448\u0435\u043d\u043d\u0438\u043a",
            "url": "https://t.me/mamontyatina",
            "source_icon": ""
          },
          {
            "id": 87250890,
            "name": "\u041c\u0435\u043c\u043d\u044b\u0439 \u0421\u043a\u0430\u043c",
            "url": "https://t.me/mems_skam",
            "source_icon": ""
          },
          {
            "id": 101639375,
            "name": "X NEWS",
            "url": "https://t.me/XNEWSWORLDFI0CE",
            "source_icon": ""
          },
          {
            "id": 109677706,
            "name": "\u967d\u5b50\u3055\u3093\u98ef\u3041\u307e\u3060\u304b\u3044\uff1f\ud83d\udc36\ud83d\udc8b\ud83c\udfa1",
            "url": "https://twitter.com/flower68163985",
            "source_icon": ""
          },
          {
            "id": 119804316,
            "name": "PRG CORP \u265b",
            "url": "https://t.me/prgcorp",
            "source_icon": ""
          },
          {
            "id": 86723761,
            "name": "\ud83c\udde9\ud83c\uddea Krypto | News | Deutsch \ud83c\udde9\ud83c\uddea",
            "url": "https://t.me/KryptoKompassDe",
            "source_icon": ""
          },
          {
            "id": 119830722,
            "name": "Crypto Galaxy Market Watch (CGMW)",
            "url": "https://t.me/cgmarketwatch",
            "source_icon": ""
          },
          {
            "id": 123214895,
            "name": "unfolded. DeFi",
            "url": "https://t.me/unfolded_defi",
            "source_icon": ""
          },
          {
            "id": 127935448,
            "name": "Bihut Global Crypto News",
            "url": "https://t.me/bihut_global_crypto_news",
            "source_icon": ""
          },
          {
            "id": 140397034,
            "name": "The Block News Lite",
            "url": "https://t.me/theblocknewslite",
            "source_icon": ""
          },
          {
            "id": 158206321,
            "name": "\ud83d\udea8 MoneyAcademyKE",
            "url": "https://t.me/MoneyAcademyKE",
            "source_icon": ""
          },
          {
            "id": 87250793,
            "name": "\u041d\u0435 \u0431\u0430\u0433, \u0430 \u0444\u0438\u0447\u0430",
            "url": "https://t.me/bugfeature",
            "source_icon": ""
          },
          {
            "id": 85266023,
            "name": "CODE RED \ud83d\udea8\u2618\ufe0f\ud83c\uddfa\ud83c\uddf8",
            "url": "https://t.me/RedPillDealer4833",
            "source_icon": ""
          },
          {
            "id": 86679583,
            "name": "Graham Cluley",
            "url": "https://twitter.com/gcluley",
            "source_icon": ""
          },
          {
            "id": 60892,
            "name": "Benzinga",
            "url": "https://twitter.com/Benzinga",
            "source_icon": ""
          },
          {
            "id": 85257475,
            "name": "Azazel News",
            "url": "https://t.me/AzazelNews",
            "source_icon": ""
          },
          {
            "id": 58497065,
            "name": "Eric Vanderburg",
            "url": "https://twitter.com/evanderburg",
            "source_icon": ""
          },
          {
            "id": 8111005,
            "name": "Press TV",
            "url": "https://t.me/presstv_iran",
            "source_icon": ""
          },
          {
            "id": 2050779,
            "name": "Adam",
            "url": "https://twitter.com/Adam_42m",
            "source_icon": ""
          },
          {
            "id": 2050553,
            "name": "VTOLHussar \u2626\ufe0f",
            "url": "https://twitter.com/vtolhussar",
            "source_icon": ""
          },
          {
            "id": 2045820,
            "name": "AMERICA NEWS",
            "url": "https://t.me/america_news_channel",
            "source_icon": ""
          },
          {
            "id": 251543,
            "name": "Breaking News",
            "url": "https://t.me/breaking_news_update",
            "source_icon": ""
          },
          {
            "id": 250339,
            "name": "\ud83d\udfe1 News by Florian Sondershausen",
            "url": "https://t.me/newsfloriansondershausen",
            "source_icon": ""
          },
          {
            "id": 242416,
            "name": "One America News Network",
            "url": "https://t.me/OANNTV",
            "source_icon": ""
          },
          {
            "id": 181777,
            "name": "Stock Market News",
            "url": "https://twitter.com/Stock_Market_Pr",
            "source_icon": ""
          },
          {
            "id": 136846,
            "name": "unusual_whales",
            "url": "https://twitter.com/unusual_whales",
            "source_icon": ""
          },
          {
            "id": 127931,
            "name": "New York Post",
            "url": "https://twitter.com/nypost",
            "source_icon": ""
          },
          {
            "id": 108459,
            "name": "Dom Lucre | Breaker of Narratives",
            "url": "https://twitter.com/dom_lucre",
            "source_icon": ""
          },
          {
            "id": 165444564,
            "name": "NAIROBI GOSSIP CLUB",
            "url": "https://t.me/nairobigossipclub_pro",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 0.0,
          "negative_share": 100.0,
          "reactions_analyzed": 8347,
          "comments_analyzed": 370
        }
      },
      {
        "id": 12,
        "publication_date": "2024-08-05 18:39:35+00:00",
        "title": "A significant gathering of Muslim immigrants occurred outside a Birmingham McDonald's, involving weapons",
        "content": "A significant riot planned by far-right groups is anticipated to commence at McDonald's near Heartlands Hospital in Birmingham, marking a critical security concern.",
        "rating": 0,
        "sources": [
          {
            "id": 74560,
            "name": "Navdeep Singh Rajwanshi",
            "url": "https://twitter.com/TheGameVeda",
            "source_icon": ""
          },
          {
            "id": 80506,
            "name": "Party of Wales Supporters",
            "url": "https://twitter.com/TLlanelli",
            "source_icon": ""
          },
          {
            "id": 136928,
            "name": "Will Alexander",
            "url": "https://twitter.com/Bill_C54",
            "source_icon": ""
          },
          {
            "id": 143282,
            "name": "\ud83d\udd49 Om Dr of \ud83d\udc99 - Humanist RenuMarley",
            "url": "https://twitter.com/QueenPhoenix1",
            "source_icon": ""
          },
          {
            "id": 168652,
            "name": "Mark2c @antmarkc.bsky.social",
            "url": "https://twitter.com/AnthonyCockbur2",
            "source_icon": ""
          },
          {
            "id": 2052537,
            "name": "Orwell420 \ud83c\uddf5\ud83c\uddf8",
            "url": "https://twitter.com/Orwell420",
            "source_icon": ""
          },
          {
            "id": 21072524,
            "name": "Power Ade",
            "url": "https://twitter.com/AdedePaor",
            "source_icon": ""
          }
        ],
        "opinion_balance": {
          "positive_share": 0.0,
          "negative_share": 100.0,
          "reactions_analyzed": 0,
          "comments_analyzed": 0
        }
      }
    ]
  }
}

