import * as d3 from 'd3';
import cn from 'classnames';
import DOMPurify from 'dompurify';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useMemo, useEffect, useState, useContext, useRef } from 'react';
import Select from 'react-select';
import { format, parseISO, formatISO, set } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {API, ShareAPI} from '../../API';
import { Loader } from '../../components/Loader/Loader';
import { LoaderSmall } from '../../components/LoaderSmall/LoaderSmall';
import { ExpandableText } from '../../components/CollapsibleText/ExpandableText';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as ViewIcon } from './assets/view.svg';
import { ReactComponent as EnvelopeIcon} from '../../assets/envelope.svg';
import { ReactComponent as EnvelopeOpenIcon } from '../../assets/envelopeOpen.svg';
import { ReactComponent as RecoverIcon } from '../../assets/recover.svg';
import { ReactComponent as SaveIcon } from '../../assets/save.svg';
import { ReactComponent as ArrowIcon } from '../NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../assets/engagement.svg';
import { ReactComponent as ManipulationIcon } from '../../assets/manipulation.svg';
import { ReactComponent as FollowersIcon } from '../../assets/followers.svg';
import { ReactComponent as TranslationIcon } from '../../assets/translation.svg';
import { ReactComponent as OriginalTextIcon } from '../../assets/originalText.svg';
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg';
import { ReactComponent as ChevronIcon } from '../../pages/SearchPage/assets/chevron-down.svg';
import { ReactComponent as ActorIcon } from '../../pages/SearchPage/assets/link.svg';
import { ReactComponent as SourceIcon } from '../../pages/SearchPage/assets/person.svg';

import { ampli } from "../../ampli";
import { MessageModal } from '../../components/MessagesTable/MessageModal';
import {contentTypesIconsMapping, contentTypesMapping} from '../../utils/contentTypes';
import {platformIconsMapping, platformNamesMapping} from '../../utils/platforms';
import Tooltip from '../../components/Tooltip/Tooltip';
import { getSentimentIcon, getSentimentName } from "../../utils/sentiment";

import { MessagesContext } from './contexts/MessagesContext';
import { BarChart } from '../../components/BarChart/BarChart';
import { palette } from '../../utils/colors';

import styles from './NarrativePage.module.scss';
import {useCurrentUser} from "../../contexts/CurrentUser";
import messageStyles from '../../components/MessagesTable/ExtendedMessageView.module.scss';
import {decodeSourceName} from "../../utils/decodeURI";
import { CircleChartStories } from '../../components/CircleChartStories/CircleChartStories';
import { SummaryPanel } from '../../components/SummaryPanel/SummaryPanel';
import { SentimentBar } from '../../components/SentimentBar/SentimentBar';

import { CircleChartIcons } from '../../components/CircleChartIcons/CircleChartIcons';
import { CircleChart } from '../../components/CircleChart/CircleChart';
import { EvolutionChart } from '../../components/EvolutionChart/EvolutionChart';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export const Distribution = ({ 
  narrative,
  isShare,
}) => {
  const [currentUser] = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const api = isShare ? ShareAPI : API;

  const {
    messages,
    originalSources,
    deduplication,
    narrativeShares,
    narrativeStats,
    mostMentionedEntities,
    getMessages,
    fetchNarrativeStats,
    narrativeStatsStories,
    fetchNarrativeShares,
    fetchMostMentionedEntities,
    fetchOriginalSources,
    dispatch,
    translateMessage,
} = useContext(MessagesContext);

  const [statsAggregationOptions] = useState(() => [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ]);
  const [statsBreakdownOptions] = useState(() => [
    { value: null, label: t('All') },
    { value: 'PLATFORM', label: t('Platform') },
    { value: 'COUNTRY', label: t('Country') },
    { value: 'ACTOR_GROUP', label: t('Actor group') },
  ]);

  const [viewOptions] = useState(() => [
    {value: 'STANDARD', name: t('Standard')},
    {value: 'VALUES', name: t('Values')},
    {value: 'POINTS', name: t('Points')}
  ])

  const mapViewOptions = {
    'STANDARD': {value: 'STANDARD', name: t('Standard')},
    'VALUES': {value: 'VALUES', name: t('Values')},
    'POINTS': {value: 'POINTS', name: t('Points')}
  }


  const [totalEvolutionChart, setTotalEvolutionChart] = useState('publications');
  const [mostMentionedEntitiesChart, setMostMentionedEntitiesChart] = useState('Person');
  const [messageModal, setMessageModal] = useState({message: null, isOpen: false, narrartiveMessage: null});
  const [messagesList, setMessagesList] = useState(null);
  const [isTrendChartMenuOpen, setIsTrendChartMenuOpen] = useState(false);
  const trendChartRef = useRef(null);
  const trendChartCanvasRef = useRef(null);

  const entitiesMapping = {
    'Person': 'persons',
    'Organization': 'organizations',
    'Location': 'locations'
  }

  const dynamicValue = entitiesMapping[mostMentionedEntitiesChart];
  const translatedDynamicValue = t(dynamicValue);

  const [statsAggregation, setStatsAggregation] = useState(
    () => statsAggregationOptions[0],
  );

  const [statsBreakdown, setStatsBreakdown] = useState(
    () => statsBreakdownOptions[0],
  );

  const [chartViewOptions, setChartViewOptions] = useState(() => viewOptions[0]);

  const [sentimentData, setSentimentData] = useState(null);

  useEffect(() => {
    dispatch({type: 'TOGGLE_TAB'});
    getMessages(narrative, null, 'date_publicated', null, deduplication, 1)
    fetchOriginalSources(narrative, narrative.original_source_ids)
    fetchNarrativeStats(narrative)
  }, [])

useEffect(() => {
  if(sentimentData === null && narrativeShares) {
    setSentimentData(narrativeShares?.sentiment )
  }
}, [narrativeShares])

  useEffect(() => {
    fetchNarrativeShares(narrative, statsAggregation.value, statsBreakdown.value)
  }, [statsAggregation, statsBreakdown])

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const isNerWidgetVisible = featureFlags?.includes('ner_widget');
  const showMessageManipulationIndex = featureFlags?.includes('show_manipulation_index');

  const [distributionChart, setDistributionChart] = useState(null);

  useEffect(() => {
    if (distributionChart !== null) {
      return
    }
    if (!narrativeStatsStories) {
      return
    }
    if(narrativeStatsStories && narrativeStatsStories?.objects?.length > 0) {
      setDistributionChart('stories')
    } else {
      setDistributionChart('countries')
    }
  }, [narrativeStats, narrativeStatsStories])


  useEffect(() => {
    if (isNerWidgetVisible) {
      fetchMostMentionedEntities(narrative, mostMentionedEntitiesChart)
    }
  }, [mostMentionedEntitiesChart]);

  useEffect(() => {
    ampli.track({
      event_type: 'Open Overview tab',
      event_properties: {
        user_id: currentUser?.id,
        workspace_id: currentUser?.workspace_id,
        narrative_id: narrative.id,
      }
    });
  }, []);

  const aggregationSelect = (
    <Select
      className="chart-select"
      onChange={(data) => {
        ampli.track({
          event_type: 'Change granularity in evolution chart',
          event_properties: {
            user_id: currentUser?.id,
            workspace_id: currentUser?.workspace_id,
            narrative_id: narrative.id,
            granularity: data.label,
          }
        });
        setStatsAggregation(data);
      }}
      value={statsAggregation}
      options={statsAggregationOptions}
    />
  );
  const breakdownSelect = (
    <Select
      className="chart-select"
      onChange={(data) => {
        ampli.track({
          event_type: 'Change breakdown in evolution chart',
          event_properties: {
            user_id: currentUser?.id,
            workspace_id: currentUser?.workspace_id,
            narrative_id: narrative.id,
            breakdown: data.label,
          }
        });
        setStatsBreakdown(data);
      }}
      value={statsBreakdown}
      options={statsBreakdownOptions}
    />
  );

  const getThreatDescription = (threat) => {
    if (!threat?.is_public) {
      return threat?.description
    }

    let public_description;
    if(
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      public_description = threat?.public_description_uk;
    } else {
      public_description = threat?.public_description_en;
    }

    return public_description ?? threat?.description
  }

  let caseDescription = narrative.is_threat ? getThreatDescription(narrative) : narrative.description;
  const newText = caseDescription.replace(/\n+(\s*\n+)?/g, '\n').split('\n');
  const sanitizedText = newText.map(item => DOMPurify.sanitize(item, { ALLOWED_TAGS: ['mark', 'span'],  ALLOWED_ATTR: ['class']}));

  const clean = useMemo(
    // () => DOMPurify.sanitize(newText, { ALLOWED_TAGS: ['mark'] }),
    () => {
      return sanitizedText;
    },
    [sanitizedText],
  );

  useEffect(() => {
    if(originalSources) {
      setMessagesList(originalSources?.objects)
    } else if (messages?.objects?.length > 0) {
      setMessagesList(messages?.objects?.slice(0,1))
    }
  },[originalSources, messages])


  if (!narrativeShares) {
    return <Loader />
  }

  const translateOriginalSource = (messageId, text, nativeLang, sourceLang='') => {
    let body;
    if(sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang
      }
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      }
    }
    api.fetch('POST', '/API/v1/translations/translate', null, body).then((data) => {
       setMessagesList(messagesList.map((message) => {
        if (message.id === messageId) {
          return {
            ...message,
            isTranslation: true,
            translated: data.destination_text,
          };
        } else {
          return message;
        }
      }))
      })
    .catch(e => {
      setMessagesList(messagesList.map((message) => {
        if (message.id === messageId) {
          return {
            ...message,
            isTranslation: true,
            translated: t('We were not able to translate this text.'),
            error: true,
          };
        } else {
          return message;
        }
    }))
  })}

  const toggleTranslate = (messageId, isTranslation) => {
    setMessagesList(messagesList.map((message) => {
      if (message.id === messageId) {
        return {
          ...message,
          isTranslation: isTranslation,
        };
      } else {
        return message;
      }
  }))
  }

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messagesList.find(message => message.id === messageId);
  
    if (target.translated) {
      toggleTranslate(messageId, isTranslation)
    } else {
      const nativeLanguage = narrative.parameters?.translate_keywords_query ? 
        narrative.parameters?.keywords_query_origin_language : 
        window.clientInformation.language.split('-', 1)[0];
      toggleTranslate(messageId, true);
      translateOriginalSource(messageId, text, nativeLanguage);

      ampli.track({
        event_type: 'Translate original source',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        }
      });
    }
  }

  const toggleTranslateChartMessage = (messageId, isTranslation, isStories) => {
    if(isStories === true) {
      dispatch({type: 'TOGGLE_TRANSLATE_STORIES_CHART_MESSAGES', payload: {messageId: messageId, isTranslation: isTranslation}})
    } else {
      dispatch({type: 'TOGGLE_TRANSLATE_CHART_MESSAGES', payload: {messageId: messageId, isTranslation: isTranslation}})
    }
  }

  const handleChartMessageTranslation = (messageId, text, isTranslation, isStories) => {
    let target;
    if(isStories === true) {
      target = narrativeStatsStories.objects.map(obj => obj.stories).flat().find(message => message.id === messageId);
    } else {
      target = narrativeStats.objects[0]?.messages?.find(message => message.id === messageId);
    }
    
    if (target.translated) {
      toggleTranslateChartMessage(messageId, isTranslation, isStories)
    } else {
      const nativeLanguage = narrative.parameters?.translate_keywords_query ? 
        narrative.parameters?.keywords_query_origin_language : 
        window.clientInformation.language.split('-', 1)[0];
      toggleTranslateChartMessage(messageId, true, isStories);
      translateMessage(messageId, text, nativeLanguage).then(data => {
        if(isStories === true) {
          dispatch({type: 'TRANSLATE_STORIES_CHART_MESSAGE', payload: {data: data, messageId: messageId}})
        } else {
          dispatch({type: 'TRANSLATE_CHART_MESSAGE', payload: {data: data, messageId: messageId}})
        }
      })
      .catch(e => {
        if(isStories === true) {
          dispatch(
            {
              type: 'TRANSLATION_ERROR_STORIES_CHART_MESSAGE',
              payload: {data: t('We were not able to translate this text.'), messageId: messageId}}
          )
        } else {
          dispatch(
            {
              type: 'TRANSLATION_ERROR_CHART_MESSAGE',
              payload: {data: t('We were not able to translate this text.'), messageId: messageId}}
          )
        }
        
      })

      ampli.track({
        event_type: 'Translate circle chart message',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        }
      });
    }
  }

  let narrativeColor = null;
  if (narrativeShares) {
    narrativeColor = Object.fromEntries(
      narrativeShares?.objects?.map((narrative, i) => [narrative.id, palette[i % palette.length]])
    );
  }
  

  return(
    <div className="list-content">
      {caseDescription.length > 0
        ? <div className='report-section'>
          <h3>{t('Description')}</h3>
          <div className={styles.descriptionCard}> 
            {clean.map((item, i) => <p key={`paragraph_${i}`} dangerouslySetInnerHTML={{ __html: item }}></p>)}
          </div>
         
          </div> 
        : ''}
        <div className="report-section">
          <h3>{t('Summary')}</h3>
          <SummaryPanel narrativeIds={[narrative.id]} isShare={isShare}/>
        </div>

      {narrative.type !== "IMPACT_ASSESSMENT" && messagesList ? <div className='report-section'>
        <h3>{t('Original source')}</h3>
        <table className={`${messageStyles.extendedMessages} ${styles.extendedMessages}`}>
        <tbody>
          {messagesList.map((message, index) => {
            let followers_cnt = message?.source?.audience;
            let textClass = styles.extendedMessage;

            if(message.error && message.isTranslation) {
              textClass = `${styles.extendedMessage} ${messageStyles.error}`
            } else if (message.error && !message.isTranslation) {
              textClass = styles.extendedMessage;
            }

          return(
            <tr key={`message.message_url_${index}`}>
              <td>
                <div className={messageStyles.messageInfoWrapper}> 
                  <div className={messageStyles.messageInfo}>
                    {message.content_type ? <div className={messageStyles.contentType}>
                      <Tooltip content={t(contentTypesMapping[message.content_type])} position='bottom'>
                        {contentTypesIconsMapping[message.content_type]}
                      </Tooltip>
                    </div> : ''}
                    <div className={messageStyles.date}>
                      <span className={messageStyles.anchor}>
                        <Tooltip content={t('Publication date')} position='bottom'><CalendarIcon /></Tooltip>
                      </span>
                      {format(parseISO(message.date_publicated + 'Z'), 'dd LLL yyyy HH:mm',)}
                    </div>
                    <div className={messageStyles.platform}>
                      <span className={messageStyles.anchor}>
                        <Tooltip content={platformNamesMapping[message.source.source_type]} position='bottom'>
                          {platformIconsMapping[message.source.source_type]}
                        </Tooltip>
                      </span>
                      {followers_cnt ? <span className={messageStyles.anchor}>
                          <Tooltip content={t('Followers')} position='bottom'>
                            <FollowersIcon />
                          </Tooltip>
                          <span>{formatNumberSignificant(followers_cnt)}</span>
                        </span> : ''}
                    </div>
                    <div className={messageStyles.sourceActorWrapper}>
                      <span className={messageStyles.anchor}>
                        <Tooltip content={message.source === null ? t('N/A') : decodeSourceName(message.source?.name)} position='bottom'>
                          <SourceIcon />
                          <span className={messageStyles.cutText}>
                          {isShare ? decodeSourceName(message.source?.name) : message.source === null ? t('N/A') : <Link to={`/sources/${message.source?.id}`}>{decodeSourceName(message.source?.name)}</Link>}
                          </span>
                        </Tooltip>
                        { message.actor && message.actor.id !== message.source?.id ? <Tooltip content={decodeSourceName(message.actor?.name)} position='bottom'>
                          <ActorIcon />
                          <span className={messageStyles.cutText}>
                          {<Link to={`/sources/${message.actor?.id}`}>{decodeSourceName(message.actor?.name)}</Link>}
                          </span>
                        </Tooltip> : ''}
                        <a href={message.url} className={messageStyles.externalLink} target="_blank" rel="noreferrer">
                          <LinkIcon/>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className={'open-message-popup ' + messageStyles.modalIcon}>
                    <ExpandIcon
                      onClick={() => setMessageModal({message: message, isOpen: true, narrartiveMessage: null})}
                    />
                  </div>
                </div>
                
                <div className={`${messageStyles.messageContainer} ${styles.messageContainer}`}>
                  <div className={messageStyles.messageMetrics} style={{ 'justifyContent': 'left' }}>
                    {message.sentiment_score !== null ? <Tooltip content={t(getSentimentName(message.sentiment_score))} position='bottom'>{getSentimentIcon(message.sentiment_score)}</Tooltip> : ''}
                    <span className={messageStyles.anchor}>
                      <Tooltip content={t('Views')} position='bottom'>
                        <ViewsIcon/>
                      </Tooltip>
                      {formatNumberSignificant(message.impressions)}
                    </span>
                    <span className={messageStyles.anchor}>
                      <Tooltip content={t('Reactions')} position='bottom'>
                        <EngagementIcon />
                      </Tooltip>
                      {/* engagement */}
                      {formatNumberSignificant(message.engagement)}
                    </span>
                   {showMessageManipulationIndex ? <span className={messageStyles.anchor}>
                      <Tooltip content={t('Manipulation')} position='bottom'>
                        <ManipulationIcon />
                      </Tooltip>
                      {message.manipulation_index ? message.manipulation_index.toFixed(2) : '0'}
                      </span> : ''}
                  </div>
                  <div>
                    {message.isTranslation === true && !message.translated ?  
                    <div><LoaderSmall /></div> : 
                    <ExpandableText 
                      length={100}
                      highlights={['KEYWORDS']}
                      text={message.isTranslation ? message.translated : message.highlighted_text || message.text}
                      textClassName={textClass}
                      onExpand={(expanded) => {
                        if (expanded) {
                          ampli.track({
                            event_type: 'Expand original source',
                            event_properties: {
                              user_id: currentUser?.id,
                              workspace_id: currentUser?.workspace_id,
                              narrative_id: narrative.id,
                            }
                          });
                        }
                      }}
                    />}
                  </div>
                </div>
              </td>
              <td className='message-controlls'>
                <div className={messageStyles.translationWrapper}>
                    <span 
                      className={message?.isTranslation ? '' : messageStyles.active}
                      onClick={() =>  handleTranslation(message.id, message.text, false)}
                    >
                      <OriginalTextIcon />
                    </span>
                    <span 
                      className={message?.isTranslation ? messageStyles.active : '' }
                      onClick={() => handleTranslation(message.id, message.text, true)}
                    >
                      <TranslationIcon />
                    </span> 

                </div>
              </td>
            </tr>
          )} 
        )}
      </tbody>
    </table> 
      
    </div> : ''}

    {narrative.type === "IMPACT_ASSESSMENT" 
      ? (
        <div className='report-section'>
          <h3 className='message-distribution-header'>{t('Key opinions')}</h3>
          <div className="card card-circlechartwrapper">
            <div className="card-header" />
            <div className="card-body">
              {(narrativeStatsStories) ? (
                <CircleChartStories
                  data={narrativeStatsStories}
                  onCircleClick={(messageID) => {
                    ampli.track({
                      event_type: 'Open bubble in opinions bubble-chart',
                      event_properties: {
                        user_id: currentUser?.id,
                        workspace_id: currentUser?.workspace_id,
                        narrative_id: narrative.id,
                      }
                    });
                  }}
                  narrativeColor={narrativeColor}
                  narrative={narrative}
                  isShare={isShare}
                  currentUser={currentUser}
                  onTranslationClick={(messageId, text, bool) => handleChartMessageTranslation(messageId, text, bool, true)}
                />
              ) : <Loader />}
            </div>
          </div>
        </div>
          ) : (
          distributionChart !== null ? <div className='report-section'>
             <h3 className="trend-chart-controlls">
              {t('Message distribution')}
              {narrativeStatsStories?.objects?.length > 0 ? <button
                type="button"
                className={distributionChart === 'stories' ? 'active' : ''}
                onClick={() => {
                  ampli.track({
                    event_type: 'Change metric in stories bubble chart',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      narrative_id: narrative.id,
                      metric: 'stories',
                    }
                  });
                  setDistributionChart('stories');
                }}
              >
                <span>{t('By stories')}</span>
              </button> : ''}
              <button
                type="button"
                className={distributionChart === 'countries' ? 'active' : ''}
                onClick={() => {
                  ampli.track({
                    event_type: 'Change metric in bubble chart',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      narrative_id: narrative.id,
                      metric: 'messages countries',
                    }
                  });
                  setDistributionChart('countries');
                }}
              >
                <span>{t('By countries')}</span>
              </button>
              <button
                type="button"
                className={distributionChart === 'platforms' ? 'active' : ''}
                onClick={() => {
                  ampli.track({
                    event_type: 'Change metric in bubble chart',
                    event_properties: {
                      user_id: currentUser?.id,
                      workspace_id: currentUser?.workspace_id,
                      narrative_id: narrative.id,
                      metric: 'messages platforms',
                    }
                  });
                  setDistributionChart('platforms');
                }}
              >
                <span>{t('By platforms')}</span>
              </button>
             </h3>

             {distributionChart === 'messages' ? <div className="card card-circlechartwrapper">
              <div className="card-header" />
              <div className="card-body">
                {(narrativeStats) ? (
                  <CircleChart
                    isShare={isShare}
                    currentUser={currentUser}
                    data={narrativeStats}
                    mode={distributionChart}
                    onCircleClick={(messageID) => {
                      ampli.track({
                        event_type: 'Open bubble in messages bubble-chart',
                        event_properties: {
                          user_id: currentUser?.id,
                          workspace_id: currentUser?.workspace_id,
                          narrative_id: narrative.id,
                        }
                      });
                    }}
                    narrativeColor={{[narrative.id]: palette[0 % palette.length]}}
                    onTranslationClick={(messageId, text, bool) => handleChartMessageTranslation(messageId, text, bool, false)}
                  />
                ) : <Loader />}
              </div>
            </div> : ''}

            {distributionChart === 'countries' ||  distributionChart === 'platforms' ? <div className="card card-circlechartwrapper">
              <div className="card-header" />
              <div className="card-body">
                {(narrativeStats) ? (
                  <CircleChartIcons
                    isShare={isShare}
                    currentUser={currentUser}
                    narrative={narrative}
                    data={narrativeStats}
                    mode={distributionChart}
                    onCircleClick={(messageID) => {
                      ampli.track({
                        event_type: 'Open bubble in messages bubble-chart',
                        event_properties: {
                          user_id: currentUser?.id,
                          workspace_id: currentUser?.workspace_id,
                          narrative_id: narrative.id,
                        }
                      });
                    }}
                    narrativeColor={{[narrative.id]: palette[0 % palette.length]}}
                    onTranslationClick={(messageId, text, bool) => handleChartMessageTranslation(messageId, text, bool, false)}
                  />
                ) : <Loader />}
              </div>
            </div> : ''}
            {distributionChart === 'stories' ? <div className="card card-circlechartwrapper">
              <div className="card-header" />
              <div className="card-body">
                {(narrativeStatsStories?.objects?.length > 0) ? (
                  <CircleChartStories
                    isShare={isShare}
                    currentUser={currentUser}
                    data={narrativeStatsStories}
                    narrativeColor={narrativeColor}
                    narrative={narrative}
                    onTranslationClick={(messageId, text, bool) => handleChartMessageTranslation(messageId, text, bool, true)}
                    onCircleClick={(messageID) => {
                      ampli.track({
                        event_type: 'Open bubble in stories bubble-chart',
                        event_properties: {
                          user_id: currentUser?.id,
                          workspace_id: currentUser?.workspace_id,
                          narrative_id: narrative.id,
                        }
                      });
                    }}
                  />
                ) : <Loader />}
              </div>
            </div> : ''}
          </div> : <Loader/>)
    }


  {narrative.type !== "IMPACT_ASSESSMENT" 
  ? <EvolutionChart
      narrative={narrative}
      data={narrativeShares}
      showMessageManipulationIndex={showMessageManipulationIndex}
      chartTab={totalEvolutionChart}
      agregation={statsAggregation}
      breakdown={statsBreakdown}
      view={chartViewOptions}
      onChange={(tab, agregation, breakdown, view) => {
        setTotalEvolutionChart(tab);
        setStatsAggregation(agregation);
        setStatsBreakdown(breakdown);
        setChartViewOptions(view);
      }}
    /> : ''}

    {narrative.type !== "IMPACT_ASSESSMENT" && isNerWidgetVisible ? (
      <div className="report-section">
          <h3 className="trend-chart-controlls">
            {t('Most mentioned entities')}
            <button
              type="button"
              className={mostMentionedEntitiesChart === 'Person' ? 'active' : ''}
              onClick={() => setMostMentionedEntitiesChart('Person')}
            >
              <span>{t('Persons')}</span>
            </button>
            <button
              type="button"
              className={mostMentionedEntitiesChart === 'Organization' ? 'active' : ''}
              onClick={() => setMostMentionedEntitiesChart('Organization')}
            >
              <span>{t('Organizations')}</span>
            </button>
            <button
              type="button"
              className={mostMentionedEntitiesChart === 'Location' ? 'active' : ''}
              onClick={() => setMostMentionedEntitiesChart('Location')}
            >
              <span>{t('Locations')}</span>
            </button>
          </h3>

          <div className="card">
            {mostMentionedEntities.objects?.length > 0 ? <div className={"card-header " + styles.cardHeader}>
            </div> : ''}
            <div className="card-body">
              {Object.keys(mostMentionedEntities).length === 0 ? <Loader/> : mostMentionedEntities.objects?.length > 0 ? <BarChart
                rawdata={mostMentionedEntities.objects.filter((v) => v.type === mostMentionedEntitiesChart).slice(0, 12)}
              /> : <div className={styles.noData}>{t('entitiesNoData', {val: translatedDynamicValue})}</div>}
            </div>
          </div>
      </div> ) : ''}

      <SentimentBar data={sentimentData} entity={narrative} entityType={narrative?.is_threat ? 'threat' : 'case'} />

      {messageModal.isOpen ? <MessageModal 
      message={messageModal.message}
      isOpen={messageModal.isOpen}
      onChange={(bool, selected) => {
        setMessageModal({message: null, isOpen: bool, narrartiveMessage: null})
        // setModalHighlights(selected)
      }}
      narrativeMessage={messageModal.narrartiveMessage}
      // selected={modalHighlights.length === 0 ? highlights : modalHighlights}
      isShare={isShare}
    /> : ''}
    </div>
  )
}
