import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { Loader } from '../../../../components/Loader/Loader';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import { TopActorsWidget } from './TopActors';
import { TopTagsWidget } from './TopTags';
import styles from '../../NarrativePage.module.scss';

export const Tops = ({
  narrative,
  isShare,
  settings,
  notes,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const { t } = useTranslation();
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [title, setTitle] = useState(widgetSettings.title || t('Tops'));
  const componentRef = useRef(null);

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : t('Tops');
    setTitle(newTitle);
  }, [settings]);

  if (!narrative) {
    return <Loader />;
  }

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t('Tops')}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsEditingMode(false);
              onSettingsChange(widgetSettings, [], componentRef.current);
            }}
            onReset={() => {
              setTitle(settings.title || t('Tops'));
              setIsEditingMode(false);
              setWidgetSettings(settings);
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>

            <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="top_actors"
                    checked={widgetSettings.show_top_actors}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_top_actors: !widgetSettings.show_top_actors,
                      });
                    }}
                  />
                  <label htmlFor="top_actors">{t('Show Top actors')}</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="top_tags"
                    checked={widgetSettings.show_top_tags}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_top_tags: !widgetSettings.show_top_tags,
                      });
                    }}
                  />
                  <label htmlFor="top_tags">{t('Show Top tags')}</label>
                </li>
              </ul>
            </div>
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="widget-container">
        <div className="report-section">
          <div className="report-section__header">
            <h3>
              {widgetSettings.title
                ? widgetSettings.title
                : t('Tops')}
            </h3>
            <div className="report-section__controls">
              <button
                className="report-control-button"
                onClick={() => setIsEditingMode(true)}
              >
                <EditIcon />
              </button>
              <button
                className="report-control-button danger"
                onClick={() => onWidgetStatusChange(false)}
              >
                <DeleteIcon />
              </button>
            </div>
          </div>
          <div className={styles.breakdownsContainer}>
            <TopActorsWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_top_actors: !widgetSettings.show_top_actors,
                });
                onSettingsChange({ ...widgetSettings, show_top_actors: value });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />

            <TopTagsWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_top_tags: !widgetSettings.show_top_tags,
                });
                onSettingsChange({ ...widgetSettings, show_top_tags: value });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};
