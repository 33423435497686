import { useMemo, useEffect, useState, useRef } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import styles from '../../NarrativePage.module.scss';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import Markdown from 'react-markdown';

export const Description = ({
  narrative,
  isShare,
  notes,
  settings,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const { t } = useTranslation();
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [title, setTitle] = useState(widgetSettings.title || t('Description'));
  const componentRef = useRef(null);

  const getThreatDescription = (threat) => {
    if (!threat?.is_public) {
      return threat?.description;
    }

    let public_description;
    if (
      localStorage.getItem('i18nextLng') === 'ua-UK' ||
      localStorage.getItem('i18nextLng') === 'ru-RU' ||
      localStorage.getItem('i18nextLng') === 'ru' ||
      localStorage.getItem('i18nextLng') === 'uk'
    ) {
      public_description = threat?.public_description_uk;
    } else {
      public_description = threat?.public_description_en;
    }

    return public_description ?? threat?.description;
  };

  let caseDescription = narrative.is_threat
    ? getThreatDescription(narrative)
    : narrative.description;

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : t('Description');
    setTitle(newTitle);
    let newDescription = settings?.text?.length > 0 ? settings.text : caseDescription;
    setDescription(newDescription);
  }, [settings]);
  

  const [description, setDescription] = useState(widgetSettings.text || caseDescription);
  
  const newText = description.replace(/\n+(\s*\n+)?/g, '\n').split('\n');
  const sanitizedText = newText.map((item) =>
    DOMPurify.sanitize(item, {
      ALLOWED_TAGS: ['mark', 'span'],
      ALLOWED_ATTR: ['class'],
    }),
  );

  const clean = useMemo(() => {
    return sanitizedText;
  }, [sanitizedText]);

  if (!narrative) {
    return <Loader />;
  }

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t('Description')}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsEditingMode(false);
              onSettingsChange(widgetSettings, [], componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              setTitle(settings.title || t('Description'))
              setDescription(settings.text || caseDescription);
              setIsEditingMode(false);
              setWidgetSettings(settings);
              
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="description">{t('Description')}</label>
            
            <TextEditor
              id={"description-editor"}
              value={description}
              onChange={(text) => {
                setDescription(text)
                setWidgetSettings({...widgetSettings, text: text})
              }}
              isOpen={true}
            />
            </div>
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="widget-container">
        {caseDescription.length > 0 || description.length > 0 ? (
          <div className="report-section">
            <div className="report-section__header">
              <h3>
                {widgetSettings.title ? widgetSettings.title : t('Description')}
              </h3>
              <div className="report-section__controls">
                <button
                  className="report-control-button"
                  onClick={() => setIsEditingMode(true)}
                >
                  <EditIcon />
                </button>
                <button
                  className="report-control-button danger"
                  onClick={() => onWidgetStatusChange(false)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
            <div className={styles.descriptionCard}>
              <Markdown>{description}</Markdown>
            </div>
          </div>
        ) : (
          <div className="report-section">
            <div className="report-section__header">
              <h3>{t('Description')}</h3>
              <div className="report-section__controls">
                <button
                  className="report-control-button"
                  onClick={() => setIsEditingMode(true)}
                >
                  <EditIcon />
                </button>
                <button
                  className="report-control-button danger"
                  onClick={() => onWidgetStatusChange(false)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
            <div className={styles.noData}>
              {t('There is no data to display')}
            </div>
          </div>
        )}
      </div>
    );
  }
};
