import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import {ReactComponent as AnchorIcon} from '../../assets/anchor.svg';
import {ReactComponent as RatingIcon} from '../../assets/rating.svg';
import {ReactComponent as PositiveIcon} from '../../assets/positiveSentiment.svg';
import {ReactComponent as NegativeIcon} from '../../assets/negativeSentiment.svg';
import {ReactComponent as NeutralIcon} from '../../assets/neutralSentiment.svg';
import { da, enGB, uk} from "date-fns/locale";
import { AvatarGroup } from '../AvatarGroup/AvatarGroup';
import 'react-tooltip/dist/react-tooltip.css'

import { Tooltip } from 'react-tooltip';

import styles from './OpinionCard.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);

export const OpinionCard = ({ opinion }) => {
  const { t } = useTranslation();

  const sentimentMap = {
    'positive': {text: t('Positive'), className: styles.positive, icon: <PositiveIcon/>},
    'negative': {text: t('Negative'), className: styles.negative, icon: <NegativeIcon/>},
    'neutral': {text: t('Neutral'), className: styles.neutral, icon: <NeutralIcon/>},
  }

  return (
    <div className={styles.opinionCardContainer}>
      <div className={styles.opinionCardTitle}>{opinion?.title}</div>
      <div className={styles.opinionCardInfo}>
        <div className={styles.opinionCardRatingContainer}>
          <div className={styles.opinionCardRating}>
            <div className={styles.opinionCardRatingIcon}>
              <RatingIcon />
            </div>
            <div className={styles.opinionCardRatingValue}>{opinion?.rating}</div>
          </div>
          <div className={styles.opinionCardHint}>
            <div className={styles.opinionCardHintText}>{t('Rating')}</div>
            <div
              className={styles.opinionCardHintIcon}
              data-tooltip-id="rating-tooltip"
              data-tooltip-content={
                'Based on the number of reactions and comments'
              }
              data-tooltip-place="bottom-start"
            >
              <AnchorIcon />
              <Tooltip id="rating-tooltip" style={{ width: '200px' }} />
            </div>
          </div>
        </div>
        <div className={styles.opinionCardSourcesContainer}>
          <AvatarGroup sources={opinion?.sources} />
          <div className={styles.opinionCardHint}>
            <div className={styles.opinionCardHintText}>{t('Actors')}</div>
            <div
              className={styles.opinionCardHintIcon}
              data-tooltip-id="actors-tooltip"
              data-tooltip-content={'Top actors based on the number of reactions and comments'}
              data-tooltip-place='bottom'
            >
              <AnchorIcon />
              <Tooltip
                id="actors-tooltip"
                style={{ width: '200px'}}
              />
            </div>
          </div>
        </div>
        <div className={styles.opinionCardSentimentContainer}>
            <div className={`${styles.opinionCardSentiment} ${sentimentMap[opinion.sentiment.sentiment].className}`}>
              {sentimentMap[opinion.sentiment.sentiment].icon}
              {/* <div className={styles.opinionCardSentimentText}>{sentimentMap[opinion.sentiment.sentiment].text}</div> */}
            </div>
            <div className={styles.opinionCardHint}>
              <div className={styles.opinionCardHintText}>{t('Sentiment')}</div>
              <div
                className={styles.opinionCardHintIcon}
                data-tooltip-id="opinion-balance-tooltip"
                data-tooltip-content={`Based on ${formatNumberSignificant(opinion?.sentiment.reactions_analyzed)} reactions and ${formatNumberSignificant(opinion?.sentiment.comments_analyzed)} comments`}
                data-tooltip-place='bottom-end'
              >
                <AnchorIcon />
                <Tooltip
                  id="opinion-balance-tooltip"
                  style={{ width: '250px'}}
                />
                
              </div>
            </div>

          </div>
      </div>
    </div>
  );
}