import { useState, useEffect, useRef } from 'react';
import { TopTagsByPosts } from '../../Breakdowns/TopTagsByPosts';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import styles from '../../NarrativePage.module.scss';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import Markdown from 'react-markdown';

export const TopTagsWidget = ({
  narrative,
  isShare,
  settings,
  onWidgetStatusChange,
  onSettingsChange,
}) => {
  const { t } = useTranslation();
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [errors, setErrors] = useState({});
  const [note, setNote] = useState(settings.top_tags_notes?.length > 0 ? settings.top_tags_notes[0] : {text: "", position: "bottom"});
  const [isNote, setIsNote] = useState(settings.top_tags_notes?.length > 0);
  const [title, setTitle] = useState(widgetSettings.top_tags_title || t('Top tags by number of posts'));
  const componentRef = useRef(null);
  
  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.top_tags_title?.length > 0 ? settings.top_tags_title : t('Top tags by number of posts');
    setTitle(newTitle);
  }, [settings]);
  
  useEffect(() => {
    if(note.text.length > 0) {  
    setWidgetSettings({
      ...settings,
      top_tags_notes: [note],
    })} else {
      setWidgetSettings({
        ...settings,
        top_tags_notes: [],
      })
    }
  }, [note])

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">
            {t('Top tags by number of posts')}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsEditingMode(false);
              onSettingsChange(widgetSettings, [], componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
              setTitle(
                settings.top_tags_title || t('Top tags by number of posts'),
              );
              setIsEditingMode(false);
              setWidgetSettings(settings);
              setNote(
                settings.top_tags_notes?.length > 0
                  ? settings.top_tags_notes[0]
                  : { text: '', position: 'bottom' },
              );
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    top_tags_title: e.target.value,
                  });
                }}
              />
            </div>
            <div
              className={errors.size ? 'form-element error' : 'form-element'}
            >
              <label htmlFor="size">{t('Number of tags')}</label>
              <input
                type="number"
                id="size"
                min={5}
                max={100}
                value={widgetSettings.top_tags_size}
                onChange={(e) => {
                  if (e.target.value > 100) {
                    setErrors({ ...errors, size: 'Max value is 100' });
                  } else if (e.target.value < 5) {
                    setErrors({ ...errors, size: 'Min value is 5' });
                  } else {
                    setErrors({});
                  }
                  setWidgetSettings({
                    ...widgetSettings,
                    top_tags_size: parseInt(e.target.value),
                  });
                }}
              />
              {errors?.size ? (
                <div className="error-message" style={{ paddingTop: '0px' }}>
                  {errors.size}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="platforms"
                    checked={isNote}
                    onChange={() => {
                      setIsNote(!isNote);
                    }}
                  />
                  <label htmlFor="create-note">{t('Create a note')}</label>
                </li>
              </ul>
            </div>
            {isNote ? (
              <div className="form-element">
                <div className="form-element">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="platforms"
                        checked={note?.position === 'top'}
                        onChange={() => {
                          if (note?.position === 'top') {
                            setNote({ ...note, position: 'bottom' });
                          } else {
                            setNote({ ...note, position: 'top' });
                          }
                        }}
                      />
                      <label htmlFor="note-position">
                        {t('Place a note on the top of the widget')}
                      </label>
                    </li>
                  </ul>
                </div>

                <label htmlFor="note">{t('Note')}</label>
                <TextEditor
                  id={'note-editor'}
                  value={note.text}
                  onChange={(text) => {
                    setNote({ ...note, text: text });
                  }}
                  isOpen={true}
                />
              </div>
            ) : (
              ''
            )}
            <div className="widget-settings-controls">
              <button
                type="submit"
                className="new-button"
                disabled={Object.keys(errors).length > 0}
              >
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {settings.show_top_tags ? (
          <div ref={componentRef} className={styles.breakdownsRow}>
            <div className="widget-hover">
              <div className="widget-hover-controls">
                <button
                  className="report-control-button"
                  onClick={() => setIsEditingMode(true)}
                >
                  <EditIcon />
                </button>
                <button
                  className="report-control-button danger"
                  onClick={() => onWidgetStatusChange(false)}
                >
                  <DeleteIcon />
                </button>
              </div>
              {settings.top_tags_notes && settings.top_tags_notes.length > 0
                ? settings.top_tags_notes.map((note) => {
                    return (
                      <>
                        {note.position === 'top' ? (
                          <div className="report-note-container note-position-top">
                            <Markdown>{note.text}</Markdown>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    );
                  })
                : ''}
              <TopTagsByPosts
                narrative={narrative}
                isShare={isShare}
                isClickable={false}
                title={settings.top_tags_title}
                size={settings.top_tags_size}
              />
              {settings.top_tags_notes && settings.top_tags_notes.length > 0
                ? settings.top_tags_notes.map((note) => {
                    return (
                      <>
                        {note.position === 'bottom' ? (
                          <div className="report-note-container note-position-bottom">
                            <Markdown>{note.text}</Markdown>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    );
                  })
                : ''}
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
};
