import * as d3 from 'd3';
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { API, ShareAPI } from '../../../../API';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import Markdown from 'react-markdown';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const SentimentBar = ({
  narrative,
  isShare,
  notes,
  settings,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const { t } = useTranslation();
  const api = isShare ? ShareAPI : API;
  const [sentimentData, setSentimentData] = useState(null);
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [note, setNote] = useState(notes?.length > 0 ? notes[0] : {text: "", position: "bottom"});
  const [isNote, setIsNote] = useState(notes?.length > 0);
  const componentRef = useRef(null);

  let chartTitle;
  if (widgetSettings.mode === 'messages') {
    chartTitle = t('Sentiment: Messages');
  } else if (widgetSettings.mode === 'views') {
    chartTitle = t('Sentiment: Views');
  } else if (widgetSettings.mode === 'likes') {
    chartTitle = t('Sentiment: Reactions');
  }

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : chartTitle;
    setTitle(newTitle);
  }, [settings]);

  const [title, setTitle] = useState(widgetSettings.title || chartTitle);

  let mode = widgetSettings.mode;

  const getSentimentData = (narrative, statsAggregation, statsBreakdown) => {
    const urlParams = new URLSearchParams();
    urlParams.append('narrative_id', narrative.id);

    urlParams.set('aggregation', statsAggregation);

    let url = `/API/v1/stats/narratives/shares?${urlParams.toString()}`;

    if (statsBreakdown !== null) {
      urlParams.set('breakdown', statsBreakdown);
      url = `/API/v1/stats/narratives/${
        narrative.id
      }/shares?${urlParams.toString()}`;
    }

    api.fetch('GET', url).then((data) => {
      setSentimentData(data.sentiment);
    });
  };

  useEffect(() => {
    getSentimentData(narrative, 'DAY', null);
  }, []);

  if (!sentimentData) {
    return <Loader />;
  }

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t(chartTitle)}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              let newNotes;
              setIsEditingMode(false);
              if (note.text.length > 0) {
                newNotes = [note];
              } else {
                newNotes = [];
              }
              onSettingsChange(widgetSettings, newNotes, componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
              setTitle(settings.title || chartTitle);
              setIsEditingMode(false);
              setWidgetSettings(settings);
              setNote(
                notes?.length > 0 ? notes[0] : { text: '', position: 'bottom' },
              );
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="platforms"
                    checked={isNote}
                    onChange={() => {
                      setIsNote(!isNote);
                    }}
                  />
                  <label htmlFor="create-note">{t('Create a note')}</label>
                </li>
              </ul>
            </div>
            {isNote ? (
              <div className="form-element">
                <div className="form-element">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="platforms"
                        checked={note?.position === 'top'}
                        onChange={() => {
                          if (note?.position === 'top') {
                            setNote({ ...note, position: 'bottom' });
                          } else {
                            setNote({ ...note, position: 'top' });
                          }
                        }}
                      />
                      <label htmlFor="note-position">
                        {t('Place a note on the top of the widget')}
                      </label>
                    </li>
                  </ul>
                </div>

                <label htmlFor="note">{t('Note')}</label>
                <TextEditor
                  id={'note-editor'}
                  value={note.text}
                  onChange={(text) => setNote({ ...note, text: text })}
                  isOpen={true}
                />
              </div>
            ) : (
              ''
            )}
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="list-content widget-container">
        <div className="report-section">
          <div className="report-section__header">
            <h3>{widgetSettings.title ? widgetSettings.title : chartTitle}</h3>
            <div className="report-section__controls">
              <button
                className="report-control-button"
                onClick={() => setIsEditingMode(true)}
              >
                <EditIcon />
              </button>
              <button
                className="report-control-button danger"
                onClick={() => onWidgetStatusChange(false)}
              >
                <DeleteIcon />
              </button>
            </div>
          </div>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'top' ? (
                      <div className="report-note-container note-position-top">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}

          <div className="card card-bar">
            {/* <div className="card-header" /> */}
            <div className="card-body">
              <div className="sentiment-bar-container">
                {sentimentData?.positive[mode] === 0 ? (
                  ''
                ) : (
                  <span
                    className="sentiment-bar-positive"
                    style={{
                      width: `${sentimentData?.positive[mode + '_share']}%`,
                    }}
                  >
                    {formatWithCustomGigaBillion(sentimentData?.positive[mode])}
                  </span>
                )}
                {sentimentData?.neutral[mode] === 0 ? (
                  ''
                ) : (
                  <span
                    className="sentiment-bar-neutral"
                    style={{
                      width: `${sentimentData?.neutral[mode + '_share']}%`,
                    }}
                  >
                    {formatWithCustomGigaBillion(sentimentData?.neutral[mode])}
                  </span>
                )}
                {sentimentData?.negative[mode] === 0 ? (
                  ''
                ) : (
                  <span
                    className="sentiment-bar-negative"
                    style={{
                      width: `${sentimentData?.negative[mode + '_share']}%`,
                    }}
                  >
                    {formatWithCustomGigaBillion(sentimentData?.negative[mode])}
                  </span>
                )}
              </div>
              <div className="sentiment-bar-stats">
                <div className="sentiment-bar-stats-item">
                  <span className="sentiment-bar-stats-color positive"></span>
                  <span className="sentiment-bar-stats-data">{`${t(
                    'Positive',
                  )}: ${
                    sentimentData?.positive[mode + '_share'] === 0
                      ? '0'
                      : sentimentData?.positive[mode + '_share']?.toFixed(2)
                  }%`}</span>
                </div>
                <div className="sentiment-bar-stats-item">
                  <span className="sentiment-bar-stats-color neutral"></span>
                  <span className="sentiment-bar-stats-data">{`${t(
                    'Neutral',
                  )}: ${
                    sentimentData?.neutral[mode + '_share'] === 0
                      ? '0'
                      : sentimentData?.neutral[mode + '_share']?.toFixed(2)
                  }%`}</span>
                </div>
                <div className="sentiment-bar-stats-item">
                  <span className="sentiment-bar-stats-color negative"></span>
                  <span className="sentiment-bar-stats-data">{`${t(
                    'Negative',
                  )}: ${
                    sentimentData?.negative[mode + '_share'] === 0
                      ? '0'
                      : sentimentData?.negative[mode + '_share']?.toFixed(2)
                  }%`}</span>
                </div>
              </div>
            </div>
          </div>
          {notes && notes.length > 0
            ? notes.map((note) => {
                return (
                  <>
                    {note.position === 'bottom' ? (
                      <div className="report-note-container note-position-bottom">
                        <Markdown>{note.text}</Markdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                );
              })
            : ''}
        </div>
      </div>
    );
  }
};
