import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Async from 'react-select/async';
import Select from 'react-select';
import { API } from '../../API';
import { useCurrentUser } from '../../contexts/CurrentUser';
import Switch from '../../components/Switch';
import { Loader } from '../../components/Loader/Loader';
import { ReactComponent as CloseIcon } from '../SearchPage/assets/close.svg';
import { affiliationTypesOptions, compromisedTypesOptions } from '../../utils/sourceEntityTypes';

import styles from './SourceEditPage.module.scss';


export const SourceEditPage = () => {
  const { t } = useTranslation();
  let { sourceId } = useParams();
  const [currentUser] = useCurrentUser();

  let userLang = 'en';

  if (
    localStorage.getItem('i18nextLng') === 'ua-UK' ||
    localStorage.getItem('i18nextLng') === 'ru-RU' ||
    localStorage.getItem('i18nextLng') === 'ru' ||
    localStorage.getItem('i18nextLng') === 'uk'
  ) {
    userLang = 'uk';
  } else {
    userLang = 'en';
  }

  let navigate = useNavigate();
  const [source, setSource] = useState(null);
  const [errors, setErrors] = useState({});

  const [name, setName] = useState('');
  const [sourceURL, setSourceURL] = useState('');
  const [country, setCountry] = useState('');
  const [countriesTop, setCountriesTop] = useState('');
  const [audience, setAudience] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [inauthenticBehavior, setInauthenticBehavior] = useState(false);
  const [isAffiliated, setIsAffiliated] = useState(false);
  const [isCompromised, setIsCompromised] = useState(false);
  const [affiliation, setAffiliation] = useState({
    name: '',
    entity_type: {value: null, label: t('Not selected')},
    country_id: '',
    country: {value: null, label: t('Not selected')},
    proof_name_uk: '',
    proof_name_en: '',
    proof_link: '',
    influence_group: null,
  });

  const [compromised, setCompromised] = useState({
    entity_type: {value: null, label: t('Not selected')},
    proof_name_uk: '',
    proof_name_en: '',
    proof_link: '',
  });

  const [sourceTags, setSourceTags] = useState(null);
  const [tags, setTags] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const fetchSource = useCallback(() => {
    API.fetch('GET', `/API/v1/sources/${sourceId}`).then(data => {
      let followers_cnt = data.audience;
      let countryOption = data.origin_country === null ? {value: null, label: t('Not selected')} : {value: data.origin_country?.id, label: data.origin_country?.name};
      let isBot = data.is_bot || data.inauthentic_behavior ? true : false;
      let selectedTags = data.tags.map((tag) => {
        const label =
          userLang === 'uk'
            ? tag.name_uk_full.join(' > ')
            : tag.name_en_full.join(' > ');
        return { value: tag.full_path, label: label };
      });

      setSource(data);
      setName(data.name);
      setSourceURL(data.url);
      setCountry(countryOption);
      setAudience(followers_cnt);
      setIsVerified(data.is_verified);
      setInauthenticBehavior(isBot);
      setSourceTags(selectedTags);
    })
    .catch(error => {
      setErrors(state => ({...state, general: error.message}))
    });
  }, [sourceId]);

  useEffect(() => {
    fetchSource()
  }, [fetchSource]);

  const fetchAffiliationsAndCompromised = useCallback(() => {
    Promise.all([
      API.fetch('GET', `/API/v1/sources/${sourceId}/affiliations`),
      API.fetch('GET', `/API/v1/sources/${sourceId}/discreditations`)
    ]).then(([affiliations, discreditations]) => {
      if(affiliations?.length > 0) {
        setIsAffiliated(true);
        let affiliation = affiliations[0];
        let entityType = affiliationTypesOptions.find(option => option.value === affiliation.entity_type)
        setAffiliation({
          name: affiliation.name,
          entity_type: {value: entityType.value, label: t(entityType.label)},
          country_id: affiliation.country_id,
          country: {value: affiliation.country_id, label: affiliation.country.name, code: affiliation.country.code},
          proof_name_uk: affiliation.proof_name_uk,
          proof_name_en: affiliation.proof_name_en,
          proof_link: affiliation.proof_link,
          influence_group: affiliation.influence_group || "",
        })
      }

      if(discreditations?.length > 0) {
        setIsCompromised(true);
        let discreditation = discreditations[0];
        let entityType = compromisedTypesOptions.find(option => option.value === discreditation.entity_type)
        setCompromised({
          entity_type: {value: entityType.value, label: t(entityType.label)},
          proof_name_uk: discreditation.proof_name_uk,
          proof_name_en: discreditation.proof_name_en,
          proof_link: discreditation.proof_link,
        })
      }}).catch(error => {
        setErrors(state => ({...state, general: error.message}))

    });
  }, [sourceId]);

  useEffect(() => {
    fetchAffiliationsAndCompromised()
  }, [fetchAffiliationsAndCompromised]);

  const getTags = useCallback(() => {
    API.fetch('GET', `/API/v1/source_tags/public`).then((data) => {
      setTags(data);
    });
  }, []);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries`).then((data) => {
      let topCountries = data.objects.slice(0, 10).map((country) => {
        return { value: country.id, label: country.name, code: country.code};
      });

      topCountries.unshift({value: null, label: t('Not selected')})

      setCountriesTop(topCountries);
    });
  }, []);


  useEffect(getAllCountries, [getAllCountries]);

  const getCountries = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/countries?q=${inputValue}`).then(
      (data) => {
        callback(
          data.objects
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((country) => {
              return { value: country.id, label: country.name, code: country.code };
            }),
        );
      },
    );
  };

  let countriesPromiseTimeout;
  const countriesPromiseOptions = function (inputValue) {
    if (countriesPromiseTimeout !== null) {
      clearTimeout(countriesPromiseTimeout);
    }

    if (!inputValue) {
      return [];
    }

    return new Promise((resolve) => {
      countriesPromiseTimeout = setTimeout(() => {
        getCountries(inputValue, resolve);
      }, 1000);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let followers_cnt = source.audience;
    let body;

    if(audience == followers_cnt) {
      body = {
        name: name,
        url: sourceURL,
        origin_country_id: country.value,
        is_bot:inauthenticBehavior,
        inauthentic_behavior: inauthenticBehavior,
        is_verified: isVerified,
      }
    } else {
      body = {
        name: name,
        url: sourceURL,
        origin_country_id: country.value,
        audience: audience || null,
        is_bot:inauthenticBehavior,
        inauthentic_behavior: inauthenticBehavior,
        is_verified: isVerified,
      }
    }

    if(!isAffiliated) { 
      body.affiliations = null;
    } else if (isAffiliated && (!affiliation.entity_type || affiliation.entity_type.value === null || !affiliation.proof_name_uk || !affiliation.proof_name_en || !affiliation.proof_link || !affiliation.country || affiliation.country.value === null)) {
      if(isAffiliated && (!affiliation.country || affiliation.country.value === null)) {
        setErrors(errors => ({...errors, affiliationCountry: t('Please select country of affiliation')}))
      }
      if(isAffiliated && (!affiliation.entity_type || affiliation.entity_type.value === null)) {
        setErrors(errors => ({...errors, affiliationType: t('Please select type of affiliation')}))
      }
      if(isAffiliated && !affiliation.proof_name_uk) {
        setErrors(errors => ({...errors, affiliationProofNameUk: t('Please enter proof of affiliation (UK)')}))
      }
      if(isAffiliated && !affiliation.proof_name_en) {
        setErrors(errors => ({...errors, affiliationProofNameEn: t('Please enter proof of affiliation (EN)')}))
      }
      if(isAffiliated && !affiliation.proof_link) {
        setErrors(errors => ({...errors, affiliationProofLink: t('Please enter proof link')}))
      }
      setIsLoading(false);
      return
    } else if (isAffiliated && affiliation.entity_type && affiliation.proof_name_uk && affiliation.proof_name_en && affiliation.proof_link && affiliation.country) {
      body.affiliations = [{
        name: affiliation.name,
        entity_type: affiliation.entity_type.value,
        country_id: affiliation.country.value,
        country: {'id': affiliation.country.value, 'name': affiliation.country.label, 'code': affiliation.country.code,},
        proof_name_uk: affiliation.proof_name_uk,
        proof_name_en: affiliation.proof_name_en,
        proof_link: affiliation.proof_link,
        influence_group: affiliation.influence_group || "",
      }]
    } 

    if (!isCompromised) {
      body.discreditations = null;
    } else if (isCompromised && (!compromised.entity_type || compromised.entity_type.value === null || !compromised.proof_name_uk || !compromised.proof_name_en || !compromised.proof_link)){
      if(isCompromised && (!compromised.entity_type || compromised.entity_type.value === null)) {
        setErrors(errors => ({...errors, compromisedType: t('Please select type of compromise')}))
      }
      if(isCompromised && !compromised.proof_name_uk) {
        setErrors(errors => ({...errors, compromisedProofNameUk: t('Please enter proof of compromise (UK)')}))
      }
      if(isCompromised && !compromised.proof_name_en) {
        setErrors(errors => ({...errors, compromisedProofNameEn: t('Please enter proof of compromise (EN)')}))
      }
      if(isCompromised && !compromised.proof_link) {
        setErrors(errors => ({...errors, compromisedProofLink: t('Please enter proof link')}))
      }
      setIsLoading(false);
      return
    } else if (isCompromised && compromised.entity_type && compromised.proof_name_uk && compromised.proof_name_en && compromised.proof_link) {
      body.discreditations = [{
        entity_type: compromised.entity_type.value,
        proof_name_uk: compromised.proof_name_uk,
        proof_name_en: compromised.proof_name_en,
        proof_link: compromised.proof_link,
      }]
    }

    if(sourceTags) {
      body.tags = sourceTags.map(tag => tag.value)
    }

    API.fetch('PATCH', `/API/v1/sources/${sourceId}`, null, body).then(data => {
      setIsLoading(false);
      navigate(`/sources/${sourceId}`)
    }).catch(error => {
      setIsLoading(false);
      setErrors(errors => ({...errors, general: error.message}))
    })
  }

  if(!currentUser?.is_super_admin) {
    navigate(`/sources`)
  }

  if(!sourceId || !source) {
    return <Loader/>
  }


  const tagsList = tags?.objects?.map((tag) => {
    const label =
      userLang === 'uk'
        ? tag.name_uk_full.join(' > ')
        : tag.name_en_full.join(' > ');
    return { value: tag.full_path, label: label };
  });
    

  return(
    <div className={styles.root}>
      <div className="page-header">
        <div className="breadcrumb">
          <span>
            <Link to="/sources">{t('Actors')}</Link>
          </span>
          <span>
            <Link to={`/sources/${sourceId}`}>{source.name}</Link>
          </span>
          <span>{t('Edit')}</span>
        </div>
      </div>
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <div className={errors.name ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Name')}&#42;</label>
            <input
              className=""
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
            <div className="error-message">{errors.name}</div>
          </div>
          <div className={errors.sourceURL ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('URL')}&#42;</label>
            <input
              className=""
              id="sourceURL"
              name="sourceURL"
              type="url"
              value={sourceURL}
              onChange={e => setSourceURL(e.target.value)}
              required
            />
            <div className="error-message">{errors.sourceCountry}</div>
          </div>
          <div className={errors.audience ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Audience')}</label>
            <input
              className=""
              id="audience"
              name="audience"
              type="number"
              min={0}
              value={audience}
              onChange={e => setAudience(e.target.value)}
            />
            <div className="error-message">{errors.audience}</div>
          </div>
          <div className={errors.sourceCountry ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Origin country')}</label>
            <Async
              className="react-select-container"
              classNamePrefix="react-select"
              cacheOptions
              defaultOptions={countriesTop}
              loadOptions={countriesPromiseOptions}
              placeholder={t('Please enter at least 1 symbol to search')}
              noOptionsMessage={() => t('Please enter at least 1 symbol')}
              loadingMessage={() => t('Loading...')}
              name="countries"
              value={country}
              onChange={(value) => setCountry(value)}
            />
            <div className="error-message">{errors.sourceCountry}</div>
          </div>

          <div className="form-element">
            <label htmlFor='verification' className={styles.label}>
              {t('Verification')}
            </label>
            <Switch
              id='verification'
              value={isVerified}
              onChange={setIsVerified}
            />
          </div>
          <div className="form-element">
            <label htmlFor='inauthentic' className={styles.label}>
              {t('Inauthentic behavior')}
            </label>
            <Switch
              id='inauthentic'
              value={inauthenticBehavior}
              onChange={setInauthenticBehavior}
            />
          </div>

          <div className="form-element">
            <label htmlFor='affiliations' className={styles.label}>
              {t('Affiliation')}
            </label>
            <Switch
              id='affiliations'
              value={isAffiliated}
              onChange={setIsAffiliated}
            />
          </div>
          {isAffiliated ? <div className={errors.affiliationCountry ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Country of affiliation')}</label>
            <Async
              className="react-select-container"
              classNamePrefix="react-select"
              cacheOptions
              defaultOptions={countriesTop}
              loadOptions={countriesPromiseOptions}
              placeholder={t('Please enter at least 1 symbol to search')}
              noOptionsMessage={() => t('Please enter at least 1 symbol')}
              loadingMessage={() => t('Loading...')}
              name="affiliationCountries"
              value={affiliation.country}
              onChange={(value) => setAffiliation({...affiliation, country_id: value.value, country: value})}
              required={true}
            />
            <div className="error-message">{errors.affiliationCountry}</div>
          </div> : ''}
          {isAffiliated ? <div className={errors.affiliationType ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Type of affiliation')}</label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={affiliationTypesOptions.map(option => ({value: option.value, label: t(option.label)}))}
              placeholder={t('Type of affiliation')}
              name="affiliationType"
              value={affiliation.entity_type}
              onChange={(value) => setAffiliation({...affiliation, entity_type: value})}
              required={true}
            />
            <div className="error-message">{errors.affiliationType}</div>
          </div> : ''}

          {isAffiliated ? <div className={errors.affiliationProofNameUk ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Proof of affiliation (UK)')}</label>
            <input
              className=""
              id="affiliationProofNameUk"
              name="affiliationProofNameUk"
              type="text"
              value={affiliation.proof_name_uk}
              onChange={e => setAffiliation({...affiliation, proof_name_uk: e.target.value})}
              required
            />
            <div className="error-message">{errors.affiliationProofNameUk}</div>
          </div> : ''}
          {isAffiliated ? <div className={errors.affiliationProofNameEn ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Proof of affiliation (EN)')}</label>
            <input
              className=""
              id="affiliationProofNameEn"
              name="affiliationProofNameEn"
              type="text"
              value={affiliation.proof_name_en}
              onChange={e => setAffiliation({...affiliation, proof_name_en: e.target.value})}
              required
            />
            <div className="error-message">{errors.affiliationProofNameEn}</div>
          </div> : ''}
          {isAffiliated ? <div className={errors.affiliationProofLink ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Proof link')}</label>
            <input
              className=""
              id="affiliationProofLink"
              name="affiliationProofLink"
              type="url"
              value={affiliation.proof_link}
              onChange={e => setAffiliation({...affiliation, proof_link: e.target.value})}
              required
            />
            <div className="error-message">{errors.affiliationProofLink}</div>
          </div> : ''}
    
          <div className="form-element">
            <label htmlFor='compromised' className={styles.label}>
              {t('Compromised account')}
            </label>
            <Switch
              id='compromised'
              value={isCompromised}
              onChange={setIsCompromised}
            />
          </div>

          {isCompromised ? <div className={errors.compromisedType ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Compromised account type')}</label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={compromisedTypesOptions.map(option => ({value: option.value, label: t(option.label)}))}
              placeholder={t('Compromised account type')}
              name="compromisedType"
              value={compromised.entity_type}
              onChange={(value) => setCompromised({...compromised, entity_type: value})}
              required={true}
            />
            <div className="error-message">{errors.compromisedType}</div>
          </div> : ''}

          {isCompromised ? <div className={errors.compromisedProofNameUk ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Proof of compromise (UK)')}</label>
            <input
              className=""
              id="compromisedProofNameUk"
              name="compromisedProofNameUk"
              type="text"
              value={compromised.proof_name_uk}
              onChange={e => setCompromised({...compromised, proof_name_uk: e.target.value})}
              required
            />
            <div className="error-message">{errors.compromisedProofNameUk}</div>
          </div> : ''}

          {isCompromised ? <div className={errors.compromisedProofNameEn ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Proof of compromise (EN)')}</label>
            <input
              className=""
              id="compromisedProofNameEn"
              name="compromisedProofNameEn"
              type="text"
              value={compromised.proof_name_en}
              onChange={e => setCompromised({...compromised, proof_name_en: e.target.value})}
              required
            />
            <div className="error-message">{errors.compromisedProofNameEn}</div>
          </div> : ''}

          {isCompromised ? <div className={errors.compromisedProofLink ? 'form-element error' : 'form-element'}>
            <label htmlFor="name" className={styles.label}>{t('Proof link')}</label>
            <input
              className=""
              id="compromisedProofLink"
              name="compromisedProofLink"
              type="url"
              value={compromised.proof_link}
              onChange={e => setCompromised({...compromised, proof_link: e.target.value})}
              required
            />
            <div className="error-message">{errors.compromisedProofLink}</div>
          </div> : ''}

          <div className={errors.tags ? 'form-element error' : 'form-element'}>
            <label htmlFor='tags' className={styles.label}>
              {t('Tags')}
            </label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={tagsList}
              isMulti
              placeholder={t('Select tags')}
              noOptionsMessage={() => t('No tags found')}
              name="tags"
              value={sourceTags}
              onChange={(value) => setSourceTags(value)}
            />
            <div className="error-message">{errors.tags}</div>
          </div>


          <div className="form-element">
            <button
              className="new-button"
              type="submit"
              disabled={isLoading}
            >
              {t('Save')}
            </button>

            {errors.general 
              ? <div className={styles.errorMessage}>
                  {t(errors.general)}
                  <span onClick={() => setErrors({...errors, general: null})}>
                    <CloseIcon/>
                  </span>
                </div>
              : ''}
          </div>
        </form>
      </div>
    </div>
  )
}

