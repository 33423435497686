import * as d3 from 'd3';
import { useEffect, useState, useContext } from "react";
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import { cutString } from "../../../utils/cutString";
import Tooltip from "../../../components/Tooltip/Tooltip";
import styles from '../NarrativePage.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export const TopTagsByPosts = ({narrative, isShare, isClickable=true, title, size=15}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState(null);

  const {openModal, setSourceGroupFilter} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;

  const getTags = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/source_groups?size=${size}`).then(
      data => setTags(data)
    )
  }

  useEffect(() => {
    getTags()
  }, [narrative])

  return(
    <BreakdownsContainer
      title={title ? title : 'Top tags by number of posts'}
      onClick={() => openModal()}
      details={false}
    >
     {tags 
      ? (tags.objects?.length > 0
      ? <div className={styles.topTagsContainer}>
          <div className={styles.nameHeader}><h5>{t('Tag')}</h5></div>
          <div className={styles.sourcesHeader}><h5>{t('Actors')}</h5></div>
          <div className={styles.messagesHeader}><h5>{t('Messages')}</h5></div>
          <div className={styles.name}>
            <div className={styles.breakdownsListMain}>
              {tags?.objects?.map(tag => {
                return(
                  <div key={`tags_name_${tag?.id}`} className={styles.listItem}>
                    <div className={styles.info}>
                      {/* <span>{platformIconsMapping[source?.source_type]}</span> */}
                      <span 
                        className={styles.title}
                        onClick={() => {
                          if(isClickable) {
                            setSourceGroupFilter(tag.id)
                            openModal()
                          }
                        }}
                        >
                          <Tooltip content={tag.name} position='right'>
                            {cutString(tag.name, 40)}
                          </Tooltip>
                        </span>
                    </div>
                    {/* <div className={styles.count}>{tag.messages_count}</div> */}
                  </div>
                )
              })}
            </div> 
          </div>

          <div className={styles.sources}>
            <div className={styles.breakdownsListMain}>
              {tags?.objects?.map(tag => {
                return(
                  <div key={`tags_name_${tag?.id}`} className={styles.listItem}>
                    
                    <div className={styles.count}>{formatNumberSignificant(tag.number_of_sources)}</div>
                  </div>
                )
              })}
            </div> 
          </div>
          <div className={styles.messages}>
            <div className={styles.breakdownsListMain}>
              {tags?.objects?.map(tag => {
                return(
                  <div key={`tags_name_${tag?.id}`} className={styles.listItem}>
                    
                    <div className={styles.count}>{tag.messages_count}</div>
                  </div>
                )
              })}
            </div> 
          </div>
        </div> 
      : <div className={styles.noData}>{t('There is no data to display')}</div>
      ) : (<div className={styles.widdgetLoader}><LoaderSmall /></div>)}

    </BreakdownsContainer>
  )
}

