import * as d3 from 'd3';
import { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { MessagesContext } from '../../contexts/MessagesContext';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import { SummaryPanel } from '../../../../components/SummaryPanel/SummaryPanel';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import Markdown from 'react-markdown';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const Summary = ({
  narrative,
  isShare,
  notes,
  settings,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const { narrativeShares, fetchNarrativeShares } = useContext(MessagesContext);

  const { t } = useTranslation();
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [note, setNote] = useState(
    notes?.length > 0 ? notes[0] : { text: '', position: 'bottom' },
  );
  const [isNote, setIsNote] = useState(notes?.length > 0);
  const componentRef = useRef(null);

  let widgetTitle = t('Summary');

  if (settings.mode[0] === 'actors') {
    widgetTitle = t('Summary: Actors');
  } else if (settings.mode[0] === 'publications') {
    widgetTitle = t('Summary: Publications');
  } else if (settings.mode[0] === 'views') {
    widgetTitle = t('Summary: Views');
  } else if (settings.mode[0] === 'reactions') {
    widgetTitle = t('Summary: Reactions');
  }

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : widgetTitle;
    setTitle(newTitle);
  }, [settings]);

  const [title, setTitle] = useState(widgetSettings.title || widgetTitle);

  useEffect(() => {
    fetchNarrativeShares(narrative, 'DAY', null);
  }, []);

  if (!narrativeShares) {
    return <Loader />;
  }
  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{widgetTitle}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              let newNotes;
              setIsEditingMode(false);
              if (note.text.length > 0) {
                newNotes = [note];
              } else {
                newNotes = [];
              }
              onSettingsChange(widgetSettings, newNotes, componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
              setTitle(settings.title || widgetTitle);
              setIsEditingMode(false);
              setWidgetSettings(settings);
              setNote(
                notes?.length > 0 ? notes[0] : { text: '', position: 'bottom' },
              );
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="platforms"
                    checked={isNote}
                    onChange={() => {
                      setIsNote(!isNote);
                    }}
                  />
                  <label htmlFor="create-note">{t('Create a note')}</label>
                </li>
              </ul>
            </div>
            {isNote ? (
              <div className="form-element">
                <div className="form-element">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="platforms"
                        checked={note?.position === 'top'}
                        onChange={() => {
                          if (note?.position === 'top') {
                            setNote({ ...note, position: 'bottom' });
                          } else {
                            setNote({ ...note, position: 'top' });
                          }
                        }}
                      />
                      <label htmlFor="note-position">
                        {t('Place a note on the top of the widget')}
                      </label>
                    </li>
                  </ul>
                </div>

                <label htmlFor="note">{t('Note')}</label>
                <TextEditor
                  id={'note-editor'}
                  value={note.text}
                  onChange={(text) => setNote({ ...note, text: text })}
                  isOpen={true}
                />
              </div>
            ) : (
              ''
            )}
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="report-section widget-container">
        <div className="report-section__header">
          <h3>{title ? title : widgetTitle}</h3>
          <div className="report-section__controls">
            <button
              className="report-control-button"
              onClick={() => setIsEditingMode(true)}
            >
              <EditIcon />
            </button>
            <button
              className="report-control-button danger"
              onClick={() => onWidgetStatusChange(false)}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
        {notes && notes.length > 0
          ? notes.map((note) => {
              return (
                <>
                  {note.position === 'top' ? (
                    <div className="report-note-container note-position-top">
                      <Markdown>{note.text}</Markdown>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              );
            })
          : ''}
        <SummaryPanel
          narrativeIds={[narrative.id]}
          tabsList={settings.mode}
          isPDF={true}
          isShare={isShare}
        />

        {notes && notes.length > 0
          ? notes.map((note) => {
              return (
                <>
                  {note.position === 'bottom' ? (
                    <div className="report-note-container note-position-bottom">
                      <Markdown>{note.text}</Markdown>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              );
            })
          : ''}
      </div>
    );
  }
};
