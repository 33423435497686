export const topActorsData = {
  "1": {
    "objects": [
      {
        "id": 202758,
        "name": "Rachel",
        "url": "https://twitter.com/GoldnemovesGold",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 7,
        "audience": 13555.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1160112,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 121572,
        "name": "\u201cDems Are Desperate\u201d Tenorman \ud83c\uddfa\ud83c\uddf8",
        "url": "https://twitter.com/thetenorman",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 6,
        "audience": 5229.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1027219,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 207155,
        "name": "Lies Matter!",
        "url": "https://twitter.com/liesmatr",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 5,
        "audience": 3891.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1164509,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 66323,
        "name": "Marc Londo (O.T.B. Productions) \ud83c\uddfa\ud83c\udde6",
        "url": "https://twitter.com/mlondo856",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 5,
        "audience": 1411.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1077772,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 210050,
        "name": "NahBabyNah #Trump",
        "url": "https://twitter.com/NahBabyNah",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 5,
        "audience": 158584.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1167404,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "2": {
    "objects": [
      {
        "id": 210050,
        "name": "NahBabyNah #Trump",
        "url": "https://twitter.com/NahBabyNah",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 5,
        "audience": 158584.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1167404,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2040435,
        "name": "\ud83c\udfdb \ud83c\udf39PeriklestheGREAT \ud83c\udf39 \ud83c\udfdb \"Do Unto Others\"",
        "url": "https://twitter.com/PeriklesGREAT",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 4,
        "audience": 153943.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2058502,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 168473,
        "name": "\u269c \u2022 \ud835\udd78\ud835\udd8e\ud835\udd88\ud835\udd8d\ud835\udd86\ud835\udd8a\ud835\udd91 \u2022 \u269c",
        "url": "https://twitter.com/BasedSolutions1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 4,
        "audience": 66899.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1130062,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2040876,
        "name": "Americat \ud83c\uddfa\ud83c\uddf8",
        "url": "https://twitter.com/catmurphy209",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 3,
        "audience": 39504.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2058943,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2040428,
        "name": "\u2022 \u15f0IS\u1455\u157cI\u15f4\u15b4 \u2122 \u2022",
        "url": "https://twitter.com/4Mischief",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 3,
        "audience": 188012.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2058495,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "3": {
    "objects": [
      {
        "id": 387,
        "name": "Made in Ukraine \ud83c\uddfa\ud83c\udde6",
        "url": "https://t.me/Made_inUkraine",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 32412.0,
        "audience_type": "SUBSCRIBERS",
        "role": "INFORMAL_NEWS",
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 195,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 2.0,
          "name": "Ukraine",
          "code": "UA",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 246019,
        "name": "\u0425\u0443\u0435\u0430\u0432\u0430\u044f \u0423\u043a\u0440\u0430\u0438\u043d\u0430",
        "url": "https://t.me/hyukraine",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 6281.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2029769,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 2.0,
          "name": "Ukraine",
          "code": "UA",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 246130,
        "name": "\u0406\u0432\u0430\u043d\u043e-\u0424\u0440\u0430\u043d\u043a\u0456\u0432\u0441\u044c\u043a",
        "url": "https://t.me/frankivskpovistki",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 43417.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2029880,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 2.0,
          "name": "Ukraine",
          "code": "UA",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 246251,
        "name": "\u041f\u043e\u0432\u0456\u0441\u0442\u043a\u0438 \u0414\u043d\u0456\u043f\u0440\u043e / \u041f\u043e\u0432\u0435\u0441\u0442\u043a\u0438 \u0414\u043d\u0435\u043f\u0440",
        "url": "https://t.me/dneprpovestki",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 17821.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2030001,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 2.0,
          "name": "Ukraine",
          "code": "UA",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 246299,
        "name": "CN | \u0427\u0443\u0433\u0443\u0435\u0432 News\ufe0f | \u0427\u0443\u0433\u0443\u0457\u0432",
        "url": "https://t.me/cn_chuguev_news",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 16382.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2030049,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 2.0,
          "name": "Ukraine",
          "code": "UA",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "4": {
    "objects": [
      {
        "id": 2040326,
        "name": "THiS LiL\u2019 MiSS Can\u2019t Be Wrong",
        "url": "https://twitter.com/l42022425",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 4,
        "audience": 107439.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2058393,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 59183,
        "name": "Beyond Massage",
        "url": "https://twitter.com/bbeyondm1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 2443.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1066095,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 176785,
        "name": "Shaikh Javed Iqbal",
        "url": "https://twitter.com/ShaikhJavedIqb1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 1167.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1138349,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 51792,
        "name": "David Johnson",
        "url": "https://twitter.com/davidwjohns1111",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2013.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1003201,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2050739,
        "name": "A AJZ",
        "url": "https://twitter.com/AAJZ17",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 796.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2068802,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "5": {
    "objects": [
      {
        "id": 198049,
        "name": "FOX SA",
        "url": "https://twitter.com/KABBFOX29",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 3,
        "audience": 87439.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1155402,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 126940,
        "name": "norman rica",
        "url": "https://twitter.com/waketfuamerica",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 3987.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1034250,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2647745,
        "name": "Cathie\u270d\ufe0f\ud83c\uddfa\ud83c\uddf8\ud83e\udd85",
        "url": "https://twitter.com/AmericanMOM01",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 4588.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2748054,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 173229,
        "name": "TheSacredFlame",
        "url": "https://twitter.com/TheSacredFlame",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 2259.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1134804,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 158856,
        "name": "Miss Murray's Mom",
        "url": "https://twitter.com/sgh956",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 2954.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1120491,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "6": {
    "objects": [
      {
        "id": 7739520,
        "name": "nocensuraeverita",
        "url": "https://twitter.com/nocensuraverita",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 2365.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 7829937,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 20.0,
          "name": "Italy",
          "code": "IT",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 42106,
        "name": "Vectis \ud83d\udc3e",
        "url": "https://twitter.com/iow_richard",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 1275.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1072788,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 5737799,
        "name": "@blokirovanmaskom",
        "url": "https://twitter.com/ubitmaskom",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 81.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 5837515,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 1.0,
          "name": "Russia",
          "code": "RU",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 224870,
        "name": "Abstract4Art",
        "url": "https://twitter.com/Harpo4Marx",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2765.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1182229,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 23.0,
          "name": "Netherlands",
          "code": "NL",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 248361,
        "name": "Arystokrata",
        "url": "https://twitter.com/Arystokrata1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 10505.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2032116,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 25.0,
          "name": "Poland",
          "code": "PL",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "7": {
    "objects": [
      {
        "id": 43921,
        "name": "KETV NewsWatch 7",
        "url": "https://twitter.com/KETV",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 201235.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1073935,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 40685,
        "name": "MyNBC5",
        "url": "https://twitter.com/MyNBC5",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 39230.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1071954,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 132663,
        "name": "ABC7 News",
        "url": "https://twitter.com/abc7newsbayarea",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 593195.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1041647,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 46798,
        "name": "Berkley Bear",
        "url": "https://twitter.com/BerkleyBearNews",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 1895.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1011118,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 40632,
        "name": "WXII 12 News",
        "url": "https://twitter.com/WXII",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 147033.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1071925,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "8": {
    "objects": [
      {
        "id": 87960764,
        "name": "Digital Soldier Back Channel \ud83d\udea8\ud83d\udea8\ud83d\udea8\ud83d\udea8\ud83d\udea8",
        "url": "https://t.me/digitalsoldierbackchannel",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 8246.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 88060903,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 47240,
        "name": "Citizen of EU",
        "url": "https://twitter.com/wavetossed",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 8126.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1076018,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 11.0,
          "name": "European Union",
          "code": "EU",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 85770818,
        "name": "SATIRE CLOUD",
        "url": "https://t.me/satirecloud",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 597.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 85870387,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 48460225,
        "name": "\u20bfased male\u2122 \ud83c\uddfa\ud83c\uddf8",
        "url": "https://twitter.com/GetMeRoss",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 7160.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 48554077,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 66446520,
        "name": "17soul",
        "url": "https://twitter.com/17soul160523",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 926.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 66549596,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "9": {
    "objects": [
      {
        "id": 68968,
        "name": "Gerry Negron",
        "url": "https://twitter.com/NegronDoc",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 4211.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1079668,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 3385407,
        "name": "Ali Adair \ud83d\udfe7",
        "url": "https://twitter.com/AliAdair22",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 37874.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 3484117,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 132285,
        "name": "The Recount",
        "url": "https://twitter.com/therecount",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 362260.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1041159,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 92168,
        "name": "Muzamana Victor",
        "url": "https://twitter.com/Muzamana_Victor",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 1299.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1097331,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2042244,
        "name": "Heather Thomas",
        "url": "https://twitter.com/HeatherThomasAF",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 114087.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2060311,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "10": {
    "objects": [
      {
        "id": 164894,
        "name": "Martyn Bradbury",
        "url": "https://twitter.com/CitizenBomber",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 3,
        "audience": 7893.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1126492,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 24.0,
          "name": "New Zealand",
          "code": "NZ",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2051546,
        "name": "Ertu\u011frul Altun \ud83c\uddf9\ud83c\uddf7\ud83c\udde6\ud83c\uddff\ud83c\uddf5\ud83c\uddf0\ud83c\uddf2\ud83c\uddfb\ud83c\uddf9\ud83c\uddf3\ud83c\uddef\ud83c\uddf4\ud83c\uddf2\ud83c\uddf7\ud83c\udde9\ud83c\uddff",
        "url": "https://twitter.com/ferasetname",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 15272.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2069609,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 31.0,
          "name": "Turkey",
          "code": "TR",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 49037,
        "name": "Mats Nilsson",
        "url": "https://twitter.com/mazzenilsson",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 24007.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1005186,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 84.0,
          "name": "South Africa",
          "code": "ZA",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 177760801,
        "name": "Aksa Tufan\u0131 \ud83c\udde6\ud83c\uddea | Anl\u0131k \ud83d\udd34",
        "url": "https://t.me/aksaanlik",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 1340.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 178012693,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 31.0,
          "name": "Turkey",
          "code": "TR",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 14989491,
        "name": "Misvak Caps",
        "url": "https://twitter.com/misvakcaps",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 15079908,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "11": {
    "objects": [
      {
        "id": 87832015,
        "name": "osintcorpbot",
        "url": "https://t.me/osintcorpbot",
        "source_type": "TELEGRAM",
        "status": "ARCHIVED",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 87932162,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 141914,
        "name": "John Morgan",
        "url": "https://twitter.com/johnmorganFL",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 32300.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1053203,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 145317,
        "name": "WendylDail",
        "url": "https://twitter.com/wendylwilson2",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 4026.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1057482,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 113020888,
        "name": "Korean Alpha EN",
        "url": "https://t.me/korean_alpha_en",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 1024.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 113125575,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 43647,
        "name": "Mario Nawfal",
        "url": "https://twitter.com/MarioNawfal",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 1660641.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": false,
        "suspended_by_platform": 0,
        "inauthentic_behavior": false,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1008109,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "12": {
    "objects": [
      {
        "id": 74560,
        "name": "Navdeep Singh Rajwanshi",
        "url": "https://twitter.com/TheGameVeda",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2202.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1083732,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 80506,
        "name": "Party of Wales Supporters",
        "url": "https://twitter.com/TLlanelli",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 3161.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1035979,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 136928,
        "name": "Will Alexander",
        "url": "https://twitter.com/Bill_C54",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 4342.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1047058,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 143282,
        "name": "\ud83d\udd49 Om Dr of \ud83d\udc99 - Humanist RenuMarley",
        "url": "https://twitter.com/QueenPhoenix1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2127.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1054939,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 168652,
        "name": "Mark2c @antmarkc.bsky.social",
        "url": "https://twitter.com/AnthonyCockbur2",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2413.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1130241,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  }
}
