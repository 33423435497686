import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { Loader } from '../../../../components/Loader/Loader';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import { PlatformsWidget } from './Platforms';
import { CountriesWidget } from './Countries';
import { VerificationWidget } from './Verification';
import { AudienceWidget } from './Audience';
import styles from '../../NarrativePage.module.scss';


export const ActorsBreakdowns = ({
  narrative,
  isShare,
  settings,
  notes,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const { t } = useTranslation();
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [title, setTitle] = useState(widgetSettings.title || t('Actors breakdowns'));
  const componentRef = useRef(null);

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : t('Actors breakdowns');
    setTitle(newTitle);
  }, [settings]);

  if (!narrative) {
    return <Loader />;
  }

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t('Actors breakdowns')}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsEditingMode(false);
              onSettingsChange(widgetSettings, [], componentRef.current);
            }}
            onReset={() => {
              setTitle(settings.title || t('Actors breakdowns'))
              setIsEditingMode(false);
              setWidgetSettings(settings);
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>

            <div className="form-element">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="platforms"
                    checked={widgetSettings.show_platforms}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_platforms: !widgetSettings.show_platforms,
                      });
                    }}
                  />
                  <label htmlFor="platforms">{t('Show Platforms')}</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="countries"
                    checked={widgetSettings.show_countries}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_countries: !widgetSettings.show_countries,
                      });
                    }}
                  />
                  <label htmlFor="countries">{t('Show Countries')}</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="verification"
                    checked={widgetSettings.show_verification}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_verification: !widgetSettings.show_verification,
                      });
                    }}
                  />
                  <label htmlFor="verification">{t('Show Verification')}</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="audience"
                    checked={widgetSettings.show_audience}
                    onChange={() => {
                      setWidgetSettings({
                        ...widgetSettings,
                        show_audience: !widgetSettings.show_audience,
                      });
                    }}
                  />
                  <label htmlFor="audience">{t('Show Audience')}</label>
                </li>
              </ul>
            </div>
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="widget-container">
        <div className="report-section">
          <div className="report-section__header">
            <h3>
              {widgetSettings.title
                ? widgetSettings.title
                : t('Actors breakdowns')}
            </h3>
            <div className="report-section__controls">
              <button
                className="report-control-button"
                onClick={() => setIsEditingMode(true)}
              >
                <EditIcon />
              </button>
              <button
                className="report-control-button danger"
                onClick={() => onWidgetStatusChange(false)}
              >
                <DeleteIcon />
              </button>
            </div>
          </div>
          <div className={styles.breakdownsContainer}>
            <PlatformsWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_platforms: !widgetSettings.show_platforms,
                });
                onSettingsChange({ ...widgetSettings, show_platforms: value });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />

            <CountriesWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_countries: !widgetSettings.show_countries,
                });
                onSettingsChange({ ...widgetSettings, show_countries: value });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />

            <VerificationWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_verification: !widgetSettings.show_verification,
                });
                onSettingsChange({
                  ...widgetSettings,
                  show_verification: value,
                });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />

            <AudienceWidget
              narrative={narrative}
              settings={settings}
              isShare={isShare}
              onWidgetStatusChange={(value) => {
                setWidgetSettings({
                  ...widgetSettings,
                  show_audience: !widgetSettings.show_audience,
                });
                onSettingsChange({ ...widgetSettings, show_audience: value });
              }}
              onSettingsChange={(value, ref) => {
                setWidgetSettings(value);
                onSettingsChange(value, [], ref);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};
