export const summaryData = {
  "1": {
    "actors": {
      "total": 631,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 902,
      "breakdown": {
        "social_media_original_posts": { "total": 96 },
        "social_media_reposts": { "total": 805 },
        "media_articles": { "total": 0 },
        "comments": { "total": 1 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 1514170, "breakdown": 0 },
    "reactions": {
      "total": 6657316,
      "breakdown": {
        "comments": { "total": 1269 },
        "reposts": { "total": 6589281 },
        "likes_emoji": { "total": 66766 }
      }
    }
  },
  "2": {
    "actors": {
      "total": 105,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 141,
      "breakdown": {
        "social_media_original_posts": { "total": 19 },
        "social_media_reposts": { "total": 122 },
        "media_articles": { "total": 0 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 32233, "breakdown": 0 },
    "reactions": {
      "total": 42376,
      "breakdown": {
        "comments": { "total": 75 },
        "reposts": { "total": 40671 },
        "likes_emoji": { "total": 1630 }
      }
    }
  },
  "3": {
    "actors": {
      "total": 65,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 65,
      "breakdown": {
        "social_media_original_posts": { "total": 64 },
        "social_media_reposts": { "total": 1 },
        "media_articles": { "total": 0 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 844171, "breakdown": 0 },
    "reactions": {
      "total": 12761,
      "breakdown": {
        "comments": { "total": 4 },
        "reposts": { "total": 5548 },
        "likes_emoji": { "total": 7209 }
      }
    }
  },
  "4": {
    "actors": {
      "total": 87,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 92,
      "breakdown": {
        "social_media_original_posts": { "total": 11 },
        "social_media_reposts": { "total": 80 },
        "media_articles": { "total": 0 },
        "comments": { "total": 1 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 244201, "breakdown": 0 },
    "reactions": {
      "total": 171404,
      "breakdown": {
        "comments": { "total": 166 },
        "reposts": { "total": 158352 },
        "likes_emoji": { "total": 12886 }
      }
    }
  },
  "5": {
    "actors": {
      "total": 56,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 82,
      "breakdown": {
        "social_media_original_posts": { "total": 41 },
        "social_media_reposts": { "total": 41 },
        "media_articles": { "total": 0 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 5635, "breakdown": 0 },
    "reactions": {
      "total": 111906,
      "breakdown": {
        "comments": { "total": 0 },
        "reposts": { "total": 111898 },
        "likes_emoji": { "total": 8 }
      }
    }
  },
  "6": {
    "actors": {
      "total": 61,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 62,
      "breakdown": {
        "social_media_original_posts": { "total": 7 },
        "social_media_reposts": { "total": 55 },
        "media_articles": { "total": 0 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 132364, "breakdown": 0 },
    "reactions": {
      "total": 227988,
      "breakdown": {
        "comments": { "total": 87 },
        "reposts": { "total": 219119 },
        "likes_emoji": { "total": 8782 }
      }
    }
  },
  "7": {
    "actors": {
      "total": 58,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 62,
      "breakdown": {
        "social_media_original_posts": { "total": 59 },
        "social_media_reposts": { "total": 1 },
        "media_articles": { "total": 2 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 127764, "breakdown": 0 },
    "reactions": {
      "total": 885,
      "breakdown": {
        "comments": { "total": 10 },
        "reposts": { "total": 501 },
        "likes_emoji": { "total": 374 }
      }
    }
  },
  "8": {
    "actors": {
      "total": 51,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 52,
      "breakdown": {
        "social_media_original_posts": { "total": 8 },
        "social_media_reposts": { "total": 44 },
        "media_articles": { "total": 0 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 102633, "breakdown": 0 },
    "reactions": {
      "total": 19818,
      "breakdown": {
        "comments": { "total": 18 },
        "reposts": { "total": 17908 },
        "likes_emoji": { "total": 1892 }
      }
    }
  },
  "9": {
    "actors": {
      "total": 43,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 50,
      "breakdown": {
        "social_media_original_posts": { "total": 9 },
        "social_media_reposts": { "total": 41 },
        "media_articles": { "total": 0 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 345924, "breakdown": 0 },
    "reactions": {
      "total": 17877,
      "breakdown": {
        "comments": { "total": 92 },
        "reposts": { "total": 11430 },
        "likes_emoji": { "total": 6355 }
      }
    }
  },
  "10": {
    "actors": {
      "total": 40,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 43,
      "breakdown": {
        "social_media_original_posts": { "total": 34 },
        "social_media_reposts": { "total": 8 },
        "media_articles": { "total": 0 },
        "comments": { "total": 1 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 330247, "breakdown": 0 },
    "reactions": {
      "total": 23117,
      "breakdown": {
        "comments": { "total": 1076 },
        "reposts": { "total": 6957 },
        "likes_emoji": { "total": 15084 }
      }
    }
  },
  "11": {
    "actors": {
      "total": 35,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 39,
      "breakdown": {
        "social_media_original_posts": { "total": 32 },
        "social_media_reposts": { "total": 7 },
        "media_articles": { "total": 0 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 1424596, "breakdown": 0 },
    "reactions": {
      "total": 10745,
      "breakdown": {
        "comments": { "total": 370 },
        "reposts": { "total": 2028 },
        "likes_emoji": { "total": 8347 }
      }
    }
  },
  "12": {
    "actors": {
      "total": 7,
      "breakdown": {
        "inauthentic": { "total": 0 },
        "state_affiliated": { "total": 0 },
        "disinfo_spreaders": { "total": 0 },
        "influence_operations": { "total": 0 }
      }
    },
    "publications": {
      "total": 7,
      "breakdown": {
        "social_media_original_posts": { "total": 0 },
        "social_media_reposts": { "total": 7 },
        "media_articles": { "total": 0 },
        "comments": { "total": 0 },
        "videos": { "total": 0 },
        "ads": { "total": 0 },
        "chat_forum_messages": { "total": 0 }
      }
    },
    "views": { "total": 0, "breakdown": 0 },
    "reactions": {
      "total": 4540,
      "breakdown": {
        "comments": { "total": 0 },
        "reposts": { "total": 4540 },
        "likes_emoji": { "total": 0 }
      }
    }
  }
}

