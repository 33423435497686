import { useEffect, useState, useContext } from "react";
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import { getFlagEmoji } from "../../../utils/countries";
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import styles from '../NarrativePage.module.scss';

export const Countries = ({narrative, isShare, isClickable=true, title}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState(null);

  const {openModal, setCountriesFilter} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;

  const getNarrativeCountries = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/countries`).then(
      data => setCountries(data)
    )
  }

  useEffect(() => {
    getNarrativeCountries()
  }, [narrative])

  const topCountries = countries?.objects?.slice(0, 10);
  const otherCountries = countries?.objects?.slice(10);
  const otherCountriesCount = otherCountries?.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)



  return(
    <BreakdownsContainer
      title={title ? title : 'Countries'}
      onClick={() => openModal()}
      details={false}
    >
     {countries 
      ? (countries?.objects?.length > 0
      ? <div className={styles.breakdownsListMain}>
        {topCountries.map(country => {
          return(
            <div key={`countries_${country?.id}`} className={styles.listItem}>
              <div className={styles.info}>
                {/* <span>{getFlagEmoji(country.code)}</span> */}
                <span 
                  className={styles.title}
                  onClick={() => {
                    if (isClickable) {
                      setCountriesFilter([{value: country.id, label: country.name}])
                      openModal()
                    }
                  }}
                  >
                      {country.name}
                  </span>
              </div>
              <div className={styles.count}>{country.count}</div>
            </div>
          )
        })}
        {otherCountries?.length > 0 ? (
          <div className={styles.listItem}>
          <div className={styles.info}>
            <span 
              className={styles.title}
              onClick={() => {
                if (isClickable) {
                  setCountriesFilter(otherCountries.map(country => ({value: country.id, label: country.name})))
                  openModal()
                }
              }}
              >
                  {t('Other')}
              </span>
          </div>
          <div className={styles.count}> + {otherCountriesCount}</div>
        </div>
        ) : ''}
      </div> 
      : <div className={styles.noData}>{t('There is no data to display')}</div>
      ) : (<div className={styles.widdgetLoader}><LoaderSmall /></div>)}

    </BreakdownsContainer>
  )
}

