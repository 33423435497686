import { useMemo } from 'react';
import SimpleMDE from 'react-simplemde-editor';
import Markdown from 'react-markdown';
import { renderToString } from 'react-dom/server';
import 'easymde/dist/easymde.min.css';
import styles from './TextEditor.scss';

export const TextEditor = ({ id, value, onChange, isOpen }) => {
  const customOptions = useMemo(() => {
    return {
      toolbar: [
        'bold',
        'italic',
        'heading',
        '|',
        'quote',
        'unordered-list',
        'ordered-list',
        '|',
        'preview',
        '|',
        'guide',
      ],
      previewRender(text) {
        return renderToString(<Markdown>{text}</Markdown>);
      },
    };
  }, []);

  return (
    <>
      {isOpen ? (
        <div className={styles.textEditor}>
          <SimpleMDE
            id={id}
            value={value}
            onChange={(value) => onChange(value)}
            options={customOptions}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};
