import { useState } from 'react';
import {ReactComponent as OsavulLogo} from '../../../../assets/osavulLogo.svg';
import styles from '../../NarrativePage.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';


export const Header = ({ narrative }) => {
  const { t } = useTranslation();
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [title, setTitle] = useState(narrative?.name);
  const [newTitle, setNewTitle] = useState(narrative?.name);

  if (isEditingMode) {
    return (
      <div className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t('Report name')}</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (newTitle.trim().length > 0) {
                setTitle(newTitle);
              } else {
                setTitle(narrative?.name);
                setNewTitle(narrative?.name);
              }
              setIsEditingMode(false);
              
            }}
            onReset={() => {
              setNewTitle(title);
              setIsEditingMode(false);
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={newTitle}
                onChange={(e) => {
                  setNewTitle(e.target.value);
                }}
              />
            </div>
            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {

  return(
    <>
      <div className="widget-hover">
        <div className="widget-hover-controls">
          <button
            className="report-control-button"
            onClick={() => setIsEditingMode(true)}
          >
            <EditIcon />
          </button>
        </div>
        {narrative ? (
          <div className="report-section">
            <div className={styles.exportHeaderCard}>
              <div className={styles.logoContainer}>
                <OsavulLogo />
                <h2 className={styles.logoTitle}>OSAVUL</h2>
              </div>
              <h2 className={styles.headerInfoTitle}>{title}</h2>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}}
