import { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { ampli } from '../../ampli';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { Loader } from '../Loader/Loader';

const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const SentimentBar = ({
  data,
  tabList = ['messages', 'views', 'likes'],
  entity,
  entityType
}) => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [sentimentTabs, setSentimentTabs] = useState(tabList);
  const [sentimentBar, setSentimentBar] = useState(sentimentTabs[0]);

  const sentimentTabsMap = {
    messages: 'Messages',
    views: 'Views',
    likes: 'Reactions',
    posts: 'Posts',
    comments: 'Comments',
  };

  useEffect(() => {
    setSentimentBar(sentimentTabs[0]);
  }, [sentimentTabs]);

  if(entityType === 'case' && entity?.type === 'IMPACT_ASSESSMENT') {
    setSentimentTabs(['messages', 'likes']);
  }

  return (
    <div className="report-section">
      <h3 className="trend-chart-controlls">
        {t('Sentiments')}
        {sentimentTabs.map((tab) => {
          return (
            <button
              type="button"
              className={sentimentBar === tab ? 'active' : ''}
              onClick={() => {
                ampli.track({
                  event_type: 'Change metric in sentiment chart',
                  event_properties: {
                    user_id: currentUser?.id,
                    workspace_id: currentUser?.workspace_id,
                    entity_id: entity?.id,
                    entity_type: entityType,
                    metric: sentimentBar,
                  }
                });
                setSentimentBar(tab)
              }}
            >
              <span>{t(sentimentTabsMap[tab])}</span>
            </button>
          );
        })}
      </h3>

      {data ? <div className="card">
        <div className="card-body">
          <div className="sentiment-bar-container">
            {data?.positive[sentimentBar] === 0 ? (
              ''
            ) : (
              <span
                className="sentiment-bar-positive"
                style={{
                  width: `${
                    data?.positive[sentimentBar + '_share']
                  }%`,
                }}
              >
                {formatNumberSignificant(
                  data?.positive[sentimentBar],
                )}
              </span>
            )}
            {data?.neutral[sentimentBar] === 0 ? (
              ''
            ) : (
              <span
                className="sentiment-bar-neutral"
                style={{
                  width: `${
                    data?.neutral[sentimentBar + '_share']
                  }%`,
                }}
              >
                {formatNumberSignificant(
                  data?.neutral[sentimentBar],
                )}
              </span>
            )}
            {data?.negative[sentimentBar] === 0 ? (
              ''
            ) : (
              <span
                className="sentiment-bar-negative"
                style={{
                  width: `${
                    data?.negative[sentimentBar + '_share']
                  }%`,
                }}
              >
                {formatNumberSignificant(
                  data?.negative[sentimentBar],
                )}
              </span>
            )}
          </div>
          <div className="sentiment-bar-stats">
            <div className="sentiment-bar-stats-item">
              <span className="sentiment-bar-stats-color positive"></span>
              <span className="sentiment-bar-stats-data">{`${t('Positive')}: ${
                data?.positive[sentimentBar + '_share'] ===
                0
                  ? '0'
                  : data?.positive[
                      sentimentBar + '_share'
                    ].toPrecision(4)
              }%`}</span>
            </div>
            <div className="sentiment-bar-stats-item">
              <span className="sentiment-bar-stats-color neutral"></span>
              <span className="sentiment-bar-stats-data">{`${t('Neutral')}: ${
                data?.neutral[sentimentBar + '_share'] ===
                0
                  ? '0'
                  : data?.neutral[
                      sentimentBar + '_share'
                    ].toPrecision(4)
              }%`}</span>
            </div>
            <div className="sentiment-bar-stats-item">
              <span className="sentiment-bar-stats-color negative"></span>
              <span className="sentiment-bar-stats-data">{`${t('Negative')}: ${
                data?.negative[sentimentBar + '_share'] ===
                0
                  ? '0'
                  : data?.negative[
                      sentimentBar + '_share'
                    ].toPrecision(4)
              }%`}</span>
            </div>
          </div>
        </div>
      </div> : <Loader/>}
    </div>
  );
};
