import { useEffect, useState, useContext } from "react";
import * as d3 from 'd3';
import { API } from "../../../API";
import { ShareAPI } from "../../../API";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BreakdownsContext } from "./contexts/BreakdownsContext";
import {platformIconsMapping, platformNamesMapping} from '../../../utils/platforms';
import { BreakdownsContainer } from "../../../components/BreakdownsContainer/BreakdownsContainer";
import { LoaderSmall } from "../../../components/LoaderSmall/LoaderSmall";
import { decodeSourceName } from '../../../utils/decodeURI';
import { ReactComponent as VerifiedTwitter } from '../../SourcePage/assets/verifiedTwitter.svg';
import Tooltip from "../../../components/Tooltip/Tooltip";
import { cutString } from "../../../utils/cutString";
import styles from '../NarrativePage.module.scss';

const formatNumber = d3.format(",d");
const formatNumberSignificant = d3.format('.3~s',);
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
}

export const Compromised = ({isShare, narrative, details, isClickable=true, title}) => {
  const { t } = useTranslation();
  const [compromised, setCompromised] = useState(null);
  const [topCompromised, setTopCompromised] = useState(null);
  const {openModal, setTypesFilter, setSubtypesFilter, sourcesSubtypesFilter, sourcesTypesFilter} = useContext(BreakdownsContext);

  const api = isShare ? ShareAPI : API;

  const mapCompromisedEntityTypes = {
    INVOLVED_IN_INFLUENCE_OPERATIONS: {title: t('Influence operations'), tooltip: t('Account has been involved in influence operations')},
    HACKED_OR_STOLEN: {title: t('Hacked / Stolen'), tooltip: t('Account has been hacked or stolen')},
    SANCTIONS: {title: t('Sanctions'), tooltip: t('Sources are listed in official sanctions list of EU (and member countries), US, UK, and other countries')},
    DISINFORMATION: {title: t('Disinformation'), tooltip: t('Sources are frequently sharing disinformative content, which was autodetected by Osavul AI')}
  }


  const getNarrativeCompromised = () => {
    api.fetch('GET', `/API/v1/narratives/${narrative.id}/discreditations`).then(
      data => setCompromised(data)
    )
  }

  const getCompromisedTop = () => {
    const urlParams = new URLSearchParams();
      urlParams.set('size', '5');
      urlParams.set('page', 1);
      urlParams.set('narrative_id', narrative.id);

      urlParams.set('sorting', '-audience');
      
      urlParams.set('discreditated', true);

      api.fetch('GET', `/API/v1/sources?${urlParams.toString()}`).then(
        (data) => {
          setTopCompromised(data);
        },
      );
  }

  useEffect(() => {
    getNarrativeCompromised()
    getCompromisedTop()
  }, [narrative])

  const totalSources = compromised?.objects?.reduce((accumulator, currentValue) => accumulator + currentValue.sources, 0);
  const totalAudience = compromised?.objects?.reduce((accumulator, currentValue) => accumulator + currentValue.audience, 0);

  return(
    <BreakdownsContainer 
      title={title ? title : 'Compromised actors'}
      details={details}
      onClick={() => {
        setTypesFilter({value: 'discreditated', label: 'Compromised accounts', bool: true})
        openModal()
      }}
    >
     {compromised 
      ? ( compromised.objects?.length > 0
      ? <div className={styles.widgetRow}>
        <div className={styles.widgetSummary}>
          <div className={styles.titleWrapper}>
            <h5>{t('Total actors')}</h5>
          </div>
          
          <div> 
            <h2>{formatNumber(totalSources)}</h2>
            <p>{t('Total audience')}: </p>
            <p>{formatWithCustomGigaBillion(totalAudience)}</p>
          </div>
        </div>
        <div className={styles.widgetStats}>
          <div className={styles.titleWrapper}>
            <h5>{t('Split by type')}</h5>
          </div>
          
          <div className={styles.breakdownsListMain}>
            {compromised.objects.map((sourceType, index) => {
              return(
                <div key={`sourceType_${index}`} className={styles.listItem}>
                  <div className={styles.info}>
                    <span 
                      className={styles.title}
                      onClick={() => {
                        if(isClickable) {
                          setTypesFilter({value: 'discreditated', label: 'Compromised accounts', bool: true})
                          setSubtypesFilter(sourceType?.entity_type)
                          openModal()
                        }
                      }}
                      >
                        <Tooltip content={mapCompromisedEntityTypes[sourceType?.entity_type]?.tooltip} position='right'>
                          {mapCompromisedEntityTypes[sourceType?.entity_type]?.title}
                        </Tooltip>
                      </span>
                  </div>
                  <div className={styles.count}>{formatNumber(sourceType?.sources)}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.widgetTop}>
          <div className={styles.titleWrapper}>
            <h5>{t('Top compromised actors')}</h5>
          </div>
        
        {topCompromised ? <div className={styles.breakdownsListMain}>
          {topCompromised?.objects?.map((source, index) => {
            return(
              <div key={`source_${index}`} className={styles.listItem}>
                <div className={styles.info}>
                  <span>{platformIconsMapping[source.source_type]}</span>
                  <span className={styles.title}>
                  {isShare || !isClickable ? <Tooltip content={source.name} position='bottom'>{cutString(decodeSourceName(source.name), 30)} </Tooltip>
                            : <Link to={`/sources/${source.id}`}>
                                <Tooltip content={source.name} position='bottom'>{cutString(decodeSourceName(source.name), 30)}</Tooltip>
                                {source?.is_verified ? (
                                  <span className={styles.verified}>
                                      <VerifiedTwitter/>
                                  </span>
                                      ) : (
                                        ''
                                  )}
                              </Link>}
                  </span>
                </div>
                <div className={styles.details}>{mapCompromisedEntityTypes[source?.discreditations[0].entity_type]?.title}</div>
              </div>
            )
          })}
        </div> : <div className={styles.widdgetLoader}><LoaderSmall /></div>}
      </div>
        
      </div> 
      : <div className={styles.noData}>{t('No compromised actors identified')}</div>
      ) :(<div className={styles.widdgetLoader}><LoaderSmall /></div>)}

    </BreakdownsContainer>
  )
}

