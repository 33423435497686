export const trendData = {
  "1": {
    "objects": [{
      "id": 1,
      "name": "Allegations of misinformation regarding Kamala Harris's McDonald's employment circulate",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-29",
          "engagement": 3115043,
          "engagement_share": 46.79127444153169,
          "impressions": 726807,
          "impressions_share": 50.21452199938649,
          "narrative_share": 100,
          "publications": 432,
          "publications_share": 47.893569844789354,
          "score": 0,
          "score_share": 0,
          "sources": 332,
          "sources_share": 52.614896988906494,
          "views": 765436,
          "views_share": 50.551523276778696,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-31",
          "engagement": 217242,
          "engagement_share": 3.2632069741018754,
          "impressions": 580881,
          "impressions_share": 40.13260983111833,
          "narrative_share": 100,
          "publications": 98,
          "publications_share": 10.864745011086475,
          "score": 0,
          "score_share": 0,
          "sources": 90,
          "sources_share": 14.263074484944532,
          "views": 603963,
          "views_share": 39.887397055812755,
          "manipulation_index": 0.004009111151968295
        },
        {
          "date_publicated": "2024-08-30",
          "engagement": 3316812,
          "engagement_share": 49.822060421947825,
          "impressions": 136702,
          "impressions_share": 9.444633288287168,
          "narrative_share": 100,
          "publications": 356,
          "publications_share": 39.467849223946786,
          "score": 0,
          "score_share": 0,
          "sources": 292,
          "sources_share": 46.275752773375594,
          "views": 141633,
          "views_share": 9.353837415878006,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-11",
          "engagement": 4597,
          "engagement_share": 0.06905185212779444,
          "impressions": 3014,
          "impressions_share": 0.20823488120801104,
          "narrative_share": 100,
          "publications": 9,
          "publications_share": 0.9977827050997783,
          "score": 0,
          "score_share": 0,
          "sources": 9,
          "sources_share": 1.4263074484944533,
          "views": 3138,
          "views_share": 0.2072422515305415,
          "manipulation_index": 0.2709376042709376
        },
        {
          "date_publicated": "2024-08-10",
          "engagement": 3622,
          "engagement_share": 0.05440631029081389,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 0.7760532150776053,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 1.109350237717908,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.6096096096096096
        }
      ]
    }]
  },
  "2": {
    "objects": [{
      "id": 2,
      "name": "McDonald's confirms Vice President Harris's claim of past employment is unfounded",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-09",
          "engagement": 2544,
          "engagement_share": 6.003398149896167,
          "impressions": 6387,
          "impressions_share": 20.870502891873347,
          "narrative_share": 100,
          "publications": 10,
          "publications_share": 7.092198581560283,
          "score": 0,
          "score_share": 0,
          "sources": 10,
          "sources_share": 9.523809523809524,
          "views": 6436,
          "views_share": 19.96711444792604,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-30",
          "engagement": 15210,
          "engagement_share": 35.89295827827072,
          "impressions": 3020,
          "impressions_share": 9.86831356402967,
          "narrative_share": 100,
          "publications": 36,
          "publications_share": 25.53191489361702,
          "score": 0,
          "score_share": 0,
          "sources": 33,
          "sources_share": 31.428571428571427,
          "views": 3180,
          "views_share": 9.865665622188441,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-31",
          "engagement": 4429,
          "engagement_share": 10.451670757032282,
          "impressions": 153,
          "impressions_share": 0.4999509851975297,
          "narrative_share": 100,
          "publications": 27,
          "publications_share": 19.148936170212767,
          "score": 0,
          "score_share": 0,
          "sources": 17,
          "sources_share": 16.19047619047619,
          "views": 153,
          "views_share": 0.4746688176713306,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-29",
          "engagement": 13520,
          "engagement_share": 31.904851802907306,
          "impressions": 17152,
          "impressions_share": 56.04679279809169,
          "narrative_share": 100,
          "publications": 28,
          "publications_share": 19.858156028368793,
          "score": 0,
          "score_share": 0,
          "sources": 28,
          "sources_share": 26.666666666666668,
          "views": 18453,
          "views_share": 57.24878230385009,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-10",
          "engagement": 1424,
          "engagement_share": 3.3603926750991127,
          "impressions": 472,
          "impressions_share": 1.5423324510668888,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 4.964539007092198,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 6.666666666666667,
          "views": 477,
          "views_share": 1.479849843328266,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-12",
          "engagement": 189,
          "engagement_share": 0.446007173872003,
          "impressions": 3163,
          "impressions_share": 10.33558801424697,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 1.4184397163120568,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 1.9047619047619047,
          "views": 3271,
          "views_share": 10.147984984332826,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-25",
          "engagement": 157,
          "engagement_share": 0.3704927317349443,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.7092198581560284,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.9523809523809523,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-15",
          "engagement": 147,
          "engagement_share": 0.3468944685671135,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 2.8368794326241136,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 1.9047619047619047,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-16",
          "engagement": 3363,
          "engagement_share": 7.936095903341514,
          "impressions": 14,
          "impressions_share": 0.045747148972322974,
          "narrative_share": 100,
          "publications": 14,
          "publications_share": 9.929078014184396,
          "score": 0,
          "score_share": 0,
          "sources": 13,
          "sources_share": 12.380952380952381,
          "views": 15,
          "views_share": 0.04653615859522849,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-26",
          "engagement": 1,
          "engagement_share": 0.0023598263167830848,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.7092198581560284,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.9523809523809523,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-17",
          "engagement": 695,
          "engagement_share": 1.6400792901642438,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 2.127659574468085,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 2.857142857142857,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-18",
          "engagement": 80,
          "engagement_share": 0.18878610534264678,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.7092198581560284,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.9523809523809523,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-19",
          "engagement": 104,
          "engagement_share": 0.24542193694544082,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 0.7092198581560284,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 0.9523809523809523,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-11",
          "engagement": 513,
          "engagement_share": 1.2105909005097224,
          "impressions": 242,
          "impressions_share": 0.7907721465215829,
          "narrative_share": 100,
          "publications": 6,
          "publications_share": 4.25531914893617,
          "score": 0,
          "score_share": 0,
          "sources": 6,
          "sources_share": 5.714285714285714,
          "views": 248,
          "views_share": 0.7693978221077777,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "3": {
    "objects": [{
      "id": 3,
      "name": "McDonald's celebrated Ukrainian Independence Day with a special burger giveaway",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-22",
          "engagement": 10491,
          "engagement_share": 82.21142543687799,
          "impressions": 630594,
          "impressions_share": 75.3432055457715,
          "narrative_share": 100,
          "publications": 48,
          "publications_share": 73.84615384615384,
          "score": 0,
          "score_share": 0,
          "sources": 48,
          "sources_share": 73.84615384615384,
          "views": 636379,
          "views_share": 75.38508193245208,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-21",
          "engagement": 171,
          "engagement_share": 1.3400203745787949,
          "impressions": 20581,
          "impressions_share": 2.4590124760741827,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 3.076923076923077,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.076923076923077,
          "views": 20677,
          "views_share": 2.449385254883193,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 2099,
          "engagement_share": 16.448554188543216,
          "impressions": 185787,
          "impressions_share": 22.197781978154325,
          "narrative_share": 100,
          "publications": 15,
          "publications_share": 23.076923076923077,
          "score": 0,
          "score_share": 0,
          "sources": 15,
          "sources_share": 23.076923076923077,
          "views": 187115,
          "views_share": 22.165532812664733,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "4": {
    "objects": [{
      "id": 4,
      "name": "Calls for a McDonald's boycott emerge over allegations of association with child harm",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-11",
          "engagement": 92777,
          "engagement_share": 54.12767496674523,
          "impressions": 86134,
          "impressions_share": 37.2366686120658,
          "narrative_share": 100,
          "publications": 43,
          "publications_share": 46.73913043478261,
          "score": 0,
          "score_share": 0,
          "sources": 43,
          "sources_share": 49.42528735632184,
          "views": 96687,
          "views_share": 39.593203959033744,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-12",
          "engagement": 34537,
          "engagement_share": 20.149471424237476,
          "impressions": 9,
          "impressions_share": 0.0038907982621101097,
          "narrative_share": 100,
          "publications": 19,
          "publications_share": 20.652173913043477,
          "score": 0,
          "score_share": 0,
          "sources": 19,
          "sources_share": 21.839080459770116,
          "views": 9,
          "views_share": 0.0036854885934128035,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-13",
          "engagement": 459,
          "engagement_share": 0.26778838300156355,
          "impressions": 10894,
          "impressions_share": 4.709595140825281,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 3.260869565217391,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 3.4482758620689653,
          "views": 11289,
          "views_share": 4.622831192337459,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-26",
          "engagement": 1768,
          "engagement_share": 1.0314811789689855,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.0869565217391304,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.1494252873563218,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 3588,
          "engagement_share": 2.093300039672353,
          "impressions": 2382,
          "impressions_share": 1.0297646067051422,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 3.260869565217391,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 3.4482758620689653,
          "views": 2451,
          "views_share": 1.0036813936060869,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-24",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 10,
          "impressions_share": 0.004323109180122344,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.0869565217391304,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.1494252873563218,
          "views": 10,
          "views_share": 0.004094987326014226,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-22",
          "engagement": 10878,
          "engagement_share": 6.346409652050127,
          "impressions": 124997,
          "impressions_share": 54.037567818775265,
          "narrative_share": 100,
          "publications": 8,
          "publications_share": 8.695652173913043,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 8.045977011494253,
          "views": 126740,
          "views_share": 51.8998693699043,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-01",
          "engagement": 27143,
          "engagement_share": 15.835686448390936,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 5,
          "publications_share": 5.434782608695652,
          "score": 0,
          "score_share": 0,
          "sources": 5,
          "sources_share": 5.747126436781609,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-02",
          "engagement": 116,
          "engagement_share": 0.06767636694592892,
          "impressions": 4620,
          "impressions_share": 1.997276441216523,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 4.3478260869565215,
          "score": 0,
          "score_share": 0,
          "sources": 4,
          "sources_share": 4.597701149425287,
          "views": 4662,
          "views_share": 1.909083091387832,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-03",
          "engagement": 137,
          "engagement_share": 0.07992812303096777,
          "impressions": 2227,
          "impressions_share": 0.962756414413246,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 3.260869565217391,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 3.4482758620689653,
          "views": 2311,
          "views_share": 0.9463515710418876,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-04",
          "engagement": 1,
          "engagement_share": 0.0005834169564304217,
          "impressions": 42,
          "impressions_share": 0.018157058556513846,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 2.1739130434782608,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 2.2988505747126435,
          "views": 42,
          "views_share": 0.01719894676925975,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "5": {
    "objects": [{
      "id": 5,
      "name": "A criminal case saw a sentencing delay request due to the defendant's employment at McDonald's",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-15",
          "engagement": 90477,
          "engagement_share": 80.85089271352743,
          "impressions": 25,
          "impressions_share": 0.444286475919673,
          "narrative_share": 100,
          "publications": 58,
          "publications_share": 70.73170731707317,
          "score": 0,
          "score_share": 0,
          "sources": 35,
          "sources_share": 62.5,
          "views": 25,
          "views_share": 0.44365572315882873,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-16",
          "engagement": 21418,
          "engagement_share": 19.139277607992423,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 11,
          "publications_share": 13.414634146341463,
          "score": 0,
          "score_share": 0,
          "sources": 10,
          "sources_share": 17.857142857142858,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-06",
          "engagement": 1,
          "engagement_share": 0.0008936071345593623,
          "impressions": 1203,
          "impressions_share": 21.379065221254663,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 4.878048780487805,
          "score": 0,
          "score_share": 0,
          "sources": 4,
          "sources_share": 7.142857142857143,
          "views": 1204,
          "views_share": 21.366459627329192,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-04",
          "engagement": 7,
          "engagement_share": 0.006255249941915536,
          "impressions": 1452,
          "impressions_share": 25.804158521414607,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 4.878048780487805,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 5.357142857142857,
          "views": 1457,
          "views_share": 25.856255545696538,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-05",
          "engagement": 3,
          "engagement_share": 0.002680821403678087,
          "impressions": 2947,
          "impressions_share": 52.372489781411055,
          "narrative_share": 100,
          "publications": 5,
          "publications_share": 6.097560975609756,
          "score": 0,
          "score_share": 0,
          "sources": 5,
          "sources_share": 8.928571428571429,
          "views": 2949,
          "views_share": 52.33362910381544,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "6": {
    "objects": [{
      "id": 6,
      "name": "Bill Gates is linked to agriculture, owning land used for McDonald's potato supply",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-25",
          "engagement": 122303,
          "engagement_share": 53.6444900608804,
          "impressions": 3781,
          "impressions_share": 3.0595070479519673,
          "narrative_share": 100,
          "publications": 35,
          "publications_share": 56.45161290322581,
          "score": 0,
          "score_share": 0,
          "sources": 34,
          "sources_share": 55.73770491803279,
          "views": 3830,
          "views_share": 2.893536006769212,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-24",
          "engagement": 101531,
          "engagement_share": 44.53348421846764,
          "impressions": 119801,
          "impressions_share": 96.94049295204803,
          "narrative_share": 100,
          "publications": 26,
          "publications_share": 41.935483870967744,
          "score": 0,
          "score_share": 0,
          "sources": 26,
          "sources_share": 42.622950819672134,
          "views": 128534,
          "views_share": 97.10646399323079,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-26",
          "engagement": 4154,
          "engagement_share": 1.822025720651964,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.6129032258064515,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.639344262295082,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "7": {
    "objects": [{
      "id": 7,
      "name": "McDonald's France considers removing 'Curry' sauce from its menu for four years",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-12",
          "engagement": 565,
          "engagement_share": 63.84180790960452,
          "impressions": 80654,
          "impressions_share": 63.3126619043881,
          "narrative_share": 100,
          "publications": 48,
          "publications_share": 77.41935483870968,
          "score": 0,
          "score_share": 0,
          "sources": 47,
          "sources_share": 81.03448275862068,
          "views": 80754,
          "views_share": 63.20559782098243,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-11",
          "engagement": 108,
          "engagement_share": 12.203389830508474,
          "impressions": 26861,
          "impressions_share": 21.085642515111076,
          "narrative_share": 100,
          "publications": 4,
          "publications_share": 6.451612903225806,
          "score": 0,
          "score_share": 0,
          "sources": 4,
          "sources_share": 6.896551724137931,
          "views": 26953,
          "views_share": 21.095926865157633,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-13",
          "engagement": 210,
          "engagement_share": 23.728813559322035,
          "impressions": 19226,
          "impressions_share": 15.09223643928095,
          "narrative_share": 100,
          "publications": 9,
          "publications_share": 14.516129032258064,
          "score": 0,
          "score_share": 0,
          "sources": 9,
          "sources_share": 15.517241379310345,
          "views": 19407,
          "views_share": 15.189724805109421,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-14",
          "engagement": 2,
          "engagement_share": 0.22598870056497175,
          "impressions": 649,
          "impressions_share": 0.509459141219876,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.6129032258064515,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.7241379310344827,
          "views": 650,
          "views_share": 0.5087505087505088,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "8": {
    "objects": [{
      "id": 8,
      "name": "Allegations of dangerous chemicals in fast food spark concern among consumers",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-24",
          "engagement": 2744,
          "engagement_share": 13.845998587143,
          "impressions": 5641,
          "impressions_share": 5.599507648325905,
          "narrative_share": 100,
          "publications": 9,
          "publications_share": 17.307692307692307,
          "score": 0,
          "score_share": 0,
          "sources": 9,
          "sources_share": 17.647058823529413,
          "views": 5680,
          "views_share": 5.534282345834186,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-17",
          "engagement": 12996,
          "engagement_share": 65.57674841053587,
          "impressions": 49752,
          "impressions_share": 49.38604937413764,
          "narrative_share": 100,
          "publications": 16,
          "publications_share": 30.76923076923077,
          "score": 0,
          "score_share": 0,
          "sources": 16,
          "sources_share": 31.372549019607842,
          "views": 51359,
          "views_share": 50.041409683045416,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-19",
          "engagement": 129,
          "engagement_share": 0.6509234029669997,
          "impressions": 5956,
          "impressions_share": 5.912190667156371,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 3.8461538461538463,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.9215686274509802,
          "views": 5979,
          "views_share": 5.825611645377218,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-22",
          "engagement": 20,
          "engagement_share": 0.10091835704914724,
          "impressions": 2881,
          "impressions_share": 2.8598088166684863,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 5.769230769230769,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 5.882352941176471,
          "views": 2883,
          "views_share": 2.809038028704218,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-18",
          "engagement": 3482,
          "engagement_share": 17.569885962256535,
          "impressions": 4894,
          "impressions_share": 4.858002203670799,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 13.461538461538462,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 13.72549019607843,
          "views": 4928,
          "views_share": 4.801574542301209,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-16",
          "engagement": 77,
          "engagement_share": 0.3885356746392169,
          "impressions": 2991,
          "impressions_share": 2.968999712133094,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 5.769230769230769,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 5.882352941176471,
          "views": 3021,
          "views_share": 2.9434977054163864,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 13,
          "impressions_share": 0.012904378554908131,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.9230769230769231,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.9607843137254901,
          "views": 13,
          "views_share": 0.01266649128447965,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-13",
          "engagement": 51,
          "engagement_share": 0.25734181047532545,
          "impressions": 12300,
          "impressions_share": 12.209527401951538,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 3.8461538461538463,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.9215686274509802,
          "views": 12301,
          "views_share": 11.985423791568014,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-14",
          "engagement": 22,
          "engagement_share": 0.11101019275406196,
          "impressions": 2425,
          "impressions_share": 2.4071629227424785,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.9230769230769231,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.9607843137254901,
          "views": 2443,
          "views_share": 2.3803260159987527,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-26",
          "engagement": 67,
          "engagement_share": 0.33807649611464324,
          "impressions": 4730,
          "impressions_share": 4.695208504978112,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 5.769230769230769,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 5.882352941176471,
          "views": 4771,
          "views_share": 4.648602301404032,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-25",
          "engagement": 78,
          "engagement_share": 0.39358159249167424,
          "impressions": 0,
          "impressions_share": 0.0,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.9230769230769231,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.9607843137254901,
          "views": 0,
          "views_share": 0.0,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-09",
          "engagement": 28,
          "engagement_share": 0.14128569986880612,
          "impressions": 7321,
          "impressions_share": 7.267150415421725,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 3.8461538461538463,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 3.9215686274509802,
          "views": 7321,
          "views_share": 7.1331832841288865,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-06",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 36,
          "impressions_share": 0.03573520215205329,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.9230769230769231,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.9607843137254901,
          "views": 36,
          "views_share": 0.03507643740317442,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-05",
          "engagement": 124,
          "engagement_share": 0.6256938137047129,
          "impressions": 1801,
          "impressions_share": 1.7877527521068879,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 1.9230769230769231,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 1.9607843137254901,
          "views": 1898,
          "views_share": 1.849307727534029,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "9": {
    "objects": [{
      "id": 9,
      "name": "Bill Clinton humorously anticipates breaking a McDonald's-related record if Hillary becomes president",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-22",
          "engagement": 17877,
          "engagement_share": 100.0,
          "impressions": 338939,
          "impressions_share": 99.81447069667726,
          "narrative_share": 100,
          "publications": 49,
          "publications_share": 98.0,
          "score": 0,
          "score_share": 0,
          "sources": 42,
          "sources_share": 97.67441860465117,
          "views": 345294,
          "views_share": 99.81787907170362,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 630,
          "impressions_share": 0.18552930332274148,
          "narrative_share": 100,
          "publications": 1,
          "publications_share": 2.0,
          "score": 0,
          "score_share": 0,
          "sources": 1,
          "sources_share": 2.3255813953488373,
          "views": 630,
          "views_share": 0.1821209282963888,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "10": {
    "objects": [{
      "id": 10,
      "name": "Protests in D\u00fczce target McDonald's for its support to the Israeli military",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-04",
          "engagement": 20615,
          "engagement_share": 89.17679629709737,
          "impressions": 277735,
          "impressions_share": 88.12424047239048,
          "narrative_share": 100,
          "publications": 27,
          "publications_share": 62.7906976744186,
          "score": 0,
          "score_share": 0,
          "sources": 27,
          "sources_share": 67.5,
          "views": 291107,
          "views_share": 88.14826478363165,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-07",
          "engagement": 425,
          "engagement_share": 1.838473850413116,
          "impressions": 1217,
          "impressions_share": 0.38614938936359916,
          "narrative_share": 100,
          "publications": 5,
          "publications_share": 11.627906976744185,
          "score": 0,
          "score_share": 0,
          "sources": 5,
          "sources_share": 12.5,
          "views": 1303,
          "views_share": 0.39455316778047944,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-05",
          "engagement": 1855,
          "engagement_share": 8.024397629450188,
          "impressions": 35779,
          "impressions_share": 11.352538210386372,
          "narrative_share": 100,
          "publications": 6,
          "publications_share": 13.953488372093023,
          "score": 0,
          "score_share": 0,
          "sources": 6,
          "sources_share": 15.0,
          "views": 37403,
          "views_share": 11.325765260547408,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-03",
          "engagement": 2,
          "engagement_share": 0.008651641649002898,
          "impressions": 271,
          "impressions_share": 0.08598725104152455,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 4.651162790697675,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 5.0,
          "views": 273,
          "views_share": 0.08266539892868065,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-08",
          "engagement": 220,
          "engagement_share": 0.9516805813903189,
          "impressions": 161,
          "impressions_share": 0.051084676818027495,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 6.976744186046512,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 7.5,
          "views": 161,
          "views_share": 0.048751389111786024,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "11": {
    "objects": [{
      "id": 11,
      "name": "McDonald's unexpected promotion of Solana MemeCoin on Instagram raises eyebrows",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-29",
          "engagement": 6,
          "engagement_share": 0.05583992554676594,
          "impressions": 1720,
          "impressions_share": 0.1214475703071988,
          "narrative_share": 100,
          "publications": 3,
          "publications_share": 7.6923076923076925,
          "score": 0,
          "score_share": 0,
          "sources": 3,
          "sources_share": 8.571428571428571,
          "views": 1723,
          "views_share": 0.1209465701153169,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-21",
          "engagement": 9753,
          "engagement_share": 90.76779897626803,
          "impressions": 1316987,
          "impressions_share": 92.99120423032956,
          "narrative_share": 100,
          "publications": 16,
          "publications_share": 41.02564102564103,
          "score": 0,
          "score_share": 0,
          "sources": 15,
          "sources_share": 42.857142857142854,
          "views": 1324659,
          "views_share": 92.98488834729285,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-22",
          "engagement": 986,
          "engagement_share": 9.176361098185202,
          "impressions": 96722,
          "impressions_share": 6.829448776309816,
          "narrative_share": 100,
          "publications": 18,
          "publications_share": 46.15384615384615,
          "score": 0,
          "score_share": 0,
          "sources": 17,
          "sources_share": 48.57142857142857,
          "views": 97394,
          "views_share": 6.8366049041272055,
          "manipulation_index": 0.0
        },
        {
          "date_publicated": "2024-08-23",
          "engagement": 0,
          "engagement_share": 0.0,
          "impressions": 820,
          "impressions_share": 0.057899423053431986,
          "narrative_share": 100,
          "publications": 2,
          "publications_share": 5.128205128205129,
          "score": 0,
          "score_share": 0,
          "sources": 2,
          "sources_share": 5.714285714285714,
          "views": 820,
          "views_share": 0.05756017846463138,
          "manipulation_index": 0.0
        }
      ]
    }]
  },
  "12": {
    "objects": [{
      "id": 12,
      "name": "A significant gathering of Muslim immigrants occurred outside a Birmingham McDonald's, involving weapons",
      "aggregation": "DAY",
      "stats": [
        {
          "date_publicated": "2024-08-05",
          "engagement": 4540,
          "engagement_share": 100.0,
          "impressions": 0,
          "impressions_share": 0,
          "narrative_share": 100,
          "publications": 7,
          "publications_share": 100.0,
          "score": 0,
          "score_share": 0,
          "sources": 7,
          "sources_share": 100.0,
          "views": 0,
          "views_share": 0,
          "manipulation_index": 0.0
        }
      ]
    }]
  }
}

