import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StoryCard } from "../StoryCard/StoryCard";
import { subDays } from 'date-fns';
import { ReactComponent as ListIcon } from "../../assets/list.svg";
import { ReactComponent as MessageIcon} from "../../pages/NarrativePage/assets/messages.svg";
import { ReactComponent as ArrowIcon} from '../../pages/NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon} from '../../pages/NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon} from '../../assets/sort.svg';
import { Calendar } from "../../pages/SearchPage/Calendar/Calendar";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { Radiobutton } from "../DropdownMenu/Radiobutton";
import { SortingOrder } from "../DropdownMenu/SortingOrder";
import { Filter } from "./Filter/Filter";

import styles from "./StoriesFeed.module.scss";


export const StoriesFeed = ({ report, stories=[], opinions=[], onTabChange, onFilterChange, onStoryChange, onOpinionChange }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('stories');
  const [activeStory, setActiveStory] = useState(stories[0]);
  const [activeOpinion, setActiveOpinion] = useState(opinions[0]);
  const [startDate, setStartDate] = useState(new Date(report?.start_date) || subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date(report?.end_date) || new Date());
  const [cursor, setCursor] = useState(null);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sorting, setSorting] = useState({ fieldName: 'date', isAscending: false });
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [keywordsRef, setKeywordsRef] = useState(null);

  useEffect(() => {
    onStoryChange(activeStory);
    onOpinionChange(activeOpinion);
  }, [activeStory, activeOpinion]);

  useEffect(() => {
    setActiveStory(stories[0]);
    setActiveOpinion(opinions[0]);
    onTabChange(activeTab);
  }, [activeTab]);

  const initStartDate = null;
  const initEndDate = null;

  const sortingMenu = [
    {
      name: t('Date'),
      value: 'DATE',
    },
    {
      name: t('Rating'),
      value: 'RATING',
    }
  ]

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon/>,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon/>,
    },
  ]

  return (
    <div className={styles.mainContainer}>
      <div className={styles.filtersContainer}>
        <div className={styles.calendarWrapper}>
          <Calendar
            onChange={(start, end) => {
              setStartDate(start)
              setEndDate(end)
              setCursor(null)
              setPage(1)
              if(keywordsRef?.current) {
                setSearchQuery(keywordsRef?.current)
              }
            }}
            startDate={startDate}
            endDate={endDate}
            initStartDate={startDate}
            initEndDate={endDate}
            overrideStartDate={startDate}
            overrideEndDate={endDate}
          />
        </div>
        <div className={styles.filters}>
          <DropdownMenu  
            isOpen={dropdownMenu}
            header={t('Sort by')}
            onClick={() => setDropdownMenu(!dropdownMenu)}
            // buttonName={t('Sort')}
            icon={<SortIcon/>}
            menuClassName='only-icon'
          >
            <Radiobutton 
              itemsList={sortingMenu}
              current={sorting.fieldName}
              onChange={(value) => setSorting({...sorting, fieldName: value})}
            />

            <SortingOrder 
              itemsList={sortingOrder}
              onClick={(value) => setSorting({...sorting, isAscending: value})}
              current={sorting.isAscending}
            />
          </DropdownMenu>

          <Filter
            onlyIcon={true}
            className="onlyIcon"
          />
        </div>
        

      </div>
      <div className={styles.feedMainContainer}>
        <div className={styles.controls}>
          <div
            className={activeTab === 'stories' ? `${styles.tab} ${styles.active}` : styles.tab}
            onClick={() => setActiveTab('stories')}
          >
            <ListIcon />
            {t('Stories')}
          </div>
          {/* <div
            className={activeTab === 'opinions' ? `${styles.tab} ${styles.active}` : styles.tab}
            onClick={() => setActiveTab('opinions')}
          >
            <MessageIcon />
            {t('Opinions')}
          </div> */}
        </div>
        
        {activeTab === 'stories' ? (
          <div className={styles.feedContainer}>
            {stories?.map((story) => (
              <StoryCard
                key={story?.id}
                story={story}
                isActive={activeStory?.id === story?.id}
                onChange={(story) => setActiveStory(story)}
              />
            ))}
          </div>
        ) : (
          ''
        )}
        {activeTab === 'opinions' ? (
          <div className={styles.feedContainer}>
            {opinions?.map((opinion) => (
              <StoryCard
                key={opinion?.id}
                story={opinion}
                isActive={activeOpinion?.id === opinion?.id}
                onChange={(opinion) => setActiveOpinion(opinion)}
                isStory={false}
              />
            ))}
          </div>
        ) : (
          ''
        )}
        
      </div>
    </div>
  );
};