import { Tooltip } from 'react-tooltip';
import { decodeSourceName } from '../../utils/decodeURI';
import styles from './AvatarGroup.module.scss';

export const AvatarGroup = ({ sources, maxAvatars = 3 }) => {
  const makeAvatar = (sourceName) => {
    return sourceName.charAt(0).toUpperCase();
  };
  
  return (
    <div className={styles.avatarGroup}>
      {sources.slice(0, maxAvatars).map((source) => (
        <>
          {source?.source_icon ? (
            <div
              key={source.id}
              className={styles.avatar}
              data-tooltip-id={`avatar-` + source.id}
              data-tooltip-content={decodeSourceName(source.name)}
              data-tooltip-place="bottom-start"
            >
              <img
                src={source.source_icon}
                alt={decodeSourceName(source.name)}
              />
            </div>
          ) : (
            <div
              key={source.id}
              className={styles.avatarLetter}
              data-tooltip-id={`avatar-` + source.id}
              data-tooltip-content={decodeSourceName(source.name)}
              data-tooltip-place="bottom-start"
            >
              {makeAvatar(decodeSourceName(source.name))}
            </div>
          )}
          <Tooltip id={`avatar-` + source.id} />
        </>
      ))}
      {/* {sources.length > maxAvatars && (
        <div className={styles.more}>
          <span>+{sources.length - maxAvatars}</span>
        </div>
      )} */}
    </div>
  );
};
