export const actorsNegativeData = {
  "1": {
    "objects": [
      {
        "id": 202758,
        "name": "Rachel",
        "url": "https://twitter.com/GoldnemovesGold",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 7,
        "audience": 13555.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1160112,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2038016,
        "name": "Ultra Ultra Nuclear TRUMP 2024 Patti the Patriot",
        "url": "https://twitter.com/Patti55866878",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 5,
        "audience": 11135.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2056083,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 121572,
        "name": "\u201cDems Are Desperate\u201d Tenorman \ud83c\uddfa\ud83c\uddf8",
        "url": "https://twitter.com/thetenorman",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 5,
        "audience": 5229.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1027219,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 124482,
        "name": "SCW",
        "url": "https://twitter.com/SCWOFTX",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 4,
        "audience": 2485.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1031068,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 85351,
        "name": "Michael E. Dehn",
        "url": "https://twitter.com/mediapython",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 4,
        "audience": 2998.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1091737,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "2": {
    "objects": [
      {
        "id": 2040435,
        "name": "\ud83c\udfdb \ud83c\udf39PeriklestheGREAT \ud83c\udf39 \ud83c\udfdb \"Do Unto Others\"",
        "url": "https://twitter.com/PeriklesGREAT",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 153943.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2058502,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 180720,
        "name": "Make L.A. Great Again \ud83c\uddfa\ud83c\uddf8",
        "url": "https://twitter.com/lalovestrump",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 18879.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1142268,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 204529,
        "name": "C-Reason\ud83c\uddfa\ud83c\uddf8",
        "url": "https://twitter.com/CreasonJana",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 96488.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1161883,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 210050,
        "name": "NahBabyNah #Trump",
        "url": "https://twitter.com/NahBabyNah",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 158584.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1167404,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2040876,
        "name": "Americat \ud83c\uddfa\ud83c\uddf8",
        "url": "https://twitter.com/catmurphy209",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 39504.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2058943,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "3": { "objects": [], "page": 1, "size": 5, "total": 0 },
  "4": {
    "objects": [
      {
        "id": 2040326,
        "name": "THiS LiL\u2019 MiSS Can\u2019t Be Wrong",
        "url": "https://twitter.com/l42022425",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 4,
        "audience": 107439.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2058393,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 59183,
        "name": "Beyond Massage",
        "url": "https://twitter.com/bbeyondm1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 2443.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1066095,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 51792,
        "name": "David Johnson",
        "url": "https://twitter.com/davidwjohns1111",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2013.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1003201,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2040122,
        "name": "3luckysoab2 \ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc77\udb40\udc6c\udb40\udc73\udb40\udc7f",
        "url": "https://twitter.com/3luckysoab2",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 822.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2058189,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2050739,
        "name": "A AJZ",
        "url": "https://twitter.com/AAJZ17",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 796.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2068802,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "5": {
    "objects": [
      {
        "id": 198049,
        "name": "FOX SA",
        "url": "https://twitter.com/KABBFOX29",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 3,
        "audience": 87439.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1155402,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 45420,
        "name": "CBS Austin",
        "url": "https://twitter.com/cbsaustin",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 123880.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1048610,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 136620,
        "name": "ABC 13 News - WSET",
        "url": "https://twitter.com/ABC13News",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 55457.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1046666,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 136716,
        "name": "WLOS",
        "url": "https://twitter.com/WLOS_13",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 102877.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1046784,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 136984,
        "name": "NBC Montana",
        "url": "https://twitter.com/NBCMontana",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 13897.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1047130,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "6": {
    "objects": [
      {
        "id": 213246,
        "name": "Illinois girl Desi",
        "url": "https://twitter.com/d_ewinger",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 8355.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1170602,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "7": {
    "objects": [
      {
        "id": 41361,
        "name": "WHLT 22 Hattiesburg",
        "url": "https://twitter.com/WHLT22",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 9526.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1048169,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 198065,
        "name": "WKRG",
        "url": "https://twitter.com/WKRG",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 90501.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1155418,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 207502,
        "name": "ABC4 News",
        "url": "https://twitter.com/abc4utah",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 78425.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1164856,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 203263,
        "name": "DC News Now",
        "url": "https://twitter.com/DCNewsNow",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 30098.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1160617,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 201235,
        "name": "ABC22 & FOX44",
        "url": "https://twitter.com/WVNYWFFF",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 19593.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1158589,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "8": {
    "objects": [
      {
        "id": 87960764,
        "name": "Digital Soldier Back Channel \ud83d\udea8\ud83d\udea8\ud83d\udea8\ud83d\udea8\ud83d\udea8",
        "url": "https://t.me/digitalsoldierbackchannel",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 8246.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 88060903,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 47240,
        "name": "Citizen of EU",
        "url": "https://twitter.com/wavetossed",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 8126.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1076018,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 11.0,
          "name": "European Union",
          "code": "EU",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 85257477,
        "name": "Patriot's Creative Concepts",
        "url": "https://t.me/PatriotsCreativeConcepts",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 904.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 85357056,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 5762130,
        "name": "Alberto Rossi",
        "url": "https://twitter.com/Alberto93383706",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 1604.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 5852547,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 20.0,
          "name": "Italy",
          "code": "IT",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 8110997,
        "name": "NEWSFORCE \ud83c\udf0e",
        "url": "https://t.me/newsforce",
        "source_type": "TELEGRAM",
        "status": "ARCHIVED",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 9851.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 8201414,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "9": { "objects": [], "page": 1, "size": 5, "total": 0 },
  "10": {
    "objects": [
      {
        "id": 164894,
        "name": "Martyn Bradbury",
        "url": "https://twitter.com/CitizenBomber",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 3,
        "audience": 7893.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1126492,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 24.0,
          "name": "New Zealand",
          "code": "NZ",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 2051546,
        "name": "Ertu\u011frul Altun \ud83c\uddf9\ud83c\uddf7\ud83c\udde6\ud83c\uddff\ud83c\uddf5\ud83c\uddf0\ud83c\uddf2\ud83c\uddfb\ud83c\uddf9\ud83c\uddf3\ud83c\uddef\ud83c\uddf4\ud83c\uddf2\ud83c\uddf7\ud83c\udde9\ud83c\uddff",
        "url": "https://twitter.com/ferasetname",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 15272.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 2069609,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 31.0,
          "name": "Turkey",
          "code": "TR",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 49037,
        "name": "Mats Nilsson",
        "url": "https://twitter.com/mazzenilsson",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 24007.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1005186,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 84.0,
          "name": "South Africa",
          "code": "ZA",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 177760801,
        "name": "Aksa Tufan\u0131 \ud83c\udde6\ud83c\uddea | Anl\u0131k \ud83d\udd34",
        "url": "https://t.me/aksaanlik",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 1340.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 178012693,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 31.0,
          "name": "Turkey",
          "code": "TR",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 14989491,
        "name": "Misvak Caps",
        "url": "https://twitter.com/misvakcaps",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 15079908,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "11": {
    "objects": [
      {
        "id": 141914,
        "name": "John Morgan",
        "url": "https://twitter.com/johnmorganFL",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 32300.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1053203,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 145317,
        "name": "WendylDail",
        "url": "https://twitter.com/wendylwilson2",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 4026.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1057482,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 113020888,
        "name": "Korean Alpha EN",
        "url": "https://t.me/korean_alpha_en",
        "source_type": "TELEGRAM",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 1024.0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 113125575,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 87832015,
        "name": "osintcorpbot",
        "url": "https://t.me/osintcorpbot",
        "source_type": "TELEGRAM",
        "status": "ARCHIVED",
        "data": 0,
        "is_verified": null,
        "mentions_count": 0,
        "messages_count": 2,
        "audience": 0,
        "audience_type": null,
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 87932162,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 43647,
        "name": "Mario Nawfal",
        "url": "https://twitter.com/MarioNawfal",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": true,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 1660641.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": false,
        "suspended_by_platform": 0,
        "inauthentic_behavior": false,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1008109,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 34.0,
          "name": "United States",
          "code": "US",
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  },
  "12": {
    "objects": [
      {
        "id": 74560,
        "name": "Navdeep Singh Rajwanshi",
        "url": "https://twitter.com/TheGameVeda",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2202.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1083732,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 80506,
        "name": "Party of Wales Supporters",
        "url": "https://twitter.com/TLlanelli",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 3161.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1035979,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 136928,
        "name": "Will Alexander",
        "url": "https://twitter.com/Bill_C54",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 4342.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1047058,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 143282,
        "name": "\ud83d\udd49 Om Dr of \ud83d\udc99 - Humanist RenuMarley",
        "url": "https://twitter.com/QueenPhoenix1",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2127.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1054939,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      },
      {
        "id": 168652,
        "name": "Mark2c @antmarkc.bsky.social",
        "url": "https://twitter.com/AnthonyCockbur2",
        "source_type": "TWITTER",
        "status": "ACTIVE",
        "data": 0,
        "is_verified": false,
        "mentions_count": 0,
        "messages_count": 1,
        "audience": 2413.0,
        "audience_type": "SUBSCRIBERS",
        "role": null,
        "is_bot": null,
        "suspended_by_platform": 0,
        "inauthentic_behavior": null,
        "immature_account": null,
        "engagement": 0,
        "post_reach": 0,
        "fetcher_source_id": 1130241,
        "groups": 0,
        "affiliations": 0,
        "discreditations": 0,
        "date_created": 0,
        "origin_country": {
          "id": 0,
          "name": 0,
          "code": 0,
          "sources_count": 0
        },
        "tags": 0
      }
    ],
    "page": 1,
    "size": 5,
    "total": 15
  }
}
