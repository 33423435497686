import * as d3 from 'd3';
import { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader/Loader';
import { MessagesContext } from '../../contexts/MessagesContext';
import styles from '../../NarrativePage.module.scss';
import messageStyles from '../../../../components/MessagesTable/ExtendedMessageView.module.scss';
import {
  contentTypesMapping,
  contentTypesIconsMapping,
} from '../../../../utils/contentTypes';
import {
  platformNamesMapping,
  platformIconsMapping,
} from '../../../../utils/platforms';
import { decodeSourceName } from '../../../../utils/decodeURI';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { format, parseISO, set } from 'date-fns';
import { API, ShareAPI } from '../../../../API';
import { Link } from 'react-router-dom';
import { ExpandableText } from '../../../../components/CollapsibleText/ExpandableText';
import { ReactComponent as FollowersIcon } from '../../../../assets/followers.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/calendar.svg';
import { ReactComponent as ViewsIcon } from '../../../../assets/views.svg';
import { ReactComponent as EngagementIcon } from '../../../../assets/engagement.svg';
import { ReactComponent as ManipulationIcon } from '../../../../assets/manipulation.svg';
import { ReactComponent as SourceIcon } from '../../../SearchPage/assets/person.svg';
import { ReactComponent as ActorIcon } from '../../../SearchPage/assets/link.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/link.svg';
import { ReactComponent as OriginalTextIcon } from '../../../../assets/originalText.svg';
import { ReactComponent as TranslationIcon } from '../../../../assets/translation.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete.svg';
import { LoaderSmall } from '../../../../components/LoaderSmall/LoaderSmall';
import {
  getSentimentIcon,
  getSentimentName,
} from '../../../../utils/sentiment';
import { ampli } from '../../../../ampli';
import { useCurrentUser } from '../../../../contexts/CurrentUser';
import Switch from '../../../../components/Switch';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import Markdown from 'react-markdown';


const formatNumber = d3.format(',d');
const formatNumberSignificant = d3.format('.3~s');
const formatWithCustomGigaBillion = (value) => {
  const formattedValue = formatNumberSignificant(value);
  const formattedWithBillion = formattedValue.replace('G', 'B');
  return formattedWithBillion;
};

export const OriginalSource = ({
  narrative,
  isShare,
  notes,
  settings,
  onSettingsChange,
  onWidgetStatusChange,
}) => {
  const {
    messages,
    originalSources,
    deduplication,
    getMessages,
    fetchOriginalSources,
  } = useContext(MessagesContext);
  const { t } = useTranslation();
  const api = isShare ? ShareAPI : API;
  const [currentUser] = useCurrentUser();
  const [messagesList, setMessagesList] = useState(null);
  const [widgetSettings, setWidgetSettings] = useState(settings);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [showHighlight, setShowHighlight] = useState(true);
  const [note, setNote] = useState(notes?.length > 0 ? notes[0] : {text: "", position: "bottom"});
  const [isNote, setIsNote] = useState(notes?.length > 0);
  const [title, setTitle] = useState(widgetSettings.title || t('Original source'));
  const componentRef = useRef(null);

  useEffect(() => {
    setWidgetSettings(settings);
    let newTitle = settings?.title?.length > 0 ? settings.title : t('Original source');
    setTitle(newTitle);
  }, [settings]);

  const featureFlags = currentUser?.workspace?.config.featureflags;
  const showMessageManipulationIndex = featureFlags?.includes(
    'show_manipulation_index',
  );

  useEffect(() => {
    if (!messages) {
      getMessages(narrative, null, 'date_publicated', null, deduplication, 1);
    }
    fetchOriginalSources(narrative, narrative.original_source_ids);
  }, []);

  useEffect(() => {
    if (originalSources) {
      setMessagesList(originalSources?.objects);
    } else if (messages?.objects?.length > 0) {
      setMessagesList(messages?.objects?.slice(0, 1));
    }
  }, [originalSources, messages]);

  if (narrative.type === 'IMPACT_ASSESSMENT') {
    return (
      <div className="report-section">
        <div className={styles.descriptionCard}>
          {t(
            'This type of case does not provide an original source information.',
          )}
        </div>
      </div>
    );
  }

  if (!messages && !originalSources) {
    return <Loader />;
  }

  const translateOriginalSource = (
    messageId,
    text,
    nativeLang,
    sourceLang = '',
  ) => {
    let body;
    if (sourceLang) {
      body = {
        text: text,
        destination_language: nativeLang,
        source_language: sourceLang,
      };
    } else {
      body = {
        text: text,
        destination_language: nativeLang,
      };
    }
    api
      .fetch('POST', '/API/v1/translations/translate', null, body)
      .then((data) => {
        setMessagesList(
          messagesList.map((message) => {
            if (message.id === messageId) {
              return {
                ...message,
                isTranslation: true,
                translated: data.destination_text,
              };
            } else {
              return message;
            }
          }),
        );
      })
      .catch((e) => {
        setMessagesList(
          messagesList.map((message) => {
            if (message.id === messageId) {
              return {
                ...message,
                isTranslation: true,
                translated: t('We were not able to translate this text.'),
                error: true,
              };
            } else {
              return message;
            }
          }),
        );
      });
  };

  const toggleTranslate = (messageId, isTranslation) => {
    setMessagesList(
      messagesList.map((message) => {
        if (message.id === messageId) {
          return {
            ...message,
            isTranslation: isTranslation,
          };
        } else {
          return message;
        }
      }),
    );
  };

  const handleTranslation = (messageId, text, isTranslation) => {
    const target = messagesList.find((message) => message.id === messageId);

    if (target.translated) {
      toggleTranslate(messageId, isTranslation);
    } else {
      const nativeLanguage = narrative.parameters?.translate_keywords_query
        ? narrative.parameters?.keywords_query_origin_language
        : window.clientInformation.language.split('-', 1)[0];
      toggleTranslate(messageId, true);
      translateOriginalSource(messageId, text, nativeLanguage);

      ampli.track({
        event_type: 'Translate original source',
        event_properties: {
          user_id: currentUser?.id,
          workspace_id: currentUser?.workspace_id,
          narrative_id: narrative.id,
        },
      });
    }
  };

  if (isEditingMode) {
    return (
      <div ref={componentRef} className="report-cection">
        <div className="widget-settings-container">
          <div className="widget-settings-title">{t('Original source')}</div>
          <form
            onSubmit={() => {
              let newNotes;
              setIsEditingMode(false);
              if (note.text.length > 0) {
                newNotes = [note];
              } else {
                newNotes = [];
              }
              onSettingsChange(widgetSettings, newNotes, componentRef.current);
            }}
            onReset={() => {
              componentRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
              setTitle(settings.title || t('Original source'));
              setIsEditingMode(false);
              setWidgetSettings(settings);
              setNote(
                notes?.length > 0 ? notes[0] : { text: '', position: 'bottom' },
              );
            }}
          >
            <div className="form-element">
              <label htmlFor="title">{t('Title')}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setWidgetSettings({
                    ...widgetSettings,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="full-text" className={styles.label}>
                {t('Show full text')}
              </label>
              <Switch
                id="full-text"
                value={widgetSettings.show_full_text}
                onChange={(value) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    show_full_text: value,
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="text-length">{t('Text length(characters)')}</label>
              <input
                type="number"
                id="text-length"
                value={widgetSettings.text_length}
                disabled={widgetSettings.show_full_text}
                onChange={(e) => {
                  setWidgetSettings({
                    ...widgetSettings,
                    text_length: parseInt(e.target.value),
                  });
                }}
              />
            </div>
            <div className="form-element">
              <label htmlFor="highlights">{t('Highlights')}</label>
              <ul>
                <li>
                  <input
                    id="show_highlights"
                    type="checkbox"
                    checked={showHighlight}
                    onChange={() => {
                      setShowHighlight(!showHighlight);
                    }}
                  />
                  <label htmlFor="show_highlights">
                    {t('Show highlights')}
                  </label>
                </li>
              </ul>
            </div>

            {narrative.type !== 'IMPACT_ASSESSMENT' && messagesList ? (
              <div className="form-element">
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      id="platforms"
                      checked={isNote}
                      onChange={() => {
                        setIsNote(!isNote);
                      }}
                    />
                    <label htmlFor="create-note">{t('Create a note')}</label>
                  </li>
                </ul>
              </div>
            ) : (
              ''
            )}
            {isNote ? (
              <div className="form-element">
                <div className="form-element">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="platforms"
                        checked={note?.position === 'top'}
                        onChange={() => {
                          if (note?.position === 'top') {
                            setNote({ ...note, position: 'bottom' });
                          } else {
                            setNote({ ...note, position: 'top' });
                          }
                        }}
                      />
                      <label htmlFor="note-position">
                        {t('Place a note on the top of the widget')}
                      </label>
                    </li>
                  </ul>
                </div>

                <label htmlFor="note">{t('Note')}</label>
                <TextEditor
                  id={'note-editor'}
                  value={note.text}
                  onChange={(text) => setNote({ ...note, text: text })}
                  isOpen={true}
                />
              </div>
            ) : (
              ''
            )}

            <div className="widget-settings-controls">
              <button type="submit" className="new-button">
                {t('Apply')}
              </button>
              <button type="reset" className="btn-reset">
                {t('Cancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={componentRef} className="widget-container">
        {narrative.type !== 'IMPACT_ASSESSMENT' && messagesList ? (
          <div className="report-section">
            <div className="report-section__header">
              <h3>
                {widgetSettings.title
                  ? widgetSettings.title
                  : t('Original source')}
              </h3>
              <div className="report-section__controls">
                <button
                  className="report-control-button"
                  onClick={() => setIsEditingMode(true)}
                >
                  <EditIcon />
                </button>
                <button
                  className="report-control-button danger"
                  onClick={() => onWidgetStatusChange(false)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>

            {notes && notes?.length > 0
              ? notes.map((note) => {
                  return (
                    <>
                      {note.position === 'top' ? (
                        <div className="report-note-container note-position-top">
                          <Markdown>{note.text}</Markdown>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })
              : ''}

            <table
              className={`${messageStyles.extendedMessages} ${styles.extendedMessages} ${messageStyles.pdfMode}`}
            >
              <tbody>
                {messagesList.map((message, index) => {
                  let followers_cnt = message?.source?.audience;
                  let textClass = styles.extendedMessage;

                  if (message.error && message.isTranslation) {
                    textClass = `${styles.extendedMessage} ${messageStyles.error}`;
                  } else if (message.error && !message.isTranslation) {
                    textClass = styles.extendedMessage;
                  }

                  return (
                    <tr key={`message.message_url_${index}`}>
                      <td>
                        <div className={messageStyles.messageInfoWrapper}>
                          <div className={messageStyles.messageInfo}>
                            {message.content_type ? (
                              <div className={messageStyles.contentType}>
                                <Tooltip
                                  content={t(
                                    contentTypesMapping[message.content_type],
                                  )}
                                  position="bottom"
                                >
                                  {
                                    contentTypesIconsMapping[
                                      message.content_type
                                    ]
                                  }
                                </Tooltip>
                              </div>
                            ) : (
                              ''
                            )}
                            <div className={messageStyles.date}>
                              <span className={messageStyles.anchor}>
                                <Tooltip
                                  content={t('Publication date')}
                                  position="bottom"
                                >
                                  <CalendarIcon />
                                </Tooltip>
                              </span>
                              {format(
                                parseISO(message.date_publicated + 'Z'),
                                'dd LLL yyyy HH:mm',
                              )}
                            </div>
                            <div className={messageStyles.platform}>
                              <span className={messageStyles.anchor}>
                                <Tooltip
                                  content={
                                    platformNamesMapping[
                                      message.source.source_type
                                    ]
                                  }
                                  position="bottom"
                                >
                                  {
                                    platformIconsMapping[
                                      message.source.source_type
                                    ]
                                  }
                                </Tooltip>
                              </span>
                              {followers_cnt ? (
                                <span className={messageStyles.anchor}>
                                  <Tooltip
                                    content={t('Followers')}
                                    position="bottom"
                                  >
                                    <FollowersIcon />
                                  </Tooltip>
                                  <span>
                                    {formatNumberSignificant(followers_cnt)}
                                  </span>
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className={messageStyles.sourceActorWrapper}>
                              <span className={messageStyles.anchor}>
                                <Tooltip
                                  content={
                                    message.source === null
                                      ? t('N/A')
                                      : decodeSourceName(message.source?.name)
                                  }
                                  position="bottom"
                                >
                                  <SourceIcon />
                                  <span className={messageStyles.cutText}>
                                    {isShare ? (
                                      decodeSourceName(message.source?.name)
                                    ) : message.source === null ? (
                                      t('N/A')
                                    ) : (
                                      <Link
                                        to={`/sources/${message.source?.id}`}
                                      >
                                        {decodeSourceName(message.source?.name)}
                                      </Link>
                                    )}
                                  </span>
                                </Tooltip>
                                {message.actor &&
                                message.actor.id !== message.source?.id ? (
                                  <Tooltip
                                    content={decodeSourceName(
                                      message.actor?.name,
                                    )}
                                    position="bottom"
                                  >
                                    <ActorIcon />
                                    <span className={messageStyles.cutText}>
                                      {
                                        <Link
                                          to={`/sources/${message.actor?.id}`}
                                        >
                                          {decodeSourceName(
                                            message.actor?.name,
                                          )}
                                        </Link>
                                      }
                                    </span>
                                  </Tooltip>
                                ) : (
                                  ''
                                )}
                                <a
                                  href={message.url}
                                  className={messageStyles.externalLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LinkIcon />
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${messageStyles.messageContainer} ${styles.messageContainer}`}
                        >
                          <div
                            className={messageStyles.messageMetrics}
                            style={{ justifyContent: 'left' }}
                          >
                            {message.sentiment_score !== null ? (
                              <Tooltip
                                content={t(
                                  getSentimentName(message.sentiment_score),
                                )}
                                position="bottom"
                              >
                                {getSentimentIcon(message.sentiment_score)}
                              </Tooltip>
                            ) : (
                              ''
                            )}
                            <span className={messageStyles.anchor}>
                              <Tooltip content={t('Views')} position="bottom">
                                <ViewsIcon />
                              </Tooltip>
                              {formatNumberSignificant(message.impressions)}
                            </span>
                            <span className={messageStyles.anchor}>
                              <Tooltip
                                content={t('Reactions')}
                                position="bottom"
                              >
                                <EngagementIcon />
                              </Tooltip>
                              {/* engagement */}
                              {formatNumberSignificant(message.engagement)}
                            </span>
                            {showMessageManipulationIndex ? (
                              <span className={messageStyles.anchor}>
                                <Tooltip
                                  content={t('Manipulation')}
                                  position="bottom"
                                >
                                  <ManipulationIcon />
                                </Tooltip>
                                {message.manipulation_index
                                  ? message.manipulation_index.toFixed(2)
                                  : '0'}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {message.isTranslation === true &&
                            !message.translated ? (
                              <div>
                                <LoaderSmall />
                              </div>
                            ) : (
                              <ExpandableText
                                length={settings?.text_length}
                                highlights={['KEYWORDS']}
                                text={
                                  message.isTranslation
                                    ? message.translated
                                    : showHighlight ? (message.highlighted_text ?  message.highlighted_text : message.text) : message.text
                                }
                                textClassName={textClass}
                                showFullText={settings?.show_full_text}
                                onExpand={(expanded) => {
                                  if (expanded) {
                                    ampli.track({
                                      event_type: 'Expand original source',
                                      event_properties: {
                                        user_id: currentUser?.id,
                                        workspace_id: currentUser?.workspace_id,
                                        narrative_id: narrative.id,
                                      },
                                    });
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="message-controlls">
                        <div className={messageStyles.translationWrapper}>
                          <span
                            className={
                              message?.isTranslation ? '' : messageStyles.active
                            }
                            onClick={() =>
                              handleTranslation(message.id, message.text, false)
                            }
                          >
                            <OriginalTextIcon />
                          </span>
                          <span
                            className={
                              message?.isTranslation ? messageStyles.active : ''
                            }
                            onClick={() =>
                              handleTranslation(message.id, message.text, true)
                            }
                          >
                            <TranslationIcon />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {notes && notes.length > 0
              ? notes.map((note) => {
                  return (
                    <>
                      {note.position === 'bottom' ? (
                        <div className="report-note-container note-position-bottom">
                          <Markdown>{note.text}</Markdown>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })
              : ''}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
};
