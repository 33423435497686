import { useTranslation } from 'react-i18next';
import { useState, useEffect} from 'react';
import { SummaryMainCard } from './SummaryMainCard';
import { SummaryCardSmall } from './SummaryCardSmall';
import { metricsMap } from '../../utils/metrics';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { API, ShareAPI } from '../../API';
import { summaryData } from './summary';

import styles from './SummaryPanel.module.scss';


export const SummaryPanel = ({
  narrativeIds,
  tabsList = ['actors', 'publications', 'views', 'reactions'],
  isPDF = false,
  isShare,
  isStory=false,
}) => {
  const { t } = useTranslation();
  const [summary, setSummary] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const fetchSummary = () => {
    const api = isShare ? ShareAPI : API;
    const urlParams = new URLSearchParams();

    narrativeIds.forEach((narrative_id) =>
      urlParams.append('narrative_ids', narrative_id),
    );

    let url = `/API/v1/dashboard/narratives/summary?${urlParams.toString()}`;
    // if (isStory) {
    //   url = `/API/v1/dashboard/stories/summary?${urlParams.toString()}`;
    // }
    api.fetch('GET', url)
    .then((data) => {
      if (isStory) {
        data = summaryData[narrativeIds[0]];
      }
      setSummary(data);
    });
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  useEffect(() => {
    if (isStory) {
      setSummary(summaryData[narrativeIds[0]])
    }
  }, [narrativeIds]);

  if (!summary) {
    return (
      <div className={`${styles.summaryPanel} ${styles.loader}`}>
        <LoaderSmall />
      </div>
    );
  }

  const value = summary[tabsList[tabIndex]]?.breakdown || {};

  let currentBreakdown = Object.keys(value)
    .filter((metric) => value[metric].total)
    .reduce((obj, key) => {
      obj[key] = value[key];
      return obj;
    }, {});

  if (tabsList[tabIndex] === 'actors') {
    currentBreakdown = value;
  }


  return (
    <div className={styles.summaryPanel}>
      <div className={styles.summaryPanelCards}>
        {tabsList.map((tab, index) => {
          return (
            <SummaryMainCard
              title={tab}
              data={summary[tab].total}
              isActive={
                tabsList.length > 1 && tabIndex === index ? true : false
              }
              onClick={() => setTabIndex(index)}
              key={`summary_main_card_${tab}`}
            />
          );
        })}
      </div>
      <div
        className={
          Object.keys(currentBreakdown).length > 3
            ? styles.summaryPanelBody
            : `${styles.summaryPanelBody} ${styles.center}`
        }
      >
        {Object.keys(currentBreakdown).length > 0 ? (
          Object.entries(currentBreakdown).map(([key, value]) => {
            return (
              <SummaryCardSmall
                title={metricsMap[key]}
                data={value.total}
                key={`summary_small_card_${key}`}
              />
            );
          })
        ) : isPDF ? (
          ''
        ) : (
          <div className={styles.noData}>
            {t('There is no data to display')}
          </div>
        )}
      </div>
    </div>
  );
};
